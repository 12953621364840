import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmployeeDatasetToCreate } from '@services/employee/employee-service.interface';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EmpDataFields } from 'src/app/model/interfaces/data-mapping.interface';
import { IDataset, SyncFileDataWithEmployees } from 'src/app/model/interfaces/dataset.interface';
import { IResponse, IResponseData, IResponseMessage, ResponseData } from 'src/app/model/interfaces/response.interface';
import {
  ISignatureDbField,
  ISignatureDbFieldValue,
  ISignaturePlaceholderValueImg
} from 'src/app/model/interfaces/signature.interface';
import { IUserImage } from 'src/app/model/interfaces/user.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { environment } from 'src/environments/environment';
import { INTERCOM_DATA } from '../intercom/intercom-content';
import { IntercomService } from '../intercom/intercom.service';
import { IDatasetGetDefaultDataSets, IImageObject } from './dataset-service.interface';

@Injectable({
  providedIn: 'root'
})
export class DatasetService {
  constructor(private http: HttpClient, private intercomService: IntercomService, private operator: CustomOperators) {}

  /**
   * Gets the default datasets if there is no selected.
   * Soft error handling because dataset should always be set.
   * @returns Observable containing default datasets
   */
  getDefaultDataSets(): Observable<IDatasetGetDefaultDataSets> {
    return this.http
      .get<IResponseData<IDatasetGetDefaultDataSets>>('/datasets/companyanduser/default')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets all datasets available for the admin's company
   * @returns Observable containing company datasets
   */
  getAllCompany(): Observable<IDataset[]> {
    return this.http.get<IResponseData<IDataset[]>>('/datasets/company/all').pipe(this.operator.extractResponseData());
  }

  /**
   * Gets all datasets which are available for the users of the admin.
   * It is not a dataset for a specific user.
   * @returns
   */
  getAllUser(): Observable<IDataset[]> {
    return this.http.get<IResponseData<IDataset[]>>('/datasets/user/all').pipe(this.operator.extractResponseData());
  }

  /**
   * Creates a specific user data set entry for a user set definition
   * @param employeeId
   * @param dataToSave
   * @param datasetDefinition
   * @returns
   */
  createSpecificSetForUser(employeeId: string, datasetDefinition: string): Observable<IDataset> {
    return this.http
      .get<ResponseData<IDataset>>(`/datasets/user/specific/${employeeId}/${datasetDefinition}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the dataset from the specific `id`.
   * @param id - Dataset id
   * @returns Observable of dataset
   */
  getOne(id: string): Observable<IDataset> {
    return this.http.get<IResponseData<IDataset>>(`/datasets/byid/${id}`).pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the dataset entry for a specific user for a specific datasetdefinition
   * @param userId - User id
   * @param datasetDefinitionId - Dataset definition id
   * @returns Observable of dataset for a specific user and dataset definition
   */
  getOneSpecificForUser(userId: string, datasetDefinitionId: string): Observable<IDataset> {
    return this.http
      .get<IResponseData<IDataset>>('/datasets/user/specific/' + userId + '/' + datasetDefinitionId)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Creates a new company data profile
   * @param title - Title of the new dataset
   * @returns Observable containing the new dataset
   */
  createCompanySet(title: string): Observable<IDataset> {
    return this.http
      .post<IResponseData<IDataset>>('/datasets/company', { title })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Creates a new user data definition entry
   * @param title - Title of the new dataset
   * @returns Observable containing the new dataset
   */
  createUserSetdefinition(title: string): Observable<IDataset> {
    return this.http
      .post<IResponseData<IDataset>>('/datasets/user', { title })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Create multiple datasets for an employee
   * @param userId - User id
   * @param datasets - Array of dataset objects to save of the employee
   * @returns Observable containing the new datasets
   */
  createMultipleForUser(userId: string, datasets: EmployeeDatasetToCreate[]): Observable<IDataset> {
    return this.http
      .post<IResponseData<IDataset>>(`/datasets/user/multiple/${userId}`, { datasets })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates the title and data of a dataset with the given id
   * @param id - Dataset id to update
   * @param data - New dataset data
   * @param title - Dataset title
   * @returns Observable of true
   */
  update(id: string, data: ISignatureDbFieldValue | ISignatureDbField, title: string): Observable<IResponseMessage> {
    return this.http.put<IResponseMessage>(`/datasets/${id}`, { data, title }).pipe(
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.user_data_changed);
      }),
      map(value => {
        if (!value.success) throw new Error(value.message);
        return value;
      })
    );
  }
  /**
   * Deletes the dataset with the given id
   * @param id - Dataset id to update
   * @returns Observable of true
   */
  // TODO: Add new interface - can return signatures or sftp connection
  delete(id: string): Observable<IResponseMessage> {
    return this.http.delete<IResponseMessage>(`/datasets/${id}`).pipe(this.operator.extractResponseMessage());
  }

  /**
   * Checks if a given dataset contains a profile photo
   * @param dataset - Dataset to check for profile photo
   * @returns Flag indicating if user has image and image (if available)
   */
  getUserImageStatus(dataset: IDataset): IUserImage {
    if (dataset) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const datasetData = JSON.parse(dataset.data || ''); // as IEmployeeSignaturePlaceholders;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const imageData = datasetData.ma_foto as ISignaturePlaceholderValueImg;

        if (imageData && typeof imageData.image === 'string' && imageData.image.length) {
          return {
            userHasImage: Boolean(imageData.image || imageData.whichImage === 'own'),
            userImage: imageData.image + '?force=true'
          };
        }
      } catch (error) {
        return { userHasImage: false, userImage: undefined };
      }
    }
    return { userHasImage: false, userImage: undefined };
  }

  /**
   * Makes a copy of a dataset. ONLY works for the company dataset
   * @param id - Dataset id to copy
   * @returns Observable of true
   */
  makeCopyCompany(id: string): Observable<IResponseMessage> {
    return this.http
      .post<IResponseMessage>(`/datasets/company/copy/${id}`, null)
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Makes a copy of a user dataset
   * This means from the definition of the dataset AND from every single user dataset
   * @param id - Dataset id to copy
   * @returns Observable of true
   */
  makeCopyUser(id: string): Observable<IResponseMessage> {
    return this.http
      .post<IResponseMessage>(`/datasets/user/copy/${id}`, null)
      .pipe(this.operator.extractResponseMessage());
  }

  /**
   * Syncs the user data from the file
   * @param dataAsJson - The uploaded file data
   * @param datasetDefinitionId - The selected dataset id
   * @param handleGroups - The type of how to update the groups from uploaded file
   * @param overwriteWithEmpty - The trigger of overwrite with empty fields or not
   * @returns Observable of synced data
   */
  syncUserDataFromFile(
    dataAsJson: EmpDataFields[],
    datasetDefinitionId: string,
    handleGroups: string,
    overwriteWithEmpty: boolean
  ): Observable<SyncFileDataWithEmployees> {
    const obj = {
      dataAsJson,
      datasetDefinitionId,
      handleGroups,
      overwriteWithEmpty
    };
    return this.http
      .put<IResponseData<SyncFileDataWithEmployees>>('/datasets/user/sync/file', obj)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Creates a CSV export of the dataset
   * @param datasetId - The id of the dataset to export
   * @param language - The language to export in
   * @returns Observable containing the exported csv
   */
  createCSVExport(datasetId: string, language: string): Observable<string> {
    return this.http
      .post<IResponseData<string>>('/datasets/user/export/file', { datasetId, language })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates multiple user photos
   * @param photoObjects - Objects containing photo and dataset to update
   * @returns Observable of true
   */
  updateMultipleUserPhotos(photoObjects: IImageObject[]): Observable<true> {
    return this.http
      .post<IResponse>('/datasets/multipleUserPhotos', { photoObjects })
      .pipe(this.operator.extractResponse());
  }
}
