import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mt-product-container-modal-price',
  templateUrl: './product-container-modal-price.component.html',
  styleUrls: ['./product-container-modal-price.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductContainerModalPriceComponent {
  /**
   * Input of cost per user
   */
  @Input() costPerUser!: number;

  /**
   * Input of current amount of system users
   */
  @Input() currentAmountOfSystemUsers!: number;

  /**
   * Input of product title
   */
  @Input() tariff!: string;
}
