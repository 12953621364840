<div class="target-group-entry-number-col" *ngIf="item.Entries">
  <div *ngIf="item.numberOfEntries > 1" class="title">
    <div class="innertitle" style="overflow: hidden">
      <span
        style="cursor: default"
        tooltipClass="status-info-tooltip"
        placement="top"
        [ngbTooltip]="multipleEntries"
      >
        <span class="mt-mediumgrey">
          <mt-svg
            class="medium-svg"
            [ngClass]="iconColor"
            src="assets/images/svgs/domain_list_svg.svg"
          ></mt-svg>
          <span> {{ item.numberOfEntries }} </span>
          <span
            [innerHTML]="
              item.type === 'domain'
                ? ('account_s' | transloco)
                : ('contact_s' | transloco)
            "
          ></span>
        </span>
      </span>
    </div>
  </div>

  <div
    *ngIf="item.numberOfEntries === 1"
    class="title"
    ngClass="{'mt-blau': !disableLinks, 'mt-mediumgrey': disableLinks}"
  >
    <div class="innertitle" style="overflow: hidden">
      <mt-svg
        class="medium-svg"
        [ngClass]="iconColor"
        src="assets/images/svgs/domain_list_svg.svg"
      >
      </mt-svg>
      <a
        *ngIf="!disableLinks"
        class="link mt-darkgrey ms-1"
        style="line-height: 1; vertical-align: top"
        (click)="$event.stopPropagation()"
        tooltipClass="status-info-tooltip"
        placement="top"
        ngbTooltip="{{ item.Entries[0].content }}"
        [routerLink]="['/domaindetails', item.Entries[0].id]"
        transloco="1_domain"
      ></a>
      <span
        *ngIf="disableLinks"
        style="line-height: 1; vertical-align: top"
        (click)="$event.stopPropagation()"
        tooltipClass="status-info-tooltip"
        placement="top"
        ngbTooltip="{{ item.Entries[0].content }}"
        transloco="1_domain"
      ></span>
    </div>
  </div>

  <div
    *ngIf="item.Entries.length === 0"
    class="title italic"
    [ngClass]="{ schrift_rot: !timingError }"
    style="cursor: default; overflow: hidden"
  >
    <span transloco="no_domains_assigned"></span>
  </div>

  <div class="subline">
    <span
      [translate]="
        item.source && item.source !== 'manual'
          ? 'imported_from_' + item.source
          : 'target_group_type_manual'
      "
    ></span>
  </div>

  <ng-template #multipleEntries>
    <div *ngFor="let entry of item.Entries.slice(0, 10)" class="mt-white">
      <mt-svg
        class="medium-svg mt-darkgrey"
        [ngClass]="'iconColor'"
        src="assets/images/svgs/domain_list_svg.svg"
      ></mt-svg>
      <span class="grouptitle ms-1">{{ entry.content }}</span>
    </div>
    <div *ngIf="item.numberOfEntries > 10" class="mt-white text-right">
      <span class="grouptitle" style="line-height: 1; vertical-align: top"
        >...{{ item.numberOfEntries - 10 }}
        <span [innerHTML]="'sort_titel' | transloco"></span
      ></span>
    </div>
  </ng-template>
</div>
