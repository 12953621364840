import { Component, Input } from '@angular/core';

import { NgbActiveModal, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';

import { skip, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { CampaignService } from 'src/app/services/campaign/campaign.service';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';

import { ICampaignGetAllBy, IParams } from 'src/app/services/campaign/campaign-services.interface';
import { TargetSender } from 'src/app/model/enums/target-sender.enum';
import { ReplaySubject } from 'rxjs';
import { environment } from '@environments/environment';
import { SearchService } from '@services/search/search.service';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoModule } from '@ngneat/transloco';
import { ModalService } from '@services/modal/modal.service';
import { EmptyStateComponent } from '@organisms/empty-states/empty-state.component';
import { TagColComponent } from '@organisms/cols/tag-col/tag-col.component';
import { CampaignColComponent } from '@organisms/cols/campaign-col/campaign-col.component';
import { SelectColComponent } from '@organisms/cols/select-col/select-col.component';
import { RowComponent } from '@atoms/row/row.component';
import { ListComponent } from '@atoms/list/list.component';
import { SortDropdownComponent } from '@molecules/buttons/sort-dropdown/sort-dropdown.component';
import { MtSearchComponent } from '@atoms/inputs/search/search.component';
import { ListActionsComponent } from '@atoms/list-actions/list-actions.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { EDataType } from '@model/enums/data-type.enum';

/**
 * Modal used to assign a campaign to a group, target group, event, etc
 */
@Component({
  selector: 'mt-assign-campaign-modal',
  templateUrl: './assign-campaign-modal.component.html',
  styleUrls: ['./assign-campaign-modal.component.scss'],
  providers: [ListHelperService, SearchService],
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CampaignColComponent,
    CustomModalComponent,
    EmptyStateComponent,
    ListActionsComponent,
    ListComponent,
    MtSearchComponent,
    NgbDropdownItem,
    NgFor,
    NgIf,
    RowComponent,
    SelectColComponent,
    SortDropdownComponent,
    TagColComponent,
    TranslocoModule
  ]
})
export class AssignCampaignModalComponent {
  isCognismSignatures = environment.isCogSig;

  constructor(
    private campaignService: CampaignService,
    private modalService: ModalService,
    private router: Router,
    private translateService: TranslocoService,
    public list: ListHelperService<ICampaignGetAllBy>,
    public modal: NgbActiveModal,
    public searchService: SearchService
  ) {}

  /**
   * Id of currently selected campaign, if any
   */
  @Input() selectedCampaign?: number;

  /**
   * Type of campaigns to load
   */
  @Input() set campaignType(type: TargetSender) {
    this._campaignType$.next(type);
  }

  private _campaignType$ = new ReplaySubject<TargetSender>(1);
  campaignType$ = this._campaignType$.asObservable();

  params: IParams = {};

  loadCampaigns$ = this.campaignType$.pipe(
    switchMap(type => this.campaignService.getAllBy({ type })),
    tap(campaigns => {
      this.list.all = campaigns;

      const selected = this.list.all.find(campaign => campaign.id === this.selectedCampaign);
      if (selected) {
        this.list.selected.push(selected);
      }

      this.list.selectionType = 'single';
      this.list.setFiltered();
    })
  );

  // Filters list by setting the search term on the query object
  filterList$ = this.searchService.filterText$.pipe(
    skip(1), // Skip the first emition, otherwise same data is loaded twice
    tap(text => {
      this.list.filterText = text; // prevents long hold Enter key pressed
      this.list.all = this.list.filtered.filter(data => data.title.toLowerCase().includes(text.toLowerCase()));
      this.list.createFraction(this.list.all.length);
    })
  );

  /**
   * Opens the campaign creation modal
   */
  openCreateCampaign(): void {
    if (this.isCognismSignatures) {
      void this.router.navigate(['/campaigns/create/sender']);
    } else {
      let link = '';
      if (this.translateService.getActiveLang() === 'en')
        link = 'https://help.mailtastic.com/en/articles/2669049-what-are-campaigns';
      else if (this.translateService.getActiveLang() === 'de')
        link = 'https://help.mailtastic.com/de/articles/2669049-was-sind-kampagnen';

      const modalInputData = {
        link,
        linkText: 'about_campaign_types',
        routeSender: '/campaigns/create/sender/',
        routeTarget: '/campaigns/create/target/',
        textSender: 'sender_based_info',
        textTarget: 'target_group_based_info',
        title: 'what_kind_of_campaign'
      };
      this.modalService.openCreateEventOrCampaignModal(EDataType.CAMPAIGN, modalInputData);
    }
    this.modal.close();
  }
}
