<ng-container *transloco="let translate"
  ><mt-custom-modal
    [headline]="translate('edit_image')"
    [closeBtn]="false"
    *ngIf="photoAndStatus$ | async as companyLogo"
  >
    <div [formGroup]="advancedSettingsForm" modal-body>
      <div class="container-fluid text-center">
        <div data-hj-suppress class="row mt-5 justify-content-center">
          <div class="col-6 justify-content-center mx-auto">
            <div class="image-displayed">
              <ng-container
                *ngIf="
                  companyLogo.currentPhoto.whichImage === 'default' ||
                    !companyLogo.currentPhoto.image;
                  else showPhoto
                "
              >
                <div class="no-photo-preview">
                  <mt-svg
                    src="assets/images/svgs/image.svg"
                    size="150"
                    filter="--filter-white"
                  ></mt-svg>
                </div>
              </ng-container>
              <ng-template #showPhoto>
                <img
                  class="uploaded_photo"
                  [src]="companyLogo.currentPhoto.image"
                  alt="uploaded_photo"
                  [ngStyle]="{
                    'border-radius':
                      companyLogo.currentPhoto.cropType === 'circle' &&
                      companyLogo.currentPhoto.isCropped
                        ? '50%'
                        : '0%'
                  }"
              /></ng-template>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col col-6 justify-content-center mx-auto">
            <mt-upload-image-button
              (loadImage)="processImage($event)"
              [max-width]="1500"
              [max-size-mb]="2"
              [buttonTitle]="
                companyLogo.currentPhoto.whichImage === 'own'
                  ? 'change_photo_btn_2'
                  : 'uploade_photo_btn_2'
              "
              style="display: inline-flex"
            ></mt-upload-image-button>
            <mt-icon-button
              *ngIf="companyLogo.currentPhoto.whichImage === 'own'"
              class="mt_left ms-2"
              text="remove_photo_btn_2"
              color="white"
              style="display: inline-flex"
              (click)="deleteLogo()"
            ></mt-icon-button>
          </div>
        </div>

        <div class="row mt-3">
          <div
            class="campaign-banner-props col-6 justify-content-center mx-auto"
          >
            <div class="info-text">
              <span>{{ translate('max_image_size_info') }}</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="container-fluid"
        *ngIf="
          advancedSettingsForm.controls.whichImage.value === 'own' &&
          showCustomDimension
        "
      >
        <div class="row">
          <div class="col-12">
            <div role="separator" class="line full-width-imp"></div>

            <div class="form-group martop25">
              <label
                >{{ translate('Sizeofthepermanentlysetaftersavingthesignature')
                }}</label
              >
            </div>

            <div class="imageprevarea flex">
              <div class="formarea">
                <div class="radio top">
                  <label class="container-radio">
                    <input
                      type="radio"
                      formControlName="imageDimensionMode"
                      value="default"
                    />
                    <span class="checkmark-radio"></span>
                    <span>{{ translate('originalsize') }}</span>
                  </label>
                </div>
                <div class="radio bottom">
                  <label class="container-radio">
                    <input
                      type="radio"
                      value="custom"
                      formControlName="imageDimensionMode"
                    />
                    <span class="checkmark-radio"></span>
                    <span>{{ translate('Manually') }}</span>
                  </label>
                </div>

                <div
                  *ngIf="
                    advancedSettingsForm.controls.imageDimensionMode.value ===
                    'custom'
                  "
                  class="form-group"
                >
                  <label>{{ translate('Widthinpx') }}</label>
                  <input
                    formControlName="imageDimensionWidth"
                    type="text"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mt-2">
        <div class="row">
          <div role="separator" class="line full-width-imp"></div>
          <div class="col-8">
            <p class="font-weight-bold">{{ translate('advanced_settings') }}</p>

            <div class="photo-link form-floating mt-3">
              <input
                type="text"
                class="form-control"
                id="input_link_photo_url"
                placeholder="{{ translate('link_photo_url_example') }}"
                formControlName="photoUrl"
              />
              <label for="input_link_photo_url" class="form-label fw-normal"
                >{{ translate('link_photo_url') }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container modal-footer>
      <div class="d-flex flex-row-reverse">
        <div class="d-flex">
          <mt-button
            color="default"
            text="abort"
            class="leftbutton"
            (click)="modal.close(companyLogo.initialPhoto)"
          ></mt-button>
          <mt-button
            color="primary"
            text="Save"
            (click)="saveLogo(companyLogo.currentPhoto, companyLogo.isNewPhoto)"
          ></mt-button>
        </div>
      </div>
    </ng-container> </mt-custom-modal
></ng-container>
