import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

// Interfaces
import { ConnectionSyncDisconnectType } from 'src/app/services/azure-sync/azure-sync.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-cancel-connection',
  templateUrl: './cancel-connection.component.html',
  styleUrls: ['./cancel-connection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, CustomModalComponent, TranslocoModule, FormsModule, ReactiveFormsModule, AsyncPipe, ButtonComponent]
})
export class CancelConnectionComponent {
  /**
   * Modal information
   * @defaultValue inputData - {
   *   cancelConnectionQuestion: '',
   *   credentialTextTag: '',
   *   header: '',
   *   keepEmployeeInformation: '',
   *   removeEmployeeInformation: '',
   *   syncDisplayName: '',
   *   syncEmail: '',
   *   warningText: ''
   * }
   */
  @Input() inputData = {
    cancelConnectionQuestion: '',
    credentialTextTag: '',
    header: '',
    keepEmployeeInformation: '',
    removeEmployeeInformation: '',
    syncDisplayName: '',
    syncEmail: '',
    warningText: ''
  };

  /**
   * Observable is used to handle the state of proceed button
   */
  private _syncProceed$ = new BehaviorSubject({ state: false });
  syncProceed$ = this._syncProceed$.asObservable();

  /**
   * Disconnect sync form
   */
  syncForm = new FormGroup({
    currentSyncDisconnectType: new FormControl<ConnectionSyncDisconnectType>('selectadjustment', { nonNullable: true })
  });

  constructor(public modal: NgbActiveModal) {}

  /**
   * Handles proceed button click
   */
  syncProceed(): void {
    this._syncProceed$.next({ state: true });
  }
}
