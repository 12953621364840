@if(selectedRoles$ | async; as selectedRoles) {
<div class="form-group" *transloco="let translate">
  <div class="schrift_blau infoline">{{ translate('select_user_role') }}</div>
  @for(item of roles; track item.id; let i = $index) {
  <div class="form-check">
    <input
      id="roleName{{ i }}"
      (change)="selectRoles = { rolesList: selectedRoles, role: item }"
      class="form-check-input"
      type="checkbox"
      [checked]="isChecked(selectedRoles, item)"
    />
    <label class="form-check-label fw-normal" for="roleName{{ i }}">
      {{ translate(item.titleTag) }} ({{ translate(item.descriptionTag) }})
    </label>
  </div>
  }
</div>
}
