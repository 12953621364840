<div
  (click)="bookingContainer.openStep2 = !bookingContainer.openStep2"
  data-cy="feature-signature-marketing"
>
  <mt-sticky-plan-feature-title>
    <div
      class="product-container-accordion"
      [ngClass]="{
        'accordion-title-two-coulmns':
          currentProduct && currentProduct !== 'Basic',
        'accordion-title-one-coulmn': currentProduct === 'Business'
      }"
    >
      <span
        class="glyphicon cursor-pointer chevron"
        [ngClass]="{
          'glyphicon-chevron-down': !bookingContainer.openStep2,
          'glyphicon-chevron-up': bookingContainer.openStep2
        }"
      ></span>
      <span
        class="title"
        transloco="{{ bookingContainer.signatureMarketing.title }}"
      ></span>
    </div>
  </mt-sticky-plan-feature-title>
  <div
    *ngIf="bookingContainer.openStep2"
    class="flex"
    [ngClass]="{
      justifycontentcenter: currentProduct
    }"
    data-cy="feature-signature-marketing-items"
  >
    <div
      *ngIf="!hasSubscription && !currentProduct"
      class="product-container-info tint-turquoise"
    >
      <mt-product-container-info
        [includesProduct]="bookingContainer.signatureMarketing.free.includes"
        [excludesProduct]="bookingContainer.signatureMarketing.free.excludes"
      ></mt-product-container-info>
    </div>
    <div
      *ngIf="!currentProduct || currentProduct === 'Basic'"
      class="product-container-info tint-light"
    >
      <mt-product-container-info
        [includesProduct]="bookingContainer.signatureMarketing.basic.includes"
        [excludesProduct]="bookingContainer.signatureMarketing.basic.excludes"
      ></mt-product-container-info>
    </div>
    <div
      *ngIf="currentProduct !== 'Business'"
      class="product-container-info tint-dark"
    >
      <mt-product-container-info
        [includesProduct]="
          bookingContainer.signatureMarketing.advanced.includes
        "
        [excludesProduct]="
          bookingContainer.signatureMarketing.advanced.excludes
        "
      ></mt-product-container-info>
    </div>
    <div class="product-container-info tint-light">
      <mt-product-container-info
        [includesProduct]="bookingContainer.signatureMarketing.target.includes"
        [excludesProduct]="bookingContainer.signatureMarketing.target.excludes"
      ></mt-product-container-info>
    </div>
  </div>
</div>
