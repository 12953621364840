import { Pipe, PipeTransform } from '@angular/core';
import { isBefore, parseISO, isDate } from 'date-fns';
import { ISignature, ISignatureModel } from 'src/app/model/interfaces/signature.interface';

/**
 * Determines if the signature is in rolled out status or not
 * @returns Boolean indicating the signature is rolled or not/changes were made since
 */

@Pipe({
  name: 'signatureRolledoutStatus',
  standalone: true
})
export class SignatureRolledoutStatusPipe implements PipeTransform {
  /**
   * Gets company updated at
   * @param signature - The signature
   * @returns The date of company updated at
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getCompanyUpdatedAt(signature: any): Date | null {
    // This case should be in schools on 'how not to use types'
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-return, prettier/prettier
    if (signature['CompanyDatasetRolledOut'] && signature['CompanyDatasetRolledOut']['dataUpdatedAt'])
      return signature['CompanyDatasetRolledOut']['dataUpdatedAt'];
    return null;
  }
  /**
   * Gets company change date by signature
   * @param signature - The signature
   * @returns The date of company change
   */
  private getCompanyChangeDateBySignature(signature: ISignature): Date {
    if (signature?.companyInfoUpdatedAt) return signature.companyInfoUpdatedAt;
    const companyUpdatedAt = this.getCompanyUpdatedAt(signature);
    return companyUpdatedAt ? companyUpdatedAt : new Date(0);
  }

  transform(signature: ISignature): boolean {
    // never rolledout
    if (!signature.lastRollout) {
      return false;
    }

    // determine rollout status of signature
    const lastRollOut = parseISO(this.getDateISOString(signature.lastRollout));

    const lastCompanyInfoChange = parseISO(this.getDateISOString(this.getCompanyChangeDateBySignature(signature)));
    const lastSignatureChanged = parseISO(this.getDateISOString(signature.signatureUpdatedAt));
    // if the rollout was before last company data change or before last signature change
    return !(isBefore(lastRollOut, lastCompanyInfoChange) || isBefore(lastRollOut, lastSignatureChanged));
  }

  /**
   * Gets date string
   * @param date - The date
   * @returns The date in string
   */
  getDateISOString(date: string | Date | undefined): string {
    if (!date) {
      return new Date(0).toISOString();
    } else if (isDate(date)) {
      return (date as Date).toISOString();
    } else {
      return date as string;
    }
  }
}
