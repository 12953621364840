<mt-custom-modal [closeBtn]="false" headline="{{ modalHeader | transloco }}">
  <ng-container modal-body>
    <div class="col-lg-12 marbot60" [innerHtml]="text | transloco"></div>
  </ng-container>
  <ng-container modal-footer>
    <div class="flex-center-right">
      <mt-button
        *ngIf="isSaveBtn"
        [text]="'save_and_rollout'"
        class="marright10"
        color="primary"
        (click)="modal.close('save_and_rollout')"
      ></mt-button>
      <mt-button
        *ngIf="isBackBtn"
        [text]="'go_back_and_rollout'"
        class="marright10"
        color="primary"
        (click)="modal.close('go_back_and_rollout')"
      ></mt-button>
      <mt-button
        text="proceed_without_rollout"
        (click)="modal.close('proceed_without_rollout')"
      ></mt-button>
    </div>
  </ng-container>
</mt-custom-modal>
