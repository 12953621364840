<!-- Step1 with checklist -->
<mt-wizard-tab
  [(parent)]="integrations.steps[0]"
  class="step2 detailpanel_blue"
  [ngClass]="{ 'ec-rebrand': isEcRebrand }"
>
  <form [formGroup]="integrationTypeForm" class="subtypes-form">
    <ng-container *ngIf="checkList$ | async"></ng-container>
    <div>
      <div class="row">
        <div
          data-cy="find-integration"
          class="m-auto"
          [ngClass]="
            checkSetup || checkSetup === false ? 'col-sm-8' : 'col-sm-6'
          "
        >
          <div class="col-sm-6 col-lg-6 formcontrolright">
            <ng-container
              class="form-check"
              *ngFor="
                let firstColOptions of [
                  integrations.list[0],
                  integrations.list[1]
                ];
                let i = index
              "
            >
              <div
                class="fw-bold mb-1 martop25"
                transloco="{{ firstColOptions.title }}"
              ></div>
              <div *ngFor="let data of firstColOptions.parts; let i = index">
                <span class="d-flex" *ngIf="data.name">
                  <input
                    id="{{ data.title }}"
                    class="form-check-input marright5"
                    type="checkbox"
                    formControlName="{{ data.title }}"
                  />

                  <label
                    class="form-check-label fw-normal"
                    for="{{ data.title }}"
                    transloco="{{ data.name }}"
                  ></label>
                </span>
              </div>
            </ng-container>
          </div>

          <div class="col-sm-6 col-lg-6 formcontrolright">
            <div class="col">
              <ng-container
                class="form-check"
                *ngFor="
                  let secondColOptions of [
                    integrations.list[2],
                    integrations.list[3],
                    integrations.list[4]
                  ];
                  let i = index
                "
              >
                <span>
                  <div
                    class="fw-bold mb-1 martop25"
                    transloco="{{ secondColOptions.title }}"
                  ></div>

                  <div
                    *ngFor="let data of secondColOptions.parts; let i = index"
                  >
                    <span class="d-flex" *ngIf="data.name">
                      <input
                        id="{{ data.title }}"
                        class="form-check-input marright5"
                        type="checkbox"
                        formControlName="{{ data.title }}"
                      />

                      <label
                        class="form-check-label fw-normal"
                        for="{{ data.title }}"
                        transloco="{{ data.name }}"
                      ></label>
                    </span>
                  </div>
                </span>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div
      class="col-md-6 m-auto padtop20"
      [innerHTML]="
        'param.endusercockpit_installsig_help'
          | transloco : { admin_emailaddress: adminData.email }
      "
    ></div>
  </div>
</mt-wizard-tab>

<!-- Step2 with selected list -->
<div *ngIf="selectedMailClientList$ | async as list">
  <div>
    <mt-wizard-tab
      *ngIf="isSelectedListShown$ | async"
      [(parent)]="integrations.steps[1]"
      class="step2 detailpanel_blue"
      [ngClass]="{ 'ec-rebrand': isEcRebrand }"
    >
      <div class="row marright5 marleft5">
        <div
          [innerHTML]="
            'param.endusercockpit_installsig_description'
              | transloco
                : {
                    admin_firstname: adminData.firstname,
                    admin_lastname: adminData.lastname,
                    amount: signatures.length
                  }
          "
          class="mt-3 marbot25 font16"
        ></div>
      </div>
      <div *ngFor="let data of list">
        <div class="inegrationslist">
          <div class="list">
            <div
              class="integrationsentry"
              *ngIf="installationService.getSelectedClientLength(data) !== 0"
            >
              <div class="headerrow flex-center-left">
                <img src="{{ data.image }}" />
                <div class="title" transloco="{{ data.headline }}"></div>
              </div>
              <div class="martop10" *ngFor="let part of data.parts">
                <div
                  class="part flex-center-space-between full-width selected-list"
                  *ngIf="part.checked"
                >
                  <div class="texts">
                    <div
                      class="title"
                      data-cy="integration-title"
                      transloco="{{ part.title }}"
                    ></div>
                    <div
                      class="description"
                      transloco="{{ part.description }}"
                    ></div>

                    <!-- Temporary removed -->
                    <!-- <div
                  class="btn-group ddblau padtop20"
                  *ngIf="
                    installationService.getEasySyncIntegration$
                      | async as easySyncData
                  "
                >
                  <mt-button
                    *ngIf="
                      signatures.length !== 0 &&
                      (part.title ===
                        'outlook_mac_easysync_header_rec' ||
                        part.title ===
                          'outlook_windows_easysync_header_rec')
                    "
                    icon="ok"
                    color="success"
                    text="confirmed_set_up"
                    [disabled]="true"
                  ></mt-button>
                </div> -->

                    <div class="padtop20">
                      <mt-button
                        [id]="part.name"
                        *ngIf="signatures.length === 0"
                        icon="remove"
                        color="danger"
                        text="NoSignatureavailable"
                        (click)="
                          alert.defaultErrorMessage(
                            alert.translate('noCampaignNorSignatureText'),
                            alert.translate('noCampaignNorSignatureHeadline')
                          )
                        "
                      ></mt-button>
                    </div>
                  </div>
                  <div class="actionarea flex">
                    <mt-button
                      data-cy="setup-btn"
                      [disabled]="
                        signatures.length === 0 && part.hasSignatureHTML
                      "
                      color="primary"
                      text="set_up_button"
                      (click)="
                        installationService.openSignatureInstallationGuide(
                          part.title,
                          signatures,
                          user,
                          isEcRebrand
                        )
                      "
                    ></mt-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mt-wizard-tab>
  </div>
</div>
