import { Component, Input } from '@angular/core';
import { ISignature } from 'src/app/model/interfaces/signature.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, DatePipe } from '@angular/common';
import { SignatureRolledoutStatusPipe } from '@shared/pipes/signature-rolledout-status/signature-rolledout-status.pipe';
@Component({
  selector: 'rollout-status-col',
  templateUrl: './rollout-status-col.component.html',
  styleUrls: ['./rollout-status-col.component.scss'],
  standalone: true,
  imports: [NgIf, NgbTooltip, TranslocoModule, DatePipe, SignatureRolledoutStatusPipe]
})
export class RolloutStatusColComponent {
  @Input() signature!: ISignature;
}
