<div
  modal-body
  class="gated-content-info"
  data-cy="gated-content-info"
  (click)="$event.stopPropagation()"
>
  <ng-container *ngIf="loadInfo$ | async as loadInfo; else loading">
    <ng-container modal-header>
      <div class="cursor-pointer closebutton" (click)="modal.close(false)">
        <mt-svg
          class="x-button"
          src="assets/images/modals/close_other_svg.svg"
        ></mt-svg>
      </div>
    </ng-container>

    <!-- icon & title -->
    <div class="flex-center-left">
      <img
        src="assets/images/common/gatedContentModalTitleIcon.png"
        class="icon-image"
      />
      <p class="title" transloco="signature_campaign_with_mailtastic"></p>
    </div>

    <!-- video placeholder -->
    <div class="martop30">
      <img
        src="assets/images/common/gatedContentModalVideoPlaceholder.gif"
        class="full-width"
      />
    </div>

    <!-- text -->
    <div class="martop30">
      <p
        [innerHtml]="'html.campaign_banner_functionality_promo' | transloco"
      ></p>
    </div>

    <!-- buttons -->
    <div class="flex-center-left martop20">
      <mt-button
        text="upgradeNow"
        color="primary"
        (click)="
          closeGatedContentModalAndPerformNext(
            loadInfo.hasSubscription,
            loadInfo.priceData,
            loadInfo.subscriptionInformationData
          )
        "
      ></mt-button>

      <button
        type="button"
        class="btn btnhellgrauflat marleft20 width20percent"
        (click)="
          closeGatedContentModalAndPerformNext(
            loadInfo.hasSubscription,
            loadInfo.priceData,
            loadInfo.subscriptionInformationData
          )
        "
        transloco="tariffs_at_a_glance"
      ></button>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="container">
      <div class="card martop20">
        <mt-emptystate [loadingPromise]="true"></mt-emptystate>
      </div>
    </div>
  </ng-template>
</div>
