<mt-custom-modal
  *transloco="let translate"
  [headline]="translate('account_locked_headline')"
  [closeBtn]="true"
  data-cy="failed-login-modal"
>
  <ng-container modal-body>
    <p class="mb-3" *transloco="let translate">
      {{ translate('account_locked_content_1') }}
    </p>
    <p *transloco="let translate">
      {{ translate('account_locked_content_2') }}
    </p>
  </ng-container>
  <ng-container modal-footer-right>
    <div>
      <mt-button
        class="plan-btn"
        color="secondary"
        text="Cancel"
        (click)="modal.close()"
      ></mt-button>
    </div>
    <div class="ms-2">
      <mt-button
        data-cy="open-reset-password-button"
        class="plan-btn"
        color="primary"
        text="account_locked_button_reset"
        (click)="modal.close(true)"
      ></mt-button>
    </div>
  </ng-container>
</mt-custom-modal>
