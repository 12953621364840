import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'mt-failed-login-modal',
  templateUrl: './failed-login-modal.component.html',
  styleUrls: ['./failed-login-modal.component.scss'],
  standalone: true,
  imports: [ButtonComponent, CustomModalComponent, MtSvgComponent, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FailedLoginModalComponent {
  constructor(public modal: NgbActiveModal) {}
}
