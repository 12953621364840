<div
  *ngIf="campaign"
  class="campaigncol"
  [ngClass]="[isFirst ? 'first' : '']"
  data-cy="campaign-col"
>
  <div class="flex-center-left" *ngIf="campaign.title">
    <div data-hj-suppress class="image marright15">
      <img
        [src]="campaign.image"
        alt="Campaign Image"
        data-cy="campaign-col-image"
      />
    </div>
    <div>
      <div
        data-hj-suppress
        *ngIf="campaign && !disableLinks"
        class="title"
        [ngClass]="[isFirst ? 'schrift_blau' : '']"
        style="overflow: hidden !important"
      >
        <mt-svg
          class="medium-svg calicon {{ iconColor }}"
          [ngClass]="iconColor"
          src="assets/images/siteelements/campaign_list_svg.svg"
        ></mt-svg>
        <span
          [routerLink]="['/campaigns/details/', campaign.id]"
          (click)="redirectToCampaignDetail.emit()"
          class="link"
          ngbTooltip="{{ campaign.title }}"
          placement="top"
          tooltipClass="status-info-tooltip"
          style="margin-left: 5px"
          data-cy="campaign-col-title"
          >{{ campaign.title | textTruncate : 25 }}</span
        >
        <span
          *ngIf="campaign.urlCrmState && campaign.urlCrmState !== 'published'"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{
            'crm_target_site_' + campaign.urlCrmState | transloco
          }}"
          ><span class="glyphicon glyphicon-exclamation-sign marleft5"></span
        ></span>
      </div>

      <div
        data-hj-suppress
        *ngIf="campaign && disableLinks"
        class="title"
        [ngClass]="[isFirst ? 'schrift_blau' : '']"
        style="overflow: hidden !important"
      >
        <mt-svg
          class="medium-svg mt-blau calicon"
          [ngClass]="iconColor"
          src="assets/images/siteelements/campaign_list_svg.svg"
        ></mt-svg>
        <span
          ngbTooltip="{{ campaign.title }}"
          placement="top"
          tooltipClass="status-info-tooltip"
          style="margin-left: 5px"
        >
          {{ campaign.title | textTruncate : 25 }}</span
        >
      </div>

      <div *ngIf="isFirst && !campaign" class="title schrift_rot italic">
        <span transloco="Unassigned"></span>
      </div>

      <div class="subline" *ngIf="!hideDate">
        <span transloco="CreatedOn"></span>
        {{ campaign.createdAt | date : 'dd.MM.yyyy' }}
      </div>

      <div class="subline" *ngIf="showCampaign">
        <span transloco="campaign"></span>
      </div>
    </div>
  </div>
  <div class="flex-center-left">
    <div *ngIf="!campaign.title" class="statuscol groupcol">
      <div class="title schrift_rot italic">
        <span transloco="Unassigned"></span>
      </div>

      <div class="subline">
        <span transloco="Campaign"></span>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!campaign" class="statuscol groupcol">
  <div class="title schrift_rot italic">
    <span transloco="Unassigned"></span>
  </div>

  <div class="subline">
    <span transloco="Campaign"></span>
  </div>
</div>
