import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, map } from 'rxjs';
import {
  CustomerHasFreeSubscription,
  CustomerSubscriptionData,
  PriceData,
  UserStatus
} from 'src/app/model/interfaces/payment.interface';
import { BookingService } from 'src/app/modules/booking/services/booking/booking.service';
import { PlansService } from '@services/plans/plans.service';
import { NavigationSidebarService } from 'src/app/services/navigation-sidebar/navigation-sidebar.service';
import { GetOverallStatsResponse } from 'src/app/services/user/user-service.interface';
import { EmptyStateComponent } from '@organisms/empty-states/empty-state.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

interface SubscriptionInformationData {
  statData: GetOverallStatsResponse;
  subscriptionData: CustomerSubscriptionData | CustomerHasFreeSubscription;
  userStatus: UserStatus;
}

@Component({
  selector: 'mt-gated-content-info',
  templateUrl: './gated-content-info.component.html',
  styleUrls: ['./gated-content-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MtSvgComponent, TranslocoModule, EmptyStateComponent, AsyncPipe, ButtonComponent]
})
export class GatedContentInfoComponent {
  /**
   * Load required data to open plan upgrade modal
   */
  loadInfo$ = combineLatest([
    this.bookingService.priceData$,
    this.navigationSidebarService.hasSubscription$,
    this.plansService.subscriptionInformationData$
  ]).pipe(
    map(([priceData, hasSubscription, subscriptionInformationData]) => ({
      priceData,
      hasSubscription: hasSubscription.hasSubscription,
      subscriptionInformationData
    }))
  );

  constructor(
    private bookingService: BookingService,
    private navigationSidebarService: NavigationSidebarService,
    private plansService: PlansService,
    private router: Router,
    public modal: NgbActiveModal
  ) {}

  /**
   * Close the current modal and redirect to the booking page
   * @param hasSubscription - Boolean for user has subscription or not
   * @param priceData - Object of PriceData
   * @param subscriptionInformationData - Object of subscription information data
   */
  closeGatedContentModalAndPerformNext(
    hasSubscription: boolean,
    priceData: PriceData,
    subscriptionInformationData: SubscriptionInformationData
  ): void {
    this.modal.close(true);
    if (hasSubscription) {
      this.plansService.openChangeProductModal(
        this.bookingService.config,
        this.bookingService.bookingContainer,
        priceData,
        subscriptionInformationData.userStatus.currentProduct,
        subscriptionInformationData.statData
      );
    } else {
      void this.router.navigate(['booking']);
    }
  }
}
