<div modal-body class="expiredmodal" *transloco="let translate">
  <div class="cursor-pointer close" (click)="modal.close()">
    <mt-svg
      class="x-button"
      src="assets/images/modals/close_other_svg.svg"
    ></mt-svg>
  </div>
  <div class="textcol">
    <div class="headline">
      {{ translate('html.Thisactioncanunfortunatelyperiodhasexpired') }}
    </div>
    <div class="middlearea">
      <div
        class="bottomarea"
        [innerHTML]="translate('html.Soyoumailtasticbooksubscription')"
      ></div>
      <mt-button
        (click)="openBooking()"
        color="primary"
        text="Mailtasticbooknow"
      ></mt-button>
      <div class="uppertext">
        {{ translate('Testphasehasexpiredwhatdoesthismean') }}
      </div>
      <div class="downtext">
        {{ translate('invisiblegraphicuntilanactivewasbooked') }}
      </div>
    </div>
  </div>
</div>
