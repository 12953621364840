import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { ICampaign } from 'src/app/model/interfaces/campaign.interface';

import { TranslocoModule } from '@ngneat/transloco';
import { RouterLink } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgClass, NgIf } from '@angular/common';
import { TextTruncatePipe } from '@shared/pipes/text-truncate/text-truncate.pipe';

@Component({
  selector: 'activecampaigncol',
  templateUrl: './active-campaign-col.component.html',
  styleUrls: ['./active-campaign-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, MtSvgComponent, NgbTooltip, RouterLink, TranslocoModule, TextTruncatePipe]
})
export class ActiveCampaignColComponent {
  /**
   * Determines if link to details page should be disabled
   * @defaultValue false
   */
  @Input() disableLinks = false;

  /**
   * Hide title
   * @defaultValue false
   */
  @Input() hideLabel = false;

  /**
   * SVG icon color class
   * @defaultValue ''
   */
  @Input() iconColor = '';

  /**
   * Determines if specific styling is applied
   * @defaultValue true
   */
  @Input() isFirst = true;

  /**
   * Campaign to display information about
   */
  @Input() activeCampaign?: ICampaign;

  /**
   * Emit events to redirect to campaign details page
   */
  @Output() redirectToCampaignDetail: EventEmitter<void> = new EventEmitter<void>();
}
