<div
  cg-busy="loadingPromise"
  class="valueeditmodal urlvalue"
  data-cy="signature-placeholder-type-link-modal"
>
  <div class="modal-body">
    <div class="cursor-pointer pull-right" (click)="modal.close()">
      <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
    </div>

    <div class="modal-title">
      <span class="headlinefirst">{{ modalOptions.heading }}</span>
      <span class="headlinesecond">{{ modalOptions.subheading }}</span>
    </div>

    <div role="separator" class="line full-width-imp"></div>

    <form *ngIf="linkForm" [formGroup]="linkForm">
      <div class="form-group">
        <label transloco="NameTags"></label>
        <input
          data-cy="placeholder-link-tag-input"
          [disabled]="true"
          formControlName="tag"
          type="text"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <label transloco="targetlink"></label>
        <input
          ng-change="changedOtherThanLinkColor = true"
          formControlName="url"
          type="text"
          class="form-control"
          data-cy="placeholder-link-target-link-url"
        />
      </div>

      <div class="form-group">
        <label transloco="linktext"></label>
        <input
          ng-change="changedOtherThanLinkColor = true"
          formControlName="linkText"
          required="true"
          type="text"
          class="form-control"
          data-cy="placeholder-link-target-link-text"
        />
      </div>

      <div *ngIf="modalOptions.showSignatureRelevantData" class="form-group">
        <label class="format2light" transloco="linkcolor"></label>
        <mt-color-picker
          [color]="linkForm.get('color')?.value"
          (colorChange)="setLinkColor($event)"
        ></mt-color-picker>
      </div>

      <mt-box
        *ngIf="!linkForm.get('tag')?.value.startsWith('ma_')"
        type="info"
        icon="info-sign"
        translateTag="this_happens_when_you_change_the_email_adress"
      >
        <div class="additionaltext">
          <span
            transloco="Changesinthisinformationareincludedsignaturesemployees"
          ></span>
        </div>
      </mt-box>

      <div role="separator" class="line full-width-imp"></div>

      <div class="flex-center-right">
        <mt-button
          (click)="modal.close()"
          class="leftbutton"
          color="default"
          text="rejection"
        ></mt-button>
        <mt-button
          data-cy="save-placeholder-link-changes"
          (click)="saveValue()"
          color="primary"
          text="Save"
        ></mt-button>
      </div>
    </form>
  </div>
</div>
