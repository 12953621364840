import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INotification } from 'src/app/model/interfaces/notification.interface';
import { IntercomService, INTERCOM_DATA } from '../intercom/intercom.module';
import { IQueryObj } from '../query-helper/query-helper-service.interface';
import { IResponse, IResponseData } from 'src/app/model/interfaces/response.interface';
import { NavigationSidebarService } from '../navigation-sidebar/navigation-sidebar.service';
import { NotificationsCreate, NotificationsGet } from 'src/app/services/notification/notifications.service.interface';
import { Observable, tap } from 'rxjs';
import { QueryHelperService } from '../query-helper/query-helper.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private http: HttpClient,
    private operator: CustomOperators,
    private intercomService: IntercomService,
    private navigationSidebarService: NavigationSidebarService,
    private queryHelperService: QueryHelperService
  ) {}

  /**
   * Gets all notifications
   * @param queryObj - query object holding the keys or empty
   * @returns - List of Notifications
   */
  getAllNotifs(queryObj?: IQueryObj): Observable<NotificationsGet[]> {
    return this.http
      .get<IResponseData<NotificationsGet[]>>(`/notif${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the count of notifications.
   * @param queryObj - query object holding the keys or empty
   * @returns Count of notifications that exist
   */
  count(queryObj?: IQueryObj): Observable<number> {
    return this.http
      .get<IResponseData<number>>(`/notif/count${this.queryHelperService.createQuerySearch(queryObj)}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets all notifications for a target group
   * @param targetGroupListId - Id of target group
   * @returns List of notifications
   */
  getAllNotifsForTargetGroup(targetGroupListId: string): Observable<INotification[]> {
    return this.http
      .get<IResponseData<INotification[]>>(`/notif/target-group/${targetGroupListId}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets all notifications for a campaign
   * @param campaignId
   * @returns
   */
  getAllNotifsForCampaign(campaignId: string): Observable<INotification[]> {
    return this.http
      .get<IResponseData<INotification[]>>(`/notif/campaign/${campaignId}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets one notification
   * @param id - notification id for getting details
   * @returns Observable of notification object
   */
  getOneNotif(id: string): Observable<NotificationsGet> {
    return this.http.get<IResponseData<NotificationsGet>>(`/notif/${id}`).pipe(this.operator.extractResponseData());
  }

  /**
   * Updates one notification
   * @param notifBody - notification object to update
   * @returns Observable of the response success
   */
  updateNotif(notifBody: NotificationsGet): Observable<true> {
    return this.http.put<IResponse>('/notif/', notifBody).pipe(this.operator.extractResponse());
  }

  /**
   * Creates a notification
   * @param notifBody - notification object to save in backend
   * @returns Observable of the response success
   */
  createNotif(notifBody: Partial<NotificationsCreate>): Observable<true> {
    return this.http.post<IResponse>('/notif/', notifBody).pipe(
      this.operator.extractResponse(),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.notification_created);
        this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'notification');
      })
    );
  }

  /**
   * Deletes many notifications
   * @param ids - Multiple Notification ids to delete
   * @returns Observable of the response success
   */
  removeManyNotifs(ids: string[]): Observable<true> {
    return this.http.post<IResponse>('/notif/removemany', ids).pipe(
      this.operator.extractResponse(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(-ids.length, 'notification');
      })
    );
  }

  /**
   * Deletes one notification
   * @param id - Notification id to delete
   * @returns Observable of the response success
   */
  removeNotif(id: string): Observable<true> {
    return this.http.delete<IResponse>(`/notif/${id}`).pipe(
      this.operator.extractResponse(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(-1, 'notification');
      })
    );
  }
}
