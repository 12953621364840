import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ESignatureType } from 'src/app/model/enums/signature-type.enum';
import { IDataset } from 'src/app/model/interfaces/dataset.interface';
import {
  ImageDimensionWithMode,
  ISignatureDbData,
  ISignatureDbField,
  ISignatureDbFieldValue,
  ISignaturePlaceholderImg,
  ISignaturePlaceholderTxt,
  ISignaturePlaceholderURL,
  ISignaturePlaceholderValueImg,
  ISignaturePlaceholderValueURL,
  TSignaturePlaceholder
} from 'src/app/model/interfaces/signature.interface';
import { DatasetService } from '../dataset/dataset.service';
import { EmployeeService } from '../employee/employee.service';
import { GroupService } from '../group/group.service';
import { IActiveGroups, ISignatureGetJsonInfoFields } from '../signature/signature-service.interface';
import { SignatureService } from '../signature/signature.service';
import { UserGetAccountData } from '../user/user-service.interface';
import { UserService } from '../user/user.service';
import {
  DEFAULT_IMAGE,
  DEFAULT_IMAGE_VALUE,
  DEFAULT_LINK,
  DEFAULT_LINK_VALUE,
  EMPLOYEE_DUMMY_DATA,
  Permission
} from './signature-helper-service.interface';

@Injectable({
  providedIn: 'root'
})
export class SignatureHelperService {
  dataManager = {
    fields: {} as ISignatureGetJsonInfoFields,
    signatureDatasets: {
      companyDatasetId: '',
      companyDatasetTitle: '',
      employeeDatasetId: '',
      employeeDatasetTitle: {} as string | undefined
    },
    specificUserSetId: '',
    employeeData: {} as ISignatureDbField,
    companyData: {} as ISignatureDbField,
    customDefaultIcons: {} as ISignatureDbField | undefined,
    signatureData: {} as ISignatureDbData,
    signatureTpl: '',
    signatureTxt: {} as string | undefined,
    signatureTitle: '',
    signatureId: '',
    signatureLastRollout: {} as Date | undefined,
    signatureUpdatedAt: {} as Date | undefined,
    companyInfoUpdatedAt: {} as Date | undefined,
    disableHelperStyle: {} as boolean | undefined,
    activeGroups: [] as IActiveGroups[], // Which groups the signature is active
    campaignIsUsed: false, // Mark if campaign is used in the signature designer so that the button turns blue
    sigGraphicProperties: {} as string | undefined,
    employeeCampaignUrl: {} as string | undefined,
    employeeCampaignImage: {} as string | undefined,
    employeeCampaignImageWidth: {} as number | undefined,
    employeeCampaignImageHeight: {} as number | undefined
  };

  globalValues = {} as ISignatureDbField;

  employeeDummyData = EMPLOYEE_DUMMY_DATA as unknown as ISignatureDbField;

  constructor(
    private signatureService: SignatureService,
    private datasetService: DatasetService,
    private userService: UserService,
    private employeeService: EmployeeService,
    private groupService: GroupService,
    private translate: TranslocoService
  ) {}

  getRelevantDataForSignature(
    userId: string,
    signatureId: string,
    groupId: number | string,
    version: string
  ): Observable<[[UserGetAccountData, IDataset], void, void]> {
    const jsonField$ = this.loadJsonFieldStructure();

    const defaultDatasets$ = this.datasetService.getDefaultDataSets().pipe(
      map(defaultDatasets => {
        this.dataManager.signatureDatasets.companyDatasetId = defaultDatasets.companyDatasetId;
        this.dataManager.signatureDatasets.employeeDatasetId = defaultDatasets.userDefinitionDatasetId;
        this.dataManager.signatureDatasets.companyDatasetTitle = defaultDatasets.companyDatasetTitle;
        this.dataManager.signatureDatasets.employeeDatasetTitle = defaultDatasets.userDefinitionDatasetTitle;
      })
    );

    return jsonField$.pipe(
      tap(fields => {
        this.dataManager.fields = fields;
      }),
      switchMap(fields => this.getSignatureData(signatureId, fields, version)),
      switchMap(() => {
        if (
          !this.dataManager.signatureDatasets.companyDatasetId ||
          !this.dataManager.signatureDatasets.employeeDatasetId
        ) {
          return defaultDatasets$;
        } else {
          return of(this.dataManager);
        }
      }),
      switchMap(dataManager => {
        if (dataManager) {
          return forkJoin([
            this.getCompanyAccountData(dataManager.signatureDatasets.companyDatasetId, dataManager.fields),
            this.getEmployeeAccountData(userId, dataManager.signatureDatasets.employeeDatasetId, dataManager.fields),
            this.getCampaignDataForSignature(groupId)
          ]);
        }
        return forkJoin([
          this.getCompanyAccountData(this.dataManager.signatureDatasets.companyDatasetId, this.dataManager.fields),
          this.getEmployeeAccountData(
            userId,
            this.dataManager.signatureDatasets.employeeDatasetId,
            this.dataManager.fields
          ),
          this.getCampaignDataForSignature(groupId)
        ]);
      })
    );
  }

  getSignatureData(signatureId: string, fields: ISignatureGetJsonInfoFields, version: string): Observable<void> {
    return this.signatureService.getOne(signatureId).pipe(
      map(signature => {
        // Check if the version to edit or the already rolledout version is shown
        if (version === 'rolledout' && signature?.signatureTplRolledOut) {
          if (signature?.signatureDataRolledOut) {
            this.globalValues = this.getGlobalValuesAsArray(
              JSON.parse(signature.signatureDataRolledOut) as ISignatureDbData
            );
            this.mergeDbDataWithStructure(
              'signatureData',
              JSON.parse(signature.signatureDataRolledOut) as ISignatureDbData,
              fields
            );
            this.dataManager.signatureData = JSON.parse(signature.signatureDataRolledOut) as ISignatureDbData;
          }

          this.dataManager.signatureTpl = signature.signatureTplRolledOut;

          if (signature?.companyDatasetIdRolledOut) {
            this.dataManager.signatureDatasets.companyDatasetId = signature.companyDatasetIdRolledOut;
          }

          if (signature?.employeeDatasetIdRolledOut) {
            this.dataManager.signatureDatasets.employeeDatasetId = signature.employeeDatasetIdRolledOut;
          }
        } else {
          if (signature?.signatureDataToEdit) {
            this.globalValues = this.getGlobalValuesAsArray(
              JSON.parse(signature.signatureDataToEdit) as ISignatureDbData
            );
            this.mergeDbDataWithStructure(
              'signatureData',
              JSON.parse(signature.signatureDataToEdit) as ISignatureDbData,
              fields
            );
            this.dataManager.signatureData = JSON.parse(signature.signatureDataToEdit) as ISignatureDbData;
          }

          if (signature?.signatureTplToEdit) {
            this.dataManager.signatureTpl = signature.signatureTplToEdit;
          }

          if (signature?.companyDatasetIdToEdit) {
            this.dataManager.signatureDatasets.companyDatasetId = signature.companyDatasetIdToEdit;
          }

          if (signature?.employeeDatasetIdToEdit) {
            this.dataManager.signatureDatasets.employeeDatasetId = signature.employeeDatasetIdToEdit;
          }
        }

        this.dataManager = {
          ...this.dataManager,
          signatureTitle: signature.title,
          signatureTxt: signature.signatureTplToEditTXT,
          signatureId: signature.id,
          activeGroups: signature.activeGroups,
          signatureLastRollout: signature.lastRollout,
          signatureUpdatedAt: signature.signatureUpdatedAt,
          companyInfoUpdatedAt: signature.companyInfoUpdatedAt,
          disableHelperStyle: signature.disableHelperStyle,
          sigGraphicProperties: signature.sigGraphicProperties
        };
      })
    );
  }

  /**
   * Generates Preview and take the already loaded data from datamanager here in the service
   * @param employeeId Employee id to generate for
   * @param signatureType Type of signature to generate 'html'|'plain'
   */
  generatePreviewComplete(employeeId: string, signatureType: ESignatureType.HTML | ESignatureType.PLAIN): string {
    // Merge all data sources with field structure
    this.mergeDbDataWithStructure('company', this.dataManager.companyData, this.dataManager.fields);
    this.mergeDbDataWithStructure('employee', this.dataManager.employeeData, this.dataManager.fields);
    this.mergeDbDataWithStructure('signatureData', this.dataManager.signatureData, this.dataManager.fields);

    // Get html content with placeholders
    const htmlTpl =
      signatureType === ESignatureType.PLAIN && this.dataManager.signatureTxt
        ? this.dataManager.signatureTxt
        : this.dataManager.signatureTpl;

    // Get all field objects in an easily accessible array
    const fieldData = this.getFieldStructureAsFlatArray(this.dataManager.fields);

    return this.generatePreview(htmlTpl, fieldData, employeeId, signatureType);
  }

  /**
   * Generate HTML from Signature, Employee and Other relevant Data
   * @param {string} html HTML Template in which the placeholders have to be replaced
   * @param {ISignatureDbField} fieldData Data values with which the placeholders have to be replaced
   * @param {string} employeeId Employee to generate preview for
   * @param {ESignatureType} signatureType Type of signature
   * @returns {string} Signature preview
   */
  generatePreview(
    html: string,
    fieldData: ISignatureDbField,
    employeeId: string,
    signatureType = ESignatureType.HTML
  ): string {
    /**
     * Generate tracking image pixel so that it is possible to track if the employee has the latest signature integrated
     * @returns {string}
     */
    const generateTrackingPixelHtml = (): string => {
      return '';
    };

    // TODO: No longer in use?
    // /**
    //  * replace tags which are marked with {{}} with the values
    //  * @param {type} tpl
    //  * @param {type} values
    //  * @returns {unresolved}
    //  */
    // const replacePlaceholders = (tpl: any, values: any) => {
    //   //replace all values in template
    //   for (const key in values) {
    //     // skip loop if the property is from prototype
    //     tpl = tpl.replaceAll('{{{' + key + '}}}', values[key]);
    //     tpl = tpl.replaceAll('{{' + key + '}}', values[key]);
    //   }

    //   //clear template from empty placeholders
    //   //tpl = tpl.replaceAllRegex(/\{\{(.*?)\}\}/g , "");

    //   return tpl;
    // };

    /**
     * Generate HTML for image
     * @param {ISignaturePlaceholderImg} imgObject Image object to process
     * @param {string} signatureType Type of signature
     * @returns {string}
     */
    const generateImageHtmlFromObject = (imgObject: ISignaturePlaceholderImg, signatureType: string): string => {
      let resultHtml = '';

      const tag = imgObject.tag;

      const defaultImage = imgObject.defaultImage;

      const whichImage = imgObject?.value?.whichImage ? imgObject.value.whichImage : 'default';

      const linkcolor = imgObject?.styles?.linkcolor ? imgObject.styles.linkcolor : undefined;
      const underline = imgObject?.styles?.underline ? imgObject.styles.underline : undefined;

      const imgDimensions = imgObject.imgdimension;

      const imgObjectValue = imgObject?.value ? imgObject.value : undefined;

      // If nothing is provided show as image
      if (imgObjectValue && !imgObjectValue?.showAs) {
        imgObjectValue.showAs = 'image';
      }

      // Handle link url
      let useLink = true;

      if (imgObjectValue?.url === '#') {
        useLink = false;
      } else if (
        imgObjectValue &&
        !imgObjectValue?.url.startsWith('http://') &&
        !imgObjectValue?.url.startsWith('https://') &&
        tag !== 'ma_skype' &&
        tag !== 'u_whatsapp'
      ) {
        // If user has not provided http or https attach http
        imgObjectValue.url = 'http://' + imgObjectValue.url;
      }

      let url = '';

      if (imgObjectValue?.showAs === 'text') {
        // When no linktext is provided use url as linktext
        if (!imgObjectValue.linkText) {
          imgObjectValue.linkText = imgObjectValue.url;
        }

        url = imgObjectValue.url;

        if (tag === 'ma_skype') {
          url = 'skype:' + url + '?call';
        }

        if (tag === 'u_whatsapp') {
          url = 'tel:' + url;
        }

        if (signatureType == 'plain') {
          resultHtml = url;
        } else {
          let styles = '';
          if (linkcolor) styles = 'color: ' + linkcolor + ';';

          if (underline === 'false') styles += 'text-decoration: none;';

          resultHtml =
            "<a target='_blank'  style='" + styles + "' href='" + url + "'>" + imgObjectValue.linkText + '</a>';
        }
      } else if (imgObjectValue?.showAs === 'image') {
        let imageToShow = whichImage === 'default' ? defaultImage : imgObjectValue.image;

        url = imgObjectValue.url;

        // Check if skype and generate url for call
        if (tag === 'ma_skype') {
          url = 'skype:' + url + '?call';
        }

        if (tag === 'u_whatsapp') {
          url = 'tel:' + url;
        }

        let widthString = '';

        // Check if a custom width was chosen
        // Use only custom width when it is not default image

        if (
          whichImage !== 'default' &&
          imgDimensions?.mode === 'custom' &&
          imgDimensions?.width &&
          imgDimensions?.height
        ) {
          let height = imgDimensions.height;

          if (tag === 'ma_foto' && imgObjectValue?.initialdimension.height && imgObjectValue?.initialdimension.width) {
            // Calc height of image because it can be another height for every employee
            const originalHeight = imgObjectValue.initialdimension.height;
            const originalWidth = imgObjectValue.initialdimension.width;

            //calculate factor and new height
            const factor = originalWidth / imgDimensions.width;
            const calculatedHeight = originalHeight / factor;

            // Set new height
            height = calculatedHeight;
          }

          widthString =
            'width="' +
            imgDimensions.width.toString() +
            '" height="' +
            height.toString() +
            '" style="width:' +
            imgDimensions.width.toString() +
            'px;height:' +
            height.toString() +
            'px;"';
        } else if (whichImage !== 'default' && imgDimensions?.mode === 'default' && imgObjectValue.initialdimension) {
          widthString =
            'width="' +
            imgObjectValue.initialdimension.width.toString() +
            '" height="' +
            imgObjectValue.initialdimension.height.toString() +
            '" style="width:' +
            imgObjectValue.initialdimension.width.toString() +
            'px;height:' +
            imgObjectValue.initialdimension.height.toString() +
            'px;"';
        } else if (whichImage === 'default') {
          // TODO: check what custom default icons are
          // if (
          //   this.dataManager.customDefaultIcons &&
          //   this.dataManager.customDefaultIcons[tag]
          // ) {
          //   const icon = this.dataManager.customDefaultIcons[tag];
          //   if (this.isImg(icon) && icon.value.image) {
          //     test.
          //   }
          // } else {
          //   //take dimension of mailtastic default icons
          //   widthString = 'width="20" height="20" style="width : 20px; height:20px; "';
          // }
        }

        if (imageToShow && typeof imageToShow === 'string' && !imageToShow.indexOf('localhost')) {
          // Force HTTPS to avoid spam problems
          imageToShow = imageToShow.replace('http://', 'https://');
          imageToShow = imageToShow.replace('https://www.app.mailtastic', 'https://app.mailtastic');
        }

        // Append a get parameter to force the serving of the image in the backend
        // So that it's served even if the account isn't active
        imageToShow = imageToShow + '?force=true';

        if (useLink === false) {
          if (signatureType === 'plain') {
            resultHtml = '';
          } else {
            resultHtml =
              '<img ' +
              widthString.toString() +
              ' src="' +
              imageToShow.toString() +
              '" alt="' +
              imgObjectValue.altText +
              '"  >';
          }
        } else {
          if (signatureType === 'plain') {
            resultHtml = url;
          } else {
            resultHtml =
              "<a target='_blank' href='" +
              url +
              "'>" +
              '<img ' +
              widthString.toString() +
              ' src="' +
              imageToShow.toString() +
              '" alt="' +
              imgObjectValue.altText +
              '"  >' +
              '</a>';
          }
        }
      }

      if (signatureType === 'plain') {
        const regex = /(<([^>]+)>)/gi;
        return resultHtml
          .replace('<br>', '||br||')
          .replace('<br />', '||br||')
          .replace(regex, '')
          .replace('||br||', '<br />')
          .replace('\n', '<br />');
      } else {
        return resultHtml;
      }
    };

    /**
     * Generate HTML for link fields
     * @param {ISignaturePlaceholderURL} linkObject Link object to process
     * @param {string} signatureType Type of signature
     * @returns {string}
     */
    const generateLinkHtmlFromObject = (linkObject: ISignaturePlaceholderURL, signatureType: string): string => {
      let resultHtml = '';

      const tag = linkObject.tag;

      const color = linkObject.linkcolor
        ? 'color : ' + linkObject.linkcolor + ';color : ' + linkObject.linkcolor + ' !important;'
        : '';

      const underline = linkObject.underline === 'false' ? 'text-decoration: none;' : 'text-decoration: underline;';

      const colorStyleString = 'style="' + color + underline + '"';

      const linkObjectValue = linkObject.value;

      if (linkObjectValue) {
        //when no linktext is provided than use url as linktext
        if (!linkObjectValue.linkText) {
          linkObjectValue.linkText = linkObjectValue.url;
        }

        //if user has not provided http or https
        if (
          !linkObjectValue?.url.startsWith('http://') &&
          !linkObjectValue?.url.startsWith('https://') &&
          tag !== 'ma_skype' &&
          tag !== 'u_whatsapp'
        ) {
          linkObjectValue.url = 'http://' + linkObjectValue.url;
        }

        if (tag === 'ma_skype') {
          linkObjectValue.url = 'skype:' + linkObjectValue.url + '?call';
        }

        if (tag === 'u_whatsapp') {
          linkObjectValue.url = 'tel:' + linkObjectValue.url;
        }

        if (signatureType === 'plain') {
          return linkObjectValue.url;
        }

        resultHtml =
          '<a ' +
          colorStyleString +
          "target='_blank' href='" +
          linkObjectValue.url +
          "'>" +
          linkObjectValue.linkText +
          '</a>';
      }

      return resultHtml;
    };

    /**
     * Generate HTML for campaign banner
     * @param employeeId Employee to generate for
     * @returns {string}
     */
    const generateCampaignHtmlForUser = (employeeId: string): string => {
      this.dataManager.campaignIsUsed = true;

      if (employeeId === 'dummy') {
        //generate dummy image
        if (this.translate.getActiveLang() === 'cognism') {
          return '<a target="_blank" href="https://signatures.cognism.com"><img src="https://cognism-signatures.azurewebsites.net/img/dummy/beispielbanner-en.png" alt="Currently you cannot see the information. Please activate external sources to see the full email." /></a>';
        } else {
          if (this.translate.getActiveLang() === 'de') {
            return '<a target="_blank" href="https://www.mailtastic.com/de/"><img src="https://www.app.mailtastic.de/img/dummy/beispielbanner-de.png" alt="Aktuell koennen Sie einige Informationen nicht sehen. Bitte aktivieren Sie externe Inhalte, um die Mail vollstaendig angezeigt zu bekommen." /></a>';
          } else {
            return '<a target="_blank" href="https://www.mailtastic.com"><img src="https://cognism-signatures.azurewebsites.net/img/dummy/beispielbanner-en.png" alt=Currently you cannot see the information. Please activate external sources to see the full email." /></a>';
          }
        }
      }

      if (!this.dataManager.employeeCampaignUrl || !this.dataManager.employeeCampaignImage) {
        return '';
      }

      // Generate image but not the campaign tracking because otherwise a view is tracked

      const rawSnippetTpl =
        '<a target="_blank" href="$$$CAMPAIGNURL$$$"><img id="campaignBannerId" src="$$$CAMPAIGNIMAGE$$$" $$$CAMPAIGNIMAGEDIMENSIONS$$$ alt="Aktuell kÃ¶nnen Sie einige Informationen nicht sehen. Bitte aktivieren Sie externe Inhalte, um die Mail vollstÃ¤ndig angezeigt zu bekommen." /></a>';

      let campaignUrl = this.dataManager.employeeCampaignUrl;

      const campaignImageWidth = this.dataManager.employeeCampaignImageWidth;
      const campaignImageHeight = this.dataManager.employeeCampaignImageHeight;

      if (campaignUrl && !campaignUrl.startsWith('http://') && !campaignUrl.startsWith('https://')) {
        campaignUrl = 'http://' + campaignUrl;
      }

      // Replace api url
      let ret = rawSnippetTpl.replace('$$$CAMPAIGNURL$$$', campaignUrl);

      // Replace img dimensions
      if (
        this.dataManager.employeeCampaignImage.includes('img/dummy/beispielbanner') ||
        !campaignImageWidth ||
        !campaignImageHeight
      ) {
        // If 'beispielbanner' then no dimensions or if no width/height
        ret = ret.replace('$$$CAMPAIGNIMAGEDIMENSIONS$$$', '');
      } else {
        const campaignDimensions =
          'width="' + campaignImageWidth.toString() + '" height="' + campaignImageHeight.toString() + '"';
        ret = ret.replace('$$$CAMPAIGNIMAGEDIMENSIONS$$$', campaignDimensions);
      }

      // Replace userid
      return ret.replace('$$$CAMPAIGNIMAGE$$$', this.dataManager.employeeCampaignImage);
    };

    /**
     * Generate HTML for signature graphic
     * @returns HTML snippet for displaying a signature graphic (or empty)
     */
    const generateSignaturGraphicHtmlForSignature = (): string => {
      // Mark campaign banner in signature designer as used
      if (!this.dataManager.sigGraphicProperties) {
        return '';
      }

      let ret = '';

      // Generate image but not the campaign tracking because otherwise a view is tracked
      const rawSnippetTpl =
        '<a target="_blank" href="$$$GRAPHICURL$$$"><img src="$$$GRAPHICIMAGE$$$" $$$GRAPHICIMAGEDIMENSIONS$$$ alt="Aktuell kÃ¶nnen Sie einige Informationen nicht sehen. Bitte aktivieren Sie externe Inhalte, um die Mail vollstÃ¤ndig angezeigt zu bekommen." /></a>';

      const sigGraphicProperties = JSON.parse(this.dataManager.sigGraphicProperties) as ISignaturePlaceholderValueImg;

      if (sigGraphicProperties) {
        let graphicUrl = sigGraphicProperties.url;
        let graphicImageWidth: number | undefined;
        let graphicImageHeight: number | undefined;

        if (
          sigGraphicProperties.initialdimension &&
          sigGraphicProperties.initialdimension.width &&
          sigGraphicProperties.initialdimension.height
        ) {
          graphicImageWidth = sigGraphicProperties.initialdimension.width;
          graphicImageHeight = sigGraphicProperties.initialdimension.height;
        }

        // Handle custom manually set width (applies to both width and height)
        if (
          sigGraphicProperties.imgdimension &&
          sigGraphicProperties.imgdimension.mode &&
          sigGraphicProperties.imgdimension.mode === 'custom'
        ) {
          graphicImageWidth = sigGraphicProperties.imgdimension.width;
          graphicImageHeight = sigGraphicProperties.imgdimension.height;
        }

        if (graphicUrl && !graphicUrl.startsWith('http://') && !graphicUrl.startsWith('https://')) {
          graphicUrl = 'http://' + graphicUrl;
        }

        // Replace api url
        ret = rawSnippetTpl.replace('$$$GRAPHICURL$$$', graphicUrl);

        // Reaplace image dimensions
        if (!graphicImageWidth || !graphicImageHeight) {
          // If 'beispielbanner' then no dimensions or if no width/height
          ret = ret.replace('$$$GRAPHICIMAGEDIMENSIONS$$$', '');
        } else {
          const campaignDimensions =
            'width="' + graphicImageWidth.toString() + '" height="' + graphicImageHeight.toString() + '"';
          ret = ret.replace('$$$GRAPHICIMAGEDIMENSIONS$$$', campaignDimensions);
        }

        // Reaplace image src
        const imgUrl =
          typeof sigGraphicProperties.image === 'string'
            ? sigGraphicProperties.image
            : sigGraphicProperties.image.$ngfDataUrl;
        ret = ret.replace('$$$GRAPHICIMAGE$$$', imgUrl || '');
      }

      return ret;
    };

    // Set value to false
    // Only when campaign html is generated then mark it as used so that the button in the signature designer turns blue
    this.dataManager.campaignIsUsed = false;

    let content = html;

    // Parse all placeholders in template
    const regExForPlaceholders = /\{\{(.*?)\}\}/g;

    //tag entry in editor
    let foundTagEntry;

    //values to replace the placeholders afterwards
    const context = {} as ISignatureDbField;

    do {
      foundTagEntry = regExForPlaceholders.exec(content);

      if (foundTagEntry) {
        //check if it is tag for campaign banner
        if (foundTagEntry[1] === 'kampagnen_banner') {
          // Campaign banner
          const campaignBannerSnippet = generateCampaignHtmlForUser(employeeId);

          //context to expand
          context[foundTagEntry[1]] = campaignBannerSnippet;
        } else if (foundTagEntry[1] === 'sig_grafik') {
          let sigGraphicProperties = {} as ISignaturePlaceholderImg;
          if (this.dataManager.sigGraphicProperties) {
            sigGraphicProperties = JSON.parse(this.dataManager.sigGraphicProperties) as ISignaturePlaceholderImg;
          }

          const tagOjectSigGraphic = fieldData[foundTagEntry[1]];
          context[foundTagEntry[1]] = generateSignaturGraphicHtmlForSignature();

          if (this.isImg(tagOjectSigGraphic)) {
            if (
              tagOjectSigGraphic.value &&
              sigGraphicProperties.value &&
              typeof tagOjectSigGraphic.value === 'object' &&
              !tagOjectSigGraphic.value.url &&
              !sigGraphicProperties.value.image
            ) {
              // object value but no url set. url is used by all objects
              tagOjectSigGraphic.markedAsMissing = true;
              tagOjectSigGraphic.markedAsUsed = true;
            } else if (
              (tagOjectSigGraphic.value &&
                typeof tagOjectSigGraphic.value === 'object' &&
                tagOjectSigGraphic.value.url) ||
              (sigGraphicProperties.value?.image && sigGraphicProperties.value.url)
            ) {
              tagOjectSigGraphic.markedAsMissing = false;
              tagOjectSigGraphic.markedAsUsed = true;
            } else {
              tagOjectSigGraphic.markedAsMissing = true;
              tagOjectSigGraphic.markedAsUsed = true;
            }
          }
        } else {
          // It's a regular tag so get field value from structure
          // Get value for tag
          const tagObject = fieldData[foundTagEntry[1]];

          if (!tagObject) {
            // if someone wrote their own day
            // to nothing
            context[foundTagEntry[1]] = ''; //context to expand
          } else if (typeof tagObject !== 'string' && !tagObject.value) {
            // element must be marked in red. Simple value and no value
            tagObject.markedAsMissing = true;
            tagObject.markedAsUsed = true;
            if (tagObject.locked === false) {
              // if the field value is not set and the employee is allowed to fill in the value himself then the day should appear in the preview.
              context[foundTagEntry[1]] = '{{' + tagObject.tag + '}}';
            } else {
              context[foundTagEntry[1]] = ''; //context to expand
            }
          } else if (this.isImg(tagObject) && tagObject.value && !tagObject.value.url) {
            // object value but no url set. url is used by all objects
            if (tagObject.tag == 'ma_foto') {
              tagObject.value.url = '#';
              tagObject.markedAsMissing = false;
              tagObject.markedAsUsed = true;
              context[foundTagEntry[1]] = generateImageHtmlFromObject(tagObject, signatureType);
            } else {
              tagObject.markedAsMissing = true;
              tagObject.markedAsUsed = true;
              if (tagObject.locked === false) {
                // if the field value is not set and the employee is allowed to fill in the value himself then the day should appear in the preview.
                context[foundTagEntry[1]] = '{{' + tagObject.tag + '}}';
              } else {
                context[foundTagEntry[1]] = ''; //context to expand
              }
            }
          } else if (typeof tagObject !== 'string' && tagObject.value) {
            // object value is set
            if (this.isImg(tagObject) && tagObject.type === 'image') {
              context[foundTagEntry[1]] = generateImageHtmlFromObject(tagObject, signatureType); //context to expand
            } else if (this.isLink(tagObject)) {
              context[foundTagEntry[1]] = generateLinkHtmlFromObject(tagObject, signatureType); //context to expand
            } else if (typeof tagObject.value === 'string') {
              context[foundTagEntry[1]] = tagObject.value; //context to expand
            }
            tagObject.markedAsMissing = false; // existing objects must be unmarked
            tagObject.markedAsUsed = true;
          }
        }
      }
    } while (foundTagEntry);

    content = content || '';

    // const template = Handlebars.compile(content, {
    //   noEscape: true
    // });

    // let replacedHtml = template(context);

    // //add invisible tracking pixel so that it is possible to track if the newest signature was integrated by the employee
    // replacedHtml += generateTrackingPixelHtml();

    // //set inline style for horizontal lines
    // replacedHtml = replacedHtml.replaceAll(
    //   '<hr />',
    //   '<hr style="border: none; margin:0; height: 1px; color: grey; background-color: grey" />'
    // );

    // // TODO: handle translations
    // // var lang = $translate.setActiveLang();
    // // var translatedTag = this.tagDataTranslations;

    // // for (var tagId in translatedTag) {
    // //     if (translatedTag.hasOwnProperty(tagId)) {
    // //         if (translatedTag[tagId] && translatedTag[tagId][lang]) {
    // //             replacedHtml = replacedHtml.split(tagId + '}}').join(translatedTag[tagId][lang] + '}}');
    // //         }
    // //     }
    // // }

    // return replacedHtml;
    return content;
  }

  /**
   * Get field structure as flat array so it can be used to generate signature preview
   * @param fieldStructure Field structure containing company and employee data fields
   * @returns {ISignatureFieldAsText[]} Array of fields indexed by entry tag
   */
  getFieldStructureAsFlatArray(fieldStructure: ISignatureGetJsonInfoFields): ISignatureDbField {
    // Process company and employee field structure
    const fieldsToSearch = [fieldStructure.company.groups, fieldStructure.employee.groups];

    const result = {} as ISignatureDbField;

    for (const fields of fieldsToSearch) {
      // Get entries from field structure
      const entries = fields.map(group => {
        return group.entries;
      });

      // Flatten the array
      const flatEntries = entries.reduce((accumulator, value) => accumulator.concat(value), []);

      // Assign entry object to object's tag as index
      flatEntries.map(entry => {
        result[entry.tag] = entry;
      });
    }

    return result;
  }

  getGlobalValuesAsArray(fieldStructure: ISignatureDbData): ISignatureDbField {
    return fieldStructure.employee;
  }
  getEmployeeAccountData(
    userId: string,
    datasetDefinitionId: string,
    fields: ISignatureGetJsonInfoFields
  ): Observable<void> {
    if (userId === 'dummy') {
      this.dataManager.employeeData = this.employeeDummyData;
      this.mergeDbDataWithStructure('employee', this.dataManager.employeeData, fields);
      return EMPTY;
    } else {
      const employeeData$ = this.employeeService.getEmployeeDefaultSignatures(userId);

      const datasetData$ = this.datasetService.getOneSpecificForUser(userId, datasetDefinitionId).pipe(
        map(dataset => {
          this.dataManager.specificUserSetId = dataset.id;
          this.dataManager.signatureDatasets.employeeDatasetTitle = dataset.title;
          return dataset.data;
        })
      );

      // TODO // When no dataset data present, create new profile
      const fieldData$ = employeeData$.pipe(
        mergeMap(employeeData => {
          return this.loadJsonFieldStructure().pipe(
            map(data => {
              const dataToSave = this.prepareDataForBackend('employee', data);
              //dataToSave['ma_vorname'] = employeeData.firstname;
            })
          );
        })
      );

      return forkJoin([employeeData$, datasetData$]).pipe(
        take(1),
        mergeMap(([employeeData, datasetData]) => {
          if (!datasetData) {
            return fieldData$;
          } else {
            const employeeResultData = JSON.parse(datasetData.replaceAll('\\%', '%')) as ISignatureDbField;

            //set firstname and lastname because it has an own column
            employeeResultData['ma_vorname'] = employeeData.firstname;
            employeeResultData['ma_nachname'] = employeeData.lastname;
            if (!employeeResultData['ma_email']) {
              employeeResultData['ma_email'] = employeeData.email;
            }

            const phone = employeeResultData['ma_tel'];

            if (Array.isArray(phone)) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              employeeResultData['ma_tel'] = phone.length > 0 ? phone[0] : '';
            }
            return of(employeeResultData);
          }
        }),
        map(employeeResultData => {
          if (employeeResultData) {
            this.dataManager.employeeData = employeeResultData;

            this.mergeDbDataWithStructure('employee', employeeResultData, fields);
          }
        })
      );
    }
  }

  /**
   * Prepare/Set employee dataset field info
   * @param fields - Fields Object to set it with
   * @param dbdata - Object with respected fields
   * @returns Observable to further process
   */
  getDummyEmployeeAccountData(fields: ISignatureGetJsonInfoFields, dbdata: ISignatureDbField): Observable<boolean> {
    this.dataManager.employeeData = dbdata;
    this.mergeDbDataWithStructure('employee', this.dataManager.employeeData, fields);
    return of(true);
  }

  getCompanyAccountData(
    datasetId: string,
    fields: ISignatureGetJsonInfoFields
  ): Observable<[UserGetAccountData, IDataset]> {
    const accountData$ = this.userService.getAccountData();
    const datasetData$ = this.datasetService.getOne(datasetId);

    return forkJoin([accountData$, datasetData$]).pipe(
      map(([accountData, datasetData]) => {
        if (datasetData.data) {
          this.dataManager.companyData = JSON.parse(datasetData.data.replaceAll('\\%', '%')) as ISignatureDbField;
        }

        this.mergeDbDataWithStructure('company', this.dataManager.companyData, fields);
        this.clearMarkedAsMissing(fields);

        // TODO: update interface for custom default icons
        if (accountData.customDefaultIcons) {
          this.dataManager.customDefaultIcons = JSON.parse(accountData.customDefaultIcons) as ISignatureDbField;
        }

        return [accountData, datasetData];
      })
    );
  }

  getCampaignDataForSignature(groupId: number | string): Observable<void> {
    if (typeof groupId === 'number') {
      return this.groupService.getOne(groupId).pipe(
        take(1),
        map(data => {
          if (data && (data.campaignImage || (data.activeEvent && data.activeEvent.TriggerCampaign))) {
            // Adding dimensions to the dataManager
            this.dataManager.employeeCampaignImageWidth = data.width;
            this.dataManager.employeeCampaignImageHeight = data.height;

            if (data.activeEvent && data.activeEvent.TriggerCampaign) {
              this.dataManager.employeeCampaignImage = data.activeEvent.TriggerCampaign.image as string;
              this.dataManager.employeeCampaignUrl = data.activeEvent.TriggerCampaign.url;

              this.dataManager.employeeCampaignImageWidth = data.activeEvent.TriggerCampaign?.resizedWidth
                ? +data.activeEvent.TriggerCampaign.resizedWidth
                : undefined;

              this.dataManager.employeeCampaignImageHeight = data.activeEvent.TriggerCampaign?.resizedHeight
                ? +data.activeEvent.TriggerCampaign.resizedHeight
                : undefined;
            } else {
              this.dataManager.employeeCampaignImage = data.campaignImage;
              this.dataManager.employeeCampaignUrl = data.campaignUrl;
            }
          } else {
            this.dataManager.employeeCampaignImage = undefined;
            this.dataManager.employeeCampaignUrl = undefined;
          }
        })
      );
    } else {
      //use default banner
      this.dataManager.employeeCampaignImage =
        this.translate.getActiveLang() === 'de'
          ? 'src/app/images/dummy/beispielbanner-de.png'
          : 'src/app/images/dummy/beispielbanner-en.png';

      this.dataManager.employeeCampaignUrl = 'https://www.mailtastic.com';
      return EMPTY;
    }
  }

  /**
   * Loads json field Structure for company and employee data
   */
  loadJsonFieldStructure(): Observable<ISignatureGetJsonInfoFields> {
    return this.signatureService.getJsonInfoFields();
  }

  /**
   * Remove unnecessary structure from data to prepare for storing in database
   * Signature data is used to store signature data including value "locked"
   * employee und company is only used when a news field value structure has to be created in the backend
   * if a single new value has to be stored the method saveFieldValue is used for every type of value
   * @param mode
   * @param fields
   * @returns
   */
  prepareDataForBackend(
    mode: 'company' | 'employee' | 'signatureData',
    fields: ISignatureGetJsonInfoFields
  ): ISignatureDbFieldValue {
    const checkAndAddHttp = (url: string, additionalCase = true): string => {
      if (url && !url.startsWith('http://') && !url.startsWith('https://') && additionalCase) {
        return `http://${url}`;
      } else {
        return url;
      }
    };

    const dbdata = {} as ISignatureDbFieldValue;

    if (mode === 'company' || mode === 'employee') {
      const fieldsToSearch = mode === 'company' ? fields.company.groups : fields.employee.groups;
      for (const field of fieldsToSearch) {
        for (const entry of field.entries) {
          const valueToSet = entry.value;
          // undefined case is equivalent to break
          if (valueToSet !== undefined) {
            if (entry.type === 'link' && this.isLinkValue(valueToSet)) {
              valueToSet.linkText = valueToSet.linkText ? valueToSet.linkText : valueToSet.url; //when no linktext is set->use the url
              valueToSet.url = checkAndAddHttp(valueToSet.url); //if url does not start with http or https -> add it
            } else if (entry.type === 'image' && this.isImgValue(valueToSet)) {
              if (valueToSet.url) {
                // # is the sign to not use any link on image
                if (!valueToSet.altText) {
                  //when no alttext is set->use the url
                  //if url is marked with # -> use tag as alttext
                  valueToSet.altText = valueToSet.url === '#' ? entry.tag : valueToSet.url;
                }
              }
              //if url does not start with http or https -> add it
              //whatsapp and skype have special treatment with urls
              valueToSet.url = checkAndAddHttp(
                valueToSet.url,
                !(entry.tag === 'ma_skype' || entry.tag === 'u_whatsapp' || valueToSet.url === '#')
              );
            }
            dbdata[entry.tag] = valueToSet;
          }
        }
      }
      return dbdata;
    } else {
      // TODO implement 'signatureData' case
    }
    return {} as ISignatureDbFieldValue;
  }

  mergeDbDataWithStructure(
    mode: 'company' | 'employee' | 'signatureData',
    dbdata: ISignatureDbData | ISignatureDbField,
    structure: ISignatureGetJsonInfoFields
  ): void {
    if (mode === 'company' || mode === 'employee') {
      dbdata = dbdata as ISignatureDbField;
      const fieldsToSearch = mode === 'company' ? structure.company.groups : structure.employee.groups;
      for (const field of fieldsToSearch) {
        for (const entry of field.entries) {
          if (entry?.tag && entry?.tag !== 'sig_grafik' && dbdata[entry?.tag]) {
            const dbEntry = dbdata[entry?.tag] as string;

            // Handle image field - dimensions, linkcolor and underline
            // These are stored here because they have the scope of a signature
            if (entry.type === 'image' && this.isImg(entry) && this.isImg(dbEntry)) {
              if (dbEntry.imgdimension) {
                entry.imgdimension = dbEntry.imgdimension;
              }

              if (!entry.styles) {
                entry.styles = {};
              }

              if (dbEntry.styles?.linkcolor) {
                entry.styles.linkcolor = dbEntry.styles.linkcolor;
                entry.stylelinkcolor = dbEntry.styles.linkcolor;
              }

              if (dbEntry.styles?.underline) {
                entry.styles.underline = dbEntry.styles.underline;
                entry.styleunderline = dbEntry.styles.underline;
              }
            }

            // Handle link field
            if (entry.type === 'link' && this.isLink(entry) && this.isLink(dbEntry)) {
              if (dbEntry.linkcolor) {
                entry.linkcolor = dbEntry.linkcolor;
              }

              if (dbEntry.underline) {
                entry.underline = dbEntry.underline;
              }
            }
            entry.value = dbEntry;
          } else {
            entry.value = '';
          }
        }
      }
    } else {
      dbdata = dbdata as ISignatureDbData;

      // Proccess company fields
      for (const group of structure.company.groups) {
        for (const entry of group.entries) {
          if (entry?.tag && entry?.tag !== 'sig_grafik' && dbdata.company[entry?.tag]) {
            const currentDbField = dbdata.company[entry?.tag] as TSignaturePlaceholder;
            entry.locked = currentDbField.locked;

            // Handle image field - dimensions, linkcolor and underline
            // These are stored here because they have the scope of a signature
            if (entry.type === 'image' && this.isImg(entry) && this.isImg(currentDbField)) {
              entry.imgdimension = currentDbField.imgdimension;
              if (!entry.styles) {
                entry.styles = {};
              }
              if (currentDbField.styles?.linkcolor) {
                entry.styles.linkcolor = currentDbField.styles.linkcolor;
                entry.stylelinkcolor = currentDbField.styles.linkcolor;
              }

              if (currentDbField.styles?.underline) {
                entry.styles.underline = currentDbField.styles.underline;
                entry.styleunderline = currentDbField.styles.underline;
              }
            }

            // Handle link field
            if (entry.type === 'link' && this.isLink(entry) && this.isLink(currentDbField)) {
              if (currentDbField.linkcolor) {
                entry.linkcolor = currentDbField.linkcolor;
              }

              if (currentDbField.underline) {
                entry.underline = currentDbField.underline;
              }
            }
          }
        }
      }

      // Process employee fields
      for (const group of structure.employee.groups) {
        for (const entry of group.entries) {
          if (entry?.tag && entry?.tag !== 'sig_grafik' && dbdata.employee[entry?.tag]) {
            const currentDbField = dbdata.employee[entry?.tag] as TSignaturePlaceholder;
            const globalValue = this.globalValues[entry.tag] as TSignaturePlaceholder;
            entry.locked = currentDbField.locked;
            // Handle global value
            if (
              globalValue?.global &&
              globalValue.value &&
              this.isImg(entry) &&
              this.isImg(this.globalValues[entry.tag]) &&
              entry.value?.url
            ) {
              entry.global = currentDbField.global;
              const globalValue = this.globalValues[entry.tag];
              if (this.isImg(globalValue) && globalValue.value) {
                globalValue.value.image = entry.value.image;
                entry.value = globalValue.value;
                this.globalValues[entry.tag] = globalValue;
              }
            }

            // Handle image field - dimensions, linkcolor and underline
            // These are stored here because they have the scope of a signature
            if (entry.type === 'image' && this.isImg(entry) && this.isImg(currentDbField)) {
              entry.imgdimension = currentDbField.imgdimension;
              if (!entry.styles) {
                entry.styles = {};
              }
              if (currentDbField.styles?.linkcolor) {
                entry.styles.linkcolor = currentDbField.styles.linkcolor;
                entry.stylelinkcolor = currentDbField.styles.linkcolor;
              }

              if (currentDbField.styles?.underline) {
                entry.styles.underline = currentDbField.styles.underline;
                entry.styleunderline = currentDbField.styles.underline;
              }
            }

            // Handle link field
            if (entry.type === 'link' && this.isLink(entry) && this.isLink(currentDbField)) {
              if (currentDbField.linkcolor) {
                entry.linkcolor = currentDbField.linkcolor;
              }

              if (currentDbField.underline) {
                entry.underline = currentDbField.underline;
              }
            }
          }
        }
      }
    }
  }

  /**
   * Clears every marked as missing data flag and markedAsUsedAndSet in frontend structure
   * These flags are primarily used to show the correct color on the button in the designer (red, green, blue)
   * @param fieldStructure Field structure containing company and employee data fields
   */
  clearMarkedAsMissing(fieldStructure: ISignatureGetJsonInfoFields): void {
    const fieldsToSearch = [fieldStructure.company.groups, fieldStructure.employee.groups];

    for (const fields of fieldsToSearch) {
      // Get entries from field structure
      const entries = fields.map(group => {
        return group.entries;
      });

      // Flatten the array
      const flatEntries = entries.reduce((accumulator, value) => accumulator.concat(value), []);

      // TODO: delete markedAsMissing and markedAsUsed
    }
  }

  getDataManagerSignatureDatasets(): { employeeDataset: string; companyDataset: string } {
    return {
      employeeDataset: this.dataManager.signatureDatasets.employeeDatasetId,
      companyDataset: this.dataManager.signatureDatasets.companyDatasetId
    };
  }

  getDataManagerFields(): ISignatureGetJsonInfoFields {
    return this.dataManager.fields;
  }

  /**
   * Check if field is of type image
   * @param variableToCheck Field to check
   * @returns {boolean}
   */
  isImg(variableToCheck: unknown): variableToCheck is ISignaturePlaceholderImg {
    return (
      ((variableToCheck as ISignaturePlaceholderImg).imgdimension &&
        (variableToCheck as ISignaturePlaceholderImg).imgdimension.mode !== undefined) ||
      (variableToCheck as ISignaturePlaceholderImg).type === 'image'
    );
  }

  /**
   * Check if field is of type image
   * @param variableToCheck Field to check
   * @returns {boolean}
   */
  isImgValue(variableToCheck: unknown): variableToCheck is ISignaturePlaceholderValueImg {
    return (variableToCheck as ISignaturePlaceholderValueImg).image !== undefined;
  }

  /**
   * Check if field is of type link
   * @param variableToCheck Field to check
   * @returns {boolean}
   */
  isLink(variableToCheck: unknown): variableToCheck is ISignaturePlaceholderURL {
    const link = variableToCheck as ISignaturePlaceholderURL;
    return link.type === 'link' || link?.linkcolor !== undefined || link?.underline !== undefined;
  }

  /**
   * Check if field is of type link
   * @param variableToCheck Field to check
   * @returns {boolean}
   */
  isLinkValue(variableToCheck: unknown): variableToCheck is ISignaturePlaceholderValueURL {
    return (variableToCheck as ISignaturePlaceholderValueURL).url !== undefined;
  }

  /**
   * Check if field is of type singlevalue aka text
   * @param variableToCheck Field to check
   * @returns {boolean}
   */
  isTxt(variableToCheck: unknown): variableToCheck is ISignaturePlaceholderTxt {
    return (variableToCheck as ISignaturePlaceholderTxt).type === 'singlevalue';
  }

  /**
   * Creates an image field placeholder with optional defined params or the default one.
   * @param props - the properties of the image placeholder field
   * @param valueProps - the properties of the image placeholder field value
   * @returns the image field placeholder
   */
  createImageField(
    props?: Partial<ISignaturePlaceholderImg>,
    valueProps?: Partial<ISignaturePlaceholderValueImg>
  ): ISignaturePlaceholderImg {
    const propsWidth = props?.imgdimension?.width;
    const valuePropsWidth = valueProps?.imgdimension?.width;
    const imgdimensionWithMode = (propsWidth
      ? props.imgdimension
      : valuePropsWidth
        ? valueProps.imgdimension
        : DEFAULT_IMAGE.imgdimension) as unknown as ImageDimensionWithMode; // need to reassign type due to incompitable types

    return {
      ...props,
      defaultImage: props?.defaultImage ?? DEFAULT_IMAGE.defaultImage,
      disabled: props?.disabled ?? DEFAULT_IMAGE.disabled,
      imgdimension: imgdimensionWithMode,
      label: props?.label ?? DEFAULT_IMAGE.label,
      locked: props?.locked ?? DEFAULT_IMAGE.locked,
      styles: props?.styles ?? DEFAULT_IMAGE.styles,
      tag: props?.tag ?? DEFAULT_IMAGE.tag,
      type: props?.type ?? DEFAULT_IMAGE.type,
      value: this.createImageValueField(valueProps)
    };
  }

  /**
   * Creates an image field placeholder value with optional defined params or the default one.
   * @param props - the properties of the image placeholder field value
   * @param permission - how strict should the copy be
   * @returns the image field placeholder value
   */
  createImageValueField(
    props?: Partial<ISignaturePlaceholderValueImg>,
    permission: Permission = 'soft'
  ): ISignaturePlaceholderValueImg {
    const strictCopy: ISignaturePlaceholderValueImg = {
      altText: props?.altText ?? DEFAULT_IMAGE_VALUE.altText,
      etag: props?.etag ?? DEFAULT_IMAGE_VALUE.etag,
      image: props?.image ?? DEFAULT_IMAGE_VALUE.image,
      initialdimension: props?.initialdimension ?? DEFAULT_IMAGE_VALUE.initialdimension,
      linkcolor: props?.linkcolor ?? DEFAULT_IMAGE_VALUE.linkcolor,
      linkText: props?.linkText ?? DEFAULT_IMAGE_VALUE.linkText,
      showAs: props?.showAs ?? DEFAULT_IMAGE_VALUE.showAs,
      type: 'image',
      underline: props?.underline ?? DEFAULT_IMAGE_VALUE.underline,
      url: props?.url ?? DEFAULT_IMAGE_VALUE.url,
      whichImage: props?.whichImage ?? DEFAULT_IMAGE_VALUE.whichImage
    };
    return permission === 'strict' ? strictCopy : { ...props, ...strictCopy };
  }

  /**
   * Creates a link field placeholder with optional defined params or the default one.
   * @param props - the properties of the link placeholder field
   * @param valueProps - the properties of the link placeholder field value
   * @returns the link field placeholder
   */
  createLinkField(
    props?: Partial<ISignaturePlaceholderURL>,
    valueProps?: Partial<ISignaturePlaceholderValueURL>
  ): ISignaturePlaceholderURL {
    return {
      ...props,
      disabled: props?.disabled ?? DEFAULT_LINK.disabled,
      label: props?.label ?? DEFAULT_LINK.label,
      locked: props?.locked ?? DEFAULT_LINK.locked,
      tag: props?.tag ?? DEFAULT_LINK.tag,
      type: props?.type ?? DEFAULT_LINK.type,
      value: this.createLinkValueField(valueProps)
    };
  }

  /**
   * Creates a link field placeholder value with optional defined params or the default one.
   * @param props - the properties of the link placeholder field value
   * @returns the link field placeholder value
   */
  createLinkValueField(props?: Partial<ISignaturePlaceholderValueURL>): ISignaturePlaceholderValueURL {
    return {
      ...props,
      linkText: props?.linkText ?? DEFAULT_LINK_VALUE.linkText,
      type: props?.type ?? DEFAULT_LINK_VALUE.type,
      url: props?.url ?? DEFAULT_LINK_VALUE.url
    };
  }
}
