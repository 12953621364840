<li
  data-cy="list-row"
  class="listrow mb-1"
  [ngClass]="{
    even: isEven,
    odd: isOdd,
    redrow: isRedRow,
    selected: isSelected,
    timingerror: rowError
  }"
  data-cy="list-row"
>
  <ng-content></ng-content>
</li>
