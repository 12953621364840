<!-- Step1 check the group assignment with member and signature rollout status-->
<mt-wizard-tab
  [(parent)]="installation.setupCheckStep[0]"
  class="step2 detailpanel_blue"
>
  <div
    class="font16"
    [innerHTML]="
      'html.signature_setup_check_colleagues_description' | transloco
    "
  ></div>
  <div *ngIf="!isUserLinkedWithGroup || !(activeGroups.length > 0)">
    <!-- Check if group is not assigned or assigned group with no member -->
    <mt-wizard-tab
      data-cy="assign-department-tab"
      [(parent)]="installation.setupCheckSubSteps[0]"
      class="step2 setup-check"
      (headerClick)="clickedTabPos($event, 0)"
    >
      <p transloco="signature_setup_check_group_member_description"></p>
      <b
        class="martop10"
        transloco="signature_setup_check_group_member_note"
      ></b>
      <div class="flex-center-right martop10">
        <mt-button
          data-cy="open-assign-department-settings-btn"
          text="open_signature_settings"
          color="primary"
          (click)="redirectToSignatureEditWizard('assignDepartment')"
        ></mt-button>
      </div>
    </mt-wizard-tab>
  </div>

  <!-- Check if signature is not rollout yet -->
  <div *ngIf="!isSignatureRollOut">
    <mt-wizard-tab
      data-cy="rollout-tab"
      [(parent)]="installation.setupCheckSubSteps[1]"
      class="step2 setup-check"
      (headerClick)="clickedTabPos($event, 1)"
    >
      <p transloco="signature_setup_check_rollout_status_description"></p>
      <b
        class="martop10"
        transloco="signature_setup_check_rollout_status_note"
      ></b>
      <div class="flex-center-right martop10">
        <mt-button
          data-cy="open-rollout-settings-btn"
          text="open_signature_settings"
          color="primary"
          (click)="redirectToSignatureEditWizard('rollout')"
        ></mt-button>
      </div>
    </mt-wizard-tab>
  </div>
</mt-wizard-tab>
