<ng-container *transloco="let translate">
  <form class="form-horizontal" *ngIf="selected$ | async as selectedValue">
    <mt-custom-modal headline="{{ translate('add-directory-attribute') }}">
      <ng-container modal-body>
        <div class="marbot15">
          <div class="marbot5">
            <label for="datasetTitle" class="control-label format3">
              {{ translate('select-attribute') }} *
            </label>
          </div>
          <mat-form-field>
            <mat-select
              #singleSelect
              placeholder="{{ selectedValue.val }}"
              (selectionChange)="selected = $event.value"
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="searchCtrl"
                  [preventHomeEndKeyPropagation]="true"
                  placeholderLabel="{{ translate('type-field-name') }}"
                  noEntriesFoundLabel="{{ translate('emptystate_data_3_hl') }}"
                >
                  <mat-icon ngxMatSelectSearchClear>X</mat-icon>
                </ngx-mat-select-search>
              </mat-option>
              <ng-container>
                <mat-option
                  *ngFor="let extensionProperty of all$ | async"
                  [value]="extensionProperty.name"
                >
                  {{ extensionProperty.name }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-container>

      <div modal-footer>
        <div class="flex-center-right">
          <mt-button
            ngbAutoFocus
            class="mt_left"
            color="default"
            text="Cancel"
            (click)="modal.close()"
          ></mt-button>
          <mt-button
            color="primary"
            text="Save"
            [disabled]="!selectedValue.val"
            (click)="modal.close(selectedValue.val)"
          ></mt-button>
        </div>
      </div>
    </mt-custom-modal>
  </form>
</ng-container>
