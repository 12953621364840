<mt-custom-modal
  headline="{{ inputData.header | transloco }}"
  *ngIf="syncProceed$ | async as proceedState"
>
  <div modal-body>
    <div class="content">
      <div class="infobox infobox-error w-100">
        <span class="symbol glyphicon glyphicon-alert"></span>
        <div class="text">
          <p [transloco]="inputData.warningText"></p>
        </div>
      </div>
      <div *ngIf="!proceedState.state">
        <div
          class="format2 schrift_schwarz_imp marbot10"
          [transloco]="inputData.credentialTextTag"
        ></div>
        <div class="info2">
          <div class="text ng-binding companynamearea">
            <div class="format2 marleft20">{{ inputData.syncDisplayName }}</div>
            <div class="marleft20">{{ inputData.syncEmail }}</div>
          </div>
        </div>
      </div>
      <div *ngIf="proceedState.state">
        <div
          class="format2 remove-emp-desc"
          [transloco]="inputData.cancelConnectionQuestion"
        ></div>
        <div class="form-group martop10" [formGroup]="syncForm">
          <span class="css-select-moz">
            <select
              class="css-select form-control selectgroups input-sm"
              formControlName="currentSyncDisconnectType"
            >
              <option
                value="selectadjustment"
                disabled
                transloco="selectadjustment"
              ></option>
              <option
                value="remove_employee"
                transloco="remove_employee"
              ></option>
              <option value="keep_employee" transloco="keep_employee"></option>
            </select>
          </span>
        </div>
        <div
          *ngIf="syncForm.value.currentSyncDisconnectType === 'remove_employee'"
        >
          <div class="info">
            <div class="format innerheadline" transloco="Removeemployee"></div>
            <div class="text">
              <p [transloco]="inputData.removeEmployeeInformation"></p>
            </div>
          </div>
        </div>
        <div
          *ngIf="syncForm.value.currentSyncDisconnectType === 'keep_employee'"
        >
          <div class="info">
            <div class="format innerheadline" transloco="retainemployees"></div>
            <div class="text">
              <p [transloco]="inputData.keepEmployeeInformation"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div modal-footer>
    <div class="flex-center-right">
      <div class="margin-left">
        <mt-button
          (click)="modal.close('abort')"
          class="mt_secondary mt_left"
          color="primary"
          text="abort"
        ></mt-button>
        <mt-button
          *ngIf="!proceedState.state"
          class="roboto_light"
          color="secondary"
          text="proceeding"
          (click)="syncProceed()"
        >
        </mt-button>
        <mt-button
          *ngIf="proceedState.state"
          [disabled]="
            syncForm.value.currentSyncDisconnectType === 'selectadjustment'
          "
          class="roboto_light"
          color="secondary"
          text="proceeding"
          (click)="modal.close(syncForm.value.currentSyncDisconnectType)"
        >
        </mt-button>
      </div>
    </div>
  </div>
</mt-custom-modal>
