import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { TranslocoModule } from '@ngneat/transloco';

// Interfaces
import { O365ExtensionProperty } from '@modules/o365/model/interfaces/o365-preferences.interface';

// Components
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';

const MIN_SEARCH_LENGTH = 2;

@Component({
  selector: 'mt-add-directory-extension-attribute',
  templateUrl: './add-directory-extension-attribute.component.html',
  styleUrls: ['./add-directory-extension-attribute.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    FormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    NgFor,
    NgIf,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    TranslocoModule
  ]
})
export class AddDirectoryExtensionAttributeComponent {
  public modal = inject(NgbActiveModal);

  /**
   * Input of extension properties
   */
  o365ExtensionProperties!: O365ExtensionProperty[];

  /**
   * Observable to track the selected string
   */
  set selected(val: string) {
    this._selected$.next(val);
  }
  private _selected$ = new BehaviorSubject('');
  selected$ = this._selected$.asObservable().pipe(map(val => ({ val })));

  /**
   * FormControl for handling search input
   */
  searchCtrl = new FormControl<string>('', { nonNullable: true });

  /**
   * Returns a list of extension properties with filter if exist
   */
  all$ = this.searchCtrl.valueChanges.pipe(
    startWith(''),
    filter(searchText => searchText.length === 0 || searchText.length >= MIN_SEARCH_LENGTH), // Allow searching with 0 chars to load initial records
    distinctUntilChanged(),
    debounceTime(500),
    map(searchText =>
      searchText
        ? this.o365ExtensionProperties.filter(attr => attr.name.toLowerCase().includes(searchText.toLowerCase()))
        : this.o365ExtensionProperties
    )
  );
}
