<div class="flex-center-all main">
  <mt-upload-csv-button
    (csvData)="csvData.emit($event)"
    (errorMsg)="errorMsg.emit($event)"
  ></mt-upload-csv-button>

  <p class="mt-3 text-center" transloco="upload_csv_file_info"></p>

  <a
    class="mt-1 text-decoration-underline"
    href="https://help.mailtastic.com/en/articles/4752798-tracking-domains-and-contacts"
    target="_blank"
    transloco="csv_import_link_helpdesk"
  >
  </a>
</div>
