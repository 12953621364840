<div class="detailpanel position-relative">
  <div data-hj-suppress class="header">
    <span class="break-line" data-cy="tab-container-header">{{ header }}</span>
    <span
      *ngIf="parent.iconTitle"
      class="info-icon baseline-top"
      tooltipClass="status-info-tooltip status-info-tooltip"
      tooltipPlacement="top"
      ngbTooltip="{{ parent.iconTitle | transloco }}"
    >
      <mt-svg
        *ngIf="parent.iconSrc"
        class="mt-grau_opaque"
        src="{{ parent.iconSrc }}"
        size="18"
      ></mt-svg>
    </span>
    <div *ngIf="toggleButton" class="flex pull-right">
      <span transloco="status" class="marright10"></span>

      <mt-switch
        [checked]="toggleButtonStatus"
        [isSmallSwitch]="false"
        [tooltipText]="toggleButtonStatus ? 'paused_info' : 'aktive_info'"
        (changeStatus)="onChangeStatus($event)"
      ></mt-switch>
    </div>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>
