import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, filter, Observable, switchMap, take, tap } from 'rxjs';
import { ClaimsService } from '@services/claims/claims.service';
import { ModalService } from 'src/app/services/modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class GatedContentService {
  private modalService = inject(ModalService);
  private claimsService = inject(ClaimsService);

  /**
   * Current status of modal is opened or not
   */
  private _gatedContentInfoModalOpened$ = new BehaviorSubject<boolean>(false);
  gatedContentInfoModalOpened$ = this._gatedContentInfoModalOpened$.asObservable();

  /**
   * Open gated content modal
   */
  openGatedContentInfoModal(): Observable<boolean> {
    return this.claimsService.validateClaim('Billing:Manage').pipe(
      switchMap(() => this.gatedContentInfoModalOpened$),
      take(1),
      filter(isOpened => !isOpened),
      tap(() => this.changeValueOfGatedContentInfoModalOpened(true)),
      switchMap(() => this.modalService.openGatedContentInfoModal()),
      tap(() => this.changeValueOfGatedContentInfoModalOpened(false))
    );
  }

  /**
   * Update value to know gated content info modal is opened or not
   * @param value - Boolean value
   */
  changeValueOfGatedContentInfoModalOpened(value: boolean): void {
    this._gatedContentInfoModalOpened$.next(value);
  }
}
