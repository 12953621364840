import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISignatureGetAll } from 'src/app/services/signature/signature-service.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgClass, DatePipe } from '@angular/common';
import { SignatureRolledoutStatusPipe } from '@shared/pipes/signature-rolledout-status/signature-rolledout-status.pipe';
import { ClaimAuthorizationDirective } from '@shared/directives/claim-authorization/claim-authorization.directive';

@Component({
  selector: 'signature-rollout-status-col',
  templateUrl: './signature-rollout-status-col.component.html',
  styleUrls: ['./signature-rollout-status-col.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgbTooltip,
    TranslocoModule,
    DatePipe,
    SignatureRolledoutStatusPipe,
    ClaimAuthorizationDirective
  ]
})
export class SignatureRolloutStatusColComponent {
  /**
   * Determines that the signature row is selected
   * @defaultValue false
   */
  @Input() isRowSelected = false;

  /**
   * Item of signature
   */
  @Input() item!: ISignatureGetAll;

  /**
   * Holds the flag to check if signature is rolledout atleast once
   */
  @Input() checkRolledOutAtLeastOnce!: boolean;

  /**
   * Triggers rollout action
   */
  @Output() triggerRollOut = new EventEmitter<void>();
}
