import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { LeadsList } from 'src/app/model/enums/leads-list-type.enum';
import { DomainInputFields } from 'src/app/model/interfaces/recipient.interface';
import { IResponse, IResponseData, ResponseData } from 'src/app/model/interfaces/response.interface';
import {
  TargetGroupList,
  ITargetGroupListEntryCampaign,
  ITargetGroupListModel,
  ITargetGroupListEntry
} from 'src/app/model/interfaces/target-group-list.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { AlertService } from '../alert/alert.service';
import { IQueryObj, TargetGroupEntriesQueryObj } from '../query-helper/query-helper-service.interface';
import { QueryHelperService } from '../query-helper/query-helper.service';
import {
  CreateTargetGroup,
  CreateTargetGroupResponse,
  ITargetGroupListEntriesStatistics,
  TargetGroupGetTargetGroupEntries,
  TargetGroupsGetTargetGroups
} from './target-group-service.type';
import { NavigationSidebarService } from '@services/navigation-sidebar/navigation-sidebar.service';

export interface ITargetGroupEdit extends Omit<ITargetGroupListModel, 'activeCampaignId'> {
  activeCampaignId?: number | null;
}

@Injectable({
  providedIn: 'root'
})
export class TargetGroupService {
  private alert = inject(AlertService);
  private http = inject(HttpClient);
  private navigationSidebarService = inject(NavigationSidebarService);
  private operator = inject(CustomOperators);
  private queryHelperService = inject(QueryHelperService);

  /**
   * Creates a new target group
   * @param targetGroup - New target group to create
   * @returns Newly created target group
   */
  createTargetGroup(targetGroup: CreateTargetGroup): Observable<CreateTargetGroupResponse> {
    return this.http.post<ResponseData<CreateTargetGroupResponse>>('/target-groups', targetGroup).pipe(
      this.operator.extractResponseData(),
      tap(() => this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'targetGroups'))
    );
  }

  /**
   * Edits the target group
   * @param targetGroupList - Target group to edit
   * @returns New target group
   */
  editTargetGroup(
    targetGroupList: TargetGroupList | ITargetGroupListEntryCampaign | ITargetGroupEdit | TargetGroupsGetTargetGroups
  ): Observable<ITargetGroupListModel> {
    return this.http
      .put<ResponseData<ITargetGroupListModel>>('/target-groups', targetGroupList)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Renames the target group
   * @param targetGroupId
   * @param title
   * @returns
   */
  renameTargetGroup(targetGroupId: string, title: string): unknown {
    return this.http
      .put('/target-groups/rename', { id: targetGroupId, title: title })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * //TODO: Refactor in backend
   *
   * Should be a separate route - handles both GET / and GET /:id
   * Returns either array(GET /) or object (GET /:id)
   *
   *
   * Gets the target group
   * @param queryObj - Object containing query params
   * @returns List of target groups
   */
  getTargetGroups(queryObj?: IQueryObj): Observable<TargetGroupsGetTargetGroups[]> {
    return this.http
      .get<
        IResponseData<TargetGroupsGetTargetGroups[]>
      >(`/target-groups/${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get the target group by id
   * @param targetGroupListId - Id of target group list
   * @param queryObj - Object containing query params
   * @returns Object of target group of provided id
   */
  getTargetGroup(targetGroupListId: string, queryObj?: IQueryObj): Observable<TargetGroupsGetTargetGroups> {
    return this.http
      .get<
        IResponseData<TargetGroupsGetTargetGroups>
      >(`/target-groups/${targetGroupListId}${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the count of target groups
   * @param queryObj - query object holding the keys or empty
   * @returns Count of target groups that exist
   */
  countTargetGroups(queryObj?: IQueryObj): Observable<number> {
    return this.http
      .get<IResponseData<number>>(`/target-groups/count${this.queryHelperService.createQuerySearch(queryObj)}`)
      .pipe(
        map(value => {
          if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
          return value.data;
        })
      );
  }

  /**
   * Deletes one target group
   * @param id - Target group id to delete
   * @returns Observable of the response success
   */
  deleteTargetGroupList(id: string): Observable<true> {
    return this.http.delete<IResponse>(`/target-groups/${id}`).pipe(this.operator.extractResponse());
  }

  /**
   * Gets the statistic of the target group
   * @param begin
   * @param end
   * @param targetGroupId
   * @returns
   */
  getTargetGroupStatisticsSingle(begin: string, end: string, targetGroupId: string): unknown {
    const url = `target-groups/data/statistics/single/?begin=${begin}&end=${end}&targetGroupId=${targetGroupId}`;
    return this.http.get(url).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Get the target group list entries as per the params
   * @param targetGroupListId - Id of target group list
   * @param additionalParam - Object containing additional params
   * @param queryObj - Object containing query params
   * @returns List of target group list entries
   */
  getTargetGroupEntries(
    targetGroupListId?: string,
    additionalParam?: TargetGroupEntriesQueryObj,
    queryObj?: IQueryObj
  ): Observable<TargetGroupGetTargetGroupEntries[]> {
    const query = this.queryHelperService.createQuery(
      additionalParam ? Object.assign({}, queryObj, additionalParam) : queryObj
    );
    const url = `/target-groups/${targetGroupListId || ''}/target-group-entries/${query}&sort=content`;
    return this.http
      .get<IResponseData<TargetGroupGetTargetGroupEntries[]>>(url)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Get the target group list entry as per id and given params
   * @param id - ID of target group list entry
   * @param targetGroupListId - ID of target group list
   * @param additionalParam - Object containing additional params
   * @param queryObj - Object containing query params
   * @returns List of target group list entries
   */
  getTargetGroupEntry(
    id: string,
    targetGroupListId?: string,
    additionalParam?: TargetGroupEntriesQueryObj,
    queryObj?: IQueryObj
  ): Observable<TargetGroupGetTargetGroupEntries> {
    const query = this.queryHelperService.createQuery(
      additionalParam ? Object.assign({}, queryObj, additionalParam) : queryObj
    );
    const url = `/target-groups/${targetGroupListId || ''}/target-group-entries/${id}${query}`;
    return this.http
      .get<IResponseData<TargetGroupGetTargetGroupEntries>>(url)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the count of target group entries
   * @param targetGroupListId
   * @param queryObj - Object containing query parameters
   * @param type - The type of list `accounts` or `contacts`
   * @returns Observable of total number of target groups
   */
  countTargetGroupEntries(targetGroupListId: string, queryObj: IQueryObj, type?: LeadsList): Observable<number> {
    const query = this.queryHelperService.createQuery(type ? Object.assign({}, queryObj, { type }) : queryObj);
    // Todo : query params
    const url = `/target-groups/${targetGroupListId}/target-group-entries/count${query}&doNotUseProspects=true`;
    return this.http.get<IResponseData<number>>(url).pipe(
      map(value => {
        if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
        return value.data;
      })
    );
  }

  /**
   * Gets the statistics of the target group in relation to the target group entry
   * @param begin - Start date
   * @param end - End date
   * @param targetGroupId - Id of target group list
   * @param targetGroupEntryId - Id of target group list entry
   * @returns Observable of ITargetGroupListEntriesStatistics to load into graph
   */
  getTargetGroupEntryStatisticsSingle(
    begin: string,
    end: string,
    targetGroupId?: string,
    targetGroupEntryId?: string
  ): Observable<ITargetGroupListEntriesStatistics> {
    const url = `/target-groups/target-group-entries/data/statistics/single/?begin=${begin}&end=${end}&targetGroupId=${
      targetGroupId || ''
    }&targetGroupEntryId=${targetGroupEntryId || ''}`;
    return this.http
      .get<IResponseData<ITargetGroupListEntriesStatistics>>(url)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates the target group list entries
   * @param targetGroupListEntries - Object or array of target group entries
   * @returns Observable of the response success
   */
  updateTargetGroupListEntry(
    targetGroupListEntries:
      | TargetGroupGetTargetGroupEntries
      | TargetGroupGetTargetGroupEntries[]
      | ITargetGroupListEntry[]
  ): Observable<true> {
    return this.http
      .put<IResponse>('/target-groups/target-group-entries', targetGroupListEntries)
      .pipe(this.operator.extractResponse());
  }

  /**
   * Creates a new target group list entries
   * @param targetGroupListEntry - Object or array of target group entries
   * @returns Observable of the response success with target group entries
   */
  createTargetGroupListEntries(
    targetGroupListEntry: DomainInputFields | DomainInputFields[]
  ): Observable<ITargetGroupListEntry[]> {
    return this.http
      .post<IResponseData<ITargetGroupListEntry[]>>('/target-groups/target-group-entries', targetGroupListEntry)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Deletes the target group list entry
   * @param targetGroupListEntryId - Id of target group list entry
   * @returns Observable of the response success
   */
  deleteTargetGroupListEntry(targetGroupListEntryId: string): Observable<true> {
    return this.http
      .delete<IResponse>(`/target-groups/target-group-entries/${targetGroupListEntryId}`)
      .pipe(this.operator.extractResponse());
  }

  /**
   * Gets the new prospect single entry
   * @param id - Id of prospect
   * @returns Object of prospect
   */
  getNewProspectEntry(id: string): Observable<TargetGroupEntriesQueryObj> {
    return this.http
      .get<IResponseData<TargetGroupEntriesQueryObj>>(`/target-groups/new-prospects/${id}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the new prospect entries (Target group entries where isProspect true)
   * @param id - Prospect id
   * @param queryObj - Object containing query params
   * @returns List of new prospect entries
   */
  getNewProspectEntries(
    additionalParam?: TargetGroupEntriesQueryObj,
    id?: string,
    queryObj?: IQueryObj
  ): Observable<TargetGroupGetTargetGroupEntries[]> {
    const query = this.queryHelperService.createQuery(
      additionalParam ? Object.assign({}, queryObj, additionalParam) : queryObj
    );
    return this.http
      .get<IResponseData<TargetGroupGetTargetGroupEntries[]>>(`/target-groups/new-prospects/${id || ''}${query}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Counts the amount of new prospect entries
   * @param type - The type of lead list
   * @returns The count of lead list either accounts or contacts
   */
  countNewProspectEntries(type: LeadsList): Observable<number> {
    return this.http.get<IResponseData<number>>(`/target-groups/new-prospects/count?type=${type}`).pipe(
      map(value => {
        if (!value.success) throw new Error(this.alert.translateDataNotLoaded());
        return value.data;
      })
    );
  }

  /**
   * Gets the target group entries that are not part of a target group
   * @param targetGroupId
   * @returns
   */
  getTGEntriesNotPartOfTG(targetGroupId: string): unknown {
    return this.http
      .get('/target-groups/target-group-entries/not-part-of/' + targetGroupId)
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Check if the target group has a default campaign
   * @param targetGroup
   * @returns
   */
  hasDefaultCampaign(targetGroup: TargetGroupList): void {
    // TODO implement function hasDefaultCampaign
  }

  /**
   * Checks if the target group has an active event campaign
   * @param targetGroup
   * @returns
   */
  hasActiveEventCampaign(targetGroup: TargetGroupList): void {
    // TODO implement function hasActiveEventCampaign
  }

  /**
   * Creates a CSV export file
   * @param targetGroupId
   * @param language
   * @returns
   */
  createCSVExport(targetGroupId: string, language: string): Observable<string> {
    return this.http
      .post<IResponseData<string>>('/target-groups/export/file', { targetGroupId, language })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the target group list without views and clicks for faster edit of events
   * @param id
   * @param queryObj
   * @returns
   */
  getTargetGroupsFast(id: string, queryObj: IQueryObj): unknown {
    return this.http
      .get(`'/target-groups/fast/${id}${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Set status of optOut as per the given target group list entry Id
   * @param id - Id of target group list entry
   * @param optOutStatus - Boolean to set the value of optOut
   * @returns Observable of the response success
   */
  changeOptOutStatus(id: string, optOutStatus: boolean): Observable<true> {
    return this.http
      .put<IResponse>('/target-groups/target-group-entries/opt-out-status/' + id, { optOutStatus })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Creates a CSV export file of a target group list entry
   * @param targetGroupEntryId - ID of target group list entry to export
   * @returns Observable of CSV string data
   */
  dataReportCSVExport(targetGroupEntryId: string): Observable<string> {
    return this.http
      .post<IResponseData<string>>('/target-groups/export/target-group-entries/' + targetGroupEntryId, {
        targetGroupEntryId
      })
      .pipe(this.operator.extractResponseData());
  }
}
