import { PlanDetail } from 'src/app/model/interfaces/plans.interface';

/**
 * List of plans with default values
 */
export const PROFESSIONAL_PLAN: PlanDetail = {
  name: 'Professional',
  title: 'Mailtastic Professional',
  description: 'bestForNationalCompanies',
  hasSubscription: false,
  hideLearnMore: true,
  isActivatable: false,
  isActive: true,
  isBookable: false,
  isNew: false,
  isTestable: false,
  isTrialRunning: false,
  state: ''
};

export const BUSINESS_PLAN: PlanDetail = {
  name: 'Business',
  title: 'Mailtastic Business',
  description: 'bestForInternationalCompanies',
  hasSubscription: false,
  hideLearnMore: true,
  isActivatable: false,
  isActive: true,
  isBookable: false,
  isNew: false,
  isTestable: false,
  isTrialRunning: false,
  state: ''
};

export const BASIC_PLAN: PlanDetail = {
  name: 'Basic',
  title: 'plan_mailtastic_sig_management',
  description: 'basic_product_slogan',
  hasSubscription: false,
  isActivatable: false,
  isActive: false,
  isBookable: true,
  isNew: false,
  isTestable: false,
  isTrialRunning: false
};

export const ADVANCED_PLAN: PlanDetail = {
  name: 'Advanced',
  title: 'plan_mailtastic_sig_marketing',
  description: 'info_advanced_new',
  hasSubscription: false,
  isActivatable: false,
  isActive: false,
  isBookable: true,
  isNew: false,
  isTestable: false,
  isTrialRunning: false
};

export const TARGET_PLAN: PlanDetail = {
  name: 'Target',
  title: 'plan_mailtastic_sig_marketing_pro',
  description: 'info_target_new',
  hasSubscription: false,
  isActivatable: false,
  isActive: false,
  isBookable: true,
  isNew: false,
  isTestable: false,
  isTrialRunning: false
};
