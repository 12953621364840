import { Pipe, PipeTransform } from '@angular/core';
import { CalcPriceProductTypeDuration } from '@model/interfaces/payment.interface';
import { FIRST_SLOT_USERS, SECOND_SLOT_USERS } from '@services/payment/payment.service';

/**
 * Get price per user based on existing users count
 */
@Pipe({
  name: 'priceBasedOnUsers'
})
export class PriceBasedOnUsersPipe implements PipeTransform {
  transform(numberOfUsers: number, productDetail: CalcPriceProductTypeDuration): number {
    if (numberOfUsers > SECOND_SLOT_USERS) {
      return productDetail.yearly.thirdSlot.costPerUser;
    } else if (numberOfUsers > FIRST_SLOT_USERS) {
      return productDetail.yearly.secondSlot.costPerUser;
    } else {
      return productDetail.yearly.firstSlot.costPerUser;
    }
  }
}
