import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { urlValidator } from 'src/app/shared/validators/url-validator.directive';
import { noWhiteSpaceValidator } from 'src/app/shared/validators/no-whitespace-validator.directive';

//services
import { QueryHelperService } from 'src/app/services/query-helper/query-helper.service';

// interfaces
import { TargetGroupGetTargetGroupEntries } from 'src/app/services/target-group/target-group-service.type';
import { DOMAIN_INPUT_FIELDS } from '../../model/domain.const';

@Component({
  selector: 'mt-edit-domain-modal',
  templateUrl: './edit-domain-modal.component.html',
  styleUrls: ['./edit-domain-modal.component.scss'],
  providers: [QueryHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditDomainModalComponent {
  /**
   * Form data
   */
  domainDataForm: FormGroup = this.fb.group({
    company: '',
    content: ['', [Validators.required, urlValidator, noWhiteSpaceValidator]],
    custom1: '',
    custom2: '',
    custom3: '',
    location: '',
    source: '',
    type: ''
  });

  /**
   * Holds the domain input fields
   */
  accountData = DOMAIN_INPUT_FIELDS;

  /**
   * Hold target group entry object
   */
  private _targetGroupListEntry!: TargetGroupGetTargetGroupEntries;

  /**
   * Input for getting target group entry detail and patch into FormBuilder
   */
  @Input()
  set targetGroupListEntry(response: TargetGroupGetTargetGroupEntries) {
    this.domainDataForm.patchValue({ ...response });
    this._targetGroupListEntry = response;
  }

  constructor(private fb: FormBuilder, public modal: NgbActiveModal) {}

  /**
   * Input element for edit form is editable or not
   * @param isEditable - Boolean of isEditable flag for current input element
   * @returns Boolean to make it disabled or not
   */
  checkInputDisabled(isEditable: boolean): boolean {
    return this.domainDataForm.controls.source.value ? true : !isEditable;
  }

  /**
   * To update the values of target group entry
   */
  onSubmit(): void {
    if (this.domainDataForm.invalid) return;

    this._targetGroupListEntry.company = this.domainDataForm.get('company')?.value as string;
    this._targetGroupListEntry.location = this.domainDataForm.get('location')?.value as string;
    this._targetGroupListEntry.custom1 = this.domainDataForm.get('custom1')?.value as string;
    this._targetGroupListEntry.custom2 = this.domainDataForm.get('custom2')?.value as string;
    this._targetGroupListEntry.custom3 = this.domainDataForm.get('custom3')?.value as string;

    this.modal.close(this._targetGroupListEntry);
  }
}
