<div class="wizard mt_modal_with_head">
  <mt-custom-modal headline="{{ 'Chooseemployees' | transloco }}">
    <div modal-body>
      <div class="content">
        <div class="flex-center-space-between">
          <div class="flex">
            <div class="infobox infobox-error martop20">
              <span class="symbol glyphicon glyphicon-alert"></span>
              <div class="text">
                <p>{{ errorMessageS1 | transloco }}</p>
                <p>{{ errorMessageS2 | transloco }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div modal-footer>
      <div class="flex-end-space-between">
        <div class="flex">
          <mt-button
            (click)="modal.close(false)"
            [text]="isRollOutError ? 'abort' : 'proceedwithemptydepartment'"
            class="mt_left"
            color="default"
            ngbAutoFocus
          ></mt-button>
          <mt-button
            data-cy="assign-employee-modal-btn"
            (click)="modal.close(true)"
            class="roboto_light"
            color="primary"
            icon="plus"
            text="assigneemployees"
          >
          </mt-button>
        </div>
      </div>
    </div>
  </mt-custom-modal>
</div>
