<form
  *ngIf="accountDataForm"
  [formGroup]="accountDataForm"
  class="form-horizontal"
>
  <ng-container *ngIf="loadData$ | async; else loading">
    <mt-custom-modal headline="{{ 'domain_data_fields' | transloco }}">
      <!-- Modal Body -->
      <ng-container modal-body>
        <div class="assistant"></div>
        <div class="form-group" *ngFor="let item of accountData">
          <div class="col-sm-3 format3">
            <label
              ><span [transloco]="item.field"></span>
              <span *ngIf="item.required">
                (<span transloco="required"></span>)</span
              >
            </label>
          </div>
          <div class="col-sm-10 col-lg-8 formcontrolright">
            <input
              type="text"
              [formControlName]="item.backendField"
              class="form-control input-sm"
              [disabled]="!item.editable"
              [readonly]="!item.editable"
            />
          </div>
        </div>
      </ng-container>
      <ng-container modal-footer>
        <div class="flex-center-right">
          <mt-button
            (click)="modal.close(false)"
            class="leftbutton"
            color="default"
            text="abort"
          ></mt-button>
          <mt-button
            color="primary"
            text="save_settings"
            (click)="editAccountData()"
          ></mt-button>
        </div>
      </ng-container>
    </mt-custom-modal>
  </ng-container>
</form>

<!-- LOADING -->
<ng-template #loading>
  <div class="card martop20">
    <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
></ng-template>
