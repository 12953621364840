import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

/**
 * Dropdown used to hold list sort options
 */
@Component({
  selector: 'mt-sort-dropdown',
  templateUrl: './sort-dropdown.component.html',
  styleUrls: ['./sort-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, ButtonComponent]
})
export class SortDropdownComponent {}
