import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

// Interfaces
import { IActiveGroups } from 'src/app/services/signature/signature-service.interface';
import { GroupGetOne } from 'src/app/services/group/group-service.interface';
import { SignatureEditWizardStepName } from '@model/interfaces/signature.interface';

// Services
import { SignatureDataService } from 'src/app/services/signature-data/signature-data.service';

// Others
import { INSTALLATION } from '@organisms/installation/model/installation-modal.const';
import { cloneDeep } from 'lodash-es';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';
import { WizardTabComponent } from '@organisms/wizard/wizard-tab/wizard-tab.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-signature-setup-check',
  templateUrl: './signature-setup-check.component.html',
  styleUrls: ['./signature-setup-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WizardTabComponent, NgIf, TranslocoModule, ButtonComponent]
})
export class SignatureSetupCheckComponent implements OnInit {
  installation = cloneDeep(INSTALLATION);

  /**
   * Variable to check that the signature is linked with any group where member assigned
   * @defaultValue true
   */
  @Input() isUserLinkedWithGroup = true;

  /**
   * Holds the array of active groups of the signature
   * @defaultValue []
   */
  @Input() activeGroups = [] as IActiveGroups[] | GroupGetOne[];

  /**
   * Variable to check that the signature is rolled out yet
   * @defaultValue true
   */
  @Input() isSignatureRollOut = true;

  /**
   * Variable to checks the signature type
   * @defaultValue ''
   */
  @Input() signatureType = '';

  /**
   * Holds the signature id
   * @defaultValue ''
   */
  @Input() signatureId = '';

  /**
   * Triggers the modal close event
   */
  @Output() closeModal = new EventEmitter<void>();

  constructor(private router: Router, private signatureDataService: SignatureDataService) {}

  ngOnInit(): void {
    this.installation.setupCheckStep[0].open = true;
  }

  /**
   * Redirect to signature edit wizard and opens the related step
   * @param stepNoToOpen - Step name to open in edit signature wizard
   */
  redirectToSignatureEditWizard(stepNoToOpen: SignatureEditWizardStepName): void {
    void this.router.navigate(['/signatures/edit/', this.signatureId], {
      state: { signatureIdFromInstallation: this.signatureId, installationType: 'forMe', stepName: stepNoToOpen }
    });
    this.closeModal.emit();
  }

  /**
   * Closes other tabs than the currently opened one.
   * @param event -The mouse event
   * @param index - position in array
   */
  clickedTabPos(event: MouseEvent | any, index: number): void {
    if (event.target.type !== 'submit') {
      index === 0
        ? (this.installation.setupCheckSubSteps[1].open = false)
        : (this.installation.setupCheckSubSteps[0].open = false);
    }
  }
}
