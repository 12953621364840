import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TemplateEntry } from 'src/app/model/template.entry';
import { DummyData } from 'src/app/model/interfaces/signature-data.interface';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(private http: HttpClient) {}

  /**
   * Gets all available templates/
   */
  getMany(): Observable<TemplateEntry[]> {
    const result: Observable<any> = this.http.get('/assets/resources/templates.json');
    return result.pipe(map(({ entries }) => entries));
  }

  /**
   * Gets the dummy data to fill the missing properties.
   */
  getDummyInfo(): Observable<DummyData> {
    return this.http.get<DummyData>('/assets/resources/dummy.json');
  }

  /**
   * Gets the template code based on the template key provided.
   * @param key Unique template identifier.
   */
  getTemplateCode(key: string): Observable<string> {
    return this.http.get(`/assets/resources/templates/template_${key}.html`, {
      responseType: 'text'
    });
  }
}
