import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

// Components
import { UploadCsvButtonComponent } from '@shared/components/atoms/buttons/upload-csv-button/upload-csv-button.component';

@Component({
  selector: 'mt-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoModule, UploadCsvButtonComponent]
})
export class CsvUploadComponent {
  /**
   * Error msg from UploadCsvButtonComponent
   */
  @Output() errorMsg = new EventEmitter<string>();

  /**
   * Data from UploadCsvButtonComponent
   */
  @Output() csvData = new EventEmitter<{ fileName: string; data: string }>();
}
