import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

/**
 * Creates an info-svg icon with `margin-left: 4px`
 */
@Component({
  selector: 'mt-svg-info',
  templateUrl: './mt-svg-info.component.html',
  styleUrls: ['./mt-svg-info.component.scss'],
  standalone: true,
  imports: [NgbTooltip, MtSvgComponent, TranslocoModule]
})
export class MtSvgInfoComponent {
  /**
   * Filter variable.
   * @defaultValue '--filter-mt-hellgrau'
   */
  @Input() filter = '--filter-mt-hellgrau';

  /**
   * Icon SVG name.
   * @defaultValue 'info'
   */
  @Input() icon = 'info';

  /**
   * Size of SVG in pixels.
   */
  @Input() size = '13';

  /**
   * Translate tag of tooltip.
   * @defaultValue ''
   */
  @Input() tooltip = '';

  /**
   * CSS class of tooltip.
   * @defaultValue 'status-info-tooltip'
   */
  @Input() tooltipClass = 'status-info-tooltip';
}
