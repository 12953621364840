import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IGroup } from 'src/app/model/interfaces/group.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { GroupService } from 'src/app/services/group/group.service';
import { noSpecialCharsValidator } from 'src/app/shared/validators/no-special-chars-validator.directive';
import { noWhiteSpaceValidator } from 'src/app/shared/validators/no-whitespace-validator.directive';
import { NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

/**
 * Modal used to create a new department or edit a group's title.
 *
 *  **CREATE**
 *
 * Modal is used to create a new department from a different flow, i.e.: Create a new department when creating a new employee
 *
 *  **EDIT**
 *
 * Used when editing the title of the department, currently only used in **GroupsListComponent**
 */
@Component({
  selector: 'mt-create-edit-group-modal',
  templateUrl: './create-edit-group-modal.component.html',
  styleUrls: ['./create-edit-group-modal.component.scss'],
  standalone: true,
  imports: [CustomModalComponent, TranslocoModule, FormsModule, ReactiveFormsModule, NgIf, ButtonComponent]
})
export class CreateEditGroupModalComponent implements OnInit {
  /**
   * Group object to edit the title
   *
   * Determines the modal is in 'edit mode'
   */
  @Input() group: IGroup | undefined = undefined;

  /**
   * New group title field with default value and validators
   */
  // eslint-disable-next-line @typescript-eslint/unbound-method
  newTitle = new UntypedFormControl('', [Validators.required, noSpecialCharsValidator, noWhiteSpaceValidator]);

  headline = 'Createanewdepartment';

  submitBtnText = 'Createdepartment';

  /**
   * Observer used when submiting information.
   */
  onSubmitObserver = {
    next: (result: boolean | number) => this.modal.close(result),
    error: (err: HttpErrorResponse) => void this.alert.defaultErrorMessage(err.message)
  };

  constructor(private alert: AlertService, private groupService: GroupService, public modal: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.group) {
      this.headline = 'Editdepartment';
      this.submitBtnText = 'storedepartment';
      this.newTitle.setValue(this.group.title);
    }
  }

  /**
   * Submits the new group title to create or edit
   */
  onSubmit(): void {
    if (this.newTitle.invalid) {
      this.newTitle.markAllAsTouched();
      return;
    }

    if (this.group) {
      // Edit mode
      this.groupService
        .update({ ...this.group, title: this.newTitle.value as string })
        .subscribe(this.onSubmitObserver);
    } else {
      // Creating new group
      this.groupService
        .add({
          title: this.newTitle.value as string,
          membersToAdd: []
        })
        .subscribe(this.onSubmitObserver);
    }
  }
}
