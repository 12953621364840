<div modal-body class="congratulationsaftercheckout" *transloco="let translate">
  <div class="headline">
    <span data-cy="congratulations-text"
      >{{ translate('Congratulations') }}</span
    >!
  </div>
  <div
    class="subline"
    [innerHtml]="translate('param.youHaveBookedProductSucessfully', { product })
    "
  ></div>

  <div class="flex-center-all imagearea">
    <img src="assets/images/multisignature/giphy_glitter.gif" />
  </div>

  <div
    class="description"
    [innerHTML]="translate('html.knowHowToThisTopicInHelpCenter')"
  ></div>

  <!-- Enable/Disabled buttons on getting chargify data -->
  <div *ngIf="updateSubscriptionData$ | async; else loadingData">
    <div
      *ngIf="isPaymentMethodInvoice"
      class="text-center text-danger martop20 trial-over-invoice"
    >
      <span>{{ translate('trial_over_invoice') }}</span>
      <span (click)="modal.close(false)" class="cursor-pointer">
        {{ translate('subscriptionbills') }}
      </span>
    </div>

    <div class="flex-center-all">
      <mt-button
        (click)="modal.close(true)"
        color="primary"
        text="Continue"
        data-cy="congratulations-modal-continue"
      ></mt-button>
    </div>
  </div>

  <ng-template #loadingData>
    <div
      *ngIf="isPaymentMethodInvoice"
      class="text-center text-danger martop20 trial-over-invoice"
    >
      <span>{{ translate('trial_over_invoice') }}</span>
      <span class="cursor-pointer" [class.disabled]="true">
        {{ translate('subscriptionbills') }}
      </span>
    </div>

    <div class="flex-center-all">
      <mt-button [disabled]="true" color="primary" text="Continue"></mt-button>
    </div>
  </ng-template>
</div>
