import { Component, Input } from '@angular/core';
import { ICampaign } from 'src/app/model/interfaces/campaign.interface';
import { IEventGet } from 'src/app/model/interfaces/event.interface';
import { IGroup, IGroupObject, GroupSmall } from 'src/app/model/interfaces/group.interface';
import { ITopRecipientsGet } from 'src/app/model/interfaces/recipient.interface';
import {
  ITargetGroupListEntry,
  ITargetGroupListEntryCampaign,
  TargetGroupList
} from 'src/app/model/interfaces/target-group-list.interface';
import { ICampaignGet } from 'src/app/services/campaign/campaign-services.interface';
import {
  IEmployeeGet,
  OutdatedEmployeeGet,
  UnImportedEmployee
} from 'src/app/services/employee/employee-service.interface';
import { IGroupGet, IGroupGetMembers, GroupGetOne } from 'src/app/services/group/group-service.interface';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { IActiveGroups, ISignatureGetAll } from 'src/app/services/signature/signature-service.interface';
import { TargetGroupsGetTargetGroups } from 'src/app/services/target-group/target-group-service.type';
import { NotificationsGet } from 'src/app/services/notification/notifications.service.interface';
import { googleUser } from 'src/app/services/google-sync/google-sync.interface';
import { IDataset } from 'src/app/model/interfaces/dataset.interface';
import { LeadFields, MarketoLists, MarketoProgram, MarketoWorkspace } from 'src/app/services/crm/crm-service.interface';
import { SyncSettingsLeadFields } from '@modules/marketo/model/interfaces/marketo';
import { CrmLandingPages, HubSpotLists, PardotLists } from 'src/app/services/crm/crm-service.interface';
import { O365Users } from 'src/app/services/azure-sync/azure-sync.interface';
import { EventTargetGroup } from 'src/app/modules/events/model/event-target-groups';
import { IEventGetAllEventsForDepartment } from 'src/app/services/event/event-service.interface';
import { MtCheckboxComponent } from '@atoms/inputs/checkbox/checkbox.component';

// append all data-structures that use the 'SelectColComponent'
// this type is needed to correspond with the linter
type AllowedSelectColTypes =
  | CrmLandingPages
  | EventTargetGroup
  | googleUser
  | HubSpotLists
  | IActiveGroups
  | ICampaign
  | ICampaignGet
  | IDataset
  | IEmployeeGet
  | IEventGet
  | IEventGetAllEventsForDepartment
  | IGroup
  | IGroupGet
  | IGroupGetMembers
  | GroupGetOne
  | IGroupObject
  | GroupSmall
  | ISignatureGetAll
  | ITargetGroupListEntry
  | ITargetGroupListEntryCampaign
  | ITopRecipientsGet
  | LeadFields
  | MarketoLists
  | MarketoProgram
  | MarketoWorkspace
  | NotificationsGet
  | O365Users
  | OutdatedEmployeeGet
  | PardotLists
  | SyncSettingsLeadFields
  | TargetGroupList
  | TargetGroupsGetTargetGroups
  | UnImportedEmployee;

@Component({
  selector: 'selectcol',
  templateUrl: './select-col.component.html',
  styleUrls: ['./select-col.component.scss'],
  standalone: true,
  imports: [MtCheckboxComponent]
})
export class SelectColComponent {
  /**
   * Instance of ListHelperService
   */
  @Input() list = {} as ListHelperService<AllowedSelectColTypes>;
  /**
   * List member
   */
  @Input() item = {} as AllowedSelectColTypes;
  /**
   * If true, the checkbox-type input will be disabled
   */
  @Input() disabled = false;
  /**
   * Update the item's selection status in the list
   */
  updateItemSelection(event: Event): void {
    this.list.updateSelection(this.item);
    event.stopPropagation();
  }
}
