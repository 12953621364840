import { Directive } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors, Validator, NG_VALIDATORS } from '@angular/forms';

export const noWhiteSpaceValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  const isWhiteSpace = ((control.value as string) || '').trim().length === 0;

  return isWhiteSpace ? { whiteSpace: true } : null;
};

@Directive({
  providers: [{ provide: NG_VALIDATORS, useExisting: WhiteSpaceValidatorDirective, multi: true }],
  selector: '[mtNoWhitespaceValidator]'
})
export class WhiteSpaceValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return noWhiteSpaceValidator(control);
  }
}
