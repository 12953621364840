<form
  *ngIf="domainDataForm"
  [formGroup]="domainDataForm"
  class="form-horizontal domain-form"
>
  <mt-custom-modal headline="{{ 'domain_data_fields' | transloco }}">
    <!-- Modal Body -->
    <ng-container modal-body>
      <p
        *ngIf="domainDataForm.controls['source'].value === 'hubspot'"
        class="col-sm-offset-1 col-sm-10 pad10 marbot20 bg-info"
        transloco="hubspot_contact_info"
      ></p>
      <p
        *ngIf="domainDataForm.controls['source'].value === 'pardot'"
        class="col-sm-offset-1 col-sm-10 pad10 marbot20 bg-info"
        transloco="pardot_contact_info"
      ></p>
      <p
        *ngIf="domainDataForm.controls['source'].value === 'marketo'"
        class="col-sm-offset-1 col-sm-10 pad10 marbot20 bg-info"
        transloco="marketo_contact_detail_view_from_marketo"
      ></p>
      <div class="form-group" *ngFor="let item of accountData">
        <div class="col-sm-4 col-sm-offset-1 format3">
          <label
            ><span [transloco]="item.field"></span>
            <span *ngIf="item.required">
              (<span transloco="required"></span>)</span
            >
          </label>
        </div>
        <div class="col-sm-5 formcontrolright">
          <input
            type="text"
            [formControlName]="item.backendField"
            class="form-control input-sm"
            [disabled]="checkInputDisabled(item.editable)"
            [readonly]="checkInputDisabled(item.editable)"
            placeholder="{{ item.inputPlaceholderKey | transloco }}"
          />
        </div>
        <div
          *ngIf="!domainDataForm.controls['source'].value"
          class="col-sm-1 no-padding tooltip-position"
        >
          <div
            *ngIf="!item.editable && item.notEditableInfoMsg"
            class="info-icon"
            ngbTooltip="{{ item.notEditableInfoMsg | transloco }}"
            placement="top"
            tooltipClass="status-info-tooltip"
          >
            <mt-svg
              class="mt-grey tiny-svg"
              src="assets/images/svgs/info.svg"
            ></mt-svg>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container modal-footer>
      <div class="flex-center-right">
        <mt-button
          ngbAutoFocus
          text="abort"
          color="default"
          class="mt_left"
          (click)="modal.close()"
        ></mt-button>
        <mt-button
          class="roboto_light"
          color="primary"
          text="save_settings"
          icon="ok"
          [disabled]="domainDataForm.controls['source'].value"
          (click)="onSubmit()"
        >
        </mt-button>
      </div>
    </ng-container>
  </mt-custom-modal>
</form>
<!-- LOADING -->
<ng-template #loading>
  <div class="card martop20">
    <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
></ng-template>
