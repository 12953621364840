import { Pipe, PipeTransform } from '@angular/core';
import { ITargetGroupListEntry } from 'src/app/model/interfaces/target-group-list.interface';

/**
 * Text of Opt In Out
 * @param targetGroupEntry - Object of ITargetGroupListEntry
 * @returns String of translation to display
 */
@Pipe({
  name: 'optInOutTypeText'
})
export class OptInOutTypeTextPipe implements PipeTransform {
  transform(targetGroupEntry: ITargetGroupListEntry, contact: string, domain: string): string {
    return targetGroupEntry.type === 'contact' ? contact : domain;
  }
}
