import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, catchError, map, Observable, ReplaySubject, tap } from 'rxjs';
import {
  CountWithoutResponse,
  DataOrMessage,
  IResponse,
  IResponseData,
  Response,
  ResponseData,
  ResponseDataCode,
  ResponseMessageOrCode
} from 'src/app/model/interfaces/response.interface';
import { ISignature, SignatureDetails } from 'src/app/model/interfaces/signature.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { IntercomService, INTERCOM_DATA } from '../intercom/intercom.module';
import { NavigationSidebarService } from '../navigation-sidebar/navigation-sidebar.service';
import { IQueryObj } from '../query-helper/query-helper-service.interface';
import { QueryHelperService } from '../query-helper/query-helper.service';
import {
  GetAllSignatures,
  ISignatureGetAll,
  ISignatureGetJsonInfoFields,
  ISignatureGetOne,
  SignaturePreviewBody
} from './signature-service.interface';
import { TranslocoService } from '@ngneat/transloco';
import { getQueryStringPaginationFilter } from '@shared/standalone-functions/query-builder';

@Injectable({
  providedIn: 'root'
})
export class SignatureService {
  guideSignatureList$ = new BehaviorSubject(0);

  // Observable is used to pass which group campaign details preview will be shown
  // pass group id
  private isCampaignDetailsOpen = new ReplaySubject<number>(1);
  isCampaignDetailsOpen$ = this.isCampaignDetailsOpen.asObservable();

  // observable is used to pass which group signature details preview will be shown
  // pass group id
  private isSignatureDetailsOpen = new ReplaySubject<number>(1);
  isSignatureDetailsOpen$ = this.isSignatureDetailsOpen.asObservable();

  /**
   * Opens the current group Signature Details and close the others including Campaign Details also
   * If clicked on same group Signature Details then close it
   * @param id - group id
   * @param prevId - previous opened group id
   */
  toggleSignatureTab(id: number, prevId = -1): void {
    id === prevId ? this.isSignatureDetailsOpen.next(-1) : this.isSignatureDetailsOpen.next(id);
    this.isCampaignDetailsOpen.next(-1);
  }

  /**
   * Opens the current group Campaign Details and close the others including Signature Details also
   * If clicked on same group Campaign Details then close it
   * @param id - group id
   * @param prevId - previous opened group id
   */
  toggleCampaignTab(id: number, prevId = -1): void {
    id === prevId ? this.isCampaignDetailsOpen.next(-1) : this.isCampaignDetailsOpen.next(id);
    this.isSignatureDetailsOpen.next(-1);
  }

  constructor(
    private alert: AlertService,
    private http: HttpClient,
    private intercomService: IntercomService,
    private navigationSidebarService: NavigationSidebarService,
    private operator: CustomOperators,
    private queryHelperService: QueryHelperService,
    private translate: TranslocoService
  ) {}

  /**
   * @deprecated Use getAllSignatures
   * Gets all signatures
   * @returns Observable of array of signatures
   */
  getAll(): Observable<ISignatureGetAll[]> {
    return this.http.get<IResponseData<ISignatureGetAll[]>>('/signatures').pipe(this.operator.extractResponseData());
  }

  /**
   * @deprecated Use getAllSignatures
   * Gets all signatures with queryObject
   * @param queryObj - query object holding the keys or empty
   * @returns Observable of array of signatures
   */
  getAllV2WithQueryObject(queryObj?: IQueryObj): Observable<ISignatureGetAll[]> {
    return this.http
      .get<DataOrMessage<ISignatureGetAll[]>>(`/v2/signatures${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(
        map(value => {
          // Check if data not exists
          if (!value.data) {
            throw new Error(this.alert.translateDataNotLoaded());
          }
          return value.data || [];
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Gets a list of available signatures
   * @param queryParams - Server side filtering and pagination options
   * @returns Available signatures
   */
  getAllSignatures(queryParams: { page: number; pageSize: number; search: string }): Observable<GetAllSignatures[]> {
    return this.http
      .get<ResponseData<GetAllSignatures[]>>(`/v2/signatures?${getQueryStringPaginationFilter(queryParams)}`)
      .pipe(this.operator.extractData());
  }

  /**
   * Gets one signature
   * @param id - Signature id
   * @returns Observable of signature
   */
  getOne(id: string): Observable<ISignatureGetOne> {
    return this.http
      .get<IResponseData<ISignatureGetOne>>(`/signatures/single/${id}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the count of signatures.
   * @param queryObj - query object holding the keys or empty
   * @returns Count of signatures that exist
   */
  count(queryObj?: IQueryObj): Observable<number> {
    return this.http
      .get<CountWithoutResponse>(`/v2/signatures/count${this.queryHelperService.createQuerySearch(queryObj)}`)
      .pipe(this.operator.extractCount());
  }

  /**
   * Gets the signature for the employee
   * @param employeeEmail
   * @returns
   */
  getSignaturesByEmployeeId(employeeEmail: string): unknown {
    return this.http.get('/signatures/employee/' + employeeEmail).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Get plain text from given html text
   * @param htmlTpl - Html string
   * @returns Object of response data
   */
  getSignaturesPlainTxt(htmlTpl: string): Observable<ResponseDataCode> {
    return this.http
      .post<ResponseDataCode>('/signatures/tpl-to-txt', { htmlTpl })
      .pipe(this.operator.extractResponseMessageStatus());
  }

  /**
   * Updates the signature details
   * @param signatureId - The id of signature
   * @param signature - The object for signature details
   * @returns The signature id
   */
  updateSignatureDetails(signatureId: string, signature: SignatureDetails): Observable<{ signatureId: string }> {
    return this.http
      .put<{ success: boolean; signatureId: string }>(`/signatures/signature-details/${signatureId}`, signature)
      .pipe(
        tap(result => {
          if (result.success) {
            if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.signature_modified);
            return result.signatureId;
          } else {
            throw new Error(this.alert.translateDataNotLoaded());
          }
        })
      );
  }

  /**
   * Updates the signature
   * @param signature - The signature details object
   * @returns The signature id
   */
  update(signature: ISignature): Observable<{ signatureId: string; success: boolean }> {
    return this.http.put<{ success: boolean; signatureId: string; message?: string }>('/signatures', signature).pipe(
      tap(result => {
        if (result.success) {
          if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.signature_modified);
          return result.signatureId;
        } else {
          if (result.message) {
            void this.alert.defaultErrorMessage(result.message);
            return result;
          } else {
            void this.alert.translateDataNotLoaded();
            return result;
          }
        }
      })
    );
  }

  /**
   * Creates a copy of the signature with given id
   * @param signatureIdToCopy - The signature detail
   * @returns
   */
  createCopy(signatureIdToCopy: string) {
    return this.http.post('/signatures/copyexisting/' + signatureIdToCopy, null).pipe(
      this.operator.extractUnknownResponse(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'signatures');
      })
    );
  }

  /**
   * Creates a new signature
   * @param signature - The signature details object
   * @returns
   */
  create(signature: ISignature): Observable<{ signatureId: string }> {
    return this.http.post<{ success: boolean; signatureId: string; message?: string }>('/signatures', signature).pipe(
      tap(result => {
        if (result.success) {
          if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.signature_created);
          this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'signatures');
          return result;
        } else if (result.message) {
          void this.alert.defaultErrorMessage(result.message);
          return result;
        } else {
          void this.alert.translateDataNotLoaded();
          return result;
        }
      })
    );
  }

  /**
   * Deletes a list of Signatures. Can also only one signature in list
   * @param ids - signature Id which are going to delete
   * @returns
   */
  delete(ids: string[]) {
    return this.http.post('/signatures/delete', { sigIds: ids }).pipe(
      this.operator.extractUnknownResponse(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(-ids.length, 'signatures');
      })
    );
  }

  /**
   * Gets all groups in which the Signature is active
   * @returns
   */
  getGroupsUsedIn(): unknown {
    return this.http.get('/signatures/usedin/groups').pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Rollouts the signature to one employee
   * @param empId - Employee to rollout
   * @returns Observable of true
   */
  rolloutEmployee(empId: string): Observable<true> {
    return this.http.post<IResponse>('/signatures/rollout/employee', { empId }).pipe(this.operator.extractResponse());
  }

  /**
   * Rolls out one Signature to all groups to which it is assigned to
   * @param sigId - Signature identifier
   * @param suppressmail - If email notification should be suppressed
   * @returns Observable of boolean
   */
  rolloutSignature(sigId: string, suppressmail?: boolean): Observable<boolean> {
    return this.http.post<IResponse>('/signatures/rollout/signature', { sigId, suppressmail }).pipe(
      map(value => {
        if (value.success) {
          this.intercomService.trackEvent(INTERCOM_DATA.signature_rolledout);
        }
        return value.success;
      })
    );
  }

  /**
   * Rolls out multiple Signature
   * @param sigIds - Array of signature identifier
   * @returns Observable of boolean
   */
  rolloutMultipleSignature(sigIds: string[]): Observable<boolean> {
    return this.http.post<IResponse>('/signatures/rollout/signature', { sigIds }).pipe(
      map(value => {
        if (value.success) {
          this.intercomService.trackEvent(INTERCOM_DATA.signature_rolledout);
        }
        return value.success;
      })
    );
  }

  /**
   * Updates the signature title/template name
   * @param sigId - Signature identifier
   * @param title - The signature title
   * @returns Observable of true
   */
  updateSignatureTitle(sigId: string, title: string): Observable<true> {
    return this.http.put<IResponse>('/signatures/title/', { sigId, title }).pipe(this.operator.extractResponse());
  }

  /**
   * Rolls out an array of signatures
   * @param sigIds
   * @param suppressmail
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rolloutManySignatures(sigIds: Array<string>, suppressmail: boolean | null): void {
    // TODO implement function rolloutManySignatures
  }

  /**
   * Rollouts the signature to all employees of one group
   * @param groupId - Group to rollout
   * @returns Observable of true
   */
  rolloutGroup(groupId: number): Observable<boolean> {
    return this.http
      .post<ResponseMessageOrCode>('/signatures/rollout/group', { groupId, notificationStatus: true })
      .pipe(
        this.operator.extractResponseMessageStatusCode(),
        map(response => {
          if (!response.success) {
            if (response.code) {
              void this.alert.defaultErrorMessage(this.translate.translate('REMINDERS_NOT_SENT'));
            } else {
              void this.alert.defaultErrorMessage(this.translate.translate(response.message as string));
            }
          } else {
            void this.alert.defaultSuccessMessage(this.translate.translate('REMINDERS_SENT'));
          }
          this.intercomService.trackEvent(INTERCOM_DATA.signature_rolledout);
          return response.success;
        })
      );
  }

  /**
   * Gest json fields structure from backend
   * Structure for signature designer, employee and company fields
   * @returns Observable containing structure
   */
  getJsonInfoFields(): Observable<ISignatureGetJsonInfoFields> {
    return this.http
      .get<IResponseData<ISignatureGetJsonInfoFields>>('/signatures/infoFieldStructure')
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Sets dataset id for signature
   * @param signatureId
   * @param datasetId
   * @param type
   * @returns
   */
  setDataset(signatureId: string, datasetId: string, type: unknown): unknown {
    return this.http
      .put('/signatures/dataset', { signatureId, datasetId, type })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Checks if the signatures have the tag
   * @param tag
   * @returns
   */
  checkHasTag(tag: string): unknown {
    return this.http.post('/signatures/hasTag/', { tag }).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sets the global placeholder style
   * @param tag
   * @param signatureId
   * @returns
   */
  setGlobalPlaceholderStyle(tag: string, signatureId: string): unknown {
    return this.http
      .post('/signatures/setGlobalPlaceholderStyle/', { tag, signatureId })
      .pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Sends the signature to the employee.
   * @param userId - The user id
   * @param signature - The signature string to send in email
   * @param type - The type of signature plain or html
   * @returns -Boolean
   */
  sendFirstSignaturToUser(userId: string, signatureId: string, signatureTmpl: string): Observable<true> {
    return this.http
      .post<IResponse>('/account/sendfirstsignature', { userId, signatureId, signatureTmpl })
      .pipe(this.operator.extractResponse());
  }

  /**
   * Generates a signature preview for a given template, employee and datasets
   * @param signaturePreviewRequest - Object containing request data necessary to generate a preview
   * @returns Signature preview
   */
  generatePreview(signaturePreviewRequest: SignaturePreviewBody): Observable<string> {
    return this.http.post<DataOrMessage<string>>('/v2/signatures/preview', { ...signaturePreviewRequest }).pipe(
      map(value => {
        if (!value.data) {
          if (value.message) {
            // TODO: add appropriate error handling with better UI/UX
            // void this.alert.defaultErrorMessage(value.message);
          }
          throw new Error('');
        }
        return value.data;
      }),
      catchError(() => {
        throw new Error('');
      })
    );
  }
}
