<div
  (click)="bookingContainer.openStep1 = !bookingContainer.openStep1"
  data-cy="feature-signature-management"
>
  <mt-sticky-plan-feature-title>
    <div
      class="product-container-accordion"
      [ngClass]="{
        'accordion-title-two-coulmns':
          currentProduct && currentProduct !== 'Basic',
        'accordion-title-one-coulmn': currentProduct === 'Business'
      }"
    >
      <span
        class="glyphicon cursor-pointer chevron"
        [ngClass]="{
          'glyphicon-chevron-down': !bookingContainer.openStep1,
          'glyphicon-chevron-up': bookingContainer.openStep1
        }"
      ></span>
      <span
        class="title"
        transloco="{{ bookingContainer.signatureManagement.title }}"
      ></span>
    </div>
  </mt-sticky-plan-feature-title>
  <div
    *ngIf="bookingContainer.openStep1"
    class="flex"
    [ngClass]="{
      justifycontentcenter: currentProduct
    }"
    data-cy="feature-signature-management-items"
  >
    <div *ngIf="!hasSubscription && !currentProduct">
      <div class="product-container-info tint-turquoise">
        <mt-product-container-info
          [includesProduct]="bookingContainer.signatureManagement.free.includes"
        ></mt-product-container-info>
      </div>
      <div class="product-container-info tint-turquoise sm-free-fa12">
        <ul class="includes-items"></ul>
      </div>
      <div class="product-container-info tint-turquoise padtop0">
        <mt-product-container-info
          [excludesProduct]="bookingContainer.signatureManagement.free.excludes"
        >
        </mt-product-container-info>
      </div>
    </div>
    <div
      *ngIf="!currentProduct || currentProduct === 'Basic'"
      class="product-container-info tint-light"
    >
      <mt-product-container-info
        [includesProduct]="bookingContainer.signatureManagement.basic.includes"
        [excludesProduct]="bookingContainer.signatureManagement.basic.excludes"
      ></mt-product-container-info>
    </div>
    <div
      *ngIf="currentProduct !== 'Business'"
      class="product-container-info tint-dark"
    >
      <mt-product-container-info
        [includesProduct]="
          bookingContainer.signatureManagement.advanced.includes
        "
        [excludesProduct]="
          bookingContainer.signatureManagement.advanced.excludes
        "
      ></mt-product-container-info>
    </div>
    <div class="product-container-info tint-light">
      <mt-product-container-info
        [includesProduct]="bookingContainer.signatureManagement.target.includes"
        [excludesProduct]="bookingContainer.signatureManagement.target.excludes"
      >
      </mt-product-container-info>
    </div>
  </div>
</div>
