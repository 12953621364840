<div class="btn-group" ngbDropdown>
  <mt-button
    class="roboto_light"
    text="action"
    icon="cog"
    color="primary"
    id="dropdownAction"
    ngbDropdownToggle
  >
  </mt-button>
  <ul ngbDropdownMenu aria-labelledby="dropdownAction">
    <ng-content></ng-content>
  </ul>
</div>
