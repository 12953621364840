<div [class.upload-csv]="isExtraPaddingNeeded()">
  <div class="form-horizontal">
    <div class="form-group">
      <div
        class="col-sm-12 col-lg-12 formcontrolright"
        *transloco="let transloco"
      >
        <div class="format2 martop25">
          {{ transloco('upload_your_csv_excel') }}
        </div>

        <div class="martop25">
          {{ transloco(upload_csv_help_line()) }}
          <a
            target="_blank"
            class="links help-desk"
            [href]="
              translate.getActiveLang() === 'de'
                ? (german_help_url() | safe: 'url')
                : (english_help_url() | safe: 'url')
            "
          >
            {{ transloco('view_help_desk_article') }}
          </a>
        </div>
        <div class="martop25">
          {{ transloco(upload_csv_template_line()) }}
          <a
            target="_blank"
            class="links download-here"
            [href]="
             translate.getActiveLang() === 'de'
                ? (german_download_url() | safe: 'url')
                : (english_download_url() | safe: 'url')
            "
          >
            {{ transloco('download_here') }}
          </a>
        </div>
        <input
          type="file"
          class="file-input hide"
          accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          (change)="processFile($event)"
          #sheetInput
        />
        @if (isShowAppendButton()) {
          <div class="martop30 append-file">
            <div class="radio-btn">
              <input type="radio" name="fileUploadRadio" [formControl]="isAppendData" value="false"> {{ transloco(upload_csv_warning_line()) }}
            </div>
            <div class="radio-btn">
              <input type="radio" name="fileUploadRadio" [formControl]="isAppendData" value="true"> {{ transloco(upload_csv_append_warning_line()) }}
            </div>
            <mt-button
              class="upload-btn mt-30"
              text="Chooseafile"
              icon="open"
              color="primary"
              (click)="sheetInput.click()"
            ></mt-button>
          </div>
        } @else {
          <div class="martop30 input-file">
            <mt-button
              text="Chooseafile"
              icon="open"
              color="primary"
              (click)="sheetInput.click()"
            ></mt-button>
            <div class="form-group secondform martop20">
              <div class="excelinfo">
                {{ transloco(upload_csv_warning_line()) }}
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
