<mt-custom-modal headline="{{ 'selectsignature' | transloco }}">
  <div *ngIf="loadSignatures$ | async; else loading" modal-body>
    <div class="content" style="min-height: 400px; height: auto !important">
      <ng-container *ngIf="list.all.length">
        <div class="flex" style="justify-content: space-between">
          <div class="flex"></div>

          <div class="flex">
            <mt-search
              class="leftbutton"
              (search)="filterTextSig = $event"
              [disableEnter]="true"
            ></mt-search>

            <div ngbDropdown class="btn-group sort leftbutton">
              <mt-button
                text="sort"
                [dropdown]="true"
                id="dropdownSort"
                ngbDropdownToggle
              ></mt-button>
              <ul ngbDropdownMenu aria-labelledby="dropdownSort">
                <li>
                  <a
                    ngbDropdownItem
                    transloco="title"
                    (click)="list.sortList('title')"
                  ></a>
                </li>
                <li>
                  <a
                    ngbDropdownItem
                    transloco="rolledout"
                    (click)="list.sortList('lastRollout')"
                  ></a>
                </li>
                <li>
                  <a
                    ngbDropdownItem
                    transloco="active"
                    (click)="list.sortList('activegroups')"
                  ></a>
                </li>
                <li>
                  <a
                    ngbDropdownItem
                    transloco="CREATIONDATE"
                    (click)="list.sortList('createdAt')"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="main_block_with_scroll">
          <div>
            <!-- list items -->
            <ul class="lists">
              <ng-container
                *ngFor="
                  let signature of list.all | filter : 'title' : filterTextSig;
                  odd as isOdd;
                  even as isEven
                "
              >
                <li
                  class="listrow"
                  (click)="list.updateSelection(signature)"
                  [ngClass]="{
                    selected: list.isSelected(signature),
                    odd: isOdd,
                    even: isEven
                  }"
                >
                  <div class="tcell first select">
                    <selectcol [list]="list" [item]="signature"></selectcol>
                  </div>
                  <div class="tcell" style="width: 35%">
                    <signaturecol
                      [isFirst]="true"
                      [item]="signature"
                      [disablelinks]="true"
                    ></signaturecol>
                  </div>
                  <div class="tcell" style="width: 30%">
                    <groupcol
                      [groups]="signature.activegroups"
                      [disableLinks]="true"
                    ></groupcol>
                  </div>
                  <div class="tcell last" style="width: 25%">
                    <rollout-status-col
                      [signature]="signature"
                    ></rollout-status-col>
                  </div>
                </li>
              </ng-container>
            </ul>
          </div></div
      ></ng-container>
      <!-- Empty State/Loading -->
      <mt-emptystate
        [showEmpty]="list.all.length === 0"
        class="text-center"
        hltitle="NoSignatureavailable"
        subtitle="CreateobtainaSummaryhere"
        svg="signature"
      ></mt-emptystate>
    </div>
  </div>
  <ng-template #loading>
    <div class="card">
      <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
  ></ng-template>
  <div modal-footer-right>
    <div class="flex">
      <mt-button
        (click)="modal.close()"
        class="mt_left"
        color="default"
        text="abort"
      ></mt-button>
      <mt-button
        (click)="assignSignature()"
        [disabled]="!list.selected.length"
        class="roboto_light"
        color="primary"
        icon="plus"
        text="assignesignature"
      >
      </mt-button>
    </div>
  </div>
</mt-custom-modal>
