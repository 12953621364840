import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PriceData, Product } from 'src/app/model/interfaces/payment.interface';

@Component({
  selector: 'mt-plan-target-detail',
  templateUrl: './plan-target-detail.component.html',
  styleUrls: ['./plan-target-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanTargetDetailComponent {
  /**
   * Input of calculate price data
   */
  @Input() priceData!: PriceData;

  /**
   * Input of max number of employees
   */
  @Input() maxUsers!: number;

  /**
   * Input of current amount of system users
   */
  @Input() currentAmountOfSystemUsers!: number;

  /**
   * Input of billing interval
   */
  @Input() billingInterval!: string;

  /**
   * Hold product name for subscribed user
   */
  @Input() currentProduct!: Product;

  /**
   * Terms for target plan accepted
   * @defaultValue false
   */
  acceptedTargetTechnicalInfo = false;

  /**
   * Triggers selected plan
   */
  @Output() setProduct = new EventEmitter<Product>();
}
