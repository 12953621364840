import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

/**
 * Component for displaying modal window with unsaved changes
 *
 * All strings are translation tags and need to be provided
 */
@Component({
  selector: 'mt-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CustomModalComponent, NgIf, TranslocoModule, ButtonComponent]
})
export class UnsavedChangesComponent {
  // Modal title
  modalHeader = '';

  // Modal body
  text = '';

  // Save button
  saveBtnText = '';

  // Don't save button
  noSaveBtnText = '';

  constructor(public modal: NgbActiveModal) {}
}
