import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RBARole, UserGetAdvancedAdmins } from '@services/rba/rba-settings.interface';
import { BehaviorSubject, map } from 'rxjs';

const ACCOUNT_ADMIN_ROLE = 'account_admin_title';

@Component({
  selector: 'roles-selection',
  templateUrl: './roles-selection.component.html',
  styleUrls: ['./roles-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesSelectionComponent {
  /**
   * Roles list
   * @defaultValue []
   */
  @Input() roles: RBARole[] = [];

  /**
   * Selected admin to manage roles
   * @param admin - Selected admin
   */
  @Input() set selectedAdmin(admin: UserGetAdvancedAdmins) {
    this.selectRoles = {
      rolesList: admin.RolesAdmins.map(roles => ({ ...roles.Role, id: roles.roleId })),
      role: undefined
    };
  }

  /**
   * Triggers selected roles
   */
  @Output() selectedRoles = new EventEmitter<RBARole[]>();

  /**
   * Observable is used to holds the value of selected roles
   */
  private _selectedRoles$ = new BehaviorSubject<{ rolesList: RBARole[]; role: RBARole | undefined }>({
    rolesList: [],
    role: undefined
  });

  /**
   * Handles the stream of selected roles and manages the selected roles
   */
  selectedRoles$ = this._selectedRoles$.asObservable().pipe(
    map(data => {
      if (!!data.role) {
        const check = data.rolesList.findIndex(item => item.id === data.role?.id);
        if (check === -1) {
          if (data.role.titleTag === ACCOUNT_ADMIN_ROLE) {
            data.rolesList = [];
          } else {
            data.rolesList = data.rolesList.filter(role => role.titleTag !== ACCOUNT_ADMIN_ROLE);
          }
          data.rolesList.push(data.role);
        } else {
          data.rolesList.splice(check, 1);
        }
      }
      this.selectedRoles.emit(data.rolesList);
      return data.rolesList;
    })
  );

  /**
   * Trigger the data on select/deselect the role
   * @param data - Object of selected roles list & selected roles
   */
  set selectRoles(data: { rolesList: RBARole[]; role: RBARole | undefined }) {
    this._selectedRoles$.next({ rolesList: data.rolesList, role: data.role });
  }

  /**
   * @remarks Used method here because checkboxs are created based on the roles from backend so can not use static form control for the same
   *
   * Method is used to determine role is checked or not
   * @param rolesList - Role list
   * @param role - Selected role
   * @returns - Checked status
   */
  isChecked(rolesList: RBARole[], role: RBARole): boolean {
    return !!rolesList.find(item => item.id === role.id);
  }
}
