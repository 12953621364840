<ng-container *ngIf="targetGroupListEntry">
  <div *ngIf="targetGroupListEntry.isOptedOut; else domainOptOutContact">
    <mt-custom-modal
      headline="{{
        targetGroupListEntry
          | optInOutTypeText : 'opt_in_contact' : 'opt_in_account'
          | transloco
      }}"
    >
      <div modal-body>
        <div class="content">
          <div
            class="schrift_blau blue-heading"
            transloco="{{
              targetGroupListEntry
                | optInOutTypeText
                  : 'sure_opt_in_contact'
                  : 'sure_opt_in_account'
            }}"
          ></div>
          <div
            class="marbot20"
            [innerHtml]="
              targetGroupListEntry
                | optInOutTypeText
                  : 'html.info_opt_in_contact'
                  : 'html.info_opt_in_account'
                | transloco
            "
          ></div>
        </div>
      </div>
      <div modal-footer>
        <div class="inline-flex-left footer">
          <div class="flex text-left">
            <input
              id="termsCheckbox"
              type="checkbox"
              class="marright10"
              [(ngModel)]="acceptedTrackingTerms"
            />
            <label for="termsCheckbox" class="terms-checkbox-lbl"
              >{{ 'confirm_opt_in' | transloco }}
            </label>
            <a
              target="_blank"
              [href]="
                translate.getActiveLang() === 'de'
                  ? 'https://help.mailtastic.com/de/articles/4752798-domains-und-kontakte-tracken'
                  : 'https://help.mailtastic.com/en/articles/4752798-tracking-domains-and-contacts'
              "
            >
              <span
                class="marleft5"
                tooltipClass="status-info-tooltip"
                placement="top"
                ngbTooltip="{{ 'conform_tracking_mo_info' | transloco }}"
              >
                <mt-svg
                  src="assets/images/svgs/info_other_svg.svg"
                  class="mt-grau_opaque tiny-svg"
                ></mt-svg>
              </span>
            </a>
          </div>
        </div>
        <div class="inline-flex-right">
          <div class="flex">
            <mt-button
              ngbAutoFocus
              text="abort"
              color="default"
              class="mt_left"
              (click)="modal.close()"
            ></mt-button>
            <mt-button
              text="opt_in"
              color="primary"
              class="roboto_light"
              [disabled]="
                targetGroupListEntry.isOptedOut && !acceptedTrackingTerms
              "
              (click)="modal.close(true)"
            >
            </mt-button>
          </div>
        </div>
      </div>
    </mt-custom-modal>
  </div>
  <ng-template #domainOptOutContact>
    <mt-custom-modal
      headline="{{
        targetGroupListEntry
          | optInOutTypeText : 'opt_out_contact' : 'opt_out_account'
          | transloco
      }}"
    >
      <div modal-body>
        <div class="content">
          <div
            class="schrift_blau blue-heading"
            transloco="{{
              targetGroupListEntry
                | optInOutTypeText
                  : 'sure_opt_out_contact'
                  : 'sure_opt_out_account'
            }}"
          ></div>
          <div
            class="marbot20"
            [innerHtml]="
              targetGroupListEntry
                | optInOutTypeText
                  : 'html.info_opt_out_contact'
                  : 'html.info_opt_out_account'
                | transloco
            "
          ></div>
        </div>
      </div>
      <div modal-footer>
        <div class="inline-flex-right">
          <div class="flex">
            <mt-button
              (click)="modal.close()"
              class="mt_left"
              color="default"
              ngbAutoFocus
              text="abort"
            ></mt-button>
            <mt-button
              (click)="modal.close(true)"
              class="roboto_light"
              color="primary"
              text="opt_out"
            >
            </mt-button>
          </div>
        </div>
      </div>
    </mt-custom-modal>
  </ng-template>
</ng-container>
