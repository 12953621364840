import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';

@Component({
  selector: 'mt-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgbTooltip, TranslocoModule]
})
export class MtSwitchComponent {
  /**
   * True : on/off switch otherwise active/paused
   * @defaultValue true
   */
  @Input() isSmallSwitch = true;

  /**
   * Checked status
   * @defaultValue false
   */
  @Input() checked = false;

  /**
   * If true, the checkbox-type input will be disabled
   * @defaultValue false
   */
  @Input() disabled = false;

  /**
   * If you want to display tooltip on it pass the value
   * @defaultValue ''
   */
  @Input() tooltipText = '';

  /**
   * Sends status of switch to parent
   */
  @Output() changeStatus = new EventEmitter<boolean>();

  /**
   * Emits the status of switch
   * @param status - boolean true for on, false for off
   */
  changeSwitchStatus(status: boolean): void {
    this.changeStatus.emit(status);
  }
}
