import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Bar displayed at the top of the list page
 *
 * Floats content to left and right
 */
@Component({
  selector: 'mt-list-actions',
  templateUrl: './list-actions.component.html',
  styleUrls: ['./list-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class ListActionsComponent {}
