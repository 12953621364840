import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from 'src/app/services/alert/alert.service';
import { PatternService } from 'src/app/shared/services/pattern.service';
import { TranslocoModule } from '@ngneat/transloco';
import { DefaultBoxComponent } from '@molecules/boxes/default/default.component';
import { NgIf } from '@angular/common';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';

@Component({
  selector: 'mt-infobar-play-video',
  templateUrl: './infobar-play-video.component.html',
  styleUrls: ['./infobar-play-video.component.scss'],
  standalone: true,
  imports: [CustomModalComponent, NgIf, DefaultBoxComponent, TranslocoModule]
})
export class InfobarPlayVideoComponent implements OnInit {
  /**
   * Youtube link to play as translate tag
   */
  @Input() youtubeLink!: string;

  /**
   * Modal header title as translate tag
   */
  @Input() modalHeader!: string;

  isLinkValid = true;

  constructor(
    private alert: AlertService,
    private dom: DomSanitizer,
    private pattern: PatternService,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    const url = this.alert.translate(this.youtubeLink);
    url.match(this.pattern.youtubeVideoLinkPattern)
      ? (this.youtubeLink = this.dom.bypassSecurityTrustResourceUrl(url) as string)
      : (this.isLinkValid = false);
  }
}
