import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IDataset } from '@model/interfaces/dataset.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoModule } from '@ngneat/transloco';
import { map, forkJoin, Observable, of, ReplaySubject, switchMap, take, tap, defer, iif } from 'rxjs';
import {
  IEmployeeSignaturePlaceholders,
  ISignaturePlaceholderImg,
  ISignaturePlaceholderValueImg,
  ISignaturePlaceholderValueImgAdditional,
  UploadProfilePhotoData,
  ImageDimensionWithMode
} from 'src/app/model/interfaces/signature.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { EBoxType } from 'src/app/model/enums/box-type.enum';
import { UploadImageButtonComponent } from '@molecules/buttons/upload-image-button/upload-image-button.component';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgStyle, AsyncPipe } from '@angular/common';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { UtilService } from '@services/util/util.service';

/*
 * REMARK:
 * One needs to use value.whichImage = 'customHTML' as the default,
 * since if one uses value.whichImage = 'own' instead,
 * then an error will happen while saving on the backend and return aa 107 error code.
 * Or if one uses value.whichImage = 'default' instead,
 * then the image will have a size of 20px x 20px.
 */
const DEFAULT_IMAGE: ISignaturePlaceholderImg = {
  defaultImage: 'https://app.mailtastic.de/api/images/default/User.png',
  disabled: false,
  global: false,
  imgdimension: { height: 50, mode: 'default', width: 50 },
  label: 'Foto',
  locked: false,
  styles: { linkcolor: '', underline: 'true' },
  tag: 'ma_foto',
  type: 'image',
  value: {
    altText: 'ma_foto',
    cropType: 'square',
    etag: null,
    global: false,
    image: { $ngfDataUrl: '', $ngfHeight: 50, $ngfWidth: 50 },
    initialdimension: { height: 50, width: 50 },
    isCropped: true,
    linkText: '',
    showAs: 'image',
    source: 'uploaded',
    type: 'image',
    url: '',
    useForAllProfiles: false,
    whichImage: 'customHTML'
  }
};

@Component({
  selector: 'mt-upload-profile-photo',
  templateUrl: './upload-profile-photo.component.html',
  styleUrls: ['./upload-profile-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    FormsModule,
    MtSvgComponent,
    NgIf,
    NgStyle,
    ReactiveFormsModule,
    TranslocoModule,
    UploadImageButtonComponent
  ]
})
export class UploadProfilePhotoComponent implements OnInit {
  private alert = inject(AlertService);
  private datasetService = inject(DatasetService);
  private employeeService = inject(EmployeeService);
  private modalService = inject(ModalService);
  private operators = inject(CustomOperators);
  private translate = inject(TranslocoService);
  private utilService = inject(UtilService);
  modal = inject(NgbActiveModal);

  private _imageDimensions = DEFAULT_IMAGE.imgdimension;
  // Loads employee placeholders - ma_foto object
  loadUserPhoto$?: Observable<IEmployeeSignaturePlaceholders>;

  advancedSettingsForm = new FormGroup({
    global: new FormControl(false, { nonNullable: true }), // should photo be used for all employess
    useForAllProfiles: new FormControl(false, { nonNullable: true }), // should photo be applied to all datasets
    photoUrl: new FormControl('', { nonNullable: true }),
    whichImage: new FormControl('default', { nonNullable: true }),
    imageDimensionMode: new FormControl('default', { nonNullable: true }),
    imageDimensionWidth: new FormControl<number>(500, { nonNullable: true })
  });

  /**
   * Photo with profile id by the user
   */
  @Input() uploadProfilePhotoData!: UploadProfilePhotoData;

  /**
   * Default dataset for enduser cockpit in case of user is not loggeed in
   */
  @Input() defaultDataset!: IDataset;

  /**
   * The image dimensions to use.
   * @defaultValue `{ height: 500, mode: 'default', width: 500 }`
   */
  @Input()
  set imageDimensions(dimensions) {
    this._imageDimensions = {
      height: dimensions.height || this._imageDimensions.height,
      mode: dimensions.mode ?? this._imageDimensions.mode,
      width: dimensions.width || this._imageDimensions.width
    };
  }
  get imageDimensions(): ImageDimensionWithMode {
    return this._imageDimensions;
  }

  /**
   * Determines which of the advanced settings should be shown
   *
   * @defaultValue {
   * photoUrl: true,
   * useForAllEmployees: true,
   * useForAllProfiles: true,
   * customDimensions: false
   * }
   */
  @Input() availableOptions = {
    photoUrl: true,
    useForAllEmployees: true,
    useForAllProfiles: true,
    customDimensions: false
  };

  // Photo being displayed
  private _displayImage$ = new ReplaySubject<string>(1);
  displayImage$ = this._displayImage$.asObservable();

  ngOnInit(): void {
    if (this.uploadProfilePhotoData && this.uploadProfilePhotoData.imageUploaded) {
      // From edit employee - no need to call api
      let userData!: IEmployeeSignaturePlaceholders;
      userData = {
        ...userData,
        ma_foto: this.uploadProfilePhotoData.imageUploaded.value
      };

      this.patchFormValues(userData);

      this.loadUserPhoto$ = of(userData).pipe(
        tap(datasetData => {
          const image = datasetData.ma_foto?.image as ISignaturePlaceholderValueImgAdditional;
          if (image.$ngfDataUrl) {
            this.setUserUploadedPhoto(image?.$ngfDataUrl);
          } else {
            this.setUserUploadedPhoto('none');
          }
        })
      );
    } else if (this.uploadProfilePhotoData.userId === 'dummy') {
      // Empty dataset for dummy user to prevent API call to enter dataset record in DB
      this.loadUserPhoto$ = of({} as IEmployeeSignaturePlaceholders).pipe(tap(val => this.processDataset(val)));
    } else if (this.uploadProfilePhotoData.userId && this.uploadProfilePhotoData.datasetId) {
      this.loadUserPhoto$ = of(this.defaultDataset).pipe(
        switchMap(dataset => {
          return iif(
            () => !!dataset.id,
            defer(() => of(dataset)),
            defer(() =>
              this.datasetService.getOneSpecificForUser(
                this.uploadProfilePhotoData.userId || '',
                this.uploadProfilePhotoData.datasetId
              )
            )
          );
        }),
        tap(dataset => {
          this.uploadProfilePhotoData.userDatasetId = dataset.id;
        }),
        map(result => JSON.parse(result.data || '{}') as IEmployeeSignaturePlaceholders),
        this.operators.filterNullish(),
        tap(datasetData => this.processDataset(datasetData))
      );
    }
  }

  /**
   * Process dataset object for set initial values
   * @param datasetData - Dataset object to patch foto
   */
  processDataset(datasetData: IEmployeeSignaturePlaceholders): void {
    if (datasetData?.ma_foto?.image && typeof datasetData.ma_foto?.image === 'string') {
      this.setUserUploadedPhoto(datasetData.ma_foto.image);
    } else {
      this.setUserUploadedPhoto('none');
    }
    this.patchFormValues(datasetData);
  }

  /**
   * Patch form values from dataset object
   * @param datasetData - Dataset object to patch form field values
   */
  patchFormValues(datasetData: IEmployeeSignaturePlaceholders): void {
    if (datasetData?.ma_foto?.useForAllProfiles) {
      this.advancedSettingsForm.controls.useForAllProfiles.setValue(datasetData.ma_foto.useForAllProfiles);
    }

    if (datasetData?.ma_foto?.global) {
      this.advancedSettingsForm.controls.global.setValue(datasetData.ma_foto.global);
    }
    if (datasetData?.ma_foto?.url && datasetData?.ma_foto?.url !== '#') {
      this.advancedSettingsForm.controls.photoUrl.setValue(datasetData.ma_foto.url);
    }
    if (datasetData?.ma_foto?.showAs === 'image') {
      this.advancedSettingsForm.controls.whichImage.setValue(datasetData.ma_foto.whichImage ?? 'default');
      if (!this.advancedSettingsForm.controls.imageDimensionMode.touched) {
        this.advancedSettingsForm.controls.imageDimensionMode.setValue(this.imageDimensions.mode);
      }
      if (!this.advancedSettingsForm.controls.imageDimensionWidth.touched) {
        this.advancedSettingsForm.controls.imageDimensionWidth.setValue(this.imageDimensions.width);
      }
    }
  }

  /**
   * Displays the image cropping modal to the user
   *
   * Saves image information to the `imageUploaded` object
   * @param image - Image uploaded by the user
   */
  processImage(image: HTMLImageElement): void {
    // object containing $ngfDataUrl, isCropped and cropType
    this.modalService
      .openImageCropperModalV2(image)
      .pipe(
        tap(processedImage => {
          this.uploadProfilePhotoData.imageUploaded = this.getDefaultImage();

          this.advancedSettingsForm.reset();

          if (typeof processedImage.value?.image !== 'string' && this.uploadProfilePhotoData.imageUploaded.value) {
            this.uploadProfilePhotoData.imageUploaded.value.whichImage = 'own';
            // processedImage should be first evaluated to get the correct dimensions from the cropper
            this.uploadProfilePhotoData.imageUploaded.value.initialdimension.height =
              processedImage?.imgdimension?.height ?? image.height;
            this.uploadProfilePhotoData.imageUploaded.value.initialdimension.width =
              processedImage?.imgdimension?.width ?? image.width;

            this.uploadProfilePhotoData.imageUploaded.value.image = {
              $ngfDataUrl: processedImage.value?.image.$ngfDataUrl,
              $ngfHeight: processedImage.value?.image.$ngfHeight,
              $ngfWidth: processedImage.value?.image.$ngfWidth
            } as ISignaturePlaceholderValueImgAdditional;

            this.uploadProfilePhotoData.imageUploaded.imgdimension.height =
              processedImage.value?.image.$ngfHeight ??
              processedImage?.imgdimension?.height ??
              DEFAULT_IMAGE.imgdimension.height;

            this.uploadProfilePhotoData.imageUploaded.imgdimension.width =
              processedImage.value?.image.$ngfWidth ??
              processedImage?.imgdimension?.width ??
              DEFAULT_IMAGE.imgdimension.width;

            this.uploadProfilePhotoData.imageUploaded.value.cropType = processedImage.value?.cropType;
            this.uploadProfilePhotoData.imageUploaded.value.isCropped = processedImage.value?.isCropped;

            this.advancedSettingsForm.controls.whichImage.setValue('own');
            // auto assigns to manual crop size
            this.advancedSettingsForm.controls.imageDimensionWidth.setValue(
              this.uploadProfilePhotoData.imageUploaded.imgdimension.width
            );

            if (this.uploadProfilePhotoData.imageUploaded.value.image.$ngfDataUrl) {
              this.setUserUploadedPhoto(this.uploadProfilePhotoData.imageUploaded.value.image.$ngfDataUrl);
            }
          }
        })
      )
      .subscribe();
  }

  /**
   * Deletes the assigned photo
   */
  async deletePhoto(
    originalPhoto?: ISignaturePlaceholderValueImg,
    uploadedPhoto?: ISignaturePlaceholderImg
  ): Promise<void> {
    if (uploadedPhoto) {
      this.uploadProfilePhotoData.imageUploaded = this.getDefaultImage();
      this.setUserUploadedPhoto('none');
      return;
    }

    if (originalPhoto) {
      if (await this.alert.defaultConfirmPrompt('sure_you_want_to_delete')) {
        this.uploadProfilePhotoData.imageUploaded = this.getDefaultImage();
        this.advancedSettingsForm.reset();
        this.setUserUploadedPhoto('none');
      }
    }
  }
  /**
   * Closes the modal and saves photo/changes made if any
   */
  savePhoto(): void {
    // No changes made
    if (!this.uploadProfilePhotoData.imageUploaded && !this.advancedSettingsForm.dirty) {
      this.modal.close(undefined);
    }

    // Changes made to settings of already existing photo
    if (!this.uploadProfilePhotoData.imageUploaded && this.advancedSettingsForm.dirty) {
      const newURL = this.advancedSettingsForm.controls.photoUrl.value;
      const global = this.advancedSettingsForm.controls.global.value;
      const useForAllProfiles = this.advancedSettingsForm.controls.useForAllProfiles.value;
      this.loadUserPhoto$
        ?.pipe(
          take(1),
          map(placeholders => {
            const currentPhoto = placeholders.ma_foto;

            const updatePhoto = this.getDefaultImage();

            if (updatePhoto.value && currentPhoto) {
              updatePhoto.value = { ...currentPhoto };

              if (this.advancedSettingsForm.controls.global.value) {
                updatePhoto.value.global = global;
                updatePhoto.global = global;
              }

              if (this.advancedSettingsForm.controls.useForAllProfiles.value) {
                updatePhoto.value.useForAllProfiles = useForAllProfiles;
              }

              if (this.advancedSettingsForm.controls.photoUrl.value) {
                updatePhoto.value.url = newURL;
              }

              if (this.advancedSettingsForm.controls.imageDimensionMode.value === 'default') {
                this.advancedSettingsForm.controls.imageDimensionWidth.setValue(currentPhoto.initialdimension.width);
              }

              // update dimensions so that default dimensions are not applied
              // they will force a square crop, which is not always the case
              updatePhoto.imgdimension = this.utilService.scaleImage(
                currentPhoto.initialdimension.width,
                currentPhoto.initialdimension.height,
                this.advancedSettingsForm.controls.imageDimensionWidth.value
              );
            }

            return updatePhoto;
          }),
          switchMap(updatePhoto =>
            forkJoin({
              data: this.employeeService.setEmployeeInfoSingle(this.uploadProfilePhotoData.userDatasetId, updatePhoto),
              updatePhoto: of(updatePhoto)
            })
          )
        )
        .subscribe(result => {
          const parsedData = {
            imageUploaded: {
              imgdimension: {
                height: result.updatePhoto.imgdimension.height,
                mode: result.updatePhoto.imgdimension.mode,
                width: result.updatePhoto.imgdimension.width
              },
              value: result.data
            }
          } as UploadProfilePhotoData;
          void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
          this.modal.close(parsedData);
        });
    }

    // New photo uploaded
    else if (
      this.uploadProfilePhotoData?.imageUploaded?.value?.image &&
      (this.uploadProfilePhotoData.imageUploaded?.value.image as ISignaturePlaceholderValueImgAdditional).$ngfDataUrl
    ) {
      this.uploadProfilePhotoData.imageUploaded.global = this.advancedSettingsForm.controls.global.value;
      this.uploadProfilePhotoData.imageUploaded.value.useForAllProfiles =
        this.advancedSettingsForm.controls.useForAllProfiles.value;
      this.uploadProfilePhotoData.imageUploaded.value.url = this.advancedSettingsForm.controls.photoUrl.value || '#';
      // custom width was selected
      if (this.advancedSettingsForm.controls.imageDimensionMode.value === 'custom') {
        if (this.uploadProfilePhotoData.imageUploaded.value.isCropped === true) {
          this.uploadProfilePhotoData.imageUploaded.imgdimension = this.utilService.scaleImage(
            this.uploadProfilePhotoData.imageUploaded.imgdimension.width,
            this.uploadProfilePhotoData.imageUploaded.imgdimension.height,
            this.advancedSettingsForm.controls.imageDimensionWidth.value
          );
        } else {
          this.uploadProfilePhotoData.imageUploaded.imgdimension = this.utilService.scaleImage(
            this.uploadProfilePhotoData.imageUploaded.value.initialdimension.width,
            this.uploadProfilePhotoData.imageUploaded.value.initialdimension.height,
            this.advancedSettingsForm.controls.imageDimensionWidth.value
          );
        }
      } else {
        if (this.uploadProfilePhotoData.imageUploaded.value.isCropped === false) {
          // assign initial dimensions
          this.uploadProfilePhotoData.imageUploaded.imgdimension = {
            height: this.uploadProfilePhotoData.imageUploaded.value.initialdimension.height,
            mode: 'default',
            width: this.uploadProfilePhotoData.imageUploaded.value.initialdimension.width
          };
        }
        // else case is handled by the cropper
      }

      this.modal.close(this.uploadProfilePhotoData);
    } else {
      // Image deleted
      this.uploadProfilePhotoData.imageUploaded = this.getDefaultImage();
      this.modal.close(this.uploadProfilePhotoData);
    }
  }

  /**
   * Returns a clean copy of image object with fields required to upload
   * @returns Clean copy of image object
   */
  private getDefaultImage(): ISignaturePlaceholderImg {
    return structuredClone(DEFAULT_IMAGE);
  }

  /**
   * Sets a new photo to be displayed
   * @param newPhoto - New photo being displayed from `$ngfDataUrl`
   */
  private setUserUploadedPhoto(newPhoto: string): void {
    if (newPhoto === 'none') {
      this.advancedSettingsForm.controls.whichImage.setValue('default');
    }
    this._displayImage$.next(newPhoto);
  }

  /**
   * Returns a border radius style value by crop type
   * @returns Border radius value as a string
   */
  getBorderRadius(uploadProfilePhotoData: UploadProfilePhotoData): string {
    return uploadProfilePhotoData.imageUploaded?.value?.cropType === 'circle' &&
      uploadProfilePhotoData.imageUploaded?.value?.isCropped
      ? '50%'
      : '0%';
  }

  /**
   * Open confirmation while click on `Set graphics for all emp`
   * @param event - Object of `Event`
   */
  graphicsForAllConfirmation(event: Event): void {
    this.advancedSettingsForm.markAsDirty();
    if (!this.advancedSettingsForm.controls.global.value) {
      event.preventDefault();
      this.alert
        .defaultConfirmationPrompt(
          this.translate.translate('set_emp_photo_for_all_emps'),
          EBoxType.INFO,
          this.translate.translate('CAUTION')
        )
        .subscribe(() => this.advancedSettingsForm.controls.global.setValue(true));
    }
  }
}
