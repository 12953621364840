import { Injectable, computed, signal } from '@angular/core';

const DEFAULT_PARAMS = { page: 0, pageSize: 10, search: '' };

/**
 * Gets app default query params
 * @returns Default query params
 */
export const getDefaultQueryParams = () => ({
  page: DEFAULT_PARAMS.page,
  pageSize: DEFAULT_PARAMS.pageSize,
  search: DEFAULT_PARAMS.search
});

@Injectable()
export class QueryParamsService {
  // Holds the search term
  private search = signal(getDefaultQueryParams().search);

  // Holds the pagination object
  private pagination = signal({ page: getDefaultQueryParams().page, pageSize: getDefaultQueryParams().pageSize });

  // Computes query params object from both search and pagination signals
  readonly queryParams = computed(() => ({ ...this.pagination(), search: this.search() }));

  /**
   * Update pagination signal to next page
   */
  updateToNextPage = () => this.pagination.update(pagination => ({ ...pagination, page: ++pagination.page }));

  /**
   * Updates search signals and resets pagination signal
   * @param search - Search term
   */
  updateSearch = (search: string) => {
    this.search.set(search);

    // Reset pagination when setting a search since we want to start from page 0
    this.pagination.set({ page: getDefaultQueryParams().page, pageSize: getDefaultQueryParams().pageSize });
  };
}
