<mt-custom-modal
  *transloco="let translate"
  [headline]="translate('AddExistingemployees')"
>
  <div modal-body>
    <mt-list-actions>
      <ng-container left-side>
        <mt-select-all-button
          class="leftbutton"
          [checked]="selectionList().isSelectedAll()"
          (click)="selectionList().updateSelectionAll()"
        />

        <div class="d-flex">
          <mt-svg
            class="mt-grey tiny-svg me-2"
            src="assets/images/svgs/info.svg"
          />
          <div class="d-flex format3 text-wrap text-width">
            {{ translate('Employeesdepartmentarenotlisted') }}
          </div>
        </div>
      </ng-container>
      <ng-container right-side>
        <mt-search
          class="leftbutton"
          [disableEnter]="true"
          [minLength]="3"
          (search)="queryParamsService.updateSearch($event)"
        />
      </ng-container>
    </mt-list-actions>
    <div>
      <div class="format3 text-wrap text-width">
        {{selectionList().selected.length}}/{{employeeCount()}}
      </div>
    </div> 
    @if (isLoading()) {
      <mt-emptystate [loadingPromise]="true" />
    }    
    <div #assignableEmployeesContainer class="list-height">
      <div
        infiniteScroll
        [infiniteScrollDistance]="1"
        [infiniteScrollThrottle]="5"
        [infiniteScrollDisabled]="isLoading() || loadedAll()"
        (scrolled)="scroll()"
        [infiniteScrollContainer]="assignableEmployeesContainer"
        [fromRoot]="true"
      >
        @if (!selectionList().all.length && !isLoading()) {
          <mt-emptystate
            [showEmpty]="true"
            [svg]="'folder'"
            [hltitle]="'Thereemployeewhocanbeassignedtothegroup'"
            [subtitle]="'Notegrouparenotdisplayed'"
          />
        }

        @if (employees$ | async; as employees) {
          <mt-list>
            @for (
              employee of selectionList().all;
              track employee.id;
              let odd = $odd, even = $even
            ) {
              <mt-row
                [isEven]="even"
                [isOdd]="odd"
                (click)="selectionList().updateSelection(employee)"
              >
                <div class="tcell first select">
                  <mt-checkbox
                    [checked]="selectionList().isSelected(employee)"
                  />
                </div>

                <div class="tcell">
                  <employeecol
                    [user]="employee"
                    [disableLinks]="true"                    
                  ></employeecol>
                </div>

                <div class="tcell">
                  <groupcol
                    [groups]="employee.Groups"
                    [disableLinks]="true"
                  ></groupcol>
                </div>
              </mt-row>
            }
          </mt-list>
        } 
      </div>
    </div>
  </div>

  <div modal-footer>
    <div class="flex-center-right">
      <mt-button
        ngbAutoFocus
        text="abort"
        class="marright10"
        (click)="modal.close()"
      ></mt-button>
      <mt-button
        [disabled]="selectionList().selected.length === 0"
        color="primary"
        text="Assignselectedemployees"
        (click)="modal.close(selectionList().selected)"
      ></mt-button>
    </div>
  </div>
</mt-custom-modal>
