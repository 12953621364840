import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { TargetGroupsGetTargetGroups } from 'src/app/services/target-group/target-group-service.type';
import { DomainDetailsService } from '../../../services/domain-details/domain-details.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'mt-select-target-group-modal',
  templateUrl: './select-target-group-modal.component.html',
  styleUrls: ['./select-target-group-modal.component.scss'],
  providers: [ListHelperService, DomainDetailsService]
})
export class SelectTargetGroupModalComponent {
  /**
   * Text for searching list
   * @defaultValue ''
   */
  filterText = '';

  /**
   * Determines if data is being loaded
   * @defaultValue true
   */
  isLoading = true;

  /**
   * Input of target group list id to move other target group instead
   */
  @Input() targetGroupListId!: string;

  /**
   * Load list of taget groups
   */
  loadTargetGroup$ = this.domainDetailsService.targetGroupList$.pipe(
    tap(res => {
      this.list.all = res.filter(targetGroupList => targetGroupList.id !== this.targetGroupListId);
      this.list.selected = [];
      this.list.createFraction(this.list.all.length);
      this.list.setFiltered();
      this.list.selectionType = 'single';
      this.isLoading = false;
    })
  );

  constructor(
    public domainDetailsService: DomainDetailsService,
    public list: ListHelperService<TargetGroupsGetTargetGroups>,
    public modal: NgbActiveModal
  ) {}

  /**
   * Action when target groups have been selected
   */
  onSelectTargetGroups(): void {
    this.modal.close(this.list.selected[0]);
  }
}
