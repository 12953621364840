<ul class="includes-items">
  <li *ngFor="let item of includesProduct" class="flex">
    <div>
      <span class="glyphicon glyphicon-ok"></span>
    </div>
    <div>
      <span transloco="{{ item }}"></span>
    </div>
  </li>
</ul>
<ul class="excludes-items" *ngIf="excludesProduct">
  <li *ngFor="let item of excludesProduct" class="flex">
    <div class="empty-img">&nbsp;</div>
    <div>
      <span transloco="{{ item }}"></span>
    </div>
  </li>
</ul>
