import { Pipe, PipeTransform } from '@angular/core';
import { UsersToBuyLicenses } from '@model/interfaces/plans.interface';

/**
 * Pipe is used to get the final price amount to buy users while importing
 * @param usersToBuyData - Object with max community users, price, import users count
 * @returns The final price amount to buy users while importing
 */
@Pipe({
  name: 'usersToBuyAmount',
  standalone: true
})
export class UsersToBuyAmountPipe implements PipeTransform {
  transform(usersToBuyData: UsersToBuyLicenses): number {
    const priceToShow = usersToBuyData.validAmount * (usersToBuyData.customPrice ?? 0);
    return Math.round(priceToShow * 100) / 100;
  }
}
