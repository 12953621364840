import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, tap } from 'rxjs';
import { ICampaign, ICampaignModel } from 'src/app/model/interfaces/campaign.interface';
import {
  CountWithoutResponse,
  DataOrMessage,
  IResponseCount,
  IResponseId,
  IResponseMessageType
} from 'src/app/model/interfaces/response.interface';
import {
  ICampaignGet,
  ICampaignGetAllBy,
  ICampaignStatistics,
  IParams
} from 'src/app/services/campaign/campaign-services.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { IntercomService, INTERCOM_DATA } from '../intercom/intercom.module';
import { NavigationSidebarService } from '../navigation-sidebar/navigation-sidebar.service';
import { IQueryObj } from '../query-helper/query-helper-service.interface';
import { QueryHelperService } from '../query-helper/query-helper.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  constructor(
    private alert: AlertService,
    private http: HttpClient,
    private intercomService: IntercomService,
    private navigationSidebarService: NavigationSidebarService,
    private operator: CustomOperators,
    private queryHelperService: QueryHelperService
  ) {}

  /**
   * Get all campaigns for account, including clicks, views,
   * And assigned groups, target groups and trigger events
   * @returns {Observable<ICampaignGet[]>} Observable with array of ICampaignModel objects
   */
  get(): Observable<ICampaignGet[]> {
    return this.http.get<ICampaignGet[]>('/campaigns').pipe(
      map(value => {
        if (!value) {
          throw new Error(this.alert.translateTechnicalError());
        }
        return value;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Get all campaigns for account, including clicks, views,
   * And assigned groups, target groups and trigger events
   * @param queryObj - query object holding the keys or empty
   * @returns {Observable<ICampaignGet[]>} Observable with array of ICampaignModel objects
   */
  getV2WithQueryObject(queryObj: IQueryObj): Observable<ICampaignGet[]> {
    return this.http
      .get<DataOrMessage<ICampaignGet[]>>(`/v2/campaigns${this.queryHelperService.createQuery(queryObj)}`)
      .pipe(
        map(value => {
          if (!value.data) {
            throw new Error(this.alert.translateTechnicalError());
          }
          return value.data;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Gets all campaigns based off of parameters
   * @param params - Type of campaings to return
   * @returns Observable of array of campaigns
   */
  getAllBy(params: IParams): Observable<ICampaignGetAllBy[]> {
    return this.http.post<ICampaignGetAllBy[]>('/campaigns/get/all/by/', params).pipe(
      map(value => {
        if (!value) {
          throw new Error(this.alert.translateDataNotLoaded());
        }
        return value;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Gets one campaign
   * @param id - The campaign id to get campaign detail
   * @returns
   */
  getOne(id: number): Observable<ICampaignGet> {
    return this.http.get<ICampaignGet>(`/campaigns/${id}`).pipe(
      map(value => {
        if (!value) {
          throw new Error(this.alert.translateTechnicalError());
        }
        return value;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Gets the count of campaigns.
   * @param queryObj - The query object holding the keys or empty
   * @returns Count of campaigns that exist
   */
  count(queryObj?: IQueryObj): Observable<number> {
    return this.http
      .get<CountWithoutResponse>(`/v2/campaigns/count${this.queryHelperService.createQuerySearch(queryObj)}`)
      .pipe(this.operator.extractCount());
  }

  /**
   * Creates a new campaign
   * @param campaign - The object of campaigns to add
   * @returns The campaign id and success status
   */
  add(campaign: ICampaign | Partial<ICampaignModel>): Observable<number> {
    return this.http.post<IResponseId>('/campaigns', campaign).pipe(
      this.operator.extractResponseId(),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.campaign_created);
        this.navigationSidebarService.updateSidebarSubmenuCounter(1, 'campaigns');
      })
    );
  }

  /**
   * Campaign creation from Tour because it is with default images or image to compose
   * @param data
   * @returns
   */
  createFromTour(data: any): unknown {
    const obj = {
      whichimage: data.whichimage, //image to compose or image from url
      imageurl: data.imageurl, //image url if not to compose
      imagedata: data.imagedata, //image data if compose is chosen
      campaignurl: data.campaignurl, //where should the campaign link to
      groupId: data.groupId //group it to which the campaign has to be assigned to
    };
    return this.http.post('/campaigns/fromtour', obj).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Updates a campaign
   * @param campaign - The campaign object to update
   * @returns The campaign id and success status
   */
  update(campaign: ICampaign | ICampaignModel): Observable<number> {
    return this.http.put<IResponseId>('/campaigns', campaign).pipe(
      this.operator.extractResponseId(),
      tap(() => {
        if (environment.isCogSig) this.intercomService.trackEvent(INTERCOM_DATA.campaign_modified);
      })
    );
  }

  /**
   * Deletes one campaign
   * @param id - The campaign id to delete
   * @returns
   */
  delete(id: number): Observable<IResponseMessageType> {
    return this.http.delete<IResponseMessageType>(`/campaigns/${id}`).pipe(
      this.operator.extractResponseMessageType(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(-1, 'campaigns');
      })
    );
  }

  /**
   * Deletes many campaigns
   * @param ids - The array of campaign id to delte
   * @returns
   */
  deleteMany(ids: number[]): Observable<IResponseMessageType> {
    return this.http.post<IResponseMessageType>('/campaigns/del/many', { campaignIds: ids }).pipe(
      this.operator.extractResponseMessageType(),
      tap(() => {
        this.navigationSidebarService.updateSidebarSubmenuCounter(-ids.length, 'campaigns');
      })
    );
  }

  /**
   * Gets the results of a campaign
   * @param campaignId
   * @returns
   */
  results(campaignId: string): unknown {
    return this.http.get('/campaigns/results/' + campaignId).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the statistics for all campaigns
   * @param begin
   * @param end
   * @returns
   */
  getStatistics(begin: Date | string, end: Date | string): Observable<ICampaignStatistics> {
    return this.http
      .get<ICampaignStatistics>(`/campaigns/data/statistics/?begin=${begin.toString()}&end=${end.toString()}`)
      .pipe(
        map(value => {
          if (!value) {
            throw new Error(this.alert.translateTechnicalError());
          }
          return value;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Gets the statistic for only one campaign
   * @param begin
   * @param end
   * @param campaignId
   * @returns
   */
  getStatisticsSingle(begin: Date | string, end: Date | string, campaignId: string): Observable<ICampaignStatistics> {
    return this.http
      .get<ICampaignStatistics>(
        `/campaigns/data/statistics/single/?begin=${begin.toString()}&end=${end.toString()}&campaignId=${campaignId}`
      )
      .pipe(
        map(value => {
          if (!value) {
            throw new Error(this.alert.translateTechnicalError());
          }
          return value;
        }),
        catchError(() => {
          throw new Error(this.alert.translateDataNotLoaded());
        })
      );
  }

  /**
   * Gets the statistics based off of the group
   * @param campaignId
   * @returns
   */
  getStatisticsByGroup(campaignId: string): unknown {
    return this.http
      .get('/campaigns/data/statisticsbygroup/' + campaignId)
      .pipe(this.operator.extractUnknownResponse());
  }
}
