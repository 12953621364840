import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OutputFormat } from 'ngx-color-picker/lib/helpers';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';

/**
 * This component is used within a form and integrates the SCSS class: 'form-control'.
 */
@Component({
  selector: 'mt-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  standalone: true,
  imports: [CommonModule, ColorPickerModule],
  providers: [ColorPickerService]
})
export class MtColorPickerComponent {
  /**
   * The format for the `color`.
   * @defaultValue 'hex'
   */
  @Input() format: OutputFormat = 'hex';

  /**
   * The color to use.
   * @defaultValue ''
   */
  @Input() color = '';
  @Output() colorChange = new EventEmitter<string>();

  /**
   * Checks if the input text is allowed under the current `format`
   * and iff allowed then emits the input.
   * @param ev change event
   */
  // eslint-disable-next-line
  checkFormat(ev: any): void {
    // eslint-disable-next-line
    let testString = ev.target.value as string;

    if (this.format === 'hex') {
      if (testString[0] !== '#') {
        testString = `#${testString}`;
      }

      const hex6 = /^#[0-9A-F]{6}$/i.test(testString);
      const hex3 = /^#([0-9A-F]{3}){1,2}$/i.test(testString);

      if (hex6 || hex3) {
        this.color = testString;
        this.onColorChange(testString);
      }
    }
  }

  /**
   * Emits the color change.
   * @param colorString
   */
  onColorChange(colorString: string): void {
    this.colorChange.emit(colorString);
  }
}
