import { Injectable } from '@angular/core';
import { EventStatus } from '@model/enums/event-status.enum';
import { format } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class EventDataService {
  /**
   * Gets the event display status
   * @param startDate - The start date of the event
   * @param endDate - The end date of the event
   * @param originalEventStatus - The event status which is stored in db ex. `draft` or `live`
   * @returns - The display status of event
   */
  getEventStatus(startDate?: Date, endDate?: Date, originalEventStatus?: EventStatus): EventStatus {
    if (originalEventStatus && originalEventStatus !== 'live') {
      return originalEventStatus;
    }

    // When event created from old angular app and enddate is not selected at that time endDate value will be `0000-00-00`
    if (endDate && endDate.toString() === '0000-00-00') {
      endDate = undefined;
    }

    if (startDate && startDate.toString() === '0000-00-00') {
      startDate = undefined;
    }

    const today = format(new Date(), 'yyyy-MM-dd');

    const sDate = startDate ? format(new Date(startDate), 'yyyy-MM-dd') : '0000-00-00';

    if (startDate && endDate) {
      const eDate = format(new Date(endDate), 'yyyy-MM-dd');
      if (today < sDate && today < eDate) {
        return 'upcoming';
      } else if (today >= sDate && today <= eDate) {
        return 'live';
      } else if (today > sDate && today > eDate) {
        return 'end';
      }
    } else if (!endDate && startDate) {
      if (today >= sDate) {
        return 'end';
      } else if (today < sDate) {
        return 'upcoming';
      }
    } else if (!endDate && !startDate) {
      return 'upcoming';
    }
    return 'end';
  }
}
