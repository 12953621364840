<ng-container *transloco="let translate">
  <mt-custom-modal headline="{{translate('upload_dictionary_data')}}">

    <ng-container class="baseonecolumn" modal-body>
      <mt-upload-csv (isFileSelected)="modal.close(true)" 
        upload_csv_help_line="import_dictionary_follow_the_requirement"
        upload_csv_template_line="no_proper_excel_template_dictionary"
        upload_csv_warning_line="dictionary_warning_template_line"
        german_help_url="https://help.mailtastic.com/de/articles/2090751-mitarbeiter-importieren-externe-quellen"
        english_help_url="https://help.mailtastic.com/de/articles/2090751-mitarbeiter-importieren-externe-quellen"
        german_download_url="https://www.mailtastic.com/downloads/Importvorlage%20f%C3%BCr%20Datenfelder-%C3%9Cbersetzungen.xlsx"
        english_download_url="https://www.mailtastic.com/hubfs/downloads/Translation%20Dictionary%20Template.xlsx"
        [isExtraPaddingNeeded]="false"
        [isShowAppendButton]="true"
        noInputDataMessage="html.EXCEL_DICTIONARY_MISSING"
        ></mt-upload-csv>
    </ng-container>

    <div modal-footer>
      <div class="flex-center-right">
        <div class="margin-left">
          <mt-button color="secondary" text="abort" class="leftbutton" (click)="modal.close(false)"></mt-button>
        </div>
      </div>
    </div>
  </mt-custom-modal>
</ng-container>