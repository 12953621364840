import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, tap } from 'rxjs';

// Services
import { AlertService } from 'src/app/services/alert/alert.service';
import { QueryHelperService } from 'src/app/services/query-helper/query-helper.service';
import { TargetGroupService } from 'src/app/services/target-group/target-group.service';

// Interface
import { noWhiteSpaceValidator } from 'src/app/shared/validators/no-whitespace-validator.directive';
import { TargetGroupGetTargetGroupEntries } from 'src/app/services/target-group/target-group-service.type';
import { urlValidator } from 'src/app/shared/validators/url-validator.directive';
import { EmptyStateComponent } from '@organisms/empty-states/empty-state.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-edit-account-data',
  templateUrl: './edit-account-data.component.html',
  styleUrls: ['./edit-account-data.component.scss'],
  providers: [QueryHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    EmptyStateComponent,
    FormsModule,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    TranslocoModule
  ]
})
export class EditAccountDataComponent {
  /**
   * Id for getting details
   */
  @Input() accountId!: string;

  /**
   * Form data
   */
  accountDataForm: FormGroup = this.fb.group({
    clicks: new FormControl<number>(0, { nonNullable: true }),
    company: new FormControl<string>('', { nonNullable: true }),
    content: new FormControl<string>('', [urlValidator, noWhiteSpaceValidator]),
    createdAt: new FormControl<string>('', { nonNullable: true }),
    custom1: new FormControl<string>('', { nonNullable: true }),
    custom2: new FormControl<string>('', { nonNullable: true }),
    custom3: new FormControl<string>('', { nonNullable: true }),
    email: new FormControl<string>(''),
    firstname: new FormControl<string>(''),
    id: new FormControl<string>(''),
    isOptedOut: new FormControl<boolean>(false, { nonNullable: true }),
    isProspect: new FormControl<boolean>(false, { nonNullable: true }),
    lastname: new FormControl<string>(''),
    location: new FormControl<string>(''),
    optedOutAt: new FormControl<string>(''),
    optedOutBy: new FormControl<string>(''),
    owner: new FormControl<string>(''),
    phone: new FormControl<string>(''),
    position: new FormControl<string>(''),
    source: new FormControl<string>(''),
    sourceAccountId: new FormControl<string>(''),
    sourceCSV: new FormControl<string>(''),
    sourceId: new FormControl<string>(''),
    sourceProfileUrl: new FormControl<string>(''),
    TargetGroupList: {},
    targetGroupListId: new FormControl<string>(''),
    termsAcceptedBy: new FormControl<string>(''),
    type: new FormControl<string>(''),
    updatedAt: new FormControl<string>(''),
    views: new FormControl<number>(0, { nonNullable: true })
  });

  // Holds the values of the input fields
  accountData = [
    {
      field: 'domain',
      translation: 'domain_url',
      dataField: null,
      required: true,
      editable: false,
      notEditableInfoMsg: 'domain_not_editable_info',
      inputPlaceholderKey: 'emptystate_domain',
      backendField: 'content'
    },
    {
      field: 'company',
      translation: 'manual_add_domains_titel_company',
      dataField: null,
      required: false,
      editable: true,
      inputPlaceholderKey: 'emptystate_company',
      backendField: 'company'
    },
    {
      field: 'location',
      translation: 'manual_add_domains_titel_location',
      dataField: null,
      required: false,
      editable: true,
      inputPlaceholderKey: 'emptystate_location',
      backendField: 'location'
    },
    {
      field: 'custom1',
      translation: 'manual_add_domains_titel_custom1',
      dataField: null,
      required: false,
      editable: true,
      inputPlaceholderKey: 'emptystate_custom1',
      backendField: 'custom1'
    },
    {
      field: 'custom2',
      translation: 'manual_add_domains_titel_custom2',
      dataField: null,
      required: false,
      editable: true,
      inputPlaceholderKey: 'emptystate_custom2',
      backendField: 'custom2'
    },
    {
      field: 'custom3',
      translation: 'manual_add_domains_titel_custom3',
      dataField: null,
      required: false,
      editable: true,
      inputPlaceholderKey: 'emptystate_custom3',
      backendField: 'custom3'
    }
  ];

  /**
   * Observable is loading data to edit
   */
  loadData$ = this.queryHelperService.queryObject$.pipe(
    switchMap(queryObj =>
      this.targetGroupService.getTargetGroupEntry(this.accountId, '', { doNotUseProspects: true }, queryObj)
    ),
    tap(response => {
      const accountDetails = response;
      this.accountDataForm.patchValue({
        ...accountDetails
      });
    })
  );

  constructor(
    private alert: AlertService,
    private fb: FormBuilder,
    private queryHelperService: QueryHelperService,
    private targetGroupService: TargetGroupService,
    public modal: NgbActiveModal
  ) {}

  /**
   * Update account data
   */
  editAccountData() {
    if (this.accountDataForm.dirty && this.accountDataForm.valid) {
      this.targetGroupService
        .updateTargetGroupListEntry(this.accountDataForm.value as TargetGroupGetTargetGroupEntries)
        .subscribe(() => {
          this.modal.close(true);
          void this.alert.defaultSuccessMessage(this.alert.translate('CHANGES_SAVED_SUCCESSFULLY'));
        });
    } else this.modal.close(false);
  }
}
