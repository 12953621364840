<input
  type="file"
  class="hide"
  accept=".csv"
  (change)="processFile($event)"
  #csvReader
/>

<div class="file-upload">
  <div class="ownbtn btnblau flex" (click)="csvReader.click()">
    <span class="glyphicon glyphicon-open" aria-hidden="true"></span>
    <span class="ms-2 text" transloco="{{ buttonTitle }}"></span>
  </div>
</div>
