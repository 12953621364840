import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert/alert.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'mt-reset-password',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss']
})
export class ResetPasswordModalComponent {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  emailAddress = new UntypedFormControl('', [Validators.required, Validators.email]);

  /**
   * Reset now btn disabled
   * @param val - Boolean
   */
  set resetNowBtnDisabled(val: boolean) {
    this._resetNowBtnDisabled$.next(val);
  }
  private readonly _resetNowBtnDisabled$ = new BehaviorSubject<boolean>(false);
  resetNowBtnDisabled$ = this._resetNowBtnDisabled$.asObservable().pipe(map(isDisabled => ({ isDisabled })));

  constructor(private alert: AlertService, private userService: UserService, public modal: NgbActiveModal) {}

  /**
   * Sends email to reset password to the provided email address
   * If the email is available in the system
   */
  resetPassword(): void {
    if (this.emailAddress.invalid) return;

    this.resetNowBtnDisabled = true;
    const email = this.emailAddress.value as string;

    this.userService
      .resetPassword(email)
      .pipe(
        map(result => {
          if (!result.success) {
            throw new Error(this.alert.translate('TECHNISCHER_FEHLER'));
          }
          return result.success;
        })
      )
      .subscribe(
        () => {
          this.resetNowBtnDisabled = false;
          this.modal.close();
          void this.alert.defaultSuccessMessage(this.alert.translate('PASS_MAIL_SENT'));
        },
        (err: HttpErrorResponse) => {
          this.resetNowBtnDisabled = false;
          this.modal.close();
          void this.alert.defaultErrorMessage(err.message);
        }
      );
  }
}
