<ng-container *transloco="let translate">
  <mt-custom-modal
    headline="{{ translate('importusers') }}"
    *ngIf="isAgreeWith$ | async as status"
  >
    <div modal-body>
      <div
        *ngIf="usersToBuyData.integrationType === 'o365'"
        [innerHTML]="
          translate('param.importXo365userstomailtastic', {
            amount: usersToBuyData.totalAmount
          })
        "
      ></div>
      <div
        *ngIf="usersToBuyData.integrationType === 'google'"
        [innerHTML]="
          translate('param.importXgsuiteuserstomailtastic', {
            amount: usersToBuyData.totalAmount
          })
        "
      ></div>
      <div class="integrationsentry flex-center-space-between part">
        <div class="integrationinfo">
          <div class="leftcol">
            <div class="innerheadline down" data-cy="users-to-buy-licenses">
              {{ translate('neededLicenses') }} {{ usersToBuyData.totalAmount }}
            </div>
          </div>
        </div>
      </div>
      <div class="integrationsentry">
        <span>{{ translate('freeLicencesLeft') }}</span>
        <span class="marleft5" data-cy="users-to-buy-free-amount"
          >{{ usersToBuyData.freeAmount }}</span
        >
      </div>
      <div class="integrationsentry">
        <span
          [innerHTML]="
            translate('param.doYouWantToImpAmountEmployeeLicencesNow', {
              amount: usersToBuyData.validAmount
            })
          "
        ></span
        >?
      </div>
      <div class="integrationsentry">
        (<span data-cy="users-to-buy-amount"
          >{{ usersToBuyData | usersToBuyAmount }} €</span
        >
        / <span>{{ translate('Month') }}</span>,
        <span>{{ translate('tillTheEndOfSubscription') }}</span>)
      </div>

      <div class="checkbox">
        <label
          ><input
            data-cy="users-to-buy-agree-checkbox"
            type="checkbox"
            required="true"
            (click)="updateIsAgreeWith(!status.isAgree)"
          />
          <span
            [innerHTML]="
              translate('param.yesIWantToImportAmountEmpLicencesWithCosts', {
                amount: usersToBuyData.validAmount
              })
            "
          ></span>
          <span
            [innerHTML]="translate('html.andHaveReadAndAcceptedTheConditions')"
          ></span
          >.</label
        >
      </div>
    </div>
    <div modal-footer>
      <div class="inline-flex-right footer">
        <div class="flex text-right">
          <mt-button
            data-cy="users-to-buy-close"
            text="abort"
            class="mt_left"
            color="secondary"
            (click)="modal.close(false)"
          >
          </mt-button>
          <mt-button
            data-cy="users-to-buy-submit"
            [disabled]="!status.isAgree"
            text="Add"
            color="primary"
            class="mt_left"
            (click)="modal.close(true)"
          ></mt-button>
        </div>
      </div>
    </div>
  </mt-custom-modal>
</ng-container>
