import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mt-alert-group-with-empty-employees',
  templateUrl: './alert-group-with-empty-employees.component.html',
  styleUrls: ['./alert-group-with-empty-employees.component.scss']
})
export class AlertGroupWithEmptyEmployeesComponent {
  // variable is used to display error first message - stores translation text
  @Input() errorMessageS1!: string;

  // variable is used to display error second message - stores translation text
  @Input() errorMessageS2!: string;

  /**
   * variable is defiend its rollout error or no employees error
   *  It will used to display button text - true for cancel text and false for proceed without group text
   */
  @Input() isRollOutError!: boolean;

  constructor(public modal: NgbActiveModal) {}
}
