<!-- Step1 check the group assignment with member and signature rollout status for colleagues -->
<div class="signature-setup-colleagues">
  <mt-wizard-tab
    [(parent)]="installation.setupCheckStep[0]"
    class="step2 detailpanel_blue signature-block"
  >
    <div
      class="font16"
      [innerHTML]="'html.signature_setup_check_description' | transloco"
    ></div>
    <div *ngIf="totalAmountOfMember === 0">
      <!-- Check if group is not assigned or assigned group with no member -->
      <mt-wizard-tab
        data-cy="assign-department-tab"
        [(parent)]="installation.setupCheckSubStepsForColleagues[0]"
        class="step2 setup-check sign-setup"
      >
        <p transloco="signature_setup_check_group_member_description_2"></p>
        <div class="d-flex justify-content-between">
          <b
            class="martop10"
            transloco="signature_setup_check_group_member_note_2"
          ></b>

          <mt-button
            data-cy="open-assign-department-settings-btn"
            text="open_signature_settings"
            color="primary"
            class="marleft30 signature-button flex-shrink-0 martop10"
            (click)="redirectToSignatureEditWizard('assignDepartment')"
          ></mt-button>
        </div>
      </mt-wizard-tab>
    </div>

    <!-- Check if signature is not rollout yet -->
    <div *ngIf="!isSignatureRollOut">
      <mt-wizard-tab
        data-cy="rollout-tab"
        [(parent)]="installation.setupCheckSubStepsForColleagues[1]"
        class="step2 setup-check"
      >
        <p transloco="signature_setup_check_rollout_status_description_2"></p>
        <div class="d-flex justify-content-between">
          <b
            class="martop10"
            transloco="signature_setup_check_rollout_status_note_2"
          ></b>
          <mt-button
            data-cy="open-rollout-settings-btn"
            text="open_signature_settings"
            color="primary"
            class="marleft30 martop10"
            (click)="redirectToSignatureEditWizard('rollout')"
          ></mt-button>
        </div>
      </mt-wizard-tab>
    </div>
  </mt-wizard-tab>
</div>
