import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UsersToBuyLicenses } from '@model/interfaces/plans.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { UsersToBuyAmountPipe } from '@shared/pipes/users-to-buy-amount/users-to-buy-amount.pipe';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'mt-users-to-buy',
  templateUrl: './users-to-buy.component.html',
  styleUrls: ['./users-to-buy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ButtonComponent, CustomModalComponent, TranslocoModule, UsersToBuyAmountPipe]
})
export class UsersToBuyComponent {
  constructor(public modal: NgbActiveModal) {}

  /**
   * Object with max user, import users count
   */
  usersToBuyData!: UsersToBuyLicenses;

  /**
   * Observable is used to handle user is agreed with terms & conditions to buy
   */
  private _isAgreeWith$ = new BehaviorSubject({ isAgree: false });
  isAgreeWith$ = this._isAgreeWith$.asObservable();

  /**
   * Update the value of agreed or not
   * @param isAgreeWith - Is agree with terms & conditions
   */
  updateIsAgreeWith(isAgreeWith: boolean): void {
    this._isAgreeWith$.next({ isAgree: isAgreeWith });
  }
}
