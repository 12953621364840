<div
  (click)="bookingContainer.openStep3 = !bookingContainer.openStep3"
  data-cy="feature-general"
>
  <mt-sticky-plan-feature-title>
    <div
      class="product-container-accordion"
      [ngClass]="{
        'accordion-title-two-coulmns':
          currentProduct && currentProduct !== 'Basic',
        'accordion-title-one-coulmn': currentProduct === 'Business'
      }"
    >
      <span
        class="glyphicon cursor-pointer chevron"
        [ngClass]="{
          'glyphicon-chevron-down': !bookingContainer.openStep3,
          'glyphicon-chevron-up': bookingContainer.openStep3
        }"
      ></span>
      <span
        class="title"
        transloco="{{ bookingContainer.general.title }}"
      ></span>
    </div>
  </mt-sticky-plan-feature-title>
  <div
    *ngIf="bookingContainer.openStep3"
    class="flex"
    [ngClass]="{
      justifycontentcenter: currentProduct
    }"
    data-cy="feature-general-items"
  >
    <div *ngIf="!hasSubscription && !currentProduct">
      <div class="product-container-info tint-turquoise">
        <mt-product-container-info
          [includesProduct]="bookingContainer.general.free.includes"
        ></mt-product-container-info>
      </div>
      <div class="product-container-info tint-turquoise padtop0">
        <mt-product-container-info
          [excludesProduct]="['fc4']"
        ></mt-product-container-info>
      </div>
      <div class="product-container-info tint-turquoise padtop0">
        <mt-product-container-info
          [excludesProduct]="['fc9']"
        ></mt-product-container-info>
      </div>
      <div
        class="product-container-info tint-turquoise pc-border-bottom-turquoise turquoise-f13"
      >
        <mt-product-container-info [includesProduct]="['fc13']">
        </mt-product-container-info>
      </div>
      <div
        class="product-container-info tint-turquoise pc-border-bottom-turquoise turquoise-f14"
      >
        <mt-product-container-info [includesProduct]="['fc14']">
        </mt-product-container-info>
      </div>
    </div>
    <div
      *ngIf="!currentProduct || currentProduct === 'Basic'"
      class="product-container-info tint-light pc-border-bottom"
    >
      <mt-product-container-info
        [includesProduct]="bookingContainer.general.basic.includes"
        [excludesProduct]="bookingContainer.general.basic.excludes"
      ></mt-product-container-info>
      <mt-product-container-info [includesProduct]="['fc14']">
      </mt-product-container-info>
    </div>
    <div
      *ngIf="currentProduct !== 'Business'"
      class="product-container-info tint-dark pc-border-bottom"
    >
      <mt-product-container-info
        [includesProduct]="bookingContainer.general.advanced.includes"
        [excludesProduct]="bookingContainer.general.advanced.excludes"
      ></mt-product-container-info>
      <mt-product-container-info [includesProduct]="['fc14']">
      </mt-product-container-info>
    </div>
    <div class="product-container-info tint-light pc-border-bottom">
      <mt-product-container-info
        [includesProduct]="bookingContainer.general.target.includes"
        [excludesProduct]="bookingContainer.general.target.excludes"
      ></mt-product-container-info>
      <mt-product-container-info [includesProduct]="['fc14']">
      </mt-product-container-info>
    </div>
  </div>
</div>
