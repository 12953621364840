<mt-custom-modal headline="{{ 'selecht_targetgroup' | transloco }}">
  <div *ngIf="loadTargetGroups$ | async; else loading" modal-body>
    <div class="content" style="min-height: 400px; height: auto !important">
      <mt-list-actions>
        <ng-container left-side>
          <!-- Select all button -->
          <mt-select-all-button
            *ngIf="list.all.length"
            [checked]="list.isSelectedAll()"
            (click)="list.updateSelectionAll()"
          ></mt-select-all-button>
        </ng-container>
        <ng-container right-side>
          <mt-search
            class="leftbutton"
            [disableEnter]="true"
            (search)="
              notificationsTargetGroupsModalService.setFilterText($event)
            "
          ></mt-search>
          <mt-sort-dropdown *ngIf="list.all.length">
            <li
              ngbDropdownItem
              (click)="list.sortList('title')"
              transloco="sort_titel"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('createdAt')"
              transloco="sort_creation"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('type')"
              transloco="sort_type"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('Groups')"
              transloco="sort_department"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('views')"
              transloco="sort_impressions"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('clicks')"
              transloco="sort_clicks"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('clickRate')"
              transloco="sort_rate"
            ></li>
          </mt-sort-dropdown>
        </ng-container>
      </mt-list-actions>

      <mt-list>
        <mt-row
          *ngFor="let targetGroup of list.all; odd as isOdd; even as isEven"
          [isEven]="isEven"
          [isOdd]="isOdd"
          [isSelected]="list.isSelected(targetGroup)"
          (click)="list.updateSelection(targetGroup)"
        >
          <div class="tcell first select">
            <selectcol [list]="list" [item]="targetGroup"></selectcol>
          </div>

          <div class="tcell w-25">
            <targetgroupscol
              [targetGroups]="[targetGroup]"
              iconColor="mt-blau"
              [isFirst]="true"
            ></targetgroupscol>
          </div>

          <div class="tcell w-25">
            <targetgroupentrynumbercol
              [item]="targetGroup"
            ></targetgroupentrynumbercol>
          </div>

          <div class="tcell w-25">
            <ng-container *ngIf="targetGroup.Event; else activeCampaign">
              <eventscampaignstatuscol
                [triggerEvent]="targetGroup.Event"
              ></eventscampaignstatuscol>
            </ng-container>

            <ng-template #activeCampaign>
              <activecampaigncol
                [activeCampaign]="targetGroup?.Campaign"
              ></activecampaigncol>
            </ng-template>
          </div>
        </mt-row>
      </mt-list>
      <!-- Empty state -->
      <mt-emptystate
        [showEmpty]="list.all.length === 0"
        hltitle="emptystate_targetgroup_3_hl"
        subtitle="html.emptystate_targetgroup_sl"
        svg="targetgroup"
      ></mt-emptystate>
    </div>
  </div>

  <!-- LOADING -->
  <ng-template #loading>
    <div class="card martop20">
      <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
  ></ng-template>
  <ng-container modal-footer>
    <div class="flex-center-right">
      <div class="margin-left">
        <mt-button
          (click)="modal.close()"
          class="mt_left"
          color="default"
          ngbAutoFocus
          text="abort"
        ></mt-button>
        <mt-button
          (click)="list.selected.length && assignTargetGroup(list.selected)"
          [disabled]="!list.selected.length"
          class="roboto_light"
          color="primary"
          icon="plus"
          text="add_selected_targetgroups"
        >
        </mt-button>
      </div>
    </div>
  </ng-container>
</mt-custom-modal>
