<mt-custom-modal
  *transloco="let translate"
  [headline]="translate('edit_image')"
>
  <div modal-body>
    @if (cropperSettings()) {
    <div class="flex">
      <div class="formarea" [formGroup]="imageForm">
        <div class="marbot15">{{ translate('image_section') }}</div>
        <div ngbDropdown class="btn-group">
          <mt-button
            class="mt_dropdown padleft0"
            [text]="
              cropperSettings().roundCropper
                ? 'circle_image_section'
                : 'square_image_section'
            "
            id="dropdownCrop"
            ngbDropdownToggle
            [dropdown]="true"
            data-cy="ImageCropTypeDropdown"
          ></mt-button>
          <ul ngbDropdownMenu aria-labelledby="dropdownCrop">
            <a ngbDropdownItem (click)="cropperShape('square')">
              {{ translate('square_image_section') }}
            </a>
            <a ngbDropdownItem (click)="cropperShape('circle')">
              {{ translate('circle_image_section') }}
            </a>
          </ul>
        </div>

        <div class="martop25">{{ translate('image_size') }}</div>

        <div class="mt_range martop25">
          <input
            type="range"
            formControlName="rangeValue"
            (change)="onChangeRange($event, cropperSettings().cropperRange)"
            data-cy="ImageCropSizeRange"
          />
        </div>

        <div class="martop25">{{ translate('image_dimension') }}</div>

        <div class="martop25">
          <input
            type="number"
            class="form-control input-sm"
            formControlName="cropManualSize"
            (input)="onChangeManualSize($event)"
            data-cy="ImageCropManualSize"
          />
        </div>
      </div>

      <div data-hj-suppress class="imgpreview">
        <div class="hint">{{ translate('Preview') }}</div>
        <image-cropper
          #cropper
          [imageURL]="cropperSettings().imageURL"
          [maintainAspectRatio]="true"
          [aspectRatio]="cropperSettings().aspectRatio"
          [roundCropper]="!!cropperSettings().roundCropper"
          [cropperMinWidth]="cropperSettings().cropperMinWidth"
          [cropperMinHeight]="cropperSettings().cropperMinHeight"
          [cropperMaxWidth]="cropperSettings().cropperMaxWidth"
          [cropperMaxHeight]="cropperSettings().cropperMaxHeight"
          [resizeToWidth]="cropperSettings().resizeToWidth"
          [resizeToHeight]="cropperSettings().resizeToHeight"
          output="base64"
          (imageLoaded)="imageLoaded()"
          (imageCropped)="imageCropped($event)"
        ></image-cropper>
      </div>
    </div>
    }
  </div>

  <ng-container modal-footer>
    <div class="flex-center-right">
      <mt-button
        text="abort"
        class="mt_left"
        color="default"
        (click)="modal.dismiss('cancelled')"
        data-cy="ImageAbort"
      ></mt-button>
      <mt-button
        text="continue_without_crop"
        class="mt_left"
        color="default"
        (click)="cropAndSave(false, cropperSettings())"
        data-cy="ImageContinueWithoutCrop"
      >
      </mt-button>
      <mt-button
        text="crop_and_save"
        color="primary"
        (click)="cropAndSave(true, cropperSettings())"
        data-cy="ImageCropAndSave"
      ></mt-button>
    </div>
  </ng-container>
</mt-custom-modal>
