<div class="baseonecolumn" *ngIf="!isCogsig; else cogsigContent">
  <mt-custom-modal headline="{{ 'installsignature' | transloco }}">
    <div modal-body>
      <div class="scroll-div" *ngIf="loadData$ | async as data; else loading">
        <div class="signature-installation-modal">
          <div *ngIf="data.checkSetup">
            <mt-signature-setup-check-colleagues
              [isSignatureRollOut]="!!data.signature.lastRollout"
              [signatureId]="data.signature.id"
              [totalAmountOfMember]="data.totalAmountOfMember"
              (RedirectToSignatureEditWizard)="
                redirectToSignatureEditWizard($event, data.signature.id)
              "
            ></mt-signature-setup-check-colleagues>
          </div>

          <mt-install-signature-for-colleagues
            (closeModal)="modal.close()"
            [activationCode]="data.activationCode"
            [checkSetup]="data.checkSetup"
            [employeeId]="data.employeeId"
            [isExternalAdmin]="data.isExternalAdmin"
          ></mt-install-signature-for-colleagues>
        </div>
      </div>
      <ng-template #loading>
        <div class="card martop20">
          <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
      ></ng-template>
    </div>
  </mt-custom-modal>
</div>

<ng-template #cogsigContent>
  <mt-custom-modal
    headline="{{ 'installsignature_colleagues_hl' | transloco }}"
  >
    <ng-container modal-body>
      <div class="col-lg-6 instruction-section">
        <span [innerHTML]="'html.installsignature_colleagues_sl' | transloco">
        </span>
      </div>
      <div class="col-lg-6">
        <div
          (click)="
            videoPlay(
              'installsignature_colleagues_video',
              'installsignature_colleagues_hl'
            )
          "
          class="cursor-pointer"
        >
          <img
            src="assets/images/signatures/install-signature-modal/thumbnails_installsignature_{{
              translate.getActiveLang()
            }}.png"
          />
        </div>
      </div>
    </ng-container>
    <div modal-footer-right>
      <mt-button
        text="gotoemployees_btn"
        color="primary"
        class="mt_left"
        [routerLink]="['/employees/list']"
        routerLinkActive="router-link-active"
        (click)="modal.dismiss()"
      ></mt-button>
    </div>
  </mt-custom-modal>
</ng-template>
