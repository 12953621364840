import { Component, Input } from '@angular/core';
import { ISignatureGetAll } from 'src/app/services/signature/signature-service.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgClass, NgIf, DatePipe } from '@angular/common';
import { TextTruncatePipe } from '@shared/pipes/text-truncate/text-truncate.pipe';

@Component({
  selector: 'signaturecol',
  templateUrl: './signature-col.component.html',
  styleUrls: ['./signature-col.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, RouterLinkActive, NgbTooltip, RouterLink, TranslocoModule, DatePipe, TextTruncatePipe]
})
export class SignatureColComponent {
  customStyle = {
    'font-size': '20px',
    'font-family': 'Roboto Condensed',
    'text-transform': 'uppercase'
  };
  displayName = '';
  displayText!: string;

  /**
   * Determines if specific styling is applied
   * @defaultValue false
   */
  @Input() isFirst = false;

  /**
   * Determines if link to details page should be disabled
   * @defaultValue false
   */
  @Input() disablelinks = false;

  /**
   * Item of signature
   */
  @Input() item!: ISignatureGetAll;
}
