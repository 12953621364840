import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, ReplaySubject, Subject, switchMap, take, zip } from 'rxjs';
import { UtilService } from 'src/app/services/util/util.service';
import { DatasetService } from 'src/app/services/dataset/dataset.service';
import { SearchService } from 'src/app/services/search/search.service';

import {
  CompanyDataImage,
  ICompanySignaturePlaceholders,
  IEmployeeSignaturePlaceholders,
  IntegrationsSignature,
  ISignature,
  ISignatureData,
  ISignatureDbField,
  ISignatureModel,
  ISignaturePlaceholderImg,
  SignatureEditWizardStepName
} from 'src/app/model/interfaces/signature.interface';
import {
  DummyData,
  SignatureCompanyData,
  SignatureDataToEdit,
  SignatureDesignData,
  SignatureEmployeeData,
  SignatureGraphicPlaceholder
} from 'src/app/model/interfaces/signature-data.interface';
import { IDataset } from 'src/app/model/interfaces/dataset.interface';
import { IResponseMessage } from 'src/app/model/interfaces/response.interface';
import { ISignatureGetOne } from '@services/signature/signature-service.interface';
import { EmployeeFullDetails } from '@services/employee/employee-service.interface';
import { ICampaign, CampaignBanner } from '@model/interfaces/campaign.interface';
import { IGroupObject, IMailGroup } from '@model/interfaces/group.interface';

@Injectable({
  providedIn: 'root'
})
export class SignatureDataService {
  /**
   * Notifies of changes and triggers new data loaded to search employee component
   */
  searchEmployeeNotifier = new Subject<void>();

  /**
   * Observable is used to show the signature preview of selected user and company dataset
   */
  private _selectedDatasetId$ = new ReplaySubject<{ empDatasetId: string; companyDatasetId: string }>(1);
  selectedDatasetId$ = this._selectedDatasetId$.asObservable();

  /**
   * Observable is used to open particular step of edit signature
   */
  private _signatureStepEdit$ = new ReplaySubject<SignatureEditWizardStepName>(1);
  signatureStepEdit$ = this._signatureStepEdit$.asObservable();

  // Used for setting up design tab
  public selectDesign: Subject<ISignature> = new Subject<ISignature>();
  // Used for setting up details tab
  public selectDetails: Subject<ISignature> = new Subject<ISignature>();
  // Used for setting up template
  public selectTemplateForSignature: Subject<string> = new Subject<string>();

  // Holds the currently select toolbar tab and element id of input in detail tab to scroll down
  private _showSpecifiedToolbar$ = new BehaviorSubject<{ tabName: string; scrollTo?: string }>({
    tabName: 'design',
    scrollTo: undefined
  });
  showSpecifiedToolbar$ = this._showSpecifiedToolbar$.asObservable();

  private _currentEmployeeForm$: ReplaySubject<ISignatureDbField> = new ReplaySubject<ISignatureDbField>(1);
  currentEmployeeForm$ = this._currentEmployeeForm$.asObservable();

  constructor(
    private datasetService: DatasetService,
    private searchService: SearchService,
    private utilService: UtilService
  ) {}

  /**
   * Loads company data from a selected dataset or the default company dataset and parses the data
   * @param companyDatasetId - Company dataset id to parse data from
   * @returns Observable of company data
   */
  loadCompanyData(companyDatasetId?: string): Observable<SignatureCompanyData> {
    const companyDataset$ = companyDatasetId
      ? this.datasetService.getOne(companyDatasetId)
      : this.datasetService.getDefaultDataSets().pipe(
          map(datasets => datasets.companyDatasetId),
          switchMap(companyDatasetId => this.datasetService.getOne(companyDatasetId))
        );

    return companyDataset$.pipe(map(companyDataset => this.getCompanySignatureData(companyDataset)));
  }

  /**
   * Parses the signature graphic object to display in preview
   * @param signatureData - Signature object
   * @returns - Signature graphic data
   */
  getSignatureGraphicData(signatureData?: ISignatureGetOne): SignatureGraphicPlaceholder | undefined {
    if (signatureData && signatureData.sigGraphicProperties) {
      const signatureGraphic = JSON.parse(signatureData.sigGraphicProperties) as SignatureGraphicPlaceholder;
      signatureGraphic.url = this.utilService.removeHttpHttpsWww(signatureGraphic.url);
      return signatureGraphic;
    } else {
      return undefined;
    }
  }

  /**
   * Parses the employee data from a selected dataset or the default one to display in the preview
   * @param employee - Employee to parse data from
   * @param userDefinitionDatasetId - User definition datasetId
   * @returns Employee dataset data to display in the preview
   */
  getEmployeeSignatureData(employee: EmployeeFullDetails, userDefinitionDatasetId?: string): SignatureEmployeeData {
    const index = employee.Datasets.findIndex(item => item.definition === userDefinitionDatasetId);

    const employeeDatasetData = JSON.parse(
      `${employee.Datasets[index >= 0 ? index : 0]?.data || '{}'}`
    ) as IEmployeeSignaturePlaceholders;

    const employeeData: SignatureEmployeeData = {
      emp_acad_degree: employeeDatasetData.ma_akad_grad,
      emp_add_info_1: employeeDatasetData.ma_add_info_1,
      emp_add_info_2: employeeDatasetData.ma_add_info_2,
      emp_add_info_3: employeeDatasetData.ma_add_info_3,
      emp_add_info_4: employeeDatasetData.ma_add_info_4,
      emp_city: employeeDatasetData.ma_ort,
      emp_company: employeeDatasetData.ma_firma,
      emp_department: employeeDatasetData.ma_abteilung,
      emp_disclaimer: employeeDatasetData.ma_disclaimer,
      emp_email: employee.email,
      emp_fax: employeeDatasetData.ma_fax,
      emp_fb: this.utilService.removeHttpHttpsWww(employeeDatasetData.ma_fb?.url),
      emp_firstname: employee.firstname,
      emp_googlep: this.utilService.removeHttpHttpsWww(employeeDatasetData.ma_googlep?.url),
      emp_instagram: this.utilService.removeHttpHttpsWww(employeeDatasetData.ma_instagram?.url),
      emp_land: employeeDatasetData.ma_land,
      emp_lastname: employee.lastname,
      emp_linkedin: this.utilService.removeHttpHttpsWww(employeeDatasetData.ma_linkedin?.url),
      emp_mobil: employeeDatasetData.ma_mobil,
      emp_ms_teams: this.utilService.removeHttpHttpsWww(employeeDatasetData.ma_ms_teams?.url),
      emp_office: employeeDatasetData.ma_office,
      emp_out_of_office: employeeDatasetData.ma_out_of_office,
      emp_phone: employeeDatasetData.ma_tel,
      emp_phone2: employeeDatasetData.ma_tel2,
      emp_picture: typeof employeeDatasetData?.ma_foto?.image === 'string' ? employeeDatasetData.ma_foto.image : '',
      emp_pictureCircle:
        typeof employeeDatasetData?.ma_foto?.imageCircle === 'string' ? employeeDatasetData.ma_foto.imageCircle : '',
      emp_pictureRoundedSquare:
        typeof employeeDatasetData?.ma_foto?.imageRadiusSquare === 'string'
          ? employeeDatasetData.ma_foto.imageRadiusSquare
          : '',
      emp_pictureSquare:
        typeof employeeDatasetData?.ma_foto?.imageSquare === 'string' ? employeeDatasetData.ma_foto.imageSquare : '',
      emp_pos_extern: employeeDatasetData.ma_pos_extern,
      emp_pos_intern: employeeDatasetData.ma_pos_intern,
      emp_position: employeeDatasetData.ma_position,
      emp_postal_code: employeeDatasetData.ma_postal_code,
      emp_pronouns: employeeDatasetData.ma_pronomen,
      emp_salutation: employeeDatasetData.ma_grussformel,
      emp_skype: this.utilService.removeHttpHttpsWww(employeeDatasetData.ma_skype?.url),
      emp_state: employeeDatasetData.ma_bundesland,
      emp_street: employeeDatasetData.ma_strasse,
      emp_team: employeeDatasetData.ma_team,
      emp_tiktok: this.utilService.removeHttpHttpsWww(employeeDatasetData.emp_tiktok?.url),
      emp_titel: employeeDatasetData.ma_titel,
      emp_twitter: this.utilService.removeHttpHttpsWww(employeeDatasetData.ma_twitter?.url),
      emp_voip: employeeDatasetData.ma_voip,
      emp_website_2: this.utilService.removeHttpsPrefix(employeeDatasetData.ma_website_2?.url),
      emp_website: this.utilService.removeHttpsPrefix(employeeDatasetData.ma_website?.url),
      emp_xing: this.utilService.removeHttpHttpsWww(employeeDatasetData.ma_xing?.url),
      placeholder_branche1_1: employeeDatasetData.placeholder_branche1_1,
      placeholder_branche1_2: employeeDatasetData.placeholder_branche1_2,
      placeholder_branche1_3: employeeDatasetData.placeholder_branche1_3,
      placeholder_branche2_1: employeeDatasetData.placeholder_branche2_1,
      placeholder_branche2_2: employeeDatasetData.placeholder_branche2_2,
      placeholder_branche2_3: employeeDatasetData.placeholder_branche2_3
    };

    return employeeData;
  }

  /**
   * Parses a company data and returns the data to display in the preview
   * @param companyDataset - Company dataset to parse data from
   * @returns Company dataset data to display in the preview
   */
  getCompanySignatureData(companyDataset: IDataset): SignatureCompanyData {
    const companyDatasetData = JSON.parse(`${companyDataset.data || '{}'}`) as ICompanySignaturePlaceholders;

    const signatureCompanyData: SignatureCompanyData = {
      ...companyDatasetData,
      c_blog: this.utilService.removeHttpHttpsWww(companyDatasetData.u_blog?.url),
      c_city: companyDatasetData?.u_ort,
      c_country: companyDatasetData?.u_country,
      c_disclaimer: companyDatasetData.u_disclaimer,
      c_fax: companyDatasetData?.u_fax,
      c_fb: this.utilService.removeHttpHttpsWww(companyDatasetData.u_fb?.url),
      c_googlep: this.utilService.removeHttpHttpsWww(companyDatasetData.u_googlep?.url),
      c_instagram: this.utilService.removeHttpHttpsWww(companyDatasetData.u_instagram?.url),
      c_linkedin: this.utilService.removeHttpHttpsWww(companyDatasetData.u_linkedin?.url),
      c_logo:
        this.utilService.removeHttpHttpsWww(companyDatasetData.u_logo?.image) === 'dummy_cl'
          ? ''
          : companyDatasetData.u_logo?.image,
      c_name: companyDatasetData?.u_name,
      c_phone: companyDatasetData?.u_tel,
      c_pinterest: this.utilService.removeHttpHttpsWww(companyDatasetData.u_pinterest?.url),
      c_postal_code: companyDatasetData?.c_zip_code,
      c_snapc: this.utilService.removeHttpHttpsWww(companyDatasetData.u_snapc?.url),
      c_street: companyDatasetData?.u_strasse,
      c_tiktok: this.utilService.removeHttpHttpsWww(companyDatasetData.c_tiktok?.url),
      c_twitter: this.utilService.removeHttpHttpsWww(companyDatasetData.u_twitter?.url),
      c_website_2: this.utilService.removeHttpsPrefix(companyDatasetData.c_website2?.url),
      c_website: this.utilService.removeHttpsPrefix(companyDatasetData.u_website?.url),
      c_whatsapp: this.utilService.removeHttpHttpsWww(companyDatasetData.u_whatsapp?.url),
      c_xing: this.utilService.removeHttpHttpsWww(companyDatasetData.u_xing?.url),
      c_youtube: this.utilService.removeHttpHttpsWww(companyDatasetData.u_youtube?.url)
    };

    return signatureCompanyData;
  }

  /**
   * Parses and returns the signature design data
   * @param signature - Signature to parse data from
   * @returns Signature design data
   */
  getSignatureDesignData(signature: ISignatureModel | IntegrationsSignature): SignatureDesignData {
    const signatureData = JSON.parse(`${signature?.signatureDataToEdit || '{}'}`) as SignatureDataToEdit;

    const signatureDesign: SignatureDesignData = {
      design_disclaimer_font_color: signatureData.design.design_disclaimer_font_color,
      design_font_color: signatureData.design.design_font_color,
      design_font_family: signatureData.design.design_font_family,
      design_font_size: signatureData.design.design_font_size,
      design_logo_size: signatureData.design.design_logo_size,
      design_primary_color: signatureData.design.design_primary_color,
      design_profile_picture_shape: signatureData.design.design_profile_picture_shape,
      design_profile_picture_size: signatureData.design.design_profile_picture_size,
      design_social_media_icons_color: signatureData.design.design_social_media_icons_color,
      design_social_media_icons_shape: signatureData.design.design_social_media_icons_shape,
      design_social_media_icons_size: signatureData.design.design_social_media_icons_size,
      design_use_mailtastic: signatureData.design.design_use_mailtastic
    };

    return signatureDesign;
  }

  /**
   * Parses the employee dummy data (John Doe) to display in the preview
   * @param dummyData - John Doe data
   * @returns Employee dummy data to display in the preview
   */
  getEmployeeDummyData(dummyData: DummyData): SignatureEmployeeData {
    const employeeDummyData: SignatureEmployeeData = {
      ...dummyData
    };

    return employeeDummyData;
  }

  /**
   * Extracts signature data - data for employee, company and design
   * @param signature - Signature to get data from
   * @returns Signature data (employee, company and design data)
   */
  getSignatureData(signature: ISignature | IntegrationsSignature): ISignatureData {
    const signatureData = JSON.parse(signature.signatureDataToEdit + '');
    return signatureData;
  }

  /**
   * Getting signature template based on signature template
   * @param signature - Signature to get template from
   * @returns Return signature template number in string format
   */
  getSignatureTemplateNumber(signature: ISignature | IntegrationsSignature): string {
    let templateNumber = '01';

    const templateNumberRegex = /app-template([0-9]{2})/g;

    const result = templateNumberRegex.exec(signature.signatureTplToEdit ? signature.signatureTplToEdit : '');

    if (result) {
      templateNumber = result[1];
    }

    return templateNumber;
  }

  /**
   * Replaces src attribute of image tag which is not set correctly because browser is trying to GET
   * image with url \{\{u_logo\}\} or \{\{ma_foto\}\}.
   * That returns 404 and src attribute has value similar to \{\{ma_S_100 which is not recognized later
   * @param signatureTemplate - Current signature template
   * @param selectedTemplate - Signature template number
   * @returns Signature template html
   */
  setPlaceholderForImages(signatureTemplate: string, selectedTemplate: number): string {
    const empRegex = /src=\"\{\{ma_.*?\"/;
    const compRegex = /src=\"\{\{u_.*?\"/;
    let result;
    switch (selectedTemplate) {
      case 3:
      case 4:
      case 5:
      case 6:
        result = compRegex.exec(signatureTemplate);

        if (result) {
          signatureTemplate = signatureTemplate.replace(result[0], 'src={{u_logo}}');
        }
        break;
      case 7:
      case 8:
      case 9:
        result = empRegex.exec(signatureTemplate);
        if (result) {
          signatureTemplate = signatureTemplate.replace(result[0], 'src={{ma_foto}}');
        }
        result = compRegex.exec(signatureTemplate);
        if (result) {
          signatureTemplate = signatureTemplate.replace(result[0], 'src={{u_logo}}');
        }
    }
    return signatureTemplate;
  }

  /**
   * Updates the currently selected employee dataset data with the current form values for employee
   * @param formValues - Form holding employee and company data
   * @param selectedEmpDatasetId - Selected employee dataset id
   * @returns Observable with success message
   */
  saveEmployeeData(formValues: ISignatureDbField, selectedEmpDatasetId: string): Observable<IResponseMessage> {
    // Selected employee's id
    const selectedEmployeeId$ = this.searchService.selectedEmployeeWithDataset$.pipe(
      take(1),
      map(employee => employee.id)
    );

    return selectedEmployeeId$.pipe(
      switchMap(employeeId => this.datasetService.getOneSpecificForUser(employeeId, selectedEmpDatasetId)),
      map(dataset => {
        let employeeDatasetData = JSON.parse(dataset?.data || '{}') as ISignatureDbField;

        employeeDatasetData = {
          ...employeeDatasetData,
          ma_abteilung: formValues.emp_department,
          ma_add_info_1: formValues.emp_add_info_1,
          ma_add_info_2: formValues.emp_add_info_2,
          ma_add_info_3: formValues.emp_add_info_3,
          ma_add_info_4: formValues.emp_add_info_4,
          ma_akad_grad: formValues.emp_acad_degree,
          ma_bundesland: formValues.emp_state,
          ma_disclaimer: formValues.emp_disclaimer,
          ma_email: formValues.emp_email,
          ma_fax: formValues.emp_fax,
          ma_fb: {
            ...(employeeDatasetData.ma_fb as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_fb as string)
          },
          ma_firma: formValues.emp_company,
          ma_foto: {
            ...(employeeDatasetData.ma_foto as any), // TODO: Fix type
            image: formValues.emp_picture,
            imageCircle: formValues.emp_pictureCircle,
            imageRadiusSquare: formValues.emp_pictureRoundedSquare,
            imageSquare: formValues.emp_pictureSquare,
            whichImage: formValues.emp_picture
              ? 'own'
              : (employeeDatasetData.ma_foto as ISignaturePlaceholderImg).value?.whichImage,
            showAs: formValues.emp_picture
              ? 'image'
              : (employeeDatasetData.ma_foto as ISignaturePlaceholderImg).value?.showAs
          },
          ma_googlep: {
            ...(employeeDatasetData.ma_googlep as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_googlep as string)
          },
          ma_grussformel: formValues.emp_salutation,
          ma_instagram: {
            ...(employeeDatasetData.ma_instagram as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_instagram as string)
          },
          ma_land: formValues.emp_land,
          ma_linkedin: {
            ...(employeeDatasetData.ma_linkedin as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_linkedin as string)
          },
          ma_mobil: formValues.emp_mobil,
          ma_ms_teams: {
            ...(employeeDatasetData.ma_ms_teams as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_ms_teams as string)
          },
          ma_nachname: formValues.emp_lastname,
          ma_office: formValues.emp_office,
          ma_ort: formValues.emp_city,
          ma_out_of_office: formValues.emp_out_of_office,
          ma_pos_extern: formValues.emp_pos_extern,
          ma_pos_intern: formValues.emp_pos_intern,
          ma_position: formValues.emp_position,
          ma_postal_code: formValues.emp_postal_code,
          ma_pronomen: formValues.emp_pronouns,
          ma_skype: {
            ...(employeeDatasetData.ma_skype as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_skype as string)
          },
          ma_strasse: formValues.emp_street,
          ma_team: formValues.emp_team,
          ma_tel: formValues.emp_phone,
          ma_tel2: formValues.emp_phone2,
          emp_tiktok: {
            ...(employeeDatasetData.emp_tiktok as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_tiktok as string)
          },
          ma_titel: formValues.emp_titel,
          ma_twitter: {
            ...(employeeDatasetData.ma_twitter as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_twitter as string)
          },
          ma_voip: formValues.emp_voip,
          ma_vorname: formValues.emp_firstname,
          ma_website_2: {
            ...(employeeDatasetData.ma_website_2 as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_website_2 as string)
          },
          ma_website: {
            ...(employeeDatasetData.ma_website as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_website as string)
          },
          ma_xing: {
            ...(employeeDatasetData.ma_xing as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.emp_xing as string)
          },
          placeholder_branche1_1: formValues.placeholder_branche1_1,
          placeholder_branche1_2: formValues.placeholder_branche1_2,
          placeholder_branche1_3: formValues.placeholder_branche1_3,
          placeholder_branche2_1: formValues.placeholder_branche2_1,
          placeholder_branche2_2: formValues.placeholder_branche2_2,
          placeholder_branche2_3: formValues.placeholder_branche2_3
        };

        return { datasetId: dataset.id, data: employeeDatasetData, title: dataset.title || '' };
      }),
      switchMap(datasetData => this.datasetService.update(datasetData.datasetId, datasetData.data, datasetData.title))
    );
  }

  /**
   * Updates the company dataset data with the current form values for company
   * @param formValues - Form holding employee and company data
   * @param selectedCompanyDatasetId - Selected company dataset id
   * @returns Observable with success message
   */
  saveCompanyData(formValues: ISignatureDbField, selectedCompanyDatasetId: string): Observable<IResponseMessage> {
    return this.datasetService.getOne(selectedCompanyDatasetId).pipe(
      map(dataset => {
        let companyDatasetData = JSON.parse(dataset.data || '{}') as ISignatureDbField;

        companyDatasetData = {
          ...companyDatasetData,
          c_add_info_1: formValues.c_add_info_1,
          c_add_info_2: formValues.c_add_info_2,
          c_add_info_3: formValues.c_add_info_3,
          c_add_info_4: formValues.c_add_info_4,
          c_add_info_5: formValues.c_add_info_5,
          c_add_info_6: formValues.c_add_info_6,
          c_office: formValues.c_office,
          c_state: formValues.c_state,
          c_website2: {
            ...(companyDatasetData.c_website2 as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_website_2 as string)
          },
          c_zip_code: formValues.c_postal_code,
          u_country: formValues.c_country,
          u_disclaimer: formValues.c_disclaimer,
          u_fax: formValues.c_fax,
          u_fb: {
            ...(companyDatasetData.u_fb as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_fb as string)
          },
          u_instagram: {
            ...(companyDatasetData.u_instagram as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_instagram as string)
          },
          u_linkedin: {
            ...(companyDatasetData.u_linkedin as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_linkedin as string)
          },
          u_logo: {
            ...(companyDatasetData.u_logo as any), // TODO: Fix type
            image: this.utilService.checkAndAddHttps(formValues.c_logo as string),
            url: formValues.c_logo_link_url,
            whichImage: formValues.c_logo ? 'own' : (companyDatasetData.u_logo as CompanyDataImage)?.whichImage,
            showAs: formValues.c_logo ? 'image' : (companyDatasetData.u_logo as CompanyDataImage)?.showAs
          },
          u_name: formValues.c_name,
          u_ort: formValues.c_city,
          u_strasse: formValues.c_street,
          u_tel: formValues.c_phone,
          u_twitter: {
            ...(companyDatasetData.u_twitter as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_twitter as string)
          },
          u_website: {
            ...(companyDatasetData.u_website as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_website as string)
          },
          u_xing: {
            ...(companyDatasetData.u_xing as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_xing as string)
          },
          u_youtube: {
            ...(companyDatasetData.u_youtube as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_youtube as string)
          },
          u_pinterest: {
            ...(companyDatasetData.u_pinterest as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_pinterest as string)
          },
          u_whatsapp: {
            ...(companyDatasetData.u_whatsapp as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_whatsapp as string)
          },
          u_snapc: {
            ...(companyDatasetData.u_snapc as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_snapc as string)
          },
          u_googlep: {
            ...(companyDatasetData.u_googlep as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_googlep as string)
          },
          u_blog: {
            ...(companyDatasetData.u_blog as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_blog as string)
          },
          c_tiktok: {
            ...(companyDatasetData.c_tiktok as any), // TODO: Fix type
            url: this.utilService.checkAndAddHttps(formValues.c_tiktok as string)
          }
        };

        return { datasetId: dataset.id, data: companyDatasetData, title: dataset.title || '' };
      }),
      switchMap(datasetData => this.datasetService.update(datasetData.datasetId, datasetData.data, datasetData.title))
    );
  }

  /**
   * Gets the campaign banner
   * @param eventCampaign - The campaign of live event for group
   * @param groupsCampaignImage - The group default campaign banner image
   * @returns - The campaign banner data
   */
  getCampaignBanner(eventCampaign: ICampaign, groupsCampaignImage?: string): CampaignBanner {
    return {
      url: (eventCampaign.image || groupsCampaignImage || '') as string,
      width:
        Number(eventCampaign.width) === Number(eventCampaign.resizedWidth)
          ? Number(eventCampaign.width)
          : Number(eventCampaign.resizedWidth) || 450,
      height:
        Number(eventCampaign.height) === Number(eventCampaign.resizedHeight)
          ? Number(eventCampaign.height)
          : Number(eventCampaign.resizedHeight) || 112
    };
  }

  /**
   * Updates the selected tab
   * @param tab - New tab to be selected
   * @param scrollTo - The element reference to scroll at view
   */
  updateSelectedTab(tab: string, scrollTo?: string): void {
    this._showSpecifiedToolbar$.next({ tabName: tab, scrollTo });
  }

  /**
   * Updates current employee form
   * @param newValues - The updated value
   */
  updateCurrentEmployeeForm(newValues: ISignatureDbField): void {
    this._currentEmployeeForm$.next(newValues);
  }

  /**
   * Triggers step number which should be open by default
   * @param step - Step name of edit signature wizard
   */
  setOpenSignatureEditStep(step: SignatureEditWizardStepName): void {
    this._signatureStepEdit$.next(step);
  }

  /**
   * Sets the new value for selected data profile of employee and company
   * @param value - The Object holding the `empDatasetId` and  `companyDatasetId`
   */
  setSelectedDatasetId(value: { empDatasetId: string; companyDatasetId: string }): void {
    this._selectedDatasetId$.next(value);
  }

  /**
   * Gets default group based on priority set up
   * @param groupList - The employee group list
   * @param property - The property to compare for priority (e.g., 'defaultSigNewMailPriority' or 'defaultSigResponsePriority')
   * @returns The default group with minimum priority
   */
  getDefaultGroupByPriority(
    groupList: IGroupObject[],
    property: 'defaultSigNewMailPriority' | 'defaultSigResponsePriority'
  ): IMailGroup {
    return groupList.reduce((min, obj) => ((obj[property] || 0) < (min[property] || 0) ? obj : min)) as IMailGroup;
  }
}
