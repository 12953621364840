<div class="groupmodal assistant">
  <div class="modal-header group-modal-header">
    <h3 class="modal-title" transloco="Duplicate_Department">
      <!-- {{ modaloptions.heading }} -->
    </h3>
  </div>
  <div class="modal-body">
    <div class="flex">
      <div class="leftform">
        <div class="format2" transloco="Title_of_new_Department"></div>

        <form
          [formGroup]="duplicateForm"
          (ngSubmit)="onSubmit()"
          class="form-horizontal"
        >
          <input
            formControlName="title"
            type="text"
            class="form-control input-sm marbot15"
            placeholder="{{ 'title' | transloco }}"
          />

          <div
            *ngIf="
              duplicateForm.controls['title'].touched ||
              duplicateForm.controls['title'].dirty
            "
          >
            <!-- Validate required department title -->
            <!-- TODO: update error message text -->
            <div
              *ngIf="duplicateForm.controls['title'].errors?.required"
              class="error-text"
            >
              <strong
                >{{ 'workingtitel' | transloco }} {{ 'required_field' |
                transloco }}</strong
              >
            </div>

            <!-- Validate no special chars used -->
            <!-- TODO: update error message text -->
            <div
              *ngIf="duplicateForm.controls['title'].errors?.specialChar"
              class="error-text"
            >
              <strong transloco="Pleasevalidemailaddress"></strong>
            </div>
          </div>

          <div ng-show="modalOptions.showMemberCopyOption" class="checkbox">
            <label
              ><input type="checkbox" formControlName="copyMembers" />{{
              'Take_over_employees' | transloco }}</label
            >
          </div>

          <div class="checkbox">
            <label
              ><input type="checkbox" formControlName="copySignature" />{{
              'Take_over_signature' | transloco }}</label
            >
          </div>

          <div class="checkbox" style="margin-bottom: 50px">
            <label
              ><input type="checkbox" formControlName="copyCampaign" />{{
              'Take_over_campaign' | transloco }}</label
            >
          </div>

          <div role="separator" class="line"></div>

          <div class="flex-center-right">
            <mt-button
              (click)="cancel()"
              class="leftbutton"
              text="abort"
              color="default"
            ></mt-button>
            <mt-button
              type="submit"
              color="primary"
              text="Duplicate_Department"
            ></mt-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
