import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TargetGroupGetTargetGroupEntries } from 'src/app/services/target-group/target-group-service.type';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'mt-domain-opt-out-modal',
  templateUrl: './domain-opt-out-modal.component.html',
  styleUrls: ['./domain-opt-out-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DomainOptOutModalComponent {
  /**
   * Input of target group list entry object to change isOptOut
   */
  @Input() targetGroupListEntry!: TargetGroupGetTargetGroupEntries;

  /**
   * Accept tracking terms checkbox value for Opt In
   * @defaultValue false
   */
  acceptedTrackingTerms = false;

  constructor(public modal: NgbActiveModal, public translate: TranslocoService) {}
}
