// Module
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';

// Component
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { CsvUploadComponent } from '@modules/target-groups/components/csv-import/csv-upload/csv-upload.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { UploadCsvComponent } from '@shared/components/organisms/data-mapping/upload-csv/upload-csv.component';

@Component({
  selector: 'mt-upload-dictionary',
  templateUrl: './upload-dictionary.component.html',
  styleUrl: './upload-dictionary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CsvUploadComponent,
    CustomModalComponent,
    TranslocoModule,
    UploadCsvComponent
  ]
})
export class UploadDictionaryComponent {
  /**
   * Bootstrap modal
   */
  modal = inject(NgbActiveModal);
}
