import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, AsyncPipe } from '@angular/common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';

// Interfaces
import { SignatureInstallSelectionType } from '@model/interfaces/signature.interface';

// Services
import { AccountService } from 'src/app/services/account/account.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ModalService } from 'src/app/services/modal/modal.service';

// Components
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';

@Component({
  selector: 'mt-install-signature-modal-to-choose',
  templateUrl: './install-signature-modal-to-choose.component.html',
  styleUrls: ['./install-signature-modal-to-choose.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, ButtonComponent, CustomModalComponent, MtSvgComponent, NgIf, TranslocoModule]
})
export class InstallSignatureModalToChooseComponent {
  /**
   * Determines the signatureId for installation
   * @defaultValue ''
   */
  @Input() signatureId = '';

  /**
   * Determines admin is external admin or not
   * @defaultValue false
   */
  @Input() isExternalAdmin = false;

  constructor(
    private accountService: AccountService,
    private alert: AlertService,
    private modalService: ModalService,
    public authService: AuthService,
    public modal: NgbActiveModal,
    public translate: TranslocoService
  ) {}

  /**
   * To display the signature install guide for selected option you or college
   * @param step - Selected for which signature to install you or college
   * @param userId - Loggedin userId if exist
   */
  stepTwoForSignatureInstallGuide(step: SignatureInstallSelectionType, userId: string): void {
    if (step === 'forMe' && this.isExternalAdmin) {
      this.alert
        .defaultInfoMessageV2(this.translate.translate('alert_installsig_notaccess_to_external_admin'))
        .subscribe();
    } else if (!userId && step === 'forMe') {
      this.alert
        .defaultInfoMessageV2(
          this.translate.translate('html.param.no_user_available_to_install_sig', {
            value: this.accountService.getUserAccountData().email
          }),
          this.translate.translate('ups')
        )
        .subscribe();
    } else {
      if (step === 'forMe') {
        this.modalService.openInstallSignatureModal(this.signatureId, userId);
      } else {
        this.modalService.openInstallSignatureModalForColleagues(this.signatureId, userId, this.isExternalAdmin);
      }
      this.modal.close();
    }
  }
}
