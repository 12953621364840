export enum EDataType {
  USER = 'User',
  CAMPAIGN = 'Campaign',
  DATASET = 'Dataset',
  CONTACT = 'Contact',
  DEPARTMENT = 'Department',
  DOMAIN = 'Domain',
  DURATION = 'Duration',
  EVENT = 'Event',
  NOTIFICATION = 'Notification',
  SIGNATURE = 'Signature',
  TARGET_GROUP = 'TargetGroup',
  WEBSESSION = 'Websession'
}
