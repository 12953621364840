import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs';

import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { NotificationsTargetGroupsModalService } from '../../../services/notifications-target-groups.service';
import { TargetGroupsGetTargetGroups } from 'src/app/services/target-group/target-group-service.type';

@Component({
  selector: 'mt-select-notifications-target-groups',
  templateUrl: './select-notifications-target-groups.component.html',
  styleUrls: ['./select-notifications-target-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListHelperService]
})
export class SelectNotificationsTargetGroupsComponent {
  /**
   * Already selected target groups list
   * May be empty for the first time
   */
  selectedTargetGroup?: TargetGroupsGetTargetGroups[];

  /**
   * Loads the list of target groups
   */
  loadTargetGroups$ = this.notificationsTargetGroupsModalService.targetGroupsList$.pipe(
    tap(targetGroups => {
      this.list.all = targetGroups;
      const targetGroupsIds = this.selectedTargetGroup?.map(targetGroup => targetGroup.id);
      this.list.all = this.list.all.filter(targetGroup => !targetGroupsIds?.includes(targetGroup.id));
      this.list.setFiltered();
    })
  );

  constructor(
    public list: ListHelperService<TargetGroupsGetTargetGroups>,
    public modal: NgbActiveModal,
    public notificationsTargetGroupsModalService: NotificationsTargetGroupsModalService
  ) {}

  /**
   * Closes the modal and assigns the selected targetGroups
   * @param selectedList - Selected targetGroups
   */
  assignTargetGroup(selectedList: TargetGroupsGetTargetGroups[]): void {
    this.modal.close(selectedList);
  }
}
