import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ICampaign } from 'src/app/model/interfaces/campaign.interface';
import { ICampaignGet } from 'src/app/services/campaign/campaign-services.interface';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgClass, DatePipe } from '@angular/common';
import { TextTruncatePipe } from '@shared/pipes/text-truncate/text-truncate.pipe';

@Component({
  selector: 'campaigncol',
  templateUrl: './campaign-col.component.html',
  styleUrls: ['./campaign-col.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, MtSvgComponent, RouterLink, NgbTooltip, TranslocoModule, DatePipe, TextTruncatePipe]
})
export class CampaignColComponent {
  /**
   * Determines if specific styling is applied
   * @defaultValue false
   */
  @Input() isFirst = false;

  /**
   * Campaign to display information about
   */
  @Input() campaign?:
    | ICampaignGet
    | (ICampaign & {
        clicks: number;
        views: number;
      });

  /**
   * Determines if link to details page should be disabled
   * @defaultValue false
   */
  @Input() disableLinks = false;

  /**
   * Hide date
   * @defaultValue false
   */
  @Input() hideDate = false;

  /**
   * Show campaign label
   * @defaultValue false
   */
  @Input() showCampaign = false;

  /**
   * SVG icon color class
   * @defaultValue ''
   */
  @Input() iconColor = '';

  /**
   * Emit events to redirect to campaign details page
   */
  @Output() redirectToCampaignDetail: EventEmitter<void> = new EventEmitter<void>();
}
