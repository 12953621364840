import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';

import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { UploadImageButtonComponent } from '@shared/components/molecules/buttons/upload-image-button/upload-image-button.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';

@Component({
  selector: 'mt-trial-version-expired',
  templateUrl: './trial-version-expired.component.html',
  styleUrls: ['./trial-version-expired.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    FormsModule,
    MtSvgComponent,
    NgIf,
    ReactiveFormsModule,
    TranslocoModule,
    UploadImageButtonComponent
  ]
})
export class TrialVersionExpiredComponent {
  constructor(public modal: NgbActiveModal, private router: Router) {}

  /**
   * Navigate user to booking page and close the modal
   */
  openBooking(): void {
    void this.router.navigate(['/booking']);
    this.modal.close();
  }
}
