<div class="martop30">
  <div class="bigprice flex">
    <div class="number">{{ costPerUser }}</div>
    <div class="unit">€</div>
    <div class="fontsize20">*</div>
  </div>
  <div class="flex-center-all priceinfotext includedusers martop25">
    <span class="begin" transloco="per_user"></span>
    <span>/</span>
    <span class="end" transloco="month"></span>
  </div>
  <div class="hint"></div>
  <div class="hint height70imp">
    <p class="marbot0">
      <span>
        {{ tariff | transloco }}: {{ currentAmountOfSystemUsers }} x {{
        costPerUser }}€ = {{ currentAmountOfSystemUsers * costPerUser }}€ / {{
        'Month' | transloco }}
      </span>
    </p>
    <p>
      <span
        [innerHtml]="
          'param.AdjustBusinessFlexible'
            | transloco
              : { amount: (costPerUser | currency: 'EUR':'':'1.2-2':'de-DE') }
        "
      ></span>
      <span>, {{ 'accounting' | transloco }} {{ 'yearly' | transloco }}</span>
    </p>
  </div>
</div>
