import { Component, Input, OnInit } from '@angular/core';
import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownItem
} from '@ng-bootstrap/ng-bootstrap';
import { SearchService } from '@services/search/search.service';
import { map, skip, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { TargetGroupsGetTargetGroups } from 'src/app/services/target-group/target-group-service.type';
import { TargetGroupService } from 'src/app/services/target-group/target-group.service';

import { EmptyStateComponent } from '@organisms/empty-states/empty-state.component';
import { EventsCampaignStatusColComponent } from '@organisms/cols/events-campaign-status-col/events-campaign-status-col.component';
import { ActiveCampaignColComponent } from '@organisms/cols/active-campaign-col/active-campaign-col.component';
import { TargetGroupEntryNumberColComponent } from '@organisms/cols/target-group-entry-number-col/target-group-entry-number-col.component';
import { TargetGroupEntryTitleColComponent } from '@organisms/cols/target-group-entry-title-col/target-group-entry-title-col.component';
import { SelectColComponent } from '@organisms/cols/select-col/select-col.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSearchComponent } from '@atoms/inputs/search/search.component';
import { SelectAllButtonComponent } from '@molecules/buttons/select-all-button/select-all-button.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { FilterPipe } from '@shared/pipes/filter/filter.pipe';

@Component({
  selector: 'mt-add-target-to-campaign',
  templateUrl: './add-target-to-campaign.component.html',
  styleUrls: ['./add-target-to-campaign.component.scss'],
  standalone: true,
  imports: [
    ActiveCampaignColComponent,
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    EmptyStateComponent,
    EventsCampaignStatusColComponent,
    FilterPipe,
    MtSearchComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgClass,
    NgFor,
    NgIf,
    SelectAllButtonComponent,
    SelectColComponent,
    TargetGroupEntryNumberColComponent,
    TargetGroupEntryTitleColComponent,
    TranslocoModule
  ]
})
export class AddTargetToCampaignModalComponent implements OnInit {
  /**
   * Text for searching list
   */
  filterText = '';

  /**
   * Determines if data is being loaded
   * @defaultValue false
   */
  isLoading = true;

  /**
   * Object with Target Groups
   */
  @Input() dataObject!: TargetGroupsGetTargetGroups[];

  // Filters list by setting the search term on the query object
  filterList$ = this.searchService.filterText$.pipe(
    skip(1), // Skip the first emition, otherwise same data is loaded twice
    tap(text => {
      this.list.filterText = text; // prevents long hold Enter key pressed
      this.list.all = this.list.filtered.filter(data => data.title.toLowerCase().includes(text.toLowerCase()));
      this.list.createFraction(this.list.all.length);
    })
  );

  constructor(
    private alert: AlertService,
    private targetGroupsService: TargetGroupService,
    public list: ListHelperService<TargetGroupsGetTargetGroups>,
    public modal: NgbActiveModal,
    public searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.targetGroupsService
      .getTargetGroups()
      .pipe(
        map(targetGroup => {
          return targetGroup.filter(entry => {
            return !this.dataObject.find(tg => tg.id === entry.id);
          });
        })
      )
      .subscribe({
        next: res => {
          this.list.all = res;
          this.list.selected = [];
          this.list.createFraction(this.list.all.length);
          this.list.setFiltered();
          this.isLoading = false;
        },
        error: err => void this.alert.defaultErrorMessage(this.alert.translateTechnicalError(err))
      });
  }

  /**
   * Action when target groups have been selected
   */
  onSelectTargetGroups(): void {
    this.modal.close(this.list.selected);
  }

  /**
   * Sort list based on clicked dropdown
   * @param sort - Title of column
   */
  sortList(sort: string): void {
    switch (sort) {
      case 'title':
        this.list.all.sort((a, b) => {
          return this.list.sortDir.title ? b.title.localeCompare(a.title) : a.title.localeCompare(b.title);
        });
        this.list.sortDir.title = !this.list.sortDir.title;
        break;
      case 'Entries':
        // TODO: implement
        break;
      case 'Campaign.title':
        // TODO: implement
        break;
    }
  }
}
