<div
  class="assistant employeemodal"
  *ngIf="!showOnlyDataset; else onlyDatasetForm"
>
  <div class="modal-header" *transloco="let translate">
    <div class="title" data-cy="edit-employee-modal">
      {{ translate('edit_employee_data') }}
      <div
        class="cursor-pointer close"
        *ngIf="showCancelButton"
        (click)="modalClose()"
      >
        <mt-svg
          class="x-button"
          src="assets/images/modals/close_other_svg.svg"
        ></mt-svg>
      </div>
    </div>
  </div>

  <div class="modal-body clearfix">
    <div class="srcoll-div">
      <div class="srcoll-area">
        <form
          *ngIf="userDataForm"
          [formGroup]="userDataForm"
          class="form-horizontal"
        >
          <!-- Master Data Fields -->
          <div formGroupName="master" data-cy="master-fields">
            <div class="format2 make-header" transloco="generaldata"></div>
            <div class="form-group" *ngFor="let item of employeeMasterData">
              <!-- First or Last Name Slot -->
              <ng-container *ngIf="item.tag !== 'ma_email'">
                <div class="col-sm-3 control-label-account format3">
                  <label><span [transloco]="item.label"></span> *</label>
                </div>
                <div class="col-sm-10 col-lg-8 formcontrolright">
                  <input
                    [attr.data-cy]="'master-' + item.tag"
                    type="text"
                    [formControlName]="item.tag"
                    class="form-control input-sm"
                  />
                  <span
                    *ngIf="isMasterInvalid(item.tag)"
                    class="required"
                    transloco="required_field"
                  ></span>
                </div>
              </ng-container>
              <!-- Email Slot -->
              <ng-container *ngIf="item.tag === 'ma_email'">
                <div class="col-sm-3 control-label-account format3">
                  <label><span transloco="Emailaddress"></span>*</label>
                </div>
                <div class="col-sm-10 col-lg-8 formcontrolright">
                  <input
                    data-cy="master-ma_email"
                    type="text"
                    formControlName="ma_email"
                    class="form-control input-sm"
                  />
                  <span
                    *ngIf="isMasterInvalid('ma_email')"
                    class="required"
                    transloco="required_field"
                  ></span>
                </div>
              </ng-container>
            </div>
          </div>

          <div class="line width95"></div>

          <!-- Profile Data Fields -->
          <ng-container
            [ngTemplateOutlet]="profileDataFields"
            [ngTemplateOutletContext]="{ form: userDataForm }"
          ></ng-container>
        </form>
        <mt-emptystate [loadingPromise]="isLoading"></mt-emptystate>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="flex-center-left">
      <mt-button
        *ngIf="showCancelButton"
        text="abort"
        color="default"
        class="mt_left"
        (click)="modalClose()"
      ></mt-button>
      <mt-button
        data-cy="save-employee-info-btn"
        *ngIf="userDataForm"
        [text]="btnText"
        class="roboto_light"
        color="primary"
        [disabled]="userDataForm.get('master')?.invalid"
        type="submit"
        (click)="saveEmployeeInfoData('save_on_submit_btn')"
      ></mt-button>
    </div>
  </div>
</div>

<ng-template #onlyDatasetForm>
  <ng-container *ngIf="userDataFormValueChange$ | async"></ng-container>
  <form *ngIf="userDataForm" [formGroup]="userDataForm" class="form-horizontal">
    <ng-container
      [ngTemplateOutlet]="profileDataFields"
      [ngTemplateOutletContext]="{ form: userDataForm }"
    ></ng-container>
  </form>
</ng-template>

<ng-template let-allDatasetValues="allDatasetValues" #profilePhoto>
  <mt-button
    data-cy="ma_foto"
    color="primary"
    class="roboto_light"
    (click)="
      openUploadProfilePhotoModal(datasets.selected, user.id, allDatasetValues)
    "
    text="uploade_photo_btn_3"
  ></mt-button
></ng-template>

<ng-template let-form="form" #profileDataFields>
  <ng-container *ngIf="allDatasetValues$ | async as allDatasetValues">
    <div [formGroup]="form">
      <!-- Profile Selection Dropdown -->
      <div class="format2" transloco="employeeDataset2"></div>
      <div class="datasetselectionarea flex-center-left width95 marbot15">
        <span transloco="UserDataProfile"></span>
        <select
          data-cy="dataset-dropdown"
          (ngModelChange)="onDatasetProfileChange($event, allDatasetValues)"
          class="form-control input-sm"
          [(ngModel)]="datasets.selected"
          [ngModelOptions]="{ standalone: true }"
        >
          <option *ngFor="let dataset of datasets.all" [ngValue]="dataset.id">
            {{ dataset.title }}
            <ng-container *ngIf="dataset.isDefault"
              >({{ 'Standardprofil' | transloco }})
            </ng-container>
          </option>
        </select>
      </div>

      <!-- Input Fields -->
      <div formGroupName="texts">
        <ng-container *ngFor="let item of employeeTextData">
          <div
            class="form-group"
            *ngIf="item.tag | shouldDisplayField : isCognismSignatures"
          >
            <ng-container
              *ngIf="
                !showOnlyDataset || (showOnlyDataset && item.tag !== 'ma_email')
              "
            >
              <div class="col-sm-3 control-label-account format3">
                <label
                  *ngIf="
                    item.tag !== 'ma_email' ||
                    !isDefaultDataset(datasets.selected)
                  "
                  transloco="{{ item.label }}"
                ></label>
                <label
                  *ngIf="
                    item.tag === 'ma_email' &&
                    isDefaultDataset(datasets.selected)
                  "
                >
                  E-Mail (<span transloco="generaldata"></span>)
                </label>
              </div>
              <div class="col-sm-10 col-lg-8 formcontrolright">
                <input
                  [disabled]="
                    item.tag === 'ma_email' &&
                    isDefaultDataset(datasets.selected)
                  "
                  [attr.data-cy]="item.tag"
                  type="text"
                  [formControlName]="item.tag"
                  class="form-control input-sm"
                />
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <!-- Social Media Fields -->
      <div formGroupName="images">
        <ng-container *ngFor="let item of employeeImageData; let ii = index">
          <div
            class="form-group"
            *ngIf="item.tag | shouldDisplayField : isCognismSignatures"
          >
            <div class="col-sm-3 control-label-account format3">
              <label transloco="{{ item.label }}"></label>
            </div>
            <div class="col-sm-10 col-lg-8 formcontrolright flex">
              <ng-container *ngIf="item.tag !== 'ma_foto'">
                <input
                  type="text"
                  [attr.data-cy]="item.tag"
                  [formControlName]="item.tag"
                  class="form-control input-sm leftbutton"
                  placeholder="(URL)" />

                <mt-button
                  *ngIf="!isCognismSignatures && !showOnlyDataset"
                  (click)="editUserSignatureData(item, ii, 'Mitarbeiter')"
                  class="roboto_light"
                  text="Details"
                  color="primary"
                  [attr.data-cy]="item.tag + '-btn'"
                >
                </mt-button
              ></ng-container>
              <ng-container *ngIf="item.tag === 'ma_foto'">
                <ng-container
                  [ngTemplateOutlet]="profilePhoto"
                  [ngTemplateOutletContext]="{ allDatasetValues }"
                ></ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <!-- Link Data Fields -->
      <div formGroupName="links">
        <ng-container *ngFor="let item of employeeLinkData; let ii = index">
          <div
            class="form-group"
            *ngIf="item.tag | shouldDisplayField : isCognismSignatures"
          >
            <div class="col-sm-3 control-label-account format3">
              <label transloco="{{ item.label }}"></label>
            </div>
            <div class="col-sm-10 col-lg-8 formcontrolright flex">
              <input
                type="text"
                [attr.data-cy]="item.tag"
                [formControlName]="item.tag"
                class="form-control input-sm leftbutton"
                placeholder="(URL)"
              />
              <mt-button
                [attr.data-cy]="item.tag + '-btn'"
                (click)="editUserSignatureData(item, ii, 'Mitarbeiter')"
                class="roboto_light"
                color="primary"
                text="Details"
                *ngIf="!showOnlyDataset"
              >
              </mt-button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>
