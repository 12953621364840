import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { TargetGroupService } from 'src/app/services/target-group/target-group.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsTargetGroupsModalService {
  /**
   * Observable is used to load target groups list
   */
  private _loadTargetGroups$ = new BehaviorSubject(0);
  loadTargetGroups$ = this._loadTargetGroups$.asObservable();

  /**
   * Returns the current search value use to filter lists
   * Waits 400ms and emits only distinct values
   */
  private _filterText$ = new BehaviorSubject('');
  filterText$ = this._filterText$.asObservable().pipe(debounceTime(400), distinctUntilChanged());

  /**
   * Loads all target groups
   * Filters the list by target group title
   */
  targetGroupsList$ = this.loadTargetGroups$.pipe(
    switchMap(() => combineLatest([this.filterText$, this.targetGroupService.getTargetGroups()])),
    map(([filterText, targetGroups]) =>
      targetGroups.filter(targetGroup => targetGroup.title.toLowerCase().includes(filterText.toLowerCase()))
    )
  );

  constructor(private targetGroupService: TargetGroupService) {}

  /**
   * Sets the new value for text filtered
   * @param text - String to set as text filtered
   */
  setFilterText(text: string): void {
    this._filterText$.next(text);
  }
}
