import { Directive } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors, Validator, NG_VALIDATORS } from '@angular/forms';

export const passwordMatcherValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const repeatPassword = control.get('repeatPassword');

  return password && repeatPassword && password.value !== repeatPassword.value ? { noPasswordMatch: true } : null;
};

@Directive({
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordMatcherValidatorDirective, multi: true }],
  selector: '[mtPasswordMatcherValidator]'
})
export class PasswordMatcherValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return passwordMatcherValidator(control);
  }
}
