import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { EBoxType } from 'src/app/model/enums/box-type.enum';
import { BookingConfig, BookingContainer, PriceData, Product } from 'src/app/model/interfaces/payment.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { GetOverallStatsResponse } from 'src/app/services/user/user-service.interface';

@Component({
  selector: 'mt-plan-upgrade',
  templateUrl: './plan-upgrade.component.html',
  styleUrls: ['./plan-upgrade.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanUpgradeComponent implements OnInit, OnDestroy {
  /**
   * Fixed values for setup
   */
  @Input() config!: BookingConfig;

  /**
   * Input of feature list object
   */
  @Input() bookingContainer!: BookingContainer;

  /**
   * Input of user stat data
   */
  @Input() statData!: GetOverallStatsResponse;

  /**
   * Hold calculate price data
   */
  @Input() priceData!: PriceData;

  /**
   * Hold product name for subscribed user
   */
  @Input() currentProduct!: Product;

  /**
   * Sticky of product name
   */
  private _stickyHeader$ = new BehaviorSubject<boolean>(false);
  stickyHeader$ = this._stickyHeader$.asObservable();

  /**
   * Scroll event subscription to enable and disable sticky
   */
  scrollModal$!: Subscription;

  constructor(private alert: AlertService, public modal: NgbActiveModal) {}

  ngOnInit(): void {
    const content = document.querySelector('.mt_changeproduct_modal');
    if (content) {
      // Fixed value for offset to sticky section enabled
      const stickyOffset = 350;
      this.scrollModal$ = fromEvent(content, 'scroll').subscribe(() => {
        this._stickyHeader$.next(content.scrollTop >= stickyOffset);
      });
    }
  }

  ngOnDestroy(): void {
    this.scrollModal$?.unsubscribe();
  }

  /**
   * Change plan confirmation and update accordingly
   * @param selectedPlan - Selected plan
   */
  async upgradeToPlan(selectedPlan: Product): Promise<void> {
    const success = await this.alert.defaultConfirmPrompt(
      'sure_want_to_buy',
      EBoxType.INFO,
      'almostdone_headline',
      'Bookwithcosts'
    );
    if (success) {
      this.modal.close(selectedPlan);
    }
  }
}
