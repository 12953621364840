import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PriceData, Product } from 'src/app/model/interfaces/payment.interface';

@Component({
  selector: 'mt-plan-basic-detail',
  templateUrl: './plan-basic-detail.component.html',
  styleUrls: ['./plan-basic-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanBasicDetailComponent {
  /**
   * Input of calculate price data
   */
  @Input() priceData!: PriceData;

  /**
   * Input of max number of employees
   */
  @Input() maxUsers!: number;

  /**
   * Input of billing interval
   */
  @Input() billingInterval!: string;

  /**
   * Hold product name for subscribed user
   */
  @Input() currentProduct!: Product;

  /**
   * Triggers selected plan
   */
  @Output() setProduct = new EventEmitter<Product>();
}
