import { Pipe, PipeTransform } from '@angular/core';

/**
 * Get the download link for cogsig or mailtastic
 * @param tag - The translation tag for download a link
 * @param isCogsig - The trigger of current environment
 * @returns String of download link
 */
@Pipe({
  name: 'getDownloadLink',
  standalone: true
})
export class GetDownloadLinkPipe implements PipeTransform {
  transform(tag: string, isCogsig: boolean): string {
    if (isCogsig) {
      if (tag === 'html.outlookmac_easysync_direction_1_0') {
        return 'https://cognismsignatures.blob.core.windows.net/img/downloads/easysyncmacdownload/EasySync.app.zip';
      } else {
        return 'https://cognismsignatures.blob.core.windows.net/img/downloads/EasySync.msi';
      }
    } else {
      if (tag === 'html.outlookmac_easysync_direction_1_0') {
        return 'https://mailtastic.de/downloads/easysyncmacdownload/Mailtastic.app.zip';
      } else {
        return 'https://www.mailtastic.com/downloads/Mailtastic_EasySync.msi';
      }
    }
  }
}
