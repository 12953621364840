<mt-custom-modal
  headline="{{ 'select_campaign_banner_Btn' | transloco }}"
  data-cy="assign-campaign-modal"
>
  <div *ngIf="loadCampaigns$ | async; else loading" modal-body>
    <div class="content">
      <mt-list-actions>
        <ng-container right-side>
          <ng-container *ngIf="filterList$ | async"></ng-container>
          <!-- Search from list -->
          <mt-search
            *ngIf="list.all.length > 0 || list.filterText"
            class="leftbutton"
            [disableEnter]="true"
            (search)="searchService.setFilterText($event)"
          ></mt-search>
          <!-- Sorting list -->
          <mt-sort-dropdown *ngIf="list.all.length > 0">
            <li
              ngbDropdownItem
              (click)="list.sortList('title')"
              transloco="sort_titel"
            ></li>
          </mt-sort-dropdown>
        </ng-container>
      </mt-list-actions>
      <!-- List -->
      <div class="campaigns-list">
        <mt-list>
          <mt-row
            *ngFor="let item of list.all; odd as isOdd; even as isEven"
            [isEven]="isEven"
            [isOdd]="isOdd"
            [isSelected]="list.isSelected(item)"
            (click)="list.updateSelection(item)"
          >
            <div class="tcell first select w-5">
              <selectcol [list]="list" [item]="item"></selectcol>
            </div>
            <div class="tcell w-60">
              <campaigncol
                [disableLinks]="true"
                [campaign]="item"
                [isFirst]="true"
                iconColor="mt-blau"
              ></campaigncol>
            </div>
            <div class="tcell w-20">
              <tagcol [type]="item.type"></tagcol>
            </div>
          </mt-row>
        </mt-list>
        <mt-emptystate
          [showEmpty]="list.all.length === 0"
          class="nodataarea"
          hltitle="Thereisnocampaigntoassign"
          subtitle="html.Createyourfirstcampaigntogetanoverviewhere"
          svg="campaign"
        ></mt-emptystate>
      </div>
    </div>
  </div>

  <ng-template #loading>
    <div class="card">
      <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
  ></ng-template>
  <ng-container modal-footer>
    <div class="flex">
      <span class="margin-right" *ngIf="list.selected.length">
        {{ list.selected.length }} {{ 'select_campaigns' | transloco }}
      </span>
      <div class="margin-left">
        <mt-button
          (click)="modal.close()"
          class="mt_left"
          color="default"
          ngbAutoFocus
          text="abort"
        ></mt-button>
        <mt-button
          data-cy="assign-campaign-button"
          (click)="modal.close(list.selected[0])"
          [disabled]="!list.selected.length"
          class="roboto_light"
          color="primary"
          icon="plus"
          text="Selectcampaign"
        >
        </mt-button>
      </div>
    </div>
  </ng-container>
</mt-custom-modal>
