import { DomainInputFieldObject } from 'src/app/model/interfaces/recipient.interface';

/**
 * Domain add/edit fields for recipients
 */
export const DOMAIN_INPUT_FIELDS: DomainInputFieldObject[] = [
  {
    backendField: 'content',
    editable: false,
    field: 'domain',
    inputPlaceholderKey: 'emptystate_domain',
    notEditableInfoMsg: 'domain_not_editable_info',
    required: true,
    translation: 'domain_url'
  },
  {
    backendField: 'company',
    editable: true,
    field: 'company',
    inputPlaceholderKey: 'emptystate_company',
    required: false,
    translation: 'manual_add_domains_titel_company'
  },
  {
    backendField: 'location',
    editable: true,
    field: 'location',
    inputPlaceholderKey: 'emptystate_location',
    required: false,
    translation: 'manual_add_domains_titel_location'
  },
  {
    backendField: 'custom1',
    editable: true,
    field: 'custom1',
    inputPlaceholderKey: 'emptystate_custom1',
    required: false,
    translation: 'manual_add_domains_titel_custom1'
  },
  {
    backendField: 'custom2',
    editable: true,
    field: 'custom2',
    inputPlaceholderKey: 'emptystate_custom2',
    required: false,
    translation: 'manual_add_domains_titel_custom2'
  },
  {
    backendField: 'custom3',
    editable: true,
    field: 'custom3',
    inputPlaceholderKey: 'emptystate_custom3',
    required: false,
    translation: 'manual_add_domains_titel_custom3'
  }
];
