import { Component, ElementRef, Input } from '@angular/core';
import { mtIcons } from 'projects/mailtastic-icons-library/src/assets/mailtastic-icons';
import { MtIconsRegistry } from 'projects/mailtastic-icons-library/src/lib/mt-icons/mt-icons-registry.service';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgStyle } from '@angular/common';
import { MtIconsModule } from '@projects/mailtastic-icons-library/src/lib/mt-icons/mt-icons.module';

// TODO MT-2386: update to reflect the mt-button setup

@Component({
  selector: 'mt-icon-button',
  templateUrl: 'icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  standalone: true,
  imports: [NgIf, MtIconsModule, NgStyle, TranslocoModule]
})
export class IconButtonComponent {
  _disabled = false;
  _color!: string;

  /**
   * Icon name of `mtIcons`
   */
  @Input() icon?: mtIcons;

  /**
   * Background color of button
   */
  @Input()
  get color() {
    return this._color;
  }
  set color(val: string) {
    if (val !== '') {
      this._color = 'mt_' + val;
    }
  }

  /**
   * Button text
   * @remarks as translateTag
   * @deprecated use `text` instead
   */
  @Input() hltitle!: string;

  /**
   * Button text
   * @remarks as translateTag
   */
  @Input() text!: string;

  /**
   * Class applied on button.
   */
  @Input() klass?: string;

  /**
   * Disables button.
   * @defaultValue false
   */
  @Input()
  set disabled(value: unknown) {
    this._disabled = Boolean(value);
  }
  get disabled() {
    return this._disabled;
  }

  /**
   * Add the dropdown caret symbol to the button.
   * @defaultValue false
   */
  @Input() dropdown = false;

  constructor(private host: ElementRef, private mtIconsRegistry: MtIconsRegistry) {
    this.mtIconsRegistry.registerAllIcons();
  }

  get element(): HTMLElement {
    return this.host.nativeElement as HTMLElement;
  }
}
