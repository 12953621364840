import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ITargetGroupListEntryCampaign } from 'src/app/model/interfaces/target-group-list.interface';
import { EventTargetGroup } from 'src/app/modules/events/model/event-target-groups';
import { TargetGroupsGetTargetGroups } from 'src/app/services/target-group/target-group-service.type';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgClass, NgFor } from '@angular/common';

@Component({
  selector: 'targetgroupentrynumbercol',
  templateUrl: './target-group-entry-number-col.component.html',
  styleUrls: ['./target-group-entry-number-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgbTooltip, MtSvgComponent, NgClass, TranslocoModule, RouterLink, NgFor]
})
export class TargetGroupEntryNumberColComponent {
  /**
   * Object where is stored Target Group
   */
  @Input() item!: ITargetGroupListEntryCampaign | TargetGroupsGetTargetGroups | EventTargetGroup;

  /**
   * Determines if link to details page should be disabled
   * @defaultValue false
   */
  @Input() disableLinks = false;

  /**
   * Color used for the icon
   * @defaultValue 'mt-darkgrey'
   */
  @Input() iconColor = 'mt-darkgrey';

  /**
   * Apply style changes when used in timing error row
   * @defaultValue false
   */
  @Input() timingError = false;
}
