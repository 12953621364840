import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'mt-upload-csv-button',
  templateUrl: './upload-csv-button.component.html',
  styleUrls: ['./upload-csv-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FileReader],
  standalone: true,
  imports: [TranslocoModule]
})
export class UploadCsvButtonComponent {
  constructor(private fileReader: FileReader) {}

  @ViewChild('csvReader') csvReader!: ElementRef;

  /**
   * Allows setting a custom button title
   *
   * @defaultValue 'upload_csv_file'
   */
  @Input() buttonTitle = 'upload_csv_file';

  /**
   * Emits csv file name and data
   *
   * @remarks Data structured as follows:
   *
   * First row contains header(s)
   *
   * Following rows contain data
   */
  @Output() csvData = new EventEmitter<{ fileName: string; data: string }>();

  /**
   * Emits an error message - file or data format error
   */
  @Output() errorMsg = new EventEmitter<string>();

  /**
   * Processes the csv file and emits filename and contents
   * @param event - CSV file
   */
  processFile(event: Event): void {
    const csvFile = ((event.target as HTMLInputElement).files as FileList)[0];

    // Invalid format/size
    if (csvFile.type !== 'text/csv' || csvFile.size === 0) {
      this.fileReset();
      this.errorMsg.emit('html.csv_domains_WRONG_FORMAT');
      return;
    }

    this.fileReader.readAsText(csvFile);

    this.fileReader.onload = e => {
      if (typeof e.target?.result === 'string') {
        this.csvData.emit({ fileName: csvFile.name, data: e.target.result });
      } else {
        this.fileReset();
        this.errorMsg.emit('html.csv_domains_WRONG_FORMAT');
        return;
      }
    };

    this.fileReader.onerror = () => {
      this.errorMsg.emit('html.csv_domains_WRONG_FORMAT');
    };
  }

  /**
   * Resets the file value for `csvReader`
   */
  private fileReset(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this.csvReader.nativeElement.value = null;
  }
}
