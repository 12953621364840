import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@services/alert/alert.service';
import { PatternService } from '@shared/services/pattern.service';
import { of, filter, map } from 'rxjs';

@Component({
  selector: 'mt-alert-complete-account-data',
  templateUrl: './alert-complete-account-data.component.html',
  styleUrls: ['./alert-complete-account-data.component.scss']
})
export class AlertCompleteAccountDataComponent {
  /**
   * Get video link by translation tag
   */
  videoLink$ = of(this.alert.translate('create_first_signature_help_video')).pipe(
    filter(url => !!url.match(this.pattern.youtubeVideoLinkPattern)),
    map(url => this.dom.bypassSecurityTrustResourceUrl(url))
  );

  constructor(
    private alert: AlertService,
    private dom: DomSanitizer,
    private pattern: PatternService,
    public modal: NgbActiveModal
  ) {}
}
