<div
  data-hj-suppress
  *ngIf="groups"
  class="groupcol cursor-normal"
  [ngClass]="{ first: isFirst }"
>
  <div
    data-cy="assigned-departments"
    *ngIf="
      displayedGroups.length && (eventStatus ? eventStatus !== 'end' : true)
    "
  >
    <div class="title italic" *ngIf="displayedGroups.length > 1">
      <span
        *ngIf="displayedGroups.length < 10"
        tooltipClass="status-info-tooltip"
        placement="top"
        [ngbTooltip]="less10"
      >
        <mt-svg
          src="assets/images/emptystates/folder_es_svg.svg"
          class="medium-svg"
          ngClass="{{ iconColor }}"
        ></mt-svg>
        <span class="space-left-right">{{ displayedGroups.length }}</span>
        <span transloco="groupsAssignedShortened"></span>
      </span>

      <span
        *ngIf="displayedGroups.length > 10"
        tooltipClass="status-info-tooltip"
        placement="right"
        [ngbTooltip]="more10"
      >
        <mt-svg
          src="assets/images/emptystates/folder_es_svg.svg"
          class="medium-svg"
          ngClass="{{ iconColor }}"
        ></mt-svg>
        <span class="space-left-right">{{ displayedGroups.length }}</span>
        <span transloco="groupsAssignedShortened"></span>
      </span>
    </div>
    <div class="title" *ngIf="displayedGroups.length === 1">
      <mt-svg
        src="assets/images/emptystates/folder_es_svg.svg"
        class="medium-svg"
        ngClass="{{ iconColor }}"
      ></mt-svg>

      <a
        *ngIf="!disableLinks"
        [routerLink]="['/groups/details', displayedGroups[0].id]"
        class="space-left"
        (click)="redirectToGroupDetail.emit()"
      >
        <span
          class="link"
          tooltipClass="status-info-tooltip"
          placement="top"
          ngbTooltip="{{ displayedGroups[0].title }}"
          >{{ displayedGroups[0].title }}</span
        >
      </a>

      <ng-container *ngIf="disableLinks">
        <span
          tooltipClass="status-info-tooltip"
          tooltipPlacement="top"
          ngbTooltip="{{ displayedGroups[0].title }}"
          >{{ displayedGroups[0].title }}</span
        >
      </ng-container>
    </div>
    <div *ngIf="!isFirst" class="subline">
      <span transloco="Abteilung"></span>
    </div>
    <div
      *ngIf="isFirst"
      [ngClass]="{ schrift_rot_imp: usersLength === 0 }"
      class="subline"
    >
      <span>{{ usersLength }} </span>
      <span transloco="EMPLOYEE"> </span>
    </div>
  </div>

  <ng-container
    *ngIf="
      !displayedGroups.length || (eventStatus ? eventStatus === 'end' : false)
    "
  >
    <div
      data-cy="assign-departments"
      class="title schrift_rot italic link"
      (click)="redirectToSignatureEditor.emit()"
    >
      <span
        transloco="Unassigned"
        tooltipClass="status-info-tooltip"
        placement="top"
        ngbTooltip="{{ 'list_signature_item_mo_2' | transloco }}"
      ></span>
    </div>
    <div *ngIf="!isFirst" class="subline">
      <span transloco="Abteilung"></span>
    </div>
  </ng-container>
</div>

<!-- Template used when multiple groups but less than 10 -->
<ng-template #less10>
  <ng-container *ngFor="let group of displayedGroups">
    <mt-svg
      class="medium-svg"
      [ngClass]="iconColor"
      src="assets/images/emptystates/folder_es_svg.svg"
    ></mt-svg>
    <span class="grouptitle" style="line-height: 1; vertical-align: top"
      >{{ group.title }} <br
    /></span>
  </ng-container>
</ng-template>

<!-- Template used when more than 10 groups -->
<ng-template #more10>
  <ng-container *ngFor="let group of displayedGroups; let index = index">
    <ng-container *ngIf="index < 10">
      <mt-svg
        class="medium-svg"
        [ngClass]="iconColor"
        src="assets/images/emptystates/folder_es_svg.svg"
      ></mt-svg>
      <span class="grouptitle" style="line-height: 1; vertical-align: top"
        >{{ group.title }} <br
      /></span>
    </ng-container>
  </ng-container>
  <span class="grouptitle" style="line-height: 1; vertical-align: top"
    >...{{ displayedGroups.length - 10 }} {{ 'more' | transloco }}</span
  >
</ng-template>
