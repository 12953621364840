import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  map,
  of,
  ReplaySubject,
  switchMap
} from 'rxjs';

import { TargetGroupGetTargetGroupEntries } from 'src/app/services/target-group/target-group-service.type';

import { AlertService } from 'src/app/services/alert/alert.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { StatisticsService } from 'src/app/services/statistics/statistics.service';
import { TargetGroupService } from 'src/app/services/target-group/target-group.service';

@Injectable()
export class DomainDetailsService {
  /**
   * Observable of target group entry id in route param
   */
  private paramTargetGroupEntryId = new ReplaySubject<string>();
  paramTargetGroupEntryId$ = this.paramTargetGroupEntryId.asObservable();

  /**
   * Load target group entry data with target group and notification data as per the parameter ID
   */
  loadContent$ = this.paramTargetGroupEntryId$.pipe(
    switchMap(id => this.targetGroupService.getTargetGroupEntry(id, '', { doNotUseProspects: true })),
    concatMap(targetGroupEntry =>
      forkJoin([
        of(targetGroupEntry),
        targetGroupEntry.TargetGroupList?.id
          ? this.targetGroupService.getTargetGroup(targetGroupEntry.TargetGroupList.id)
          : of(undefined),
        targetGroupEntry.TargetGroupList?.id
          ? this.notifService.getAllNotifsForTargetGroup(targetGroupEntry.TargetGroupList.id)
          : of([])
      ])
    ),
    map(([targetGroupEntry, targetGroup, notifications]) => ({
      targetGroupEntry,
      targetGroup,
      notifications
    }))
  );

  /**
   * Observable to apply search filter for top campaigns list
   */
  private _filterTopCampaignsText$ = new BehaviorSubject('');
  filterTopCampaignsText$ = this._filterTopCampaignsText$.asObservable();

  /**
   * Load top campaign list and the search filter
   */
  loadTopTargetGroupEntries$ = this.paramTargetGroupEntryId$.pipe(
    switchMap(id =>
      combineLatest([
        this.filterTopCampaignsText$,
        this.statisticsService.getCampaignsPerformanceForTargetGroupListEntry(10, id)
      ])
    ),
    map(([filterTest, list]) => {
      return list.filter(
        list =>
          (list.lastClickCampaign.title || list.targetGroupList.title)
            .toLowerCase()
            .includes(filterTest.toLowerCase()) ?? ''
      );
    })
  );

  /**
   * Observable to apply search filter for last clicks list
   */
  private _filterLastClicksText$ = new BehaviorSubject('');
  filterLastClicksText$ = this._filterLastClicksText$.asObservable();

  /**
   * Load last clicks list and the search filter
   */
  loadLastClicksEntries$ = this.paramTargetGroupEntryId$.pipe(
    switchMap(id =>
      combineLatest([this.filterLastClicksText$, this.statisticsService.getTopTargetListEntryPerformance(25, id)])
    ),
    map(([filterTest, list]) => {
      return list.filter(
        list =>
          ((list.Campaign && list.Campaign.title) ||
            (list.TargetGroupList && list.TargetGroupList.title).toLowerCase().includes(filterTest.toLowerCase())) ??
          ''
      );
    })
  );

  /**
   * Returns the current search value use to filter lists for change target group
   * Waits 400ms and emits only distinct values
   */
  private _filterTargetGroupsText$ = new BehaviorSubject('');
  filterTargetGroupsText$ = this._filterTargetGroupsText$
    .asObservable()
    .pipe(debounceTime(400), distinctUntilChanged());

  /**
   * Loads all target group for change and select target group modal
   * Filters the list by target group title
   */
  targetGroupList$ = combineLatest([this.filterTargetGroupsText$, this.targetGroupService.getTargetGroups()]).pipe(
    map(([filterText, targetGroupList]) =>
      targetGroupList.filter(targetGroupList => targetGroupList.title.toLowerCase().includes(filterText.toLowerCase()))
    )
  );

  constructor(
    private alert: AlertService,
    private modalService: ModalService,
    private notifService: NotificationService,
    private router: Router,
    private statisticsService: StatisticsService,
    private targetGroupService: TargetGroupService
  ) {}

  /**
   * Deletes a target group list entry
   * @param targetGroupListEntryId - Target group list entry to delete
   */
  async deleteTargetGroupEntry(targetGroupListEntryId: string): Promise<void> {
    const confirmed = await this.alert.defaultConfirmPrompt(this.alert.translate('sure_want_delete_tge'));

    if (confirmed) {
      this.targetGroupService.deleteTargetGroupListEntry(targetGroupListEntryId).subscribe(() => {
        void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
        void this.router.navigate(['/recipients']);
      });
    }
  }

  /**
   * Open change target group modal for target group list entry
   * @param targetGroupListEntry - Object of target group list entry
   */
  async openAssignDomainToTargetGroup(targetGroupListEntry: TargetGroupGetTargetGroupEntries): Promise<void> {
    const completed = await this.modalService.openChangeTargetGroupModal(targetGroupListEntry.TargetGroupList?.id);
    if (completed) {
      targetGroupListEntry.targetGroupListId = completed.id;

      this.targetGroupService.updateTargetGroupListEntry([targetGroupListEntry]).subscribe(() => {
        void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
        this.setParamTargetGroupEntriesId(targetGroupListEntry.id);
      });
    }
  }

  /**
   * Open modal to set Opt In Out value for the target group list entry
   * @param targetGroupListEntry - Object of target group list entry to change Opt In Out
   */
  async openChangeOptOutStatus(targetGroupListEntry: TargetGroupGetTargetGroupEntries): Promise<void> {
    const completed = await this.modalService.openChangeOptOutStatusModal(targetGroupListEntry);
    if (completed) {
      this.targetGroupService
        .changeOptOutStatus(targetGroupListEntry.id, !targetGroupListEntry.isOptedOut)
        .subscribe(() => {
          void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
          this.setParamTargetGroupEntriesId(targetGroupListEntry.id);
        });
    }
  }

  /**
   * Open edit target group list entry modal
   * @param targetGroupListEntry - Object of target group list entry to edit
   */
  async openEditTargetGroupEntry(targetGroupListEntry: TargetGroupGetTargetGroupEntries): Promise<void> {
    const res = await this.modalService.openEditDomainModal(targetGroupListEntry);
    if (res) {
      this.targetGroupService.updateTargetGroupListEntry(res).subscribe(() => {
        void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
        this.setParamTargetGroupEntriesId(targetGroupListEntry.id);
      });
    }
  }

  /**
   * Remove currently assigned target group
   * @param targetGroupListEntry - Object of target group list entry
   */
  async removeAssignmentToTG(targetGroupListEntry: TargetGroupGetTargetGroupEntries): Promise<void> {
    const confirmed = await this.alert.defaultConfirmPrompt(this.alert.translate('html.wanna_delete_this_domains'));

    if (confirmed) {
      targetGroupListEntry.targetGroupListId = '';
      this.targetGroupService.updateTargetGroupListEntry([targetGroupListEntry]).subscribe(() => {
        void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
        this.setParamTargetGroupEntriesId(targetGroupListEntry.id);
      });
    }
  }

  /**
   * Sets the value for text filtered for update last click list
   * @param text - String to search
   */
  setLastClicksFilterText(text: string): void {
    this._filterLastClicksText$.next(text);
  }

  /**
   * Set value to observable of paramTargetGroupEntryId and reset child component data
   * @param id - Target group entry id
   */
  setParamTargetGroupEntriesId(id: string): void {
    this.paramTargetGroupEntryId.next(id);
  }

  /**
   * Sets the value for text filtered for update target group list
   * @param text - String to search
   */
  setTargetGroupsFilterText(text = ''): void {
    this._filterTargetGroupsText$.next(text);
  }

  /**
   * Sets the value for text filtered for update top campaign list
   * @param text - String to search
   */
  setTopCampaignsFilterText(text: string): void {
    this._filterTopCampaignsText$.next(text);
  }
}
