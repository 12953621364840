import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { switchMap, tap } from 'rxjs';
import { AlertService } from 'src/app/services/alert/alert.service';
import { UserService } from 'src/app/services/user/user.service';
import { passwordMatcherValidator } from 'src/app/shared/validators/password-matcher-validator.directive';

@Component({
  selector: 'mt-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm!: UntypedFormGroup;

  // eslint-disable-next-line @typescript-eslint/ban-types
  @Input() data!: { code: string; id: string };

  userData = {} as {
    password: string;
    passwordrep: string;
    activationCode: string;
    employeeId: string;
  };

  constructor(
    private alert: AlertService,
    private fb: UntypedFormBuilder,
    private modal: NgbActiveModal,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.setPasswordForm = this.fb.group(
      {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        password: ['', [Validators.required, Validators.minLength(12)]],
        // eslint-disable-next-line @typescript-eslint/unbound-method
        repeatPassword: ['', [Validators.required]]
      },
      {
        validators: passwordMatcherValidator
      }
    );
  }

  /**
   * update password for the user
   */
  setPassword(): void {
    this.userData.activationCode = this.data.code;
    this.userData.employeeId = this.data.id;
    this.userData.password = this.setPasswordForm.get('password')?.value as string;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.userService
      .setNewPassword(this.userData)
      .pipe(
        tap(() => this.modal.close()),
        switchMap(() =>
          this.alert.defaultSuccessMessageV2(
            this.alert.translate('PASS_SUC_RESET'),
            this.alert.translate('done'),
            this.alert.translate('Close'),
            false,
            true
          )
        )
      )
      .subscribe();
  }
}
