import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { CampaignService } from 'src/app/services/campaign/campaign.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsCampaignsModalService {
  /**
   * Observable is used to load campaign list
   */
  private _loadCampaigns$ = new BehaviorSubject(0);
  loadCampaigns$ = this._loadCampaigns$.asObservable();

  /**
   * Returns the current search value use to filter lists
   * Waits 400ms and emits only distinct values
   */
  private _filterText$ = new BehaviorSubject('');
  filterText$ = this._filterText$.asObservable().pipe(debounceTime(400), distinctUntilChanged());

  /**
   * Loads all campaigns
   * Filters the list by campaigns title
   */
  campaignList$ = this.loadCampaigns$.pipe(
    switchMap(() => combineLatest([this.filterText$, this.campaignService.get()])),
    map(([filterText, campaigns]) =>
      campaigns.filter(campaigns => campaigns.title.toLowerCase().includes(filterText.toLowerCase()))
    )
  );

  constructor(private campaignService: CampaignService) {}

  /**
   * Sets the new value for text filtered
   * @param text - String to set as text filtered
   */
  setFilterText(text: string): void {
    this._filterText$.next(text);
  }
}
