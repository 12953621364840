<div
  class="valueeditmodal urlvalue"
  data-cy="signature-placeholder-type-image-modal"
>
  <div class="modal-body">
    <div class="cursor-pointer pull-right" (click)="modal.close()">
      <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
    </div>

    <div class="modal-title">
      <span class="headlinefirst">{{ heading }}</span>
      <span class="headlinesecond">{{ subHeading }}</span>
    </div>

    <div role="separator" class="line full-width-imp"></div>

    <form [formGroup]="advancedSettingsForm">
      <div class="form-group">
        <label transloco="NameTags"></label>
        <input
          data-cy="placeholder-image-tag-input"
          formControlName="tag"
          type="text"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <label transloco="targetlink"></label>
        <input
          data-cy="placeholder-image-url"
          formControlName="url"
          type="text"
          class="form-control"
        />
      </div>

      <div class="flex-center-left textorimage">
        <span class="text" transloco="Viewas"></span>
        <div class="radio">
          <label
            class="container-radio"
            data-cy="placeholder-image-text-option"
          >
            <input formControlName="textOrGraphic" value="text" type="radio" />
            <span class="checkmark-radio"></span>
            <span>Text</span>
          </label>
        </div>
        <div class="radio">
          <label
            class="container-radio"
            data-cy="placeholder-image-graphic-option"
          >
            <input formControlName="textOrGraphic" value="image" type="radio" />
            <span class="checkmark-radio"></span>
            <span transloco="graphic"></span>
          </label>
        </div>
      </div>

      <!-- View as: TEXT -->
      <ng-container
        *ngIf="advancedSettingsForm.controls.textOrGraphic.value === 'text'"
      >
        <div class="form-group">
          <label transloco="linktext"></label>
          <input
            data-cy="placeholder-image-link-text"
            formControlName="linkText"
            type="text"
            class="form-control"
          />
          <br />
          <label class="format2light" transloco="linkcolor"></label>
          <mt-color-picker
            data-cy="placeholder-image-link-text-color-picker"
            [color]="advancedSettingsForm.controls.linkColor.value"
            (colorChange)="setLinkColor($event)"
          ></mt-color-picker>
          <br />
          <label class="format2light" transloco="underline"></label>
          <div class="form-group">
            <div class="radio">
              <label
                class="container-radio"
                data-cy="placeholder-image-link-underline-true"
              >
                <input formControlName="underline" value="true" type="radio" />
                <span class="checkmark-radio"></span>
                <span transloco="underline"></span>
              </label>
            </div>
          </div>
          <div class="form-group">
            <div class="radio">
              <label
                class="container-radio"
                data-cy="placeholder-image-link-underline-false"
              >
                <input formControlName="underline" value="false" type="radio" />
                <span class="checkmark-radio"></span>
                <span transloco="no_underline"></span>
              </label>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- View as: IMAGE -->
      <ng-container
        *ngIf="advancedSettingsForm.controls.textOrGraphic.value === 'image'"
      >
        <div class="form-group">
          <label transloco="Alternativetextfordisplayerrors"></label>
          <input
            data-cy="graphic-alt-text"
            formControlName="altText"
            type="text"
            class="form-control"
          />
        </div>

        <div role="separator" class="line full-width-imp"></div>

        <div class="form-group martop25">
          <label transloco="GraphicsSource"> </label>
        </div>

        <div data-hj-suppress class="imageprevarea flex">
          <div class="formarea">
            <div class="radio top">
              <label class="container-radio" data-cy="graphic-standard-option">
                <input
                  type="radio"
                  formControlName="whichImage"
                  value="default"
                />
                <span class="checkmark-radio"></span>
                <span transloco="StandardDisplay"></span>
              </label>
            </div>

            <div class="radio bottom">
              <label class="container-radio" data-cy="graphic-own-option">
                <input
                  (click)="resetImageValue(advancedSettingsForm.controls.photoUrl.value)"
                  type="radio"
                  value="own"
                  formControlName="whichImage"
                />
                <span class="checkmark-radio"></span>
                <span transloco="owngraphic"></span>
              </label>
            </div>

            <ng-container
              *ngIf="advancedSettingsForm.controls.whichImage.value === 'own'"
            >
              <mt-upload-image-button
                data-cy="graphic-upload-btn"
                (loadImage)="setImage($event)"
                [max-width]="4500"
                [max-size-mb]="5"
              ></mt-upload-image-button>
            </ng-container>
          </div>
          <div class="imgpreview">
            <div class="hint" transloco="Preview"></div>

            <!-- OWN IMAGE -->
            <ng-container
              *ngIf="advancedSettingsForm.controls.whichImage.value === 'own'"
            >
              <img [src]="getImageSrc()" />
            </ng-container>

            <!-- DEFAULT IMAGE -->
            <img
              *ngIf="advancedSettingsForm.controls.whichImage.value === 'default'"
              [src]="advancedSettingsForm.controls.defaultImage.value"
            />
          </div>
        </div>

        <!-- OWN IMAGE - SIZING -->
        <ng-container
          *ngIf="
            advancedSettingsForm.controls.whichImage.value === 'own'
          "
        >
          <div role="separator" class="line full-width-imp"></div>

          <div class="form-group martop25">
            <label
              transloco="Sizeofthepermanentlysetaftersavingthesignature"
            ></label>
          </div>

          <div class="imageprevarea flex">
            <div class="formarea">
              <div class="radio top">
                <label
                  class="container-radio"
                  data-cy="graphic-original-size-option"
                >
                  <input
                    type="radio"
                    formControlName="imageDimensionMode"
                    value="default"
                  />
                  <span class="checkmark-radio"></span>
                  <span transloco="originalsize"></span>
                </label>
              </div>
              <div class="radio bottom">
                <label
                  class="container-radio"
                  data-cy="graphic-manual-size-option"
                >
                  <input
                    type="radio"
                    value="custom"
                    formControlName="imageDimensionMode"
                  />
                  <span class="checkmark-radio"></span>
                  <span transloco="Manually"></span>
                </label>
              </div>

              <div
                *ngIf="advancedSettingsForm.controls.imageDimensionMode.value === 'custom'"
                class="form-group"
              >
                <label transloco="Widthinpx"></label>
                <input
                  formControlName="imageDimensionWidth"
                  type="text"
                  class="form-control"
                  data-cy="graphic-manual-size-width"
                />
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <mt-box
        *ngIf="advancedSettingsForm.controls.useForAllProfiles.value === true"
        type="info"
        icon="info-sign"
        translateTag="Achangetothesignaturesallemployees"
      >
        <div class="additionaltext">
          <span
            transloco="Changesinthisinformationareincludedsignaturesemployees"
          ></span>
        </div>
      </mt-box>

      <div role="separator" class="line full-width-imp"></div>
      <div class="flex-center-right">
        <mt-button
          (click)="modal.close()"
          class="leftbutton"
          text="rejection"
          color="default"
        ></mt-button>
        <mt-button
          data-cy="save-placeholder-image-changes"
          (click)="saveValue()"
          color="primary"
          text="Save"
        ></mt-button>
      </div>
    </form>
  </div>
</div>

<!-- Create New Department Modal -->
<ng-template #modalDeleteImage let-modal>
  <mt-custom-modal>
    <div modal-header-close>
      <div class="title-big">{{ 'HOWDOYOUWANTTOPROCEED' | transloco }}</div>
    </div>
    <div modal-body>
      <mt-box
        type="info"
        icon="info-sign"
        translateTag="delete_image_for_all_or_what"
      ></mt-box>
    </div>
    <div modal-footer>
      <mt-button
        text="abort"
        color="default"
        (click)="modal.close()"
        class="mt_left"
      ></mt-button>
      <mt-button
        text="delete_for_all"
        color="primary"
        (click)="deleteUserImage('all')"
        class="mt_left"
      ></mt-button>
      <mt-button
        text="delete_for_selected"
        color="primary"
        (click)="deleteUserImage(datasetId)"
      ></mt-button>
    </div>
  </mt-custom-modal>
</ng-template>
