<div class="statuscol">
  <div
    style="white-space: nowrap; margin-left: 0"
    *ngIf="signature | signatureRolledoutStatus"
    class="schrift_gruen margin-auto text-bold title"
  >
    <div class="innertitle" style="overflow: hidden">
      <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
      <span
        tooltipClass="status-info-tooltip"
        tooltipPlacement="top"
        ngbTooltip="{{ 'currentVersionOfSignatureWasRolledOut' | transloco }}"
      >
        <span transloco="rolledout"></span
      ></span>
    </div>
  </div>
  <div
    style="white-space: nowrap; margin-left: 0"
    *ngIf="!(signature | signatureRolledoutStatus)"
    class="schrift_rot margin-auto text-bold title"
  >
    <div class="innertitle" style="overflow: hidden">
      <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
      <span
        tooltipClass="status-info-tooltip"
        tooltipPlacement="top"
        ngbTooltip="{{
          'currentVersionOfSignatureWasNotRolledOut' | transloco
        }}"
      >
        <span
          transloco="Notrolled"
          *ngIf="signature.lastRollout === null; else notRolledOutNewestVersion"
        ></span>
        <ng-template #notRolledOutNewestVersion>
          <span transloco="NewestVersionNotRolled"> </span> </ng-template
      ></span>
    </div>
  </div>

  <div
    *ngIf="!signature.lastRollout"
    ng-hide="::item.lastRollout"
    class="subline"
  >
    <span transloco="LastReviewNever"></span>
  </div>

  <div *ngIf="signature.lastRollout" class="subline">
    <span transloco="Recentlyat"></span>
    {{ signature.lastRollout | date: 'dd.MM.yyyy' }}
  </div>
</div>
