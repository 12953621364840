import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChooseCreateTypeModalData } from 'src/app/model/interfaces/event.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { GatedContentService } from '@services/gated-content/gated-content.service';
import { forkJoin, of, switchMap } from 'rxjs';
import { ActivitiesService } from '@services/activities/activities.service';
import { EDataType } from '@model/enums/data-type.enum';
import { EActionType } from '@model/enums/action-type.enum';

@Component({
  selector: 'select-creation-modal',
  templateUrl: './select-creation.component.html',
  styleUrls: ['./select-creation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    MtSvgComponent,
    NgClass,
    NgIf,
    RouterLink,
    TranslocoModule
  ]
})
export class SelectCreationModalComponent {
  /**
   * Hold value of EDataType obj to identify event/campaign type to open
   */
  @Input() dataType!: EDataType;

  /**
   * Determines the counter of submenu
   * @defaultValue {
   * groupData: [],
   * title: '',
   * textSender: '',
   * textTarget: '',
   * linkText: '',
   * routeSender: '',
   * routeTarget: '',
   * link: ''
   *}
   */
  @Input() inputData: ChooseCreateTypeModalData = {
    groupData: [],
    title: '',
    textSender: '',
    textTarget: '',
    linkText: '',
    routeSender: '',
    routeTarget: '',
    link: ''
  };

  constructor(
    private activitiesService: ActivitiesService,
    private gatedContentService: GatedContentService,
    private router: Router,
    public authService: AuthService,
    public modal: NgbActiveModal
  ) {}

  /**
   * Used when create event
   * @param url - The url to redirect
   */
  openUrl(url: string): void {
    // While create event from group details
    if (this.inputData.groupData && this.inputData.groupData.length > 0) {
      void this.router.navigate([url], { state: { groupData: this.inputData.groupData } });
    } else {
      void this.router.navigate([url]);
    }
  }

  /**
   * Redirect to target URL based on subscription
   * @param hasTargetLists - Boolean to has Pro plan
   * @param url - The url to redirect
   */
  openTargetUrl(hasTargetLists: boolean, url: string): void {
    if (hasTargetLists) {
      // Navigate to the provided URL if user has Pro plan
      void this.router.navigate([url]);

      // Dismiss the modal with a 'cancelled' reason
      this.modal.dismiss('cancelled');
    } else {
      // Open gated content info modal and handle its subscription
      this.gatedContentService
        .openGatedContentInfoModal()
        .pipe(
          switchMap(shouldRedirected =>
            forkJoin([
              of(shouldRedirected),
              this.activitiesService.createActivity(this.dataType, EActionType.CREATED, 'UPGRADE_ACCOUNT')
            ])
          )
        )
        .subscribe(([shouldRedirected]) => {
          if (shouldRedirected) {
            // Dismiss the modal with a 'cancelled' reason
            this.modal.dismiss('cancelled');
          }
        });
    }
  }
}
