<mt-custom-modal class="reset-password-modal">
  <div modal-header>
    <div class="flex-center-space-between">
      <h4 class="modal-title">{{ 'assign_new_password' | transloco }}</h4>
    </div>
  </div>

  <div modal-body>
    <form [formGroup]="setPasswordForm">
      <div class="form-group">
        <div class="row">
          <input
            class="martop10 form-control input-lg secondinput"
            type="password"
            placeholder="{{ 'password' | transloco }}*"
            formControlName="password"
            required
          />
        </div>
        <div
          *ngIf="
            setPasswordForm.controls['password'].touched ||
            setPasswordForm.controls['password'].dirty
          "
        >
          <!-- Validate required password -->
          <div
            *ngIf="setPasswordForm.controls['password'].errors?.required"
            class="martop10 text-danger"
          >
            <strong
              >{{ 'password' | transloco }} {{ 'required_field' | transloco
              }}</strong
            >
          </div>

          <!-- Validate password length -->
          <div
            *ngIf="setPasswordForm.controls['password'].errors?.minlength"
            class="martop10 text-danger"
          >
            <strong>{{ 'Please6characters' | transloco }} </strong>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="row">
          <input
            class="martop10 form-control input-lg secondinput"
            type="password"
            placeholder="{{ 'Repeatpassword' | transloco }}*"
            formControlName="repeatPassword"
            required
          />
        </div>
        <div
          *ngIf="
            setPasswordForm.controls['password'].valid &&
            (setPasswordForm.controls['repeatPassword'].touched ||
              setPasswordForm.controls['repeatPassword'].dirty)
          "
        >
          <!-- Validate confirm password -->

          <div
            *ngIf="setPasswordForm.controls['repeatPassword'].errors?.required"
            class="martop10 text-danger"
          >
            <strong>{{ 'confirm_password' | transloco }}</strong>
          </div>

          <!-- Validate passwords matching -->
          <div
            *ngIf="
              setPasswordForm.controls['password'].valid &&
              setPasswordForm.errors?.noPasswordMatch
            "
            class="martop10 text-danger"
          >
            <strong>{{ 'Thepasswordsdonotmatch' | transloco }} </strong>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div modal-footer>
    <div>
      <div class="flex-center-space-between">
        <div class="flex"></div>

        <div class="flex">
          <mt-button
            text="SavePassword"
            class="roboto_light"
            color="primary"
            type="submit"
            (click)="setPassword()"
          >
          </mt-button>
        </div>
      </div>
    </div>
  </div>
</mt-custom-modal>
