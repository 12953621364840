import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from '@services/alert/alert.service';
import { EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GroupService } from 'src/app/services/group/group.service';
import { noSpecialCharsValidator } from 'src/app/shared/validators/no-special-chars-validator.directive';

@Component({
  selector: 'mt-duplicate-group',
  templateUrl: './duplicate-group.component.html',
  styleUrls: ['./duplicate-group.component.scss']
})
export class DuplicateGroupModalComponent implements OnInit {
  /**
   * Group unique identifier that determines which should be duplicated
   */
  @Input() groupId!: number;

  /**
   * Holds the form information, with default values and validators used
   */
  duplicateForm!: UntypedFormGroup;

  constructor(
    private alert: AlertService,
    private fb: UntypedFormBuilder,
    private groupService: GroupService,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.duplicateForm = this.fb.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      title: ['', [Validators.required, noSpecialCharsValidator]],
      copyMembers: [false],
      copySignature: [false],
      copyCampaign: [false]
    });
  }

  /**
   * Submits the duplicate group form data
   */
  onSubmit(): void {
    if (this.duplicateForm.invalid) {
      return;
    }

    const newGroup = {
      title: this.duplicateForm.get('title')?.value as string,
      copyMembers: this.duplicateForm.get('copyMembers')?.value as boolean,
      copySignature: this.duplicateForm.get('copySignature')?.value as boolean,
      copyCampaign: this.duplicateForm.get('copyCampaign')?.value as boolean
    };

    this.groupService
      .duplicateGroup(this.groupId, newGroup.title, newGroup.copyMembers, newGroup.copySignature, newGroup.copyCampaign)
      .pipe(
        catchError((e: HttpErrorResponse) => {
          void this.alert.defaultErrorMessage(this.alert.translate(e.message));
          return EMPTY;
        }),
        map(result => result)
      )
      .subscribe(result => this.modal.close(result));
  }

  /**
   * Closes the modal window
   */
  cancel(): void {
    this.modal.close();
  }
}
