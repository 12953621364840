import { NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { TTriggerEventGroups } from 'src/app/model/interfaces/event.interface';
import { IGroup, IGroupObject, GroupSmall } from 'src/app/model/interfaces/group.interface';
import { IGroupGet } from 'src/app/services/group/group-service.interface';
import { IActiveGroups } from 'src/app/services/signature/signature-service.interface';

// Append all data-structures that use the 'GroupColComponent'
// this type is needed to correspond with the linter
type AllowedSelectColTypes = IGroupGet[] | IGroupObject[] | GroupSmall[] | IActiveGroups[] | TTriggerEventGroups[];
@Component({
  selector: 'signaturegroupcol',
  templateUrl: './signature-group-col.component.html',
  styleUrls: ['./signature-group-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgClass, NgbTooltip, MtSvgComponent, RouterModule, TranslocoModule, NgFor]
})
export class SignatureGroupColComponent implements OnInit {
  /**
   * Assigned groups
   * @defaultValue []
   */
  @Input() groups = [] as AllowedSelectColTypes;

  /**
   * Determines if specific styling is applied
   * @defaultValue false
   */
  @Input() isFirst = false;

  /**
   * Color used for the icon
   * @defaultValue 'mt-darkgrey'
   */
  @Input() iconColor = 'mt-darkgrey';

  /**
   * Determines if link to details page should be disabled
   * @defaultValue false
   */
  @Input() disableLinks = false;

  /**
   * Amount of users assigned to a group
   * @defaultValue 0
   */
  usersLength = 0;

  /**
   * Holds basic group information for internal display purposes
   * @defaultValue []
   */
  displayedGroups: IGroup[] = [];

  /**
   * Determines if passed event status should have unassigned department
   * @defaultValue 'live'
   */
  @Input() eventStatus? = 'live';

  /**
   * Triggers the group detail page redirection
   */
  @Output() redirectToGroupDetail = new EventEmitter<void>();

  /**
   * Triggers the group step of edit signature wizard
   */
  @Output() redirectToSignatureEditor = new EventEmitter<void>();

  ngOnInit(): void {
    if ((this.groups[0] as TTriggerEventGroups)?.Group) {
      for (const item of this.groups) {
        const group = item as TTriggerEventGroups;
        this.displayedGroups.push({
          id: group.Group.id,
          title: group.Group.title,
          owner: group.Group.owner,
          createdAt: group.Group.createdAt,
          updatedAt: group.Group.updatedAt,
          isManuallyCreated: true
        });
      }
    } else {
      for (const item of this.groups) {
        const group = item as IGroupGet | IGroupObject | GroupSmall | IActiveGroups;
        this.displayedGroups.push({
          id: group.id,
          title: group.title,
          owner: group.owner,
          createdAt: group.createdAt,
          updatedAt: group.updatedAt,
          isManuallyCreated: group.isManuallyCreated
        });
      }

      if (this.hasUsers(this.groups[0])) {
        this.usersLength = Number(this.groups[0]?.Users?.length);
      } else {
        if (this.hasAmountOfMembers(this.groups[0])) {
          this.usersLength = Number(this.groups[0]?.amountOfMembers);
        }
      }
    }
  }

  /**
   * Determines if a given group has users assigned
   * @param variableToCheck - Group to check
   * @returns Whether the group has users or not
   */
  hasUsers(variableToCheck: unknown): variableToCheck is IGroupGet | GroupSmall {
    return (variableToCheck as IGroupGet | GroupSmall)?.Users !== undefined;
  }

  /**
   * Determines if a given group has amount Of Members
   * @param variableToVerify - Group to check
   * @returns Whether the group has amountOfMembers or not
   */
  hasAmountOfMembers(variableToVerify: unknown): variableToVerify is IActiveGroups {
    return (variableToVerify as IActiveGroups)?.amountOfMembers !== undefined;
  }
}
