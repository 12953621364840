<ng-container *transloco="let translate"
  ><div class="product-container-header">
    <div class="image">
      <img
        src="/assets/images/planOverview/icon_advanced.png"
        alt=""
        width="125"
        height="125"
      />
    </div>
    <div class="title" data-cy="advanced-title">
      {{ translate('plan_short_sig_marketing') }}
    </div>
    <div class="title-content">
      <span class="content" [innerHTML]="translate('info_advanced_new')"></span>
    </div>
    <div class="seperation-line martop30"></div>
  </div>
  <ng-container *ngIf="currentProduct !== 'Advanced'; else advancedSubscribed">
    <div *ngIf="priceData.totalUsers < maxUsers">
      <!-- For booking page -->
      <mt-product-container-price
        *ngIf="!currentProduct"
        [productName]="'Advanced'"
        [priceBig]="priceData.advancedBig"
        [priceSmall]="priceData.advancedSmall"
        [totalUsers]="priceData.totalUsers >= 10 ? priceData.totalUsers : 10"
        [costPerUser]="priceData.priceAdvancedPerUser"
        [billingInterval]="billingInterval"
      ></mt-product-container-price>

      <!-- For upgrade plan modal -->
      <mt-product-container-modal-price
        *ngIf="currentProduct"
        [costPerUser]="
          currentAmountOfSystemUsers
            | priceBasedOnUsers : priceData.basePrices.Advanced
        "
        [currentAmountOfSystemUsers]="currentAmountOfSystemUsers"
        [tariff]="translate('advanced_plan')"
      ></mt-product-container-modal-price>

      <div class="martop20 booking-buy-term-box"></div>

      <a
        (click)="setProduct.emit('Advanced')"
        class="btn firstbutton btn-lg mt_blau lineheight15 font13"
        data-cy="set-product-advanced"
      >
        {{ translate('buy_plan_sig_marketing') }}
      </a>
    </div>
    <div *ngIf="priceData.totalUsers >= maxUsers" class="martop40">
      <div class="product-container-demo-content">
        <div class="image">
          <img
            src="/assets/images/planOverview/avatar_sales_diana.png"
            alt=""
            width="145"
            height="145"
            data-cy="sales-image-advanced"
          />
        </div>
        <div>
          <span class="content">{{ translate('out_team_adivces_you') }}</span>
        </div>
      </div>
    </div>
    <div
      *ngIf="priceData.totalUsers >= 250"
      [ngClass]="{ martop20: priceData.totalUsers >= maxUsers }"
    >
      <a
        data-cy="btn-quote-advanced"
        class="btn firstbutton btn-lg btn-quote lineheight15 martop10"
        href="mailto:t.bauer@mailtastic.com"
        >{{ translate('get_proposal') }}</a
      >
    </div>
  </ng-container>
  <ng-template #advancedSubscribed>
    <div class="current-product fontsize20 padtop140">
      {{ translate('your_current_plan') }}
    </div>
  </ng-template>
</ng-container>
