<div [class]="!isSmallSwitch ? 'switch-toggle vertical-align-middle' : ''">
  <label [class]="isSmallSwitch ? 'switch-toggle sm' : ''">
    <input
      data-cy="toggle-switch-status"
      type="checkbox"
      [checked]="checked"
      [disabled]="disabled"
      (change)="changeSwitchStatus((checked = !checked))"
    />
    <span *ngIf="!tooltipText" class="slider-toggle radius3"></span>
    <span
      *ngIf="tooltipText"
      class="slider-toggle radius3"
      tooltipClass="status-info-tooltip"
      tooltipPlacement="top"
      ngbTooltip="{{ tooltipText | transloco }}"
    ></span>
  </label>
</div>
