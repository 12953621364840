import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ILastActionsTargetGroupEntriesGet,
  ITopRecipientsGet,
  ITopTargetGroupEntriesGet
} from 'src/app/model/interfaces/recipient.interface';
import { IResponseData } from 'src/app/model/interfaces/response.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { Observable } from 'rxjs';
import { AlertService } from '../alert/alert.service';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  constructor(private alert: AlertService, private http: HttpClient, private operator: CustomOperators) {}

  /**
   * Gets the campaign performance for the target group list entries
   * @param amount - The amount of how much entries are needed
   * @param targetGroupListEntryId - Target group list entry id
   * @returns List of campaigns performance for target group list entry
   */
  getCampaignsPerformanceForTargetGroupListEntry(
    amount: number,
    targetGroupListEntryId: string
  ): Observable<ITopTargetGroupEntriesGet[]> {
    return this.http
      .get<IResponseData<ITopTargetGroupEntriesGet[]>>(`/stats/toptlentries/${amount}/${targetGroupListEntryId || ''}`)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the top target group list performance entries
   * @param amount - The amount of how much entries are needed
   * @param targetGroupListEntryId - Target group list entry id
   * @returns List of top target group list performance entries
   */
  getTopTargetListEntryPerformance(
    amount: number,
    targetGroupListEntryId: string
  ): Observable<ILastActionsTargetGroupEntriesGet[]> {
    return this.http
      .get<IResponseData<ILastActionsTargetGroupEntriesGet[]>>(
        `/stats/lastactions/${amount}/${targetGroupListEntryId || ''}`
      )
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Gets the top recipients
   * @param amount
   * @returns
   */
  getTopRecipients(amount: number): unknown {
    return this.http.get(`/stats/toprecipients/${amount}`).pipe(this.operator.extractUnknownResponse());
  }

  /**
   * Gets the top recipients for a specific campaign
   * @param amount
   * @param campaignId
   * @returns
   */
  getCampaignTopRecipients(amount: number, campaignId: string): Observable<ITopRecipientsGet[]> {
    return this.http
      .get<IResponseData<ITopRecipientsGet[]>>(`/stats/campaigntoprecipients/${amount}/${campaignId}`)
      .pipe(this.operator.extractResponseData());
  }
}
