<mt-custom-modal
  headerColor="no-color"
  headline="{{ modalHeader | transloco }}"
  theme="video-theme"
>
  <ng-container modal-body>
    <iframe
      *ngIf="isLinkValid"
      width="647"
      height="400"
      [src]="youtubeLink"
      title="YouTube video player"
      frameborder="0"
      allow="autoplay"
      allowfullscreen
      sandbox="allow-same-origin allow-scripts allow-presentation"
    ></iframe>

    <mt-box
      *ngIf="!isLinkValid"
      type="info"
      icon="info-sign"
      translateTag="no_video_currently_notification"
    ></mt-box>
  </ng-container>
</mt-custom-modal>
