import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  forkJoin,
  iif,
  map,
  Observable,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs';
import { cloneDeep } from 'lodash-es';

// Interfaces
import {
  BookingConfig,
  BookingContainer,
  isFreeSubscription,
  PriceData,
  Product,
  UserStatus
} from 'src/app/model/interfaces/payment.interface';
import { BuyStatus, PlanDetail, ShowBuyModal } from 'src/app/model/interfaces/plans.interface';
import { GetOverallStatsResponse } from 'src/app/services/user/user-service.interface';
import { IntegrationType } from '@services/employee/employee-service.interface';

// Consts
import {
  ADVANCED_PLAN,
  BASIC_PLAN,
  BUSINESS_PLAN,
  PROFESSIONAL_PLAN,
  TARGET_PLAN
} from 'src/app/model/const/plans.const';

// Services
import { AccountService } from 'src/app/services/account/account.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from '@services/auth/auth.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilService } from '@services/util/util.service';

@Injectable({
  providedIn: 'root'
})
export class PlansService {
  /**
   * Observable is used to display gated content no authorization modal
   */
  private _showGatedContentModal$ = new BehaviorSubject(false);
  showGatedContentModal$ = this._showGatedContentModal$.asObservable();

  /**
   * Get subscription data
   */
  subscriptionData$ = this.authService.authorizationScope$.pipe(
    switchMap(() => this.accountService.getCustomerSubscriptionData())
  );

  /**
   * Load all plans as per logged in user with subscription data
   */
  private _loadData$ = new BehaviorSubject<void>(undefined);
  loadData$ = this._loadData$.asObservable().pipe(
    switchMap(() => this.subscriptionInformationData$),
    map(({ statData, subscriptionData, userStatus }) => {
      let planList: PlanDetail[] = [];
      if (userStatus.hasSubscription === false) {
        planList = this.getFreePlanList(statData, userStatus, [BASIC_PLAN, ADVANCED_PLAN, TARGET_PLAN]);
      } else {
        switch (userStatus.currentProduct) {
          case 'Professional':
            planList = this.getSubscribedPlanList(userStatus, 'Professional', [
              PROFESSIONAL_PLAN,
              ADVANCED_PLAN,
              TARGET_PLAN
            ]);
            break;
          case 'Business':
            planList = this.getSubscribedPlanList(userStatus, 'Business', [BUSINESS_PLAN, TARGET_PLAN]);
            break;
          case 'Basic':
          case 'Advanced':
          case 'Target':
            planList = this.getSubscribedPlanList(userStatus, userStatus.currentProduct, [
              BASIC_PLAN,
              ADVANCED_PLAN,
              TARGET_PLAN
            ]);
            break;
        }
      }
      return { planList, userStatus, subscriptionData, statData };
    })
  );

  /**
   * Get subscription information data
   */
  subscriptionInformationData$ = this.userService.getOverallStats().pipe(
    filter(state => state.length > 0),
    withLatestFrom(this.subscriptionData$),
    map(([[statData], subscriptionData]) => {
      const userStatus = this.paymentService.getUserStatus(statData, subscriptionData);
      return { statData, userStatus, subscriptionData };
    })
  );

  /**
   * Get invoices data
   */
  invoicesData$ = this.subscriptionData$.pipe(
    switchMap(subscriptionData =>
      forkJoin([
        iif(
          () =>
            !!(
              !isFreeSubscription(subscriptionData) &&
              (subscriptionData.payment_type === 'credit_card' || subscriptionData.payment_type === 'paypal_account')
            ),
          this.paymentService.getInvoicesForAutomaticPayment(),
          of([])
        ),
        iif(
          () => !!(!isFreeSubscription(subscriptionData) && subscriptionData.payment_collection_method === 'invoice'),
          this.paymentService.getInvoices(),
          of([])
        )
      ])
    ),
    map(([invoicesAutomaticPayment, invoices]) => ({
      invoicesAutomaticPayment,
      invoices
    }))
  );

  constructor(
    private accountService: AccountService,
    private alert: AlertService,
    private authService: AuthService,
    private modalService: ModalService,
    private paymentService: PaymentService,
    private userService: UserService,
    private utilService: UtilService
  ) {}

  /**
   * Display or hide Gated content modal
   * @param isShow - Should show or hide
   */
  showGatedContentModal(isShow: boolean): void {
    this._showGatedContentModal$.next(isShow);
  }

  /**
   * Prepare free subscription plan list
   * @param state - Object of loggedin user info
   * @param userStatus - Object of User status info
   * @param planList - Array of object for plan list
   * @returns List of plan
   */
  getFreePlanList(state: GetOverallStatsResponse, userStatus: UserStatus, planList: PlanDetail[]): PlanDetail[] {
    const plans = planList.map(plan => {
      plan.hasSubscription = userStatus.hasSubscription;
      return plan;
    });

    plans.unshift({
      name: 'Community',
      title: 'plan_mailtastic_free',
      description: 'param.mailtastic_free_info',
      descriptionValues: userStatus.amountOfFreeMembers || state.amountOfUsers,
      hasSubscription: userStatus.hasSubscription,
      isActivatable: false,
      isActive: true,
      isBookable: false,
      isNew: false,
      isTestable: false,
      isTrialRunning: false
    });
    return plans;
  }

  /**
   * Prepare subscribed user plan list
   * @param userStatus - Object of User status info
   * @param productType - String for which type of plan user subscribed
   * @param planList - Array of object for plan list
   * @returns List of plan
   */
  getSubscribedPlanList(userStatus: UserStatus, productType: Product, planList: PlanDetail[]): PlanDetail[] {
    return cloneDeep(planList).map(plan => {
      plan.isBookable = false;
      plan.hasSubscription = userStatus.hasSubscription;
      plan.state = userStatus.state;

      switch (productType) {
        case 'Professional':
          if (plan.name === 'Advanced' || plan.name === 'Target') {
            plan.isActivatable = true;
          }
          break;
        case 'Business':
          if (plan.name === 'Target') {
            plan.isActivatable = true;
          }
          break;
        case 'Basic':
          if (plan.name === 'Basic') {
            plan.isActive = true;
          } else {
            plan.isActivatable = true;
          }
          break;
        case 'Advanced':
          if (plan.name === 'Basic') {
            plan.isIncluded = true;
          } else if (plan.name === 'Advanced') {
            plan.isActive = true;
          } else {
            plan.isActivatable = true;
          }
          break;
        case 'Target':
          if (plan.name === 'Basic' || plan.name === 'Advanced') {
            plan.isIncluded = true;
          } else {
            plan.isActive = true;
          }
          break;
      }
      return plan;
    });
  }

  /**
   * Check plan status to buy or not before import
   * @param userCount - Number of user for import
   * @returns Observable with modal name and subscription data
   */
  checkBuyStatus(userCount: number): Observable<BuyStatus> {
    return this.userService.getAccountData().pipe(
      filter(userAccountData => !!userAccountData.id),
      tap(accountData => {
        if (accountData.isCommunityEditionAccount && accountData.employeeCount >= accountData.maxCommunityUsers) {
          this.showGatedContentModal(true);
        }
      }),
      filter(
        accountData =>
          !(accountData.isCommunityEditionAccount && accountData.employeeCount >= accountData.maxCommunityUsers)
      ),
      switchMap(() => this.subscriptionInformationData$),
      map(plansData => {
        let showBuyModal: ShowBuyModal = ''; // Default case - can add more users
        if (plansData.userStatus.hasSubscription && plansData.userStatus.amountOfFreeMembers < userCount) {
          // Paying customer but, Has no more free employees
          showBuyModal = 'buy_user';
        }
        if (
          !plansData.statData.forceAllow &&
          !plansData.userStatus.hasTestTime &&
          !plansData.userStatus.hasSubscription
        ) {
          // Expired : purchase mailtastic modal
          showBuyModal = 'buy_mailtastic';
        }
        return { userStatus: plansData.userStatus, showBuyModal };
      })
    );
  }

  /**
   * Open modals if plan expire or exceed the licenses
   * @param userCount - Number of user for import
   * @param integrationType - Which type of user import `o365 | google`
   * @returns Observable of boolean to proceed
   */
  checkPlanExpired(userCount: number, integrationType?: IntegrationType): Observable<boolean> {
    return this.checkBuyStatus(userCount).pipe(
      switchMap(process => {
        if (process.showBuyModal === 'buy_user') {
          const totalUsers = userCount + process.userStatus.amountOfUsers;
          const interval = process.userStatus.billingInterval === 'monthly' ? 1 : 12;
          let pricePerUser = process.userStatus.monthlyPricePerUser;
          // As per the new pricing structure we expect three different prices and It's apply based on number of users.
          // Verify that the user subscribed newer plans which has three prices.
          if (process.userStatus.priceList.length === 3) {
            if (totalUsers > process.userStatus.secondSlotUsers) {
              pricePerUser = process.userStatus.priceList[2].unit_price / interval;
            } else if (totalUsers > process.userStatus.firstSlotUsers) {
              pricePerUser = process.userStatus.priceList[1].unit_price / interval;
            } else {
              pricePerUser = process.userStatus.priceList[0].unit_price / interval;
            }
          }

          return this.modalService.openUsersToBuyModal({
            existent: 0,
            integrationType,
            billingInterval: process.userStatus.billingInterval,
            customPrice: pricePerUser,
            email: '',
            freeAmount: process.userStatus.amountOfFreeMembers,
            totalAmount: userCount,
            validAmount: userCount - process.userStatus.amountOfFreeMembers
          });
        } else if (process.showBuyModal === 'buy_mailtastic') {
          return this.modalService.openTrialVersionExpiredModal();
        } else {
          return of(true);
        }
      })
    );
  }

  /**
   * Open chargify client portal to edit information
   */
  openSelfServicePortal(): void {
    this.paymentService.getCustomerPortalUrl().subscribe(selfServicePortalUrl => {
      if (selfServicePortalUrl) {
        window.open(
          selfServicePortalUrl,
          'Your Mailtastic Customer Portal',
          'location=0,status=0,width=1400,height=800'
        );
      } else {
        void this.alert.defaultErrorMessage(
          this.alert.translate('DATEN_NICHT_GELADEN') + ': Customer Portal Url not available'
        );
      }
    });
  }

  /**
   * Shows the invoice as a PDF in a new browser window.
   * @param invoiceId - Invoice id to open in new tab
   */
  downloadInvoice(invoiceId: string): void {
    this.paymentService.downloadInvoice(invoiceId);
  }

  /**
   * Update data for overview page
   */
  updateOverviewPlanData() {
    this._loadData$.next();
  }

  /**
   * Open modal for change plan
   * @param config - Default config object
   * @param bookingContainer - Object with all plan features
   * @param priceData - Object of PriceData
   * @param currentProduct - Current subscribed product
   * @param statData - Object of user stats data
   */
  openChangeProductModal(
    config: BookingConfig,
    bookingContainer: BookingContainer,
    priceData: PriceData,
    currentProduct: Product,
    statData: GetOverallStatsResponse
  ): void {
    this.modalService
      .openPlanUpgradeModal(config, bookingContainer, priceData, currentProduct, statData)
      .pipe(
        tap(() => this.utilService.loadingStart()),
        switchMap(product =>
          combineLatest([of(product), this.paymentService.switchPlan(product, config.billingInterval)])
        ),
        tap(() => this.utilService.loadingStop()),
        switchMap(([product]) => this.modalService.openBookingConfirmationModal(product)),
        tap(() => this.updateOverviewPlanData())
      )
      .subscribe();
  }
}
