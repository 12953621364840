import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal, NgbDropdownItem } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, forkJoin, map, skip, switchMap, tap, withLatestFrom } from 'rxjs';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { QueryHelperService } from 'src/app/services/query-helper/query-helper.service';
import { SearchService } from 'src/app/services/search/search.service';
import { TargetGroupsGetTargetGroups } from 'src/app/services/target-group/target-group-service.type';
import { TargetGroupService } from 'src/app/services/target-group/target-group.service';
import { EmptyStateComponent } from '@organisms/empty-states/empty-state.component';
import { ActiveCampaignColComponent } from '@organisms/cols/active-campaign-col/active-campaign-col.component';
import { TargetGroupEntryNumberColComponent } from '@organisms/cols/target-group-entry-number-col/target-group-entry-number-col.component';
import { TargetGroupEntryTitleColComponent } from '@organisms/cols/target-group-entry-title-col/target-group-entry-title-col.component';
import { SelectColComponent } from '@organisms/cols/select-col/select-col.component';
import { RowComponent } from '@atoms/row/row.component';
import { ListComponent } from '@atoms/list/list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslocoModule } from '@ngneat/transloco';
import { SortDropdownComponent } from '@molecules/buttons/sort-dropdown/sort-dropdown.component';
import { MtSearchComponent } from '@atoms/inputs/search/search.component';
import { SelectAllButtonComponent } from '@molecules/buttons/select-all-button/select-all-button.component';
import { ListActionsComponent } from '@atoms/list-actions/list-actions.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-assign-targetgroup-modal',
  templateUrl: './assign-targetgroup-modal.component.html',
  styleUrls: ['./assign-targetgroup-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListHelperService, QueryHelperService, SearchService],
  standalone: true,
  imports: [
    ActiveCampaignColComponent,
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    EmptyStateComponent,
    InfiniteScrollModule,
    ListActionsComponent,
    ListComponent,
    MtSearchComponent,
    NgbDropdownItem,
    NgFor,
    NgIf,
    RowComponent,
    SelectAllButtonComponent,
    SelectColComponent,
    SortDropdownComponent,
    TargetGroupEntryNumberColComponent,
    TargetGroupEntryTitleColComponent,
    TranslocoModule
  ]
})
export class AssignTargetgroupModalComponent {
  constructor(
    private queryHelperService: QueryHelperService,
    private targetGroupsService: TargetGroupService,
    public list: ListHelperService<TargetGroupsGetTargetGroups>,
    public modal: NgbActiveModal,
    public searchService: SearchService
  ) {}

  // Accounts that have already been assigned
  private _assignedTargetGroups$ = new BehaviorSubject<string[]>([]);
  assignedTargetGroups$ = this._assignedTargetGroups$.asObservable();

  @Input() set assignedTargetGroups(targetGroups: string[]) {
    this._assignedTargetGroups$.next(targetGroups);
  }

  /**
   * Handles infinite scroll state
   */
  infinitescrolloptions = {
    disable: true,
    scrollAction: (): void => {
      this.infinitescrolloptions.disable = true;

      // Load more accounts until all are loaded
      if (this.list.all.length < this.list.denominator - this.nrAssignedTargetGroups) {
        this.queryHelperService.loadNextPage();
      }
    }
  };

  loadTargetGroups$ = this.queryHelperService.queryObject$.pipe(
    switchMap(queryObj =>
      forkJoin([
        this.targetGroupsService.getTargetGroups(queryObj),
        this.targetGroupsService.countTargetGroups(queryObj)
      ])
    ),
    tap(([, count]) => (this.list.denominator = count)),
    map(([targetGroups]) => targetGroups),
    withLatestFrom(this.assignedTargetGroups$),
    map(([targetGroups, assignedTargetGroups]) => ({ targetGroups, assignedTargetGroups })),
    map(data => {
      if (!data.assignedTargetGroups.length) {
        return data.targetGroups;
      }

      const filteredTargetGroups = data.targetGroups.filter(
        targetGroup => !data.assignedTargetGroups.find(assigned => assigned === targetGroup.id)
      );

      return filteredTargetGroups;
    }),
    map(accounts => {
      this.list.all.push(...accounts);
      this.list.setFiltered();
      this.list.createFraction(this.list.all.length);
      this.infinitescrolloptions.disable = false;
      return this.list.all;
    })
  );

  // Pipes the result from the search bar
  // and sets the search accordingly
  searchData$ = this.searchService.filterText$.pipe(
    skip(1), // Skip the first emition, otherwise same data is loaded twice
    tap(search => {
      this.list.resetData();
      this.list.setFiltered();
      this.queryHelperService.resetPagination();
      this.queryHelperService.setSearch(search);
      this.queryHelperService.setQueryObservable(this.queryHelperService.getObject());
    })
  );

  /**
   * @returns Nr. of already assigned accounts
   */
  private get nrAssignedTargetGroups(): number {
    return this._assignedTargetGroups$.value.length;
  }
}
