import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Interfaces
import { SignatureEditWizardStepName } from '@model/interfaces/signature.interface';

// Others
import { INSTALLATION } from '@shared/components/organisms/installation/model/installation-modal.const';
import { cloneDeep } from 'lodash-es';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';
import { WizardTabComponent } from '@organisms/wizard/wizard-tab/wizard-tab.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-signature-setup-check-colleagues',
  templateUrl: './signature-setup-check-colleagues.component.html',
  styleUrls: ['./signature-setup-check-colleagues.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [WizardTabComponent, NgIf, TranslocoModule, ButtonComponent]
})
export class SignatureSetupCheckColleaguesComponent implements OnInit {
  installation = cloneDeep(INSTALLATION);

  /**
   * Variable to check that the signature is rolled out yet
   * @defaultValue true
   */
  @Input() isSignatureRollOut = true;

  /**
   * Holds the signature id
   * @defaultValue ''
   */
  @Input() signatureId = '';

  /**
   * Total member of all assigned department
   * @defaultValue 0
   */
  @Input() totalAmountOfMember = 0;

  /**
   * Triggers the redirect signature wizard and close model
   */
  @Output() RedirectToSignatureEditWizard = new EventEmitter<SignatureEditWizardStepName>();

  ngOnInit(): void {
    this.installation.setupCheckStep[0].open = true;
  }

  /**
   * Redirect to signature edit wizard and opens the related step
   * @param stepNoToOpen - Step name to open in edit signature wizard
   */
  redirectToSignatureEditWizard(stepNoToOpen: SignatureEditWizardStepName): void {
    this.RedirectToSignatureEditWizard.emit(stepNoToOpen);
  }
}
