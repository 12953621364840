import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  // This will contain all data about signature but stringified
  private signatureData: string | null = null;
  private readonly _userSessionSubject = new BehaviorSubject<any>(this.signatureData);

  /**
   * Getting saved values from signature data (before, it was getting data from local storage)
   * @returns {Observable} with saved values
   */
  get(): Observable<any> {
    return this._userSessionSubject;
  }

  /**
   * Saving signature data to signatureData variable (before it was storing data in local storage)
   * @param {any} data signature data
   */
  save(data: any): any {
    const values = this.removeFalsy(data);
    if (values) {
      const value = this.signatureData ? JSON.parse(this.signatureData) : null;
      const result = Object.assign({}, value, values || {});
      this.signatureData = JSON.stringify(result);
      this._userSessionSubject.next(result);
      return this.signatureData;
    } else {
      this.destroy();
    }
  }

  /**
   * Clearing signature data(before. it was removing it from local storage)
   */
  destroy(): void {
    this.signatureData = null;
    this._userSessionSubject.next(this.signatureData);
  }

  /**
   * Remove falsy attributes from object
   * @param {any} obj - the object
   * @returns {any} object with removed falsy attributes or null if there are no non-falsy attributes
   */
  private removeFalsy(obj: any): any {
    const result: any = {};
    Object.keys(obj).forEach(prop => {
      if (obj[prop]) {
        result[prop] = obj[prop];
      }
    });
    return Object.keys(result).length ? result : null;
  }
}
