import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TargetGroupsGetTargetGroups } from '@services/target-group/target-group-service.type';
import { TTriggerEventGroups } from 'src/app/model/interfaces/event.interface';
import { IGroup, IGroupObject, GroupSmall } from 'src/app/model/interfaces/group.interface';
import { IGroupGet, GroupGetOne } from 'src/app/services/group/group-service.interface';
import { IActiveGroups } from 'src/app/services/signature/signature-service.interface';
import { MtSvgInfoComponent } from '@molecules/svg/info/mt-svg-info.component';
import { RouterLink } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf, NgClass, NgFor } from '@angular/common';

// Append all data-structures that use the 'GroupColComponent'
// this type is needed to correspond with the linter
type AllowedSelectColTypes =
  | IActiveGroups[]
  | IGroupGet[]
  | GroupGetOne[]
  | IGroupObject[]
  | GroupSmall[]
  | TargetGroupsGetTargetGroups[]
  | TTriggerEventGroups[]
  | Pick<IGroup, 'id' | 'title'>[];

@Component({
  selector: 'groupcol',
  templateUrl: './group-col.component.html',
  styleUrls: ['./group-col.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgbTooltip, MtSvgComponent, TranslocoModule, RouterLink, MtSvgInfoComponent, NgFor]
})
export class GroupColComponent implements OnInit {
  /**
   * Assigned groups
   * @defaultValue []
   */
  @Input() groups = [] as AllowedSelectColTypes;

  /**
   * Determines if specific styling is applied
   * @defaultValue false
   */
  @Input() isFirst = false;

  /**
   * Determines if group col component is used in group list component
   * @defaultValue false
   */
  @Input() isGroupCol = false;

  /**
   * Color used for the icon
   * @defaultValue 'mt-darkgrey'
   */
  @Input() iconColor = 'mt-darkgrey';

  /**
   * Determines if link to details page should be disabled
   * @defaultValue false
   */
  @Input() disableLinks = false;

  /**
   * Amount of users assigned to a group
   * @defaultValue 0
   */
  usersLength = 0;

  /**
   * Holds basic group information for internal display purposes
   * @defaultValue []
   */
  displayedGroups: IGroup[] = [];

  /**
   * Displays an icon and corresponding tooltip when there is an O365 connection
   * @defaultValue `{ hasO365Connection: false, isManuallyCreated: false }`
   */
  @Input() O365Group = { hasO365Connection: false, isManuallyCreated: false };

  /**
   * Triggers the group detail page redirection
   */
  @Output() redirectToGroupDetail = new EventEmitter<void>();

  ngOnInit(): void {
    // Set 'isManuallyCreated' to 'true' in the edge-case that this was not set in the db
    if (this.O365Group.hasO365Connection && this.O365Group.isManuallyCreated === undefined) {
      this.O365Group.isManuallyCreated = true;
    }

    if ((this.groups[0] as TTriggerEventGroups)?.Group) {
      for (const item of this.groups) {
        const group = item as TTriggerEventGroups;
        this.displayedGroups.push({
          createdAt: group.Group.createdAt,
          id: group.Group.id,
          isDefault: group.Group.isDefault,
          isManuallyCreated: true,
          owner: group.Group.owner,
          title: group.Group.title,
          updatedAt: group.Group.updatedAt
        });
      }
    } else {
      for (const item of this.groups) {
        const group = item as IGroupGet | IGroupObject | GroupSmall | IActiveGroups;
        this.displayedGroups.push({
          createdAt: group.createdAt,
          id: group.id,
          isDefault: group.isDefault,
          isManuallyCreated: group.isManuallyCreated,
          owner: group.owner,
          title: group.title,
          updatedAt: group.updatedAt
        });
      }

      if (this.hasUsers(this.groups[0])) {
        this.usersLength = Number(this.groups[0]?.Users?.length);
      } else {
        if (this.hasAmountOfMembers(this.groups[0])) {
          this.usersLength = Number(this.groups[0]?.amountOfMembers);
        }
      }
    }
  }

  /**
   * Determines if a given group has users assigned
   * @param variableToCheck - Group to check
   * @returns Whether the group has users or not
   */
  hasUsers(variableToCheck: unknown): variableToCheck is IGroupGet | GroupSmall {
    return (variableToCheck as IGroupGet | GroupSmall)?.Users !== undefined;
  }

  hasAmountOfMembers(variableToVerify: unknown): variableToVerify is IActiveGroups {
    return (variableToVerify as IActiveGroups)?.amountOfMembers !== undefined;
  }
}
