import { CockpitIntegrationTypes } from 'src/app/model/interfaces/integrations.interface';
import { IWizardTabComponent } from 'src/app/model/interfaces/wizard.interface';

const list: CockpitIntegrationTypes[] = [
  {
    headline: 'int_outlook_headline',
    image: 'assets/images/integrations/list/outlook.png',
    parts: [
      {
        checked: false,
        description: 'integration_outlook_1_2_description',
        integrationUrl: 'integration_outlook_1_1_integration_link',
        name: 'integration_outlook_1_1_name',
        title: 'outlook_mac_easysync_header_rec'
      },
      {
        checked: false,
        description: 'outlook_manual_subdescription',
        hasSignatureHTML: true,
        integrationUrl: 'integration_outlook_1_1_integration_link',
        title: 'outlook_mac_manual_header'
      },
      {
        checked: false,
        description: 'integration_outlook_1_2_description',
        integrationUrl: 'integration_outlook_1_2_integration_link',
        name: 'integration_outlook_1_2_name',
        title: 'outlook_windows_easysync_header_rec'
      },
      {
        checked: false,
        description: 'outlook_manual_subdescription',
        hasSignatureHTML: true,
        integrationUrl: 'integration_outlook_1_2_integration_link',
        title: 'outlook_windows_manual_header'
      },
      {
        checked: false,
        description: 'integration_outlook_1_3_description',
        hasSignatureHTML: true,
        integrationUrl: 'integration_outlook_1_3_integration_link',
        name: 'integration_outlook_1_3_name',
        title: 'integration_outlook_1_3_title'
      },
      {
        checked: false,
        description: 'integration_outlook_1_4_description',
        integrationUrl: 'integration_outlook_1_4_integration_link',
        name: 'integration_outlook_1_4_name',
        title: 'integration_outlook_1_4_title'
      },
      {
        checked: false,
        description: 'integration_outlook_1_5_description',
        integrationUrl: 'integration_outlook_1_5_integration_link',
        name: 'integration_outlook_1_5_name',
        title: 'integration_outlook_1_5_title'
      },
      {
        checked: false,
        description: 'outlook_add_in_integration_subline',
        integrationUrl: '/outlook/addin',
        title: 'outlook_addin_header_hl'
      }
    ],
    title: 'Microsoft_headline'
  },
  {
    headline: 'Gmail_headline',
    image: 'assets/images/integrations/list/logo-office-gsuite.png',
    parts: [
      {
        checked: false,
        description: 'integration_gmail_2_1_description',
        integrationUrl: '/chrome',
        name: 'integration_gmail_2_1_name',
        title: 'integration_gmail_2_1_title'
      },
      {
        checked: false,
        description: 'integration_gmail_2_2_description',
        hasSignatureHTML: true,
        integrationUrl: 'integration_gmail_2_2_integration_link',
        title: 'integration_gmail_2_2_title'
      },
      {
        checked: false,
        description: 'integration_gmail_2_3_description',
        integrationUrl: 'integration_gmail_2_3_integration_link',
        name: 'integration_gmail_2_3_name',
        title: 'integration_gmail_2_3_title'
      },
      {
        checked: false,
        description: 'integration_gmail_2_4_description',
        integrationUrl: 'integration_gmail_2_4_integration_link',
        name: 'integration_gmail_2_4_name',
        title: 'integration_gmail_2_4_title'
      }
    ],
    title: 'Google_headline'
  },
  {
    headline: 'Apple_headline',
    image: 'assets/images/integrations/list/applemail.png',
    parts: [
      {
        checked: false,
        description: 'integration_apple_mail_3_1_description',
        integrationUrl: 'integration_apple_mail_3_1_integration_link',
        name: 'integration_apple_mail_3_1_name',
        title: 'mail_easysync_header_rec'
      },
      {
        checked: false,
        description: 'mail_manual_subdescription',
        hasSignatureHTML: true,
        integrationUrl: 'integration_apple_mail_3_1_integration_link',
        title: 'mail_manual_header'
      },
      {
        checked: false,
        description: 'integration_apple_mail_3_2_description',
        integrationUrl: 'integration_apple_mail_3_2_integration_link',
        name: 'integration_apple_mail_3_2_name',
        title: 'integration_apple_mail_3_2_title'
      }
    ],
    title: 'Apple_headline'
  },
  {
    headline: 'Android_headline',
    image: 'assets/images/integrations/list/handy.png',
    parts: [
      {
        checked: false,
        description: 'integration_android_4_1_description',
        hasSignatureHTML: true,
        integrationUrl: 'integration_android_4_1_integration_link',
        name: 'integration_android_4_1_name',
        title: 'integration_android_4_1_title'
      }
    ],
    title: 'Android'
  },
  {
    headline: 'other_mail_clients',
    image: 'assets/images/integrations/list/mail.png',
    parts: [
      {
        checked: false,
        description: 'integration_other_5_1_description',
        hasSignatureHTML: true,
        integrationUrl: 'integration_other_5_1_integration_link',
        name: 'integration_other_5_1_name',
        title: 'integration_other_5_1_title'
      },
      {
        checked: false,
        description: 'integration_other_5_2_description',
        hasSignatureHTML: true,
        integrationUrl: 'integration_other_5_2_integration_link',
        title: 'integration_other_5_2_title'
      }
    ],
    title: 'other_mail_clients'
  }
];

const wizardSteps: IWizardTabComponent[] = [
  {
    isFirstTab: false,
    open: true,
    optional: false,
    stepNo: 1,
    tabTitle: 'integration_wizard_step2_title'
  },
  {
    open: true,
    optional: false,
    stepNo: 2,
    tabTitle: 'integratethrudirections'
  }
];

const setupCheckStep: IWizardTabComponent[] = [
  {
    isFirstTab: true,
    open: true,
    stepNo: 1,
    tabTitle: 'signature_setup_check'
  }
];

const setupCheckSubSteps: IWizardTabComponent[] = [
  {
    completedBtnText: 'close',
    formInputValid: false,
    isFirstTab: true,
    open: false,
    resolveBtn: 'resolve',
    stepNo: 1,
    tabTitle: 'signature_setup_check_group_member_step_hl'
  },
  {
    completedBtnText: 'close',
    formInputValid: false,
    isFirstTab: false,
    open: false,
    resolveBtn: 'resolve',
    stepNo: 2,
    tabTitle: 'signature_setup_check_rollout_status_step_hl'
  }
];

const setupCheckSubStepsForColleagues: IWizardTabComponent[] = [
  {
    completedBtnText: 'close',
    formInputValid: false,
    isFirstTab: true,
    open: false,
    resolveBtn: 'resolve',
    stepNo: 1,
    tabTitle: 'signature_setup_check_group_member_step_hl_2',
    tabThemeClass: 'detailpanel_coral'
  },
  {
    completedBtnText: 'close',
    formInputValid: false,
    isFirstTab: false,
    open: false,
    resolveBtn: 'resolve',
    stepNo: 2,
    tabTitle: 'signature_setup_check_rollout_status_step_hl_2',
    tabThemeClass: 'detailpanel_coral'
  }
];

export const INSTALLATION = {
  list: list,
  setupCheckStep,
  setupCheckSubSteps,
  setupCheckSubStepsForColleagues,
  steps: wizardSteps
};
