import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MtIconsComponent } from './mt-icons.component';

@NgModule({
    declarations: [MtIconsComponent],
    exports: [MtIconsComponent],
    imports: [CommonModule]
})
export class MtIconsModule { }
