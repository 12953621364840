// Modules
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';

// Interface
import { TranslationDictionary } from '@services/account/account-service.interface';

// Component
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { MtCheckboxComponent } from '@shared/components/atoms/inputs/checkbox/checkbox.component';

@Component({
  selector: 'mt-add-edit-dictionary',
  templateUrl: './add-edit-dictionary.component.html',
  styleUrl: './add-edit-dictionary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    CommonModule,
    CustomModalComponent,
    MtCheckboxComponent,
    NgbDropdownModule,
    ReactiveFormsModule,
    TranslocoModule
  ],
  providers: [NgbDropdown]
})
export class AddEditDictionaryComponent {
  formBuilder = inject(FormBuilder);
  modal = inject(NgbActiveModal);

  /**
   * Holds title of dialog
   */
  add_edit_dictionary_term_title = 'add_dictionary_data';

  /**
   * Dictionary data form builder
   */
  dictionaryDataForm = this.formBuilder.group({
    DE: ['', Validators.required],
    EN: ['', Validators.required],
    matchCase: [false, Validators.required],
    matchType: ['matchWholeTerm', Validators.required]
  });

  /**
   * Set dictionary data
   * @param dictionary - Set edit dictionary data to form or null if its create dictionary form
   */
  @Input() set dictionaryInfo(dictionary: TranslationDictionary) {
    if (dictionary) {
      this.add_edit_dictionary_term_title = 'edit_dictionary_data';
      this.dictionaryDataForm.setValue({
        DE: dictionary.DE,
        EN: dictionary.EN,
        matchCase: dictionary.matchCase || false,
        matchType: dictionary.matchType || 'matchWholeTerm'
      });
    }
  }
}
