<div class="signature-rollout-status-col" *ngIf="item">
  @if (
    (checkRolledOutAtLeastOnce && !!item.lastRollout) ||
    (item | signatureRolledoutStatus)
  ) {
    <div
      [ngClass]="{ 'selected-signature': isRowSelected }"
      class="schrift_gruen margin-auto text-bold title signature-rollout-status-main-div"
      *ngIf="
        (checkRolledOutAtLeastOnce && !!item.lastRollout) ||
          (item | signatureRolledoutStatus);
        else sigNotRolledOut
      "
    >
      <div class="innertitle inner-title-overflow">
        <span class="glyphicon glyphicon-ok" aria-hidden="true"></span>
        <span
          tooltipClass="status-info-tooltip"
          tooltipPlacement="top"
          ngbTooltip="{{ 'currentVersionOfSignatureWasRolledOut' | transloco }}"
        >
          <span
            data-cy="signature-rollout-status"
            class="signature-rollout-status-title"
            transloco="rolledout"
          ></span>
        </span>
      </div>
    </div>
  } @else {
    <div
      class="schrift_rot margin-auto text-bold title signature-rollout-status-main-div"
    >
      <div class="innertitle inner-title-overflow">
        <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
        <span
          data-cy="signature-rollout-click"
          tooltipClass="status-info-tooltip"
          tooltipPlacement="top"
          ngbTooltip="{{ 'list_signature_item_mo_1' | transloco }}"
          (click)="triggerRollOut.emit(); $event.stopPropagation()"
        >
          <span
            *ngIf="item.lastRollout; else notRolledOut"
            data-cy="signature-rollout-status"
            class="signature-rollout-status-title link"
            transloco="NewestVersionNotRolled"
          >
          </span>
          <ng-template #notRolledOut>
            <span
              data-cy="signature-rollout-status"
              class="signature-rollout-status-title link"
              transloco="Notrolled"
            >
            </span>
          </ng-template>
        </span>
      </div>
    </div>
  }

  <ng-template #sigNotRolledOut> </ng-template>

  <div *ngIf="!item.lastRollout" class="subline">
    <span transloco="LastReviewNever"></span>
  </div>

  <div *ngIf="item.lastRollout" class="subline">
    <span transloco="Recentlyat"></span>
    {{ item.lastRollout | date: 'dd.MM.yyyy' }}
  </div>
</div>
