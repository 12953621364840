<div>
  <div class="mt-flex-grid-row martop-5">
    <div class="title mt-blau martop5 marright5">
      <mt-svg
        class="medium-svg calicon"
        [ngClass]="iconColor"
        src="assets/images/svgs/targetgroup_list_svg.svg"
      ></mt-svg>
      <span
        tooltipClass="status-info-tooltip"
        placement="top"
        ngbTooltip="{{ item.title | transloco }}"
      >
        {{ item.title | textTruncate : 25 }}</span
      >
    </div>
    <div class="martop5 badges">
      <span
        class="status_badge hubspot"
        *ngIf="(item.source | lowercase) === 'hubspot'"
        tooltipClass="status-info-tooltip"
        placement="top"
        ngbTooltip="{{ 'hubspot_mo' | transloco }}"
      >
        <span transloco="hubspot"></span>
      </span>
      <span
        class="status_badge marketo"
        *ngIf="(item.source | lowercase) === 'marketo'"
        tooltipClass="status-info-tooltip"
        placement="top"
        ngbTooltip="{{ 'marketo_mo' | transloco }}"
      >
        <span transloco="marketo"></span>
      </span>
      <span
        class="status_badge pardot"
        *ngIf="(item.source | lowercase) === 'pardot'"
        tooltipClass="status-info-tooltip"
        placement="top"
        ngbTooltip="{{ 'pardot_mo' | transloco }}"
      >
        <span transloco="pardot"></span>
      </span>
    </div>
  </div>
  <div class="subline">
    <span
      transloco="{{
        item.type !== 'contact'
          ? 'target_group_accounts'
          : 'target_group_contacts'
      }}"
    ></span>
  </div>
</div>
