import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { AccountService } from '@services/account/account.service';
import { AlertService } from '@services/alert/alert.service';
import { ModalService } from '@services/modal/modal.service';
import { IWizardTabComponent } from '@model/interfaces/wizard.interface';
import { SignatureInstallProcessCollapse } from '@model/interfaces/signature.interface';
import { WizardTabComponent } from '@organisms/wizard/wizard-tab/wizard-tab.component';
import { BehaviorSubject } from 'rxjs';
import { UtilService } from '@services/util/util.service';

@Component({
  selector: 'mt-install-signature-for-colleagues',
  templateUrl: './install-signature-for-colleagues.component.html',
  styleUrls: ['./install-signature-for-colleagues.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, NgIf, TranslocoModule, WizardTabComponent]
})
export class InstallSignatureForColleaguesComponent {
  wizardSteps: IWizardTabComponent[] = [
    {
      open: true,
      optional: false,
      stepNo: 2,
      tabTitle: 'provide_colleagues_with_sig_hl'
    }
  ];

  /**
   * Activation code to open enduser cockpit
   * @DefaultValue ''
   */
  @Input() activationCode = '';

  /**
   * Employee id code to open enduser cockpit
   * @DefaultValue ''
   */
  @Input() employeeId = '';

  /**
   * Holds the externalAdmin role status
   * @DefaultValue false
   */
  @Input() isExternalAdmin = false;

  /**
   * Close the modal if there is no error checks
   */
  @Output() closeModal = new EventEmitter<void>();

  /**
   * Used for redirection of open employee list based setup
   * @DefaultValue false
   */
  private _checkSetup = false;

  /**
   * Change wizard step number based on value
   */
  @Input() set checkSetup(value: boolean) {
    this._checkSetup = value;
    if (value) {
      this.wizardSteps[0].stepNo = 2;
      this.wizardSteps[0].open = false;
    } else {
      this.wizardSteps[0].stepNo = 1;
      this.wizardSteps[0].open = true;
    }
  }

  get checkSetup(): boolean {
    return this._checkSetup;
  }

  /**
   * Checks the current opened section name
   * @param tab - Type of wizard
   */
  set isSectionOpen(tab: SignatureInstallProcessCollapse) {
    this._isSectionOpen$.next(tab);
  }
  private readonly _isSectionOpen$ = new BehaviorSubject<SignatureInstallProcessCollapse>('closeAll');
  isSectionOpen$ = this._isSectionOpen$.asObservable();

  constructor(
    private accountService: AccountService,
    private alert: AlertService,
    public modal: NgbActiveModal,
    private modalService: ModalService,
    public translate: TranslocoService,
    private utils: UtilService
  ) {}

  /**
   * To play the video on click of image
   * @param url - The translation tag of youtube link
   * @param title - The translation tag of the title
   */
  videoPlay(url: string, title: string): void {
    // Opens the modal and play the video within
    this.modalService.openPlayVideoModalV2(url, title).subscribe();
  }

  /**
   * To redirect to enduser cockpit
   */
  openEnduserCockpit(): void {
    if (this.isExternalAdmin) {
      void this.alert.defaultInfoMessage(
        this.translate.translate('alert_installsig_notaccess_to_external_admin') as string
      );
    } else if (!this.employeeId) {
      void this.alert.defaultInfoMessage(
        this.translate.translate('html.param.no_user_available_to_install_sig', {
          value: this.accountService.getUserAccountData().email
        }) as string,
        this.translate.translate('ups') as string
      );
    } else {
      const link = `/endusercockpit?&ac=${this.activationCode || ''}&eid=${this.employeeId}`;
      window.open(link, '_blank');
    }
  }

  /**
   * Method used to handle the click of 'Open list of employees' link
   */
  openListOfEmployees(): void {
    const target = this.checkSetup ? '_blank' : '_self';
    this.utils.openLink('/employees/list', target);
  }
}
