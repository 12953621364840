import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, switchMap, map, combineLatest, of, Subject, shareReplay } from 'rxjs';
import { SubscriptionIdentification, isFreeSubscription } from 'src/app/model/interfaces/payment.interface';
import {
  INavigationSidebarCounter,
  NavigationSidebarMenu
} from 'src/app/modules/root/model/navigation-sidebar-menu.interface';
import { environment } from 'src/environments/environment';
import { AccountService } from '../account/account.service';
import { AuthService } from '../auth/auth.service';
import { PaymentService } from '../payment/payment.service';
import { UserService } from '../user/user.service';
import { GetStartedGuideService } from '@services/get-started-guide/get-started-guide.service';

/**
 * Default value for cogsig environment
 */
const COGSIG_HAS_SUBSCRIPTION: SubscriptionIdentification = {
  forceAllow: false,
  hasSubscription: true,
  hasTestTime: false,
  isUserFromAngularTs: true,
  state: 'active',
  trialDaysLeft: 0
};

@Injectable({
  providedIn: 'root'
})
export class NavigationSidebarService {
  private accountService = inject(AccountService);
  private authService = inject(AuthService);
  private guideService = inject(GetStartedGuideService);
  private paymentService = inject(PaymentService);
  private route = inject(ActivatedRoute);
  private userService = inject(UserService);

  queryParam = this.route.snapshot.queryParamMap.get('type');

  /**
   * Triggers the integration option which user has selected
   */
  _integrationOption = new BehaviorSubject<string>(this.queryParam || 'all_integrations');

  integrationOption$ = this._integrationOption.asObservable();

  /**
   * Triggers the counter changes from user action like add employee, delete employee
   */
  private _submenuCount = new BehaviorSubject<INavigationSidebarCounter>({
    amount: 0,
    menu: 'employees'
  });

  submenuCount$ = this._submenuCount.asObservable();

  /**
   * Loads the my integration if active any
   */
  private _loadMyIntegrations = new BehaviorSubject<void>(undefined);
  loadMyIntegrations$ = this._loadMyIntegrations.asObservable();

  /**
   * Trigger event changes when menu lock and unlock by user
   */
  private _isMenuLocked$ = new BehaviorSubject<boolean>(false);
  isMenuLocked$ = this._isMenuLocked$.asObservable();

  /**
   * Trigger the sync problem list count on filter data
   */
  private _syncProblemListCount$ = new Subject<number>();
  syncProblemListCount$ = this._syncProblemListCount$.asObservable();

  /**
   * Refresh the sync problem list count based on filter data from the list
   * @param count - The count of sync problem list
   */
  set syncProblemCount(count: number) {
    this._syncProblemListCount$.next(count);
  }

  /**
   * Toogle menu lock or unlocked
   * @param isOpen - boolean value for toogle
   */
  setMenuLocked(isOpen: boolean): void {
    localStorage.setItem('submenuLocked', isOpen ? 'true' : 'false');
    this._isMenuLocked$.next(isOpen);
  }

  /**
   * Trigger event changes when menu opened by user
   */
  public _isSubMenuOpened$ = new BehaviorSubject<boolean>(false);
  isSubMenuOpened$ = this._isSubMenuOpened$.asObservable();

  /**
   * Changes from user action to open menu
   * @param isOpen - boolean value for toogle open / close menu
   */
  setSubMenuIsOpened(isOpen: boolean): void {
    localStorage.setItem('submenuOpened', isOpen ? 'true' : 'false');
    this._isSubMenuOpened$.next(isOpen);
  }

  /**
   * Trigger event changes when sub menu opened by user
   */
  public _isClickedSubMenu$ = new BehaviorSubject<string>('');
  isClickedSubMenu$ = this._isClickedSubMenu$.asObservable();

  /**
   * Observable is used to determine weather recipients leads menu will be show or not
   */
  private _isRecipientsLeads$ = new BehaviorSubject(0);
  isRecipientsLeads$ = this._isRecipientsLeads$.asObservable().pipe(
    switchMap(() => this.userService.getRecipientTracking()),
    shareReplay(1)
  );

  /**
   * Refresh recipients leads status on user change tracking
   */
  refreshRecipientTracking(): void {
    this._isRecipientsLeads$.next(1);
  }

  /**
   * Observable is used to get user has subscription
   */
  private _hasSubscription$ = new BehaviorSubject(0);
  hasSubscription$ = this._hasSubscription$.asObservable().pipe(
    switchMap(() => this.authService.authorizationScope$),
    switchMap(authorizationScope =>
      combineLatest([of(authorizationScope), this.accountService.getCustomerSubscriptionData()])
    ),
    map(([authorizationScope, subscriptionData]) => {
      const userStatus = environment.isCogSig
        ? COGSIG_HAS_SUBSCRIPTION
        : this.paymentService.getUserStatus(authorizationScope, subscriptionData);

      return {
        hasSubscription: environment.isCogSig
          ? userStatus.hasSubscription
          : !isFreeSubscription(subscriptionData) && !['expired', 'canceled'].includes(subscriptionData.state),
        hasTestTime: userStatus.hasTestTime,
        state: userStatus.state,
        isUserFromAngularTs: userStatus.isUserFromAngularTs,
        trialDaysLeft: userStatus.trialDaysLeft,
        forceAllow: userStatus.forceAllow,
        topbarExist: true // TODO: Apply the logic of topbar should be displayed or not
      };
    })
  );

  /**
   * Observable is used to get status of hide guide menu from sidebar
   */
  private _isHiddenGuideMenu$ = new BehaviorSubject({ status: false });
  isHiddenGuideMenu$ = this._isHiddenGuideMenu$.asObservable().pipe(
    switchMap(() => this.guideService.getHideGuideMenuConfig()),
    map(status => ({ status }))
  );

  /**
   * Update the hide guide menu status
   * @param status - The status of hide guide menu
   */
  set isHiddenGuideMenu(status: boolean) {
    this._isHiddenGuideMenu$.next({ status });
  }

  /**
   * Update the menu locked status
   * @param isLock - The status of menu locked
   */
  set isMenuLocked(isLock: boolean) {
    this._isMenuLocked$.next(isLock);
  }

  /**
   * Hold account basic information
   */
  account$ = this.accountService.getUserAccountData();

  /**
   * Closes other dropdowns
   * @param menuItems - Menu items of sidebar
   * @param name - Active dropdown name
   */
  closeOtherDropDown(menuItems: NavigationSidebarMenu[], name: string): void {
    menuItems.map(item => {
      if (item.name !== name) {
        item.isDropdownOpen = false;
      }
    });
  }

  /**
   * Gets the changes from user action to sidebar submenu counts
   * @param returns - The observable for object of counter
   */
  getSidebarSubmenuCount(): Observable<INavigationSidebarCounter> {
    return this.submenuCount$;
  }

  /**
   * Changes from user action to sidebar submenu counts
   * @param amount - The count of submenu
   * @param menu - The name of submenu
   */
  updateSidebarSubmenuCounter(amount: number, menu: string): void {
    this._submenuCount.next({ amount, menu });
  }

  /**
   * Loads my integrations to if any (To display the My Integrations submenu)
   */
  loadMyIntegrations(): void {
    this._loadMyIntegrations.next();
  }

  /**
   * Refresh user has subscription after subscription
   */
  refreshUserHasSubscription(): void {
    this._hasSubscription$.next(1);
  }

  /**
   * Logout from the application
   */
  logout(): void {
    this.userService.logout().subscribe();
  }

  /**
   * Given an array of menu items and a menu name, updates the `isDropdownOpen` property
   * of each menu based on whether it's title matches the provided name
   * @param menuItems - Menu items to update
   * @param menuToUpdate - Menu name to match
   * @returns New array with updated `isDropdownOpen` properties
   */
  updateDropdownState(menuItems: NavigationSidebarMenu[], menuToUpdate: string): NavigationSidebarMenu[] {
    return menuItems.map(menu => ({
      ...menu,
      isDropdownOpen: menu.name === menuToUpdate && !menu.isDropdownOpen
    }));
  }

  /**
   * Update hide status of guide menu in sidebar
   * @param status - The status of hide guide menu
   */
  updateHideGuideMenu(status: boolean): void {
    this.guideService.updateHideGuideMenuConfig(status).subscribe(() => {
      this.isHiddenGuideMenu = status;
    });
  }
}
