<div class="baseonecolumn">
  <mt-custom-modal headline="{{ 'installsignature' | transloco }}">
    <div modal-body>
      <div class="scroll-div" *ngIf="loadData$ | async as data; else loading">
        <div class="signature-installation-modal">
          <div
            *ngIf="
              !data.isUserLinkedWithGroup || data.signature.lastRollout === null
            "
          >
            <mt-signature-setup-check
              [isUserLinkedWithGroup]="data.isUserLinkedWithGroup"
              [activeGroups]="data.signature.activeGroups"
              [isSignatureRollOut]="!!data.signature.lastRollout"
              [signatureType]="data.signature.signatureType || 'mailtastic'"
              [signatureId]="inputData.signatureId"
              (closeModal)="modal.close()"
            ></mt-signature-setup-check>
          </div>

          <mt-installation-checklist-with-selected-list
            [checkSetup]="
              !data.isUserLinkedWithGroup || data.signature.lastRollout === null
            "
            [activationCode]="data.userData.activationCode || ''"
            [adminData]="data.integrationData.adminData"
            [signatures]="data.integrationData.signatures"
            [user]="data.userData"
          ></mt-installation-checklist-with-selected-list>
        </div>
      </div>
      <ng-template #loading>
        <div class="card martop20">
          <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
      ></ng-template>
    </div>
  </mt-custom-modal>
</div>
