<mt-wizard-tab
  [(parent)]="wizardSteps[0]"
  class="step2 detailpanel_blue"
  (completed)="wizardSteps[0].stepNo === 1 ? closeModal.emit() : ''"
>
  <div class="container">
    <div class="row">
      <div class="col col-xs-6">
        <div
          [innerHTML]="'html.provide_colleagues_with_sig_txt' | transloco"
        ></div>
        <div
          transloco="provide_colleagues_with_sig_txt2"
          class="signature-head"
        ></div>
        <ng-container *ngIf="isSectionOpen$ | async as tabOption">
          <div class="card signature-card">
            <div
              class="card-header signature-header"
              (click)="
                isSectionOpen =
                  tabOption === 'shareSignatureWithFew'
                    ? 'closeAll'
                    : 'shareSignatureWithFew'
              "
            >
              <div
                class="signature-header-text"
                transloco="provide_colleagues_with_sig_module_1_hl"
              ></div>
            </div>

            <div
              class="card-body signature-card-body"
              *ngIf="tabOption === 'shareSignatureWithFew'"
            >
              <div
                [innerHtml]="
                  'html.provide_colleagues_with_sig_module_1_text' | transloco
                "
              ></div>
              <span
                (click)="openEnduserCockpit()"
                transloco="Here"
                class="marright5 text-underline-imp text-bold cursor-pointer"
              ></span>
              <span
                [innerHtml]="
                  'html.provide_colleagues_with_sig_module_1_text1' | transloco
                "
              ></span>
              <span>
                <a
                  class="text-underline-imp open-list-of-employees-link"
                  transloco="open_list_of_employees"
                  (click)="openListOfEmployees()"
                >
                </a>
              </span>
              <div></div>
            </div>
          </div>
          <div class="card signature-card">
            <div
              class="card-header signature-header"
              (click)="
                isSectionOpen =
                  tabOption === 'rolloutToMany' ? 'closeAll' : 'rolloutToMany'
              "
            >
              <div
                class="signature-header-text"
                transloco="provide_colleagues_with_sig_module_2_hl"
              ></div>
            </div>

            <div
              class="card-body signature-card-body"
              *ngIf="tabOption === 'rolloutToMany'"
            >
              <div
                [innerHTML]="
                  'html.provide_colleagues_with_sig_module_2_text' | transloco
                "
              ></div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="col col-xs-6">
        <div
          (click)="
            videoPlay(
              'installsignature_colleagues_video',
              'installsignature_colleagues_hl'
            )
          "
          class="cursor-pointer"
        >
          <img
            src="assets/images/signatures/install-signature-modal/thumbnails_installsignature_{{
              translate.getActiveLang()
            }}.png"
          />
        </div>
      </div>
    </div>
  </div>
</mt-wizard-tab>
