<mt-custom-modal
  class="integration integration-guide-modal font25"
  headline="{{ 'yoursignatures' | transloco }}"
  theme="video-theme"
>
  <div modal-body>
    <div class="flex-center-right padtop20">
      <div class="content manual">
        <div class="flex srcoll-div">
          <div class="flex manualscontainer">
            <div class="content signature-installation-guide">
              <div class="detailpanel memberlist">
                <div
                  class="content guide-signature-list"
                  *ngIf="list.length > 0"
                >
                  <mt-list>
                    <mt-row
                      *ngFor="let item of list; odd as isOdd; even as isEvent"
                    >
                      <li
                        class="listrow lists"
                        [ngClass]="{
                          even: isEvent,
                          odd: isOdd
                        }"
                      >
                        <div class="tcell" style="width: 30%">
                          <signaturecol
                            [isFirst]="true"
                            [item]="item"
                            (click)="modal.close()"
                          ></signaturecol>
                        </div>
                        <div class="tcell" style="width: 30%">
                          <signaturegroupcol
                            [groups]="item.activegroups"
                            (redirectToSignatureEditor)="openAddGroupStep(item)"
                            (redirectToGroupDetail)="modal.close()"
                          ></signaturegroupcol>
                        </div>
                        <div class="tcell" style="width: 15%">
                          <signature-rollout-status-col
                            [item]="item"
                            [checkRolledOutAtLeastOnce]="true"
                            (triggerRollOut)="rolloutSignatures(item)"
                          ></signature-rollout-status-col>
                        </div>
                        <div class="tcell" style="width: 16%">
                          <div
                            class="btn-group margin-auto option-button"
                            ngbDropdown
                            container="body"
                            (click)="$event.stopPropagation()"
                          >
                            <mt-button
                              *ngIf="!!item.lastRollout"
                              icon="ok"
                              color="success"
                              text="rolledoutnotification"
                              class="ddblau"
                              [disabled]="true"
                            ></mt-button>

                            <mt-button
                              data-cy="rollout-sig-modal-btn"
                              *ngIf="!!!item.lastRollout"
                              text="rollsignature"
                              color="primary"
                              (click)="rolloutSignatures(item)"
                            ></mt-button>
                          </div>
                        </div>
                      </li>
                    </mt-row>
                  </mt-list>
                </div>
                <!-- Empty State/Loading -->
                <mt-emptystate
                  [showEmpty]="list.length === 0"
                  class="nodataarea"
                  hltitle="NoSignatureavailable"
                  subtitle="CreateobtainaSummaryhere"
                  svg="signature"
                ></mt-emptystate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mt-custom-modal>
