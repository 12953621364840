<!-- Modal Target Group -->
<mt-custom-modal
  [headline]="
    targetGroupListId
      ? ('move_domain_to_targetgroup' | transloco)
      : ('selecht_targetgroup' | transloco)
  "
>
  <div
    class="leftform"
    modal-body
    *ngIf="loadTargetGroup$ | async; else loading"
  >
    <div class="content">
      <mt-list-actions>
        <ng-container right-side>
          <mt-search
            *ngIf="list.all"
            class="leftbutton"
            [disableEnter]="true"
            (search)="domainDetailsService.setTargetGroupsFilterText($event)"
          ></mt-search>

          <mt-sort-dropdown *ngIf="list.all.length">
            <li
              ngbDropdownItem
              transloco="sort_titel"
              (click)="list.sortList('title')"
            ></li>
            <li
              ngbDropdownItem
              transloco="sort_domains"
              (click)="list.sortList('Entries')"
            ></li>
            <li
              ngbDropdownItem
              transloco="sort_campaigns"
              (click)="list.sortList('CampaignTitle')"
            ></li>
          </mt-sort-dropdown>
        </ng-container>
      </mt-list-actions>
      <mt-list>
        <mt-row
          *ngFor="let item of list.all; odd as isOdd; even as isEven"
          [isEven]="isEven"
          [isOdd]="isOdd"
          [isSelected]="list.isSelected(item)"
          (click)="list.updateSelection(item)"
        >
          <div class="tcell first select">
            <selectcol [list]="list" [item]="item"></selectcol>
          </div>

          <div class="tcell w-20">
            <targetgroupscol
              [targetGroups]="[item]"
              iconColor="mt-blau"
              [isFirst]="true"
            ></targetgroupscol>
          </div>

          <div class="tcell second">
            <targetgroupentrynumbercol
              [item]="item"
              [disableLinks]="true"
            ></targetgroupentrynumbercol>
          </div>
          <div class="tcell w-25">
            <ng-container *ngIf="item.Event; else activeCampaign">
              <eventscampaignstatuscol
                [triggerEvent]="item.Event"
                (redirectToCampaignDetail)="modal.close()"
              ></eventscampaignstatuscol>
            </ng-container>

            <ng-template #activeCampaign>
              <activecampaigncol
                [activeCampaign]="item?.Campaign"
                (redirectToCampaignDetail)="modal.close()"
              ></activecampaigncol>
            </ng-template>
          </div>
        </mt-row>
      </mt-list>

      <!-- Empty state -->
      <mt-emptystate
        [loadingPromise]="isLoading"
        [showEmpty]="list.all.length === 0"
        hltitle="html.emptystate_targetgroup_3_sl"
        subtitle="html.emptystate_targetgroup_3_sl"
        svg="contact"
      ></mt-emptystate>
    </div>
  </div>

  <!-- LOADING -->
  <ng-template #loading>
    <div class="card martop20">
      <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
  ></ng-template>

  <!-- Modal Footer -->
  <ng-container modal-footer>
    <div class="inline-flex-left" *ngIf="!targetGroupListId">
      <span class="margin-right" *ngIf="list.selected.length">
        {{ 'param.target_groups_selected' | transloco : { amount:
        list.selected.length } }}
      </span>
    </div>
    <div class="flex-center-right">
      <mt-button
        (click)="modal.close()"
        class="mt_left"
        color="default"
        text="Cancel"
      ></mt-button>
      <mt-button
        (click)="onSelectTargetGroups()"
        [icon]="!targetGroupListId ? 'plus' : ''"
        color="primary"
        [text]="
          targetGroupListId
            ? 'move_domain_to_selected_targetgroup'
            : 'add_target_group'
        "
        [disabled]="!list.selected.length"
      ></mt-button>
    </div>
  </ng-container>
</mt-custom-modal>
