<div
  class="modal-body changeproductmodal"
  *transloco="let translate"
  data-cy="change-product-modal"
>
  <div class="cursor-pointer close opacity05" (click)="modal.close()">
    <mt-svg
      class="x-button"
      src="assets/images/modals/close_other_svg.svg"
    ></mt-svg>
  </div>
  <div class="headline">{{ translate('ChooseYourPlan') }}</div>

  <!-- Sticky Headers -->
  <ng-container *ngIf="stickyHeader$ | async as stickyHeader">
    <div
      [ngClass]="{ inlineflex: stickyHeader }"
      class="flex roboto_bold text-center sticky-section booking-sticky-header"
    >
      <div class="tint-light pad25" *ngIf="currentProduct === 'Basic'">
        <div class="roboto_bold padtop25">
          {{ translate('plan_short_sig_management') }}
        </div>

        <div class="martop10">
          <div class="current-product">
            {{ translate('your_current_plan') }}
          </div>
        </div>
      </div>
      <div class="tint-dark pad25" *ngIf="currentProduct !== 'Business'">
        <div class="roboto_bold padtop25">
          {{ translate('plan_short_sig_marketing') }}
        </div>

        <div *ngIf="currentProduct === 'Advanced'" class="martop10">
          <div class="current-product">
            {{ translate('your_current_plan') }}
          </div>
        </div>
        <div *ngIf="!currentProduct" class="height25"></div>
      </div>
      <div class="tint-light pad25">
        <div class="roboto_bold padtop25">
          {{ translate('plan_short_sig_marketing_pro') }}
        </div>

        <div *ngIf="currentProduct === 'Target'" class="martop10">
          <div class="current-product">
            {{ translate('your_current_plan') }}
          </div>
        </div>
        <div *ngIf="!currentProduct" class="height25"></div>
      </div>
    </div>
  </ng-container>

  <!-- Content -->
  <div class="marright40 marleft40 martop20">
    <!-- Header -->
    <div class="products products-header">
      <div class="flex justifycontentcenter">
        <!-- Basic -->
        <div
          *ngIf="currentProduct === 'Basic'"
          class="product-container tint-light pc-border-top martop20"
        >
          <mt-plan-basic-detail
            [priceData]="priceData"
            [maxUsers]="config.maxUsers"
            [billingInterval]="config.billingInterval"
            [currentProduct]="currentProduct"
            (setProduct)="upgradeToPlan($event)"
          ></mt-plan-basic-detail>
        </div>

        <!-- Advanced -->
        <div
          class="product-container tint-dark pc-border-top padtop55"
          *ngIf="currentProduct !== 'Business'"
        >
          <mt-plan-advance-detail
            [priceData]="priceData"
            [maxUsers]="config.maxUsers"
            [billingInterval]="config.billingInterval"
            [currentProduct]="currentProduct"
            [currentAmountOfSystemUsers]="statData.amountOfUsers"
            (setProduct)="upgradeToPlan($event)"
          ></mt-plan-advance-detail>
        </div>

        <!-- Target -->
        <div class="product-container tint-light pc-border-top martop20">
          <mt-plan-target-detail
            [priceData]="priceData"
            [maxUsers]="config.maxUsers"
            [billingInterval]="config.billingInterval"
            [currentProduct]="currentProduct"
            [currentAmountOfSystemUsers]="statData.amountOfUsers"
            (setProduct)="upgradeToPlan($event)"
          ></mt-plan-target-detail>
        </div>
      </div>
    </div>

    <!-- Signature Management -->
    <mt-feature-signature-management
      [bookingContainer]="bookingContainer"
      [statData]="statData"
      [currentProduct]="currentProduct"
    ></mt-feature-signature-management>

    <!-- Signature Marketing -->
    <mt-feature-signature-marketing
      [bookingContainer]="bookingContainer"
      [statData]="statData"
      [currentProduct]="currentProduct"
    ></mt-feature-signature-marketing>

    <!-- General -->
    <mt-feature-general
      [bookingContainer]="bookingContainer"
      [statData]="statData"
      [currentProduct]="currentProduct"
    ></mt-feature-general>
  </div>

  <!-- Footer -->
  <div class="padtop60 padbottom50">
    <div class="text-center roboto_bold">
      {{ translate('YouCanChangeYourPlanAnytime') }}
    </div>
    <div class="text-center info-price-mwst2">
      <span>*</span>
      <span>{{ translate('infoPriceMwst2') }}</span>
    </div>
    <div class="text-center not-sure-about-plan">
      {{ translate('not_sure_about_plan') }}
    </div>
  </div>
</div>
