import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { UploadedFileDataObject, MappedFileData } from 'src/app/model/interfaces/data-mapping.interface';
import {
  StaticFieldsData,
  O365MappingFieldsData
} from 'src/app/modules/o365/model/interfaces/o365-preferences.interface';

@Injectable({
  providedIn: 'root'
})
export class DataMappingService {
  /**
   * Holds the uploaded file data id in import user
   */
  private _uploadedFileData$ = new ReplaySubject<UploadedFileDataObject>(1);
  uploadedFileData$ = this._uploadedFileData$.asObservable();

  /**
   * Holds the uploaded file mapped data id in import user
   */
  private _mappedFileData$ = new ReplaySubject<MappedFileData>(1);
  mappedFileData$ = this._mappedFileData$.asObservable();

  /**
   * Static Fields to map for adjust mapping on left side
   */
  private _staticFields$ = new ReplaySubject<StaticFieldsData[]>(1);
  staticFields$ = this._staticFields$.asObservable();

  /**
   * Holds the 0365 mapped data fields
   */
  private _mappedFieldsData$ = new ReplaySubject<O365MappingFieldsData[]>(1);
  mappedFieldsData$ = this._mappedFieldsData$.asObservable();

  /**
   * Update static fields on left side
   * @param o365Fields - Array of objects
   */
  updateStaticFields(o365Fields: StaticFieldsData[]): void {
    this._staticFields$.next(o365Fields);
  }

  /**
   * Updates uploaded file data
   * @param data - The details of file including `headers`, `fileData` and `fileName`
   */
  updateUploadedFileData(data: UploadedFileDataObject): void {
    this._uploadedFileData$.next(data);
  }

  /**
   * Updates uploaded file data
   * @param data - The details of file mapping data including `fileData` and `mappedData`
   */
  updateUploadedFileMappedData(data: MappedFileData): void {
    this._mappedFileData$.next(data);
  }

  /**
   * Update O365 fields mapping data
   * @param data - Detailds of array related to mapped fields
   */
  updateO365MappingFieldsData(data: O365MappingFieldsData[]): void {
    this._mappedFieldsData$.next(data);
  }
}
