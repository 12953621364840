import { Directive } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors, Validator, NG_VALIDATORS } from '@angular/forms';

export const noSpecialCharsValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }
  const nameRegexp = /[!@#$%^&*()+=[\]{};':"\\|,.<>/?]/;

  return nameRegexp.test(control.value) ? { specialChar: true } : null;
};

@Directive({
  providers: [{ provide: NG_VALIDATORS, useExisting: NoSpecialCharsValidatorDirective, multi: true }],
  selector: '[mtNoSpecialCharsValidator]'
})
export class NoSpecialCharsValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return noSpecialCharsValidator(control);
  }
}
