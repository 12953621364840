import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, Optional } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { mtIcons } from '../../assets/mailtastic-icons';

import { MtIconsRegistry } from './mt-icons-registry.service';
import { SVG_CONFIG } from './types';

@Component({
    selector: 'mt-icons',
    template: `
        <ng-content></ng-content>
    `,
    styles: [
        `
        :host {
            display: inline-block;
            fill: currentColor;
            width: 1em;
            height: 1em;
            font-size: 1rem;
        }
    `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MtIconsComponent {
    private svgIcon: SVGElement | undefined;
    private mergedConfig: SVG_CONFIG;

    @Input()
    set name(iconName: mtIcons) {
        if (this.svgIcon) {
            this.element.removeChild(this.svgIcon);
        }
        const svgData = this.mtIconsRegistry.getIcon(iconName);
        if (svgData) {
            this.svgIcon = this.svgElementFromString(svgData);
            this.element.appendChild(this.svgIcon);
        }
    }

    @Input()
    set size(value: keyof SVG_CONFIG['sizes']) {
        this.element.style.fontSize = this.mergedConfig.sizes[value];
    }

    @Input() set width(value: number | string) {
        this.element.style.width = this.coerceCssPixelValue(value);
    }

    @Input() set height(value: number | string) {
        this.element.style.height = this.coerceCssPixelValue(value);
    }

    @Input()
    set fontSize(value: number | string) {
        this.element.style.fontSize = this.coerceCssPixelValue(value);
    }

    @Input()
    set color(color: string) {
        this.element.style.fill = color;
    }

    constructor(private host: ElementRef, private mtIconsRegistry: MtIconsRegistry,
        @Optional() @Inject(DOCUMENT) private document: any) {
        this.mergedConfig = this.createConfig();
    }

    get element() {
        return this.host.nativeElement;
    }

    private svgElementFromString(svgContent: string): SVGElement {
        const div = this.document.createElement('DIV');
        div.innerHTML = svgContent;
        return div.querySelector('svg') || this.document.createElementNS('http://www.w3.org/2000/svg', 'path');
    }

    private coerceCssPixelValue(value: any): string {
        if (value == null) {
            return '';
        }

        return typeof value === 'string' ? value : `${value}px`;
    }

    private createConfig() {
        const defaults: SVG_CONFIG = {
            sizes: {
                xs: '0.5rem',
                sm: '0.75rem',
                md: '1rem',
                lg: '1.5rem',
                xl: '2rem',
                xxl: '2.5rem',
            },
        };

        return {
            ...defaults,
        };
    }
}
