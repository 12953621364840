import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, filter, from, map } from 'rxjs';
import {
  CompanyDataImage,
  ISignaturePlaceholderImg,
  ImageDimension,
  ImageDimensionWithMode,
  TImageDimensionDataMode
} from 'src/app/model/interfaces/signature.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { UtilService } from 'src/app/services/util/util.service';
import { TranslocoModule } from '@ngneat/transloco';
import { IconButtonComponent } from '@molecules/buttons/icon-button/icon-button.component';
import { UploadImageButtonComponent } from '@molecules/buttons/upload-image-button/upload-image-button.component';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { CustomModalComponent } from '@molecules/modals/custom/custom-modal.component';
import { NgIf, NgStyle, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { SignatureHelperService } from '@services/signature-helper/signature-helper.service';

const DEFAULT_IMAGE: CompanyDataImage = {
  altText: 'u_logo',
  etag: null,
  image: 'https://app.mailtastic.de/api/images/default/Home.png',
  initialdimension: {
    height: 50,
    width: 50
  },
  linkText: '',
  showAs: 'image',
  source: 'uploaded',
  type: 'image',
  url: '#',
  useForAllProfiles: false,
  whichImage: 'own'
};

type CompanyDataImageWithDimension = CompanyDataImage & { imgdimension: ImageDimensionWithMode };

@Component({
  selector: 'mt-upload-company-logo',
  templateUrl: './upload-company-logo.component.html',
  styleUrls: ['./upload-company-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    FormsModule,
    IconButtonComponent,
    MtSvgComponent,
    NgIf,
    NgStyle,
    ReactiveFormsModule,
    TranslocoModule,
    UploadImageButtonComponent
  ]
})
export class UploadCompanyLogoComponent {
  private alert = inject(AlertService);
  private modalService = inject(ModalService);
  private employeeService = inject(EmployeeService);
  private signatureHelper = inject(SignatureHelperService);
  private utilService = inject(UtilService);
  modal = inject(NgbActiveModal);

  @Input() set companyLogo(logo: CompanyDataImageWithDimension | undefined) {
    // When deleting the logo the backend can return an object with the default image
    // but with the property whichImage as 'own' so we fix that here and apply the correct string 'default'
    if (logo) {
      if (logo.image === DEFAULT_IMAGE.image && logo.whichImage !== DEFAULT_IMAGE.whichImage) {
        logo.whichImage = 'own';
      }

      // this equates to `whichImage = 'own'`
      if (logo.image !== DEFAULT_IMAGE.image) {
        this.initImageDimensionForm('own', logo.imgdimension, logo?.initialdimension);
      }

      // Special case handled by the backend, no need to patch the form with this value
      if (logo.url === '#' || this.utilService.removeHttpHttpsWww(logo.url) === '#') {
        logo.url = '';
      }

      this.advancedSettingsForm.controls.photoUrl.patchValue(this.utilService.removeHttpHttpsWww(logo.url));

      this.initialPhoto$.next({ ...logo, imgdimension: this.evalutateImageDimensionForm() });
      this.currentPhoto$.next({ ...logo, imgdimension: this.evalutateImageDimensionForm() });
    }
  }

  /**
   * Determines if the custom dimension options should be visible
   *
   * @remarks Should only be visible in the signature editor pages
   *
   * @defaultValue false
   */
  @Input() showCustomDimension = false;

  // Current photo being displayed
  private currentPhoto$ = new BehaviorSubject<CompanyDataImageWithDimension>({
    ...DEFAULT_IMAGE,
    imgdimension: {
      height: DEFAULT_IMAGE?.initialdimension?.height || 50,
      mode: 'default',
      width: DEFAULT_IMAGE?.initialdimension?.width || 50
    } as ImageDimensionWithMode
  });

  // Original photo, used when cancelling the modal
  private initialPhoto$ = new BehaviorSubject<CompanyDataImageWithDimension>({
    ...DEFAULT_IMAGE,
    imgdimension: {
      height: DEFAULT_IMAGE?.initialdimension?.height || 50,
      mode: 'default',
      width: DEFAULT_IMAGE?.initialdimension?.width || 50
    } as ImageDimensionWithMode
  });

  // Has a new photo been uploaded by the user?
  private isNewPhoto$ = new BehaviorSubject(false);

  photoAndStatus$ = combineLatest([
    this.currentPhoto$.asObservable(),
    this.initialPhoto$.asObservable(),
    this.isNewPhoto$.asObservable()
  ]).pipe(map(([currentPhoto, initialPhoto, isNewPhoto]) => ({ currentPhoto, initialPhoto, isNewPhoto })));

  advancedSettingsForm = new FormGroup({
    photoUrl: new FormControl('', { nonNullable: true }),
    whichImage: new FormControl('default', { nonNullable: true }),
    imageDimensionMode: new FormControl('default', { nonNullable: true }),
    imageDimensionHeight: new FormControl<number>(50, { nonNullable: true }),
    imageDimensionWidth: new FormControl<number>(50, { nonNullable: true })
  });

  /**
   * Displays the image cropping modal to the user.
   *
   * Sets the newly uploaded photo as displayed.
   * Marks the photo as new.
   * Resets the url formcontrol.
   *
   * @param image - Image uploaded by the user
   */
  processImage(image: HTMLImageElement): void {
    from(this.modalService.openCropImageModal(image)).subscribe(logo => {
      const imgdimension = {
        height: logo?.imgdimension?.height ?? image.height,
        mode: 'default' as TImageDimensionDataMode,
        width: logo?.imgdimension?.width ?? image.width
      } as ImageDimensionWithMode;
      this.currentPhoto$.next({
        ...this.currentPhoto$.value,
        image: logo.value.image.$ngfDataUrl,
        imgdimension,
        isCropped: logo.value.isCropped,
        cropType: logo.value.cropType,
        whichImage: 'own'
      });
      this.isNewPhoto$.next(true);
      this.advancedSettingsForm.reset();
      this.initImageDimensionForm('own', imgdimension);
    });
  }

  /**
   * Closes the modal and saves the logo/changes
   * @param companyLogo - Company logo to save
   * @param isNewPhoto - Whether this is a new logo that needs to be saved first
   */
  saveLogo(companyLogo: CompanyDataImageWithDimension, isNewPhoto: boolean): void {
    const logoUrl = this.utilService.checkAndAddHttps(this.advancedSettingsForm.controls.photoUrl.value);

    const width = companyLogo?.imgdimension?.width
      ? companyLogo.imgdimension.width
      : this.advancedSettingsForm.controls.imageDimensionWidth.value;
    const height = companyLogo?.imgdimension?.height
      ? companyLogo.imgdimension.height
      : this.advancedSettingsForm.controls.imageDimensionHeight.value;

    const scaledDimension = this.utilService.scaleImage(
      width,
      height,
      this.advancedSettingsForm.controls.imageDimensionWidth.value,
      this.advancedSettingsForm.controls.imageDimensionMode.value as TImageDimensionDataMode
    );
    if (!isNewPhoto) {
      companyLogo.url = logoUrl;
      const existingLogo = this.signatureHelper.createImageField(
        { imgdimension: this.evalutateImageDimensionForm(scaledDimension) },
        { ...companyLogo }
      );
      this.modal.close({ ...existingLogo });
    } else {
      this.employeeService.getUploadedImageData(this.logoToImgDTO(companyLogo)).subscribe(image => {
        // Always string when uploading a photo
        if (typeof image.image === 'string') {
          const newLogo = this.signatureHelper.createImageField(
            { imgdimension: this.evalutateImageDimensionForm(scaledDimension) },
            {
              ...image,
              initialdimension: {
                height: this.advancedSettingsForm.controls.imageDimensionHeight.value,
                width: this.advancedSettingsForm.controls.imageDimensionWidth.value
              },
              source: 'uploaded',
              whichImage: 'own'
            }
          );
          this.modal.close({ ...newLogo });
        }
      });
    }
  }

  /**
   * Prompts the user to delete the currently displayed logo
   */
  deleteLogo(): void {
    from(this.alert.defaultConfirmPrompt('sure_you_want_to_delete'))
      .pipe(filter(accepted => accepted))
      .subscribe(() => {
        this.currentPhoto$.next({
          ...DEFAULT_IMAGE,
          imgdimension: {
            height: DEFAULT_IMAGE?.initialdimension?.height || 50,
            mode: 'default',
            width: DEFAULT_IMAGE?.initialdimension?.width || 50
          } as ImageDimensionWithMode
        });
        this.isNewPhoto$.next(false);
        this.advancedSettingsForm.reset();
        this.initImageDimensionForm('default');
      });
  }

  /**
   * Initializes the image dimensions portion of the advancedSettingsForm.
   * @param whichImage - which image is it
   * @param dimensions - the dimensions to use
   * @param initialDimensions - the initial dimensions to use
   */
  private initImageDimensionForm(
    whichImage: 'default' | 'own',
    dimensions?: ImageDimensionWithMode,
    initialDimensions?: ImageDimension
  ): void {
    // fallback dimensions
    const defImageHeight = DEFAULT_IMAGE?.initialdimension?.height ?? 50;
    const defImageWidth = DEFAULT_IMAGE?.initialdimension?.width ?? 50;

    if (whichImage === 'own' && dimensions) {
      if (dimensions.mode === 'default' && initialDimensions) {
        // dimensions.mode = 'default', thus initialDimensions should be used
        this.advancedSettingsForm.controls.whichImage.setValue(whichImage);
        this.advancedSettingsForm.controls.imageDimensionMode.setValue(dimensions.mode);
        this.advancedSettingsForm.controls.imageDimensionHeight.setValue(initialDimensions.height || defImageHeight);
        this.advancedSettingsForm.controls.imageDimensionWidth.setValue(initialDimensions.width || defImageWidth);
      } else {
        // dimensions.mode = 'custom', thus dimensions should be used
        this.advancedSettingsForm.controls.whichImage.setValue(whichImage);
        this.advancedSettingsForm.controls.imageDimensionMode.setValue(dimensions.mode);
        this.advancedSettingsForm.controls.imageDimensionHeight.setValue(dimensions.height || defImageHeight);
        this.advancedSettingsForm.controls.imageDimensionWidth.setValue(dimensions.width || defImageWidth);
      }
    } else {
      this.advancedSettingsForm.controls.whichImage.setValue(whichImage);
      this.advancedSettingsForm.controls.imageDimensionMode.setValue('default');
      this.advancedSettingsForm.controls.imageDimensionHeight.setValue(defImageHeight);
      this.advancedSettingsForm.controls.imageDimensionWidth.setValue(defImageWidth);
    }
  }

  /**
   * Evaluates the current image dimensions portion of the advancedSettingsForm.
   * @returns the evaluated values
   */
  private evalutateImageDimensionForm(scaledDimension?: ImageDimensionWithMode): ImageDimensionWithMode {
    if (scaledDimension) {
      return scaledDimension;
    }
    return {
      height: Number(
        this.advancedSettingsForm.controls.imageDimensionHeight.value ??
          this.advancedSettingsForm.controls.imageDimensionWidth.value
      ),
      mode: this.advancedSettingsForm.controls.imageDimensionMode.value as TImageDimensionDataMode,
      width: Number(this.advancedSettingsForm.controls.imageDimensionWidth.value)
    };
  }

  /**
   * Converts a logo to an object accepted by the backend for storing a new image
   * @param logo - New logo to store
   * @returns Object in accepted format
   */
  private logoToImgDTO(logo: CompanyDataImage): ISignaturePlaceholderImg {
    return this.signatureHelper.createImageField(
      {
        defaultImage: 'https://signatures.cognism.com/api/images/default/Home.png',
        global: false,
        imgdimension: this.evalutateImageDimensionForm(),
        label: 'Firmenlogo',
        locked: true,
        tag: 'u_logo'
      },
      {
        altText: 'u_logo',
        cropType: logo.cropType,
        image: {
          $ngfDataUrl: logo.image,
          $ngfHeight: logo.initialdimension?.height,
          $ngfWidth: logo.initialdimension?.width
        },
        initialdimension: {
          width: logo.initialdimension?.width || 150,
          height: logo.initialdimension?.height || 150
        },
        isCropped: logo.isCropped,
        linkText: logo.url,
        source: 'uploaded',
        url: logo.url ?? '#',
        useForAllProfiles: false,
        whichImage: 'own'
      }
    );
  }
}
