import {
  AfterContentInit,
  Component,
  ContentChildren,
  DoCheck,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoModule } from '@ngneat/transloco';

import { IWizardTabComponent, IWizardTabsContainerComponent } from 'src/app/model/interfaces/wizard.interface';
import { WizardTabComponent } from '@organisms/wizard/wizard-tab/wizard-tab.component';
import { MtSwitchComponent } from '@atoms/inputs/switch/switch.component';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';

@Component({
  selector: 'mt-wizard-tabs-container',
  templateUrl: './wizard-tabs-container.component.html',
  styleUrls: ['./wizard-tabs-container.component.scss'],
  standalone: true,
  imports: [NgIf, NgbTooltip, MtSvgComponent, TranslocoModule, MtSwitchComponent]
})
export class WizardTabsContainerComponent implements OnInit, AfterContentInit, DoCheck {
  @ContentChildren(WizardTabComponent) tabs!: QueryList<WizardTabComponent>;

  header = '';

  /**
   * The data needed to instantiate the component
   */
  @Input() parent!: IWizardTabsContainerComponent;

  /**
   * Toggle button - true for show, false for hide
   * @defaultValue false
   */
  @Input() toggleButton = false;

  /**
   * Toggle button - true for active, false for paused
   * @defaultValue false
   */
  @Input() toggleButtonStatus = false;

  /**
   * Changes made to the children
   */
  @Output() parentChange: EventEmitter<IWizardTabComponent> = new EventEmitter<IWizardTabComponent>();

  /**
   * Changes made to toggle button
   */
  @Output() toggleButtonChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private translateService: TranslocoService) {}

  ngOnInit(): void {
    // Inits the header
    if (this.parent.header) {
      this.header = this.parent.header;
    } else if (this.parent.translate) {
      this.header = this.translateService.translate(this.parent.translate) as string;
    }

    // Inits missing data for icons
    if (this.parent.iconTitle) {
      if (!this.parent.icon) {
        this.parent.icon = 'info';
      }

      this.parent.iconSrc = `assets/images/svgs/${this.parent.icon}.svg`;
    }
  }

  ngAfterContentInit(): void {
    const invalidTabs = [] as IWizardTabComponent[];
    this.tabs.toArray().forEach((tab: WizardTabComponent, ii: number) => {
      tab.headerClick.subscribe(() => {
        this.closeTabs(ii);
      });

      if (!tab.parent.isLastStep) {
        invalidTabs.push(tab.parent);
      } else {
        tab.invalidTabs = invalidTabs;

        tab.editInvalid.subscribe((invalidTab: IWizardTabComponent) => {
          this.openInvalidTab(invalidTab);
        });
      }
    });
  }

  ngDoCheck(): void {
    this.parentChange.next(this.parent);
    // TODO: check if is good for performance here?
    if (this.parent.header) {
      this.header = this.parent.header;
    } else if (this.parent.translate) {
      this.header = this.translateService.translate(this.parent.translate) as string;
    }
  }

  /**
   * Change the status of toggle button add emits to parent component
   * @param status - boolean true for on, false for off
   */
  onChangeStatus(status: boolean): void {
    this.toggleButtonChange.emit(status);
  }

  /**
   * Opens the invalid tab.
   * @param tab - tab to open
   */
  private openInvalidTab(tab: IWizardTabComponent): void {
    const invalidTabPos = this.tabs.toArray().findIndex(val => val.parent.stepNo === tab.stepNo);
    this.tabs.toArray()[invalidTabPos].parent.open = true;
    this.closeTabs(invalidTabPos);
  }

  /**
   * Closes other tabs than the currently opened one.
   * @param clickedTabPos - position in array
   */
  private closeTabs(clickedTabPos: number): void {
    for (let ii = 0; ii < this.tabs.length; ++ii) {
      if (ii !== clickedTabPos) {
        this.tabs.toArray()[ii].parent.open = false;
      }
    }
  }
}
