import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { IUserGetAdmins } from 'src/app/model/interfaces/user.interface';
import { take } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'mt-invite-admin',
  templateUrl: './invite-admin.component.html',
  styleUrls: ['./invite-admin.component.scss']
})
export class InviteAdminComponent implements OnInit {
  @Input() public administrators!: [];

  modalTitle = '';
  modalBody = '';
  inputModel = '';
  showMessage = false;
  modalInfoMessage = '';

  constructor(
    public modal: NgbActiveModal,
    private userService: UserService,
    private alert: AlertService,
    public translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.modalTitle = this.translate.translate('createNewAdminstrator');
    this.modalBody = this.translate.translate('send_to_which_mailaddy');
    this.modalInfoMessage = this.translate.translate('admin_already_exists');
  }

  /**
   * Cancels the modal instance
   */
  cancel(): void {
    this.inputModel = '';
    this.showMessage = false;
    this.modal.close();
  }

  /**
   * Sends the invitation
   */
  submit() {
    if (
      this.administrators.some((admin: IUserGetAdmins) => {
        return admin.email === this.inputModel;
      })
    ) {
      this.modalInfoMessage = 'admin_already_existing';
      this.showMessage = true;
      return;
    }
    this.showMessage = false;

    this.userService
      .sendInvitation(this.inputModel)
      .pipe(take(1))
      .subscribe(
        result => {
          if (result.success) {
            this.modal.close('success');
            void this.alert.defaultSuccessMessage(this.alert.translateChangesSaved());
          }
        },
        (error: HttpErrorResponse) => {
          if (error.message === 'ADMIN_ALREADY_EXISTING') {
            this.showMessage = true;
          } else {
            void this.alert.defaultErrorMessage(this.alert.translateTechnicalError());
          }
        }
      );
  }
}
