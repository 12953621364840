import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'mt-svg-with-color',
  templateUrl: './svg-with-color.component.html',
  styleUrls: ['./svg-with-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class SvgWithColorComponent {
  constructor(private cdRef: ChangeDetectorRef) {}

  /**
   * Source of SVG file
   * @defaultValue ''
   */
  @Input() src = '';

  /**
   * SVG id the exist inside the file
   * @defaultValue ''
   */
  @Input() svgId = '';

  /**
   * CSS color
   * @remarks Valid css color
   * 1 - It can be variable color
   * 2 - It can be hex color
   * 3 - It can be color name in string ex. `black`
   *
   * @remarks The fill property in the svg file shouldn't exist
   * @defaultValue 'var(--weis)'
   */
  @Input() set color(value: string) {
    if (!value) {
      value = 'var(--weis)';
    }
    const data = document.documentElement;
    data.style.setProperty('--svg-color', value);
  }

  /**
   * CSS active svg color
   * @defaultValue 'var(--blau)'
   */
  @Input() set activeColor(value: string) {
    if (!value) {
      value = 'var(--blau)';
    }
    const data = document.documentElement;
    data.style.setProperty('--svg-active-color', value);
  }

  /**
   * CSS Class
   * @defaultValue ''
   */
  private _class = '';

  @Input() set class(value: string) {
    this._class = value;
    this.cdRef.markForCheck();
  }

  get class() {
    return this._class;
  }
}
