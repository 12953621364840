<!-- Modal Target Group campaign -->
<mt-custom-modal [headline]="'selecht_targetgroup' | transloco">
  <div class="leftform" modal-body>
    <div class="content" style="min-height: 400px; height: auto !important">
      <!-- list header -->
      <div class="flex" style="justify-content: space-between">
        <div class="flex">
          <!-- Select all -->
          <mt-select-all-button
            *ngIf="list.all.length"
            class="leftbutton"
            [checked]="list.isSelectedAll()"
            (click)="list.updateSelectionAll()"
          ></mt-select-all-button>
        </div>

        <div class="flex">
          <ng-container *ngIf="filterList$ | async"></ng-container>
          <!-- Search from list -->
          <mt-search
            *ngIf="list.all.length > 0 || list.filterText"
            class="leftbutton"
            [disableEnter]="true"
            (search)="searchService.setFilterText($event)"
          ></mt-search>

          <!-- Sort by -->
          <div
            class="btn-group sort leftbutton"
            ngbDropdown
            *ngIf="list.all.length"
          >
            <mt-button
              [dropdown]="true"
              id="dropdownSort"
              ngbDropdownToggle
              text="sort"
            ></mt-button>
            <ul ngbDropdownMenu aria-labelledby="sort-button">
              <li role="menuitem">
                <a
                  ngbDropdownItem
                  (click)="list.sortList('title')"
                  transloco="sort_titel"
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div style="max-height: 350px; margin-top: 15px; overflow: auto">
        <!-- list items -->
        <ul style="width: 100%; table-layout: fixed" class="lists">
          <ng-container
            *ngFor="
              let item of list.all | filter: 'title':filterText;
              let even = even;
              let odd = odd
            "
          >
            <li
              (click)="list.updateSelection(item)"
              [ngClass]="even === true ? 'odd' : 'even'"
              class="listrow"
            >
              <div class="tcell first select">
                <selectcol [list]="list" [item]="item"></selectcol>
              </div>
              <div class="tcell" style="width: 15%">
                <targetgroupentrytitlecol
                  iconColor="mt-blau"
                  [item]="item"
                ></targetgroupentrytitlecol>
              </div>
              <div class="tcell" style="width: 150px">
                <targetgroupentrynumbercol
                  [item]="item"
                  [disableLinks]="true"
                ></targetgroupentrynumbercol>
              </div>
              <div class="tcell w-30">
                <!-- TODO: Put down [ngShow]="!item.Event" -->
                <activecampaigncol
                  [activeCampaign]="item?.Campaign"
                  disablelinks="true"
                  hideLable="true"
                >
                </activecampaigncol>
                <!-- TODO: Put down [ngShow]="item.Event" ! -->
                <eventscampaignstatuscol
                  *ngIf="false"
                  [campaignStatus]="item.Event.TriggerCampaign"
                  [hideLabel]="true"
                  [iconColor]="'mt-darkgrey'"
                  (redirectToCampaignDetail)="modal.close()"
                ></eventscampaignstatuscol>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>

      <!-- no list items -->
      <mt-emptystate
        [loadingPromise]="isLoading"
        [showEmpty]="list.all.length === 0"
        class="center-children"
        hltitle="emptystate_targetgroup_3_hl"
        subtitle="html.emptystate_targetgroup_3_sl"
        [loadingPromise]="isLoading"
        svg="group_circle"
      ></mt-emptystate>
    </div>
  </div>

  <!-- Modal Footer -->
  <ng-container modal-footer>
    <div class="flex" style="align-items: center">
      <span class="margin-right" *ngIf="list.selected.length">
        {{ 'param.target_groups_selected' | transloco: { amount:
        list.selected.length } }}
      </span>
      <div class="margin-left">
        <mt-button
          (click)="modal.dismiss('cancelled')"
          class="mt_left"
          color="default"
          text="Cancel"
        ></mt-button>
        <mt-button
          [disabled]="list.all.length === 0"
          (click)="onSelectTargetGroups()"
          icon="plus"
          color="primary"
          text="add_target_group"
        ></mt-button>
      </div>
    </div>
  </ng-container>
</mt-custom-modal>
