import { Directive } from '@angular/core';
import { ValidatorFn, AbstractControl, ValidationErrors, Validator, NG_VALIDATORS } from '@angular/forms';

export const urlValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }

  const regexPattern =
    '^' +
    // Optional protocol: http, https, ftp
    '((https?|ftp):\\/\\/)?' +
    // Optional authentication: user:password@
    '(\\S+(:\\S*)?@)?' +
    // Hostname or IP
    '(localhost|' +
    // Hostname
    '([-a-z0-9\\u00a1-\\uffff]{1,63}\\.)+' +
    // Top level domain
    '\\b([a-z\\u00a1-\\uffff]{2,10})\\b|' +
    // IPv4 address
    '\\b\\d{1,3}(\\.\\d{1,3}){3}\\b|' +
    // IPv6 address
    '\\[?[0-9a-f:\\.]+\\]?)' +
    // Optional port number
    '(:\\d{2,5})?' +
    // Path
    '(\\/[-a-z0-9@:%_+.~#?&//=]*)?' +
    // Query string
    '(\\?\\S*)?' +
    // Fragment
    '(#\\S*)?' +
    '$';

  const isValid = new RegExp(regexPattern, 'i').test(control.value as string);
  return isValid ? null : { urlError: true };
};

@Directive({
  providers: [{ provide: NG_VALIDATORS, useExisting: UrlValidatorDirective, multi: true }],
  selector: '[mtUrlValidator]'
})
export class UrlValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return urlValidator(control);
  }
}
