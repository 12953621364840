import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoModule } from '@ngneat/transloco';
import { IWizardTabComponent } from 'src/app/model/interfaces/wizard.interface';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'mt-wizard-tab-last',
  templateUrl: './wizard-tab-last.component.html',
  styleUrls: ['./wizard-tab-last.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, MtSvgComponent, TranslocoModule]
})
export class WizardTabLastComponent implements OnInit {
  @Input() invalidTabs!: IWizardTabComponent[]; // holds all tabs except for the last one
  @Input() parent!: IWizardTabComponent;

  @Output() editInvalid: EventEmitter<IWizardTabComponent> = new EventEmitter<IWizardTabComponent>();

  tabSubtitle = '';

  constructor(private translateService: TranslocoService) {}

  ngOnInit(): void {
    if (this.parent.subtitle) {
      this.tabSubtitle = this.parent.subtitle;
    } else if (!this.parent.isLastStep && this.parent.optional && !this.parent.open) {
      this.tabSubtitle = this.translateService.translate('optional') as string;
    }
  }

  onInvalidTabClick(tab: IWizardTabComponent): void {
    this.editInvalid.emit(tab);
  }
}
