import { Component } from '@angular/core';

/**
 * Basic component used to render an unordered list of items
 */
@Component({
  selector: 'mt-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  standalone: true
})
export class ListComponent {}
