import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NgbActiveModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { MtSearchComponent } from '@shared/components/atoms/inputs/search/search.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { GroupColComponent } from '@shared/components/organisms/cols/group-col/group-col.component';
import { RolloutStatusColComponent } from '@shared/components/organisms/cols/rollout-status-col/rollout-status-col.component';
import { SelectColComponent } from '@shared/components/organisms/cols/select-col/select-col.component';
import { SignatureColComponent } from '@shared/components/organisms/cols/signature-col/signature-col.component';
import { EmptyStateComponent } from '@shared/components/organisms/empty-states/empty-state.component';
import { FilterPipe } from '@shared/pipes/filter/filter.pipe';

import { catchError, tap } from 'rxjs';

import { AlertService } from 'src/app/services/alert/alert.service';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { ISignatureGetAll } from 'src/app/services/signature/signature-service.interface';
import { SignatureService } from 'src/app/services/signature/signature.service';

@Component({
  selector: 'mt-add-signature-to-group',
  templateUrl: './add-signature-to-group.component.html',
  styleUrls: ['./add-signature-to-group.component.scss'],
  providers: [ListHelperService],
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    EmptyStateComponent,
    FilterPipe,
    GroupColComponent,
    MtSearchComponent,
    NgbDropdownModule,
    NgClass,
    NgFor,
    NgIf,
    RolloutStatusColComponent,
    SelectColComponent,
    SignatureColComponent,
    TranslocoModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSignatureToGroupComponent {
  filterTextSig = '';

  selectedSignature?: string;

  loadSignatures$ = this.signatureService.getAll().pipe(
    tap(signatures => {
      this.list.all = signatures;

      const selected = this.list.all.find(signature => signature.id === this.selectedSignature);
      if (selected) {
        this.list.selected.push(selected);
      }

      this.list.selectionType = 'single';
      this.list.setFiltered();
    }),
    catchError((err: HttpErrorResponse) => {
      void this.alert.defaultErrorMessage(err.message);
      throw err;
    })
  );

  constructor(
    private alert: AlertService,
    private signatureService: SignatureService,
    public list: ListHelperService<ISignatureGetAll>,
    public modal: NgbActiveModal
  ) {}

  /**
   * Closes the modal and assigns the selected signature
   */
  assignSignature(): void {
    this.modal.close(this.list.selected[0]);
  }
}
