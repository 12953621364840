import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UserWithImage } from 'src/app/model/interfaces/user.interface';
import { RouterLink } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatars';
import { NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';

@Component({
  selector: 'employeecol',
  templateUrl: './employee-col.component.html',
  styleUrls: ['./employee-col.component.scss'],
  standalone: true,
  imports: [NgIf, AvatarModule, NgbTooltip, RouterLink, TranslocoModule, MtSvgComponent]
})
export class EmployeeColComponent implements OnInit {
  customStyle = {
    'font-size': '20px',
    'font-family': 'Roboto Condensed',
    'text-transform': 'uppercase'
  };
  displayEmail!: string;
  displayName = '';
  displayNameShort = '';

  /**
   * Disables links
   * @defaultValue false
   */
  @Input() disableLinks = false;

  /**
   * Let's the email text be a `#val`
   */
  @Input() set index(val: number) {
    this.displayEmail = `#${val}`;
  }

  /**
   * The employee
   */
  @Input() user!: UserWithImage;

  /**
   * Displays an icon and corresponding tooltip when there is an O365 connection
   * @defaultValue `{ hasO365Connection: false, isUserManuallyAssigned: false }`
   */
  @Input() O365Group = { hasO365Connection: false, isUserManuallyAssigned: false };

  /**
   * Emit events to redirect to campaign details page
   */
  @Output() redirectToEmployeeDetail: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit(): void {
    // Set 'isManuallyCreated' to 'true' in the edge-case that this was not set in the db
    if (this.O365Group.hasO365Connection && this.O365Group.isUserManuallyAssigned === undefined) {
      this.O365Group.isUserManuallyAssigned = true;
    }

    if (!this.user.firstname && !this.user.lastname) {
      if (this.user.email !== undefined) {
        this.displayEmail = this.user.email;
        this.displayName = this.user.email;
        this.displayNameShort = `${this.displayName[0]} ${this.displayName[1]}`; // takes first two characters of email
        this.customStyle['text-transform'] = 'lowercase';
      }
    } else {
      this.displayName = `${this.user.firstname} ${this.user.lastname}`;
      this.displayNameShort = `${this.user.firstname ? this.user.firstname.substring(0, 1) : ''} ${
        this.user.lastname ? this.user.lastname.substring(0, 1) : ''
      }`; // takes first character of first and last name
    }

    this.displayEmail = this.user.email || '';
  }
}
