import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomOperators } from '@shared/operators/custom-operators';
import { EActionType } from '@model/enums/action-type.enum';
import { EDataType } from '@model/enums/data-type.enum';
import { IActivity } from '@model/interfaces/activity.interface';
import { DataOrMessage } from '@model/interfaces/response.interface';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {
  constructor(private operator: CustomOperators, private http: HttpClient) {}

  /**
   * Create activity
   * @param dataType - value of EDataType obj
   * @param actionType - value of EActionType obj
   * @param activityInfo - String value
   * @returns Observable of activity object
   */
  createActivity(dataType: EDataType, actionType: EActionType, activityInfo: string): Observable<IActivity> {
    return this.http
      .post<DataOrMessage<IActivity>>(`/activities`, { dataType, actionType, activityInfo })
      .pipe(this.operator.extractResponseDataOrMessage());
  }
}
