import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ISignaturePlaceholderURL } from 'src/app/model/interfaces/signature.interface';
import { ISignaturePlaceholderTypeModalOptions } from 'src/app/services/alert/alert-service.interface';
import { DefaultBoxComponent } from '@molecules/boxes/default/default.component';
import { MtColorPickerComponent } from '@atoms/inputs/color-picker/color-picker.component';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

@Component({
  selector: 'mt-signature-placeholder-type-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    DefaultBoxComponent,
    FormsModule,
    MtColorPickerComponent,
    NgIf,
    ReactiveFormsModule,
    TranslocoModule
  ]
})
export class SignaturePlaceholderTypeLinkModalComponent implements OnInit {
  linkForm!: UntypedFormGroup;

  modalOptions: ISignaturePlaceholderTypeModalOptions = {
    mode: 'create',
    showSignatureRelevantData: false
  };

  constructor(public modal: NgbActiveModal, private formBuilder: UntypedFormBuilder) {}

  //#region LIFE CYCLE
  ngOnInit(): void {
    this.linkForm = this.createLinkForm();
  }
  //#endregion

  //#region PUBLIC
  /**
   * Saves the values of the form.
   */
  saveValue(): void {
    // to prevent cyclic object value
    const result: ISignaturePlaceholderURL = Object.assign({}, this.modalOptions.formData, {
      value: {
        url: this.linkForm.get('url')?.value as string,
        linkText: this.linkForm.get('linkText')?.value as string
      },
      linkcolor: this.linkForm.get('color')?.value as string
    });

    this.modal.close(result);
  }

  /**
   * Sets the link color.
   * @param color
   */
  setLinkColor(color: string): void {
    this.linkForm.get('color')?.setValue(color);
  }
  //#endregion

  //#region PRIVATE
  private createLinkForm(): UntypedFormGroup {
    const formData = this.modalOptions.formData as ISignaturePlaceholderURL;

    return this.formBuilder.group({
      tag: this.formBuilder.control({ value: formData?.tag, disabled: true }),
      url: formData?.value?.url || '',
      linkText: formData?.value?.linkText || '',
      color: formData?.linkcolor || ''
    });
  }
  //#endregion
}
