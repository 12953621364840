<mt-custom-modal headline="{{ headline | transloco }}">
  <ng-container modal-body>
    <div class="heading">
      <div class="text-title" transloco="howshouldyourdepcalled"></div>

      <div>
        <input
          data-cy="department-title-input"
          [formControl]="newTitle"
          type="text"
          class="form-control form-control-lg text-input formcontrolright martop10"
          placeholder="{{ 'enterdeptitel' | transloco }}"
        />
      </div>
      <div *ngIf="newTitle.touched || newTitle.dirty" class="martop10">
        <!-- Validate required department title -->
        <div
          *ngIf="newTitle.errors?.required"
          class="text-danger"
          transloco="required_field"
        ></div>

        <!-- Validate no special chars used -->
        <div
          *ngIf="newTitle.errors?.specialChar"
          class="text-danger"
          transloco="no_special_chars"
        ></div>
      </div>
    </div>
  </ng-container>
  <ng-container modal-footer-right>
    <div class="flex">
      <mt-button
        (click)="modal.close()"
        class="mt_left"
        color="default"
        ngbAutoFocus
        text="abort"
      ></mt-button>
      <mt-button
        data-cy="create-department-modal-btn"
        (click)="onSubmit()"
        class="roboto_light"
        color="primary"
        icon="plus"
        text="{{ submitBtnText | transloco }}"
      >
      </mt-button>
    </div>
  </ng-container>
</mt-custom-modal>
