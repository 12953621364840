import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmployeeService } from '@services/employee/employee.service';
import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownToggle,
  NgbDropdownMenu,
  NgbDropdownItem
} from '@ng-bootstrap/ng-bootstrap';
import { filter, mergeMap, Observable } from 'rxjs';
import { GroupSmall } from 'src/app/model/interfaces/group.interface';
import { AlertService } from 'src/app/services/alert/alert.service';
import { GroupService } from 'src/app/services/group/group.service';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { ISignatureStatus } from 'src/app/services/signature-campaign-status/signature-campaign-status.service';

import { NgIf, NgFor, NgClass } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { CampaignStatusColComponent } from '@shared/components/organisms/cols/campaign-status-col/campaign-status-col.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { EmptyStateComponent } from '@shared/components/organisms/empty-states/empty-state.component';
import { GroupColComponent } from '@shared/components/organisms/cols/group-col/group-col.component';
import { MtSearchComponent } from '@shared/components/atoms/inputs/search/search.component';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { SelectColComponent } from '@shared/components/organisms/cols/select-col/select-col.component';
import { SignatureStatusColComponent } from '@shared/components/organisms/cols/signature-status-col/signature-status-col.component';
import { FilterPipe } from '@shared/pipes/filter/filter.pipe';
import { EventsCampaignStatusColComponent } from '@shared/components/organisms/cols/events-campaign-status-col/events-campaign-status-col.component';

@Component({
  selector: 'mt-assign-default-signature-modal',
  templateUrl: './assign-default-signature-modal.component.html',
  styleUrls: ['./assign-default-signature-modal.component.scss'],
  providers: [ListHelperService],
  standalone: true,
  imports: [
    ButtonComponent,
    CampaignStatusColComponent,
    CustomModalComponent,
    EmptyStateComponent,
    EventsCampaignStatusColComponent,
    FilterPipe,
    GroupColComponent,
    MtSearchComponent,
    MtSvgComponent,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgClass,
    NgFor,
    NgIf,
    SelectColComponent,
    SignatureStatusColComponent,
    TranslocoModule
  ]
})
export class AssignDefaultSignatureModalComponent implements OnInit {
  private employeeService = inject(EmployeeService);
  private route = inject(ActivatedRoute);

  @Input() userId!: string;

  isLoading = false;

  filterGroupTitle = '';

  loadAssignedGroupsObserver = {
    next: (group: GroupSmall) => this.list.all.push(group),
    error: (err: HttpErrorResponse) => void this.alert.defaultErrorMessage(err.message),
    complete: () => {
      this.list.setFiltered();
      this.isLoading = false;
    }
  };

  constructor(
    private alert: AlertService,
    private groupService: GroupService,
    public list: ListHelperService<GroupSmall>,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.list.selectionType = 'single';
    this.loadAssignedGroups().subscribe(this.loadAssignedGroupsObserver);
  }

  /**
   * Closes the modal with the selected group id
   */
  accept(): void {
    this.modal.close(this.list.selected[0].id);
  }

  /**
   * Loads the groups the user is assigned to
   * @returns Groups the user is assigned to, one by one
   */
  loadAssignedGroups(): Observable<GroupSmall> {
    this.isLoading = true;
    const { ac: activationCode, eid: empId } = this.route.snapshot.queryParams as { ac: string; eid: string };
    if (activationCode && empId) {
      return this.employeeService.getGroupSmallDetailsEUC(empId, activationCode).pipe(
        mergeMap(groups => groups),
        filter(group => group.Users?.some(user => user.id === this.userId) === true)
      );
    } else {
      return this.groupService.getSmallDetails().pipe(
        mergeMap(groups => groups),
        filter(group => group.Users?.some(user => user.id === this.userId) === true)
      );
    }
  }

  /**
   * Determines the signature status of one group
   * @param group - Group to determine status for
   * @returns Object holding signature status
   */
  getSignatureStatus(group: GroupSmall): ISignatureStatus {
    if (!group.Signature) {
      return {
        atLeastOneNotLatest: true,
        hidestatus: false,
        signatures: []
      };
    } else {
      return {
        atLeastOneNotLatest: false,
        hidestatus: true,
        signatures: [
          {
            title: group.Signature.title,
            id: group.Signature.id,
            createdAt: group.Signature.createdAt,
            updatedAt: group.Signature.updatedAt,
            owner: group.Signature.owner,
            disableHelperStyle: group.Signature.disableHelperStyle
          }
        ]
      };
    }
  }
}
