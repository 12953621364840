import { Pipe, PipeTransform } from '@angular/core';

const HIDDEN_FIELDS_FOR_COGSIG = [
  'emp_tiktok',
  'ma_add_info_3',
  'ma_add_info_4',
  'ma_bundesland',
  'ma_disclaimer',
  'ma_fax',
  'ma_fb',
  'ma_firma',
  'ma_googlep',
  'ma_instagram',
  'ma_land',
  'ma_linkedin',
  'ma_ms_teams',
  'ma_office',
  'ma_ort',
  'ma_postal_code',
  'ma_skype',
  'ma_strasse',
  'ma_tel2',
  'ma_twitter',
  'ma_website_2',
  'ma_website',
  'ma_xing',
  'placeholder_branche1_1',
  'placeholder_branche1_2',
  'placeholder_branche1_3',
  'placeholder_branche2_1',
  'placeholder_branche2_2',
  'placeholder_branche2_3'
];

/**
 * Determines if a field should be displayed when on CogSig
 * @param value - Field to check
 * @param isCognismSignatures - Is CogSig or not
 * @returns Boolean indicating if field should be displayed
 */
@Pipe({
  name: 'shouldDisplayField',
  standalone: true
})
export class ShouldDisplayFieldPipe implements PipeTransform {
  transform(value: string, isCognismSignatures: boolean): boolean {
    return !isCognismSignatures || !HIDDEN_FIELDS_FOR_COGSIG.includes(value);
  }
}
