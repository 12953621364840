<ng-container
  *ngIf="authService.authorizationScope$ | async as authorizationScope"
>
  <div class="campaign_creation_modal">
    <mt-custom-modal headline="{{ 'installsignature' | transloco }}">
      <div modal-body>
        <div class="content">
          <div
            class="what_kind_of_campaign"
            transloco="installsiganturewithwhom"
          ></div>
          <div class="flex-space-between install-signature-box">
            <!-- Install signature for user themselves -->
            <div
              data-cy="install-signature-for-me-btn"
              class="flex-center-left"
              (click)="
                stepTwoForSignatureInstallGuide(
                  'forMe',
                  authorizationScope.userId
                )
              "
            >
              <div class="campaign_creation_path_wrapper">
                <mt-svg
                  class="mt-blau"
                  src="assets/images/svgs/user.svg"
                  size="74"
                ></mt-svg>
                <br />
                <span class="campaign_based" transloco="foryou_hl"></span>
                <br />
                <span class="campaign_info" transloco="foryou_sl"></span>
                <br />
                <mt-button
                  klass="flex-center"
                  text="proceeding"
                  color="primary"
                ></mt-button>
              </div>
            </div>

            <!-- Install signature for Other employee -->
            <div
              class="flex-center-right"
              data-cy="install-signature-for-colleagues-btn"
              (click)="
                stepTwoForSignatureInstallGuide(
                  'forColleagues',
                  authorizationScope.userId
                )
              "
            >
              <div class="campaign_creation_path_wrapper">
                <mt-svg
                  class="mt-blau"
                  src="assets/images/svgs/users.svg"
                  size="74"
                ></mt-svg>
                <br />
                <span
                  class="campaign_based"
                  transloco="forcolleagues_hl"
                ></span>
                <br />
                <span class="campaign_info" transloco="forcolleagues_sl"></span>
                <br />
                <mt-button
                  klass="flex-center"
                  text="proceeding"
                  color="primary"
                ></mt-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mt-custom-modal>
  </div>
</ng-container>
