<mt-custom-modal headline="{{ 'select_campaign_banner_Btn' | transloco }}">
  <div *ngIf="loadCampaigns$ | async; else loading" modal-body>
    <div class="content" style="min-height: 400px; height: auto !important">
      <mt-list-actions>
        <ng-container left-side>
          <!-- Select all button -->
          <mt-select-all-button
            *ngIf="list.all.length"
            [checked]="list.isSelectedAll()"
            (click)="list.updateSelectionAll()"
          ></mt-select-all-button>
        </ng-container>
        <ng-container right-side>
          <!-- Search -->
          <mt-search
            class="leftbutton"
            [disableEnter]="true"
            (search)="notificationsCampaignsModalService.setFilterText($event)"
          ></mt-search>
          <mt-sort-dropdown *ngIf="list.all.length">
            <li
              ngbDropdownItem
              (click)="list.sortList('title')"
              transloco="sort_titel"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('createdAt')"
              transloco="sort_creation"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('type')"
              transloco="sort_type"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('Groups')"
              transloco="sort_department"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('views')"
              transloco="sort_impressions"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('clicks')"
              transloco="sort_clicks"
            ></li>
            <li
              ngbDropdownItem
              (click)="list.sortList('clickRate')"
              transloco="sort_rate"
            ></li>
          </mt-sort-dropdown>
        </ng-container>
      </mt-list-actions>

      <mt-list>
        <mt-row
          *ngFor="let item of list.all; odd as isOdd; even as isEven"
          [isEven]="isEven"
          [isOdd]="isOdd"
          [isSelected]="list.isSelected(item)"
          (click)="list.updateSelection(item)"
        >
          <div class="tcell first select">
            <selectcol [list]="list" [item]="item"></selectcol>
          </div>
          <div class="tcell" style="width: 45%">
            <campaigncol
              [campaign]="item"
              [isFirst]="true"
              iconColor="mt-blau"
            ></campaigncol>
          </div>
          <div class="tcell" style="width: 200px">
            <tagcol [type]="item.type"></tagcol>
          </div>

          <div class="tcell" style="width: 25%">
            <groupcol
              *ngIf="item.type === 'sender'"
              [groups]="item.Groups"
            ></groupcol>
            <targetgroupscol
              *ngIf="item.type === 'target'"
              [targetGroups]="item.TargetGroupLists || []"
            ></targetgroupscol>
          </div>
        </mt-row>
      </mt-list>
      <mt-emptystate
        [showEmpty]="list.all.length === 0"
        class="nodataarea"
        hltitle="Nocampaignyet"
        subtitle="html.Createyourfirstcampaigntogetanoverviewhere"
        svg="campaign"
      ></mt-emptystate>
    </div>
  </div>

  <!-- LOADING -->
  <ng-template #loading>
    <div class="card martop20">
      <mt-emptystate [loadingPromise]="true"></mt-emptystate>
    </div>
  </ng-template>
  <ng-container modal-footer>
    <div class="flex-center-right">
      <div class="margin-left">
        <mt-button
          (click)="modal.close()"
          class="mt_left"
          color="default"
          ngbAutoFocus
          text="abort"
        ></mt-button>
        <mt-button
          (click)="list.selected.length && assignCampaign(list.selected)"
          [disabled]="!list.selected.length"
          class="roboto_light"
          color="primary"
          icon="plus"
          text="Selectcampaign"
        >
        </mt-button>
      </div>
    </div>
  </ng-container>
</mt-custom-modal>
