import { ChangeDetectionStrategy, Component, computed, inject, Input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, Observable, scan, switchMap, tap } from 'rxjs';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TranslocoModule } from '@ngneat/transloco';

import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { MtSearchComponent } from '@shared/components/atoms/inputs/search/search.component';
import { MtSvgComponent } from '@shared/components/atoms/svg/mt-svg.component';
import { SelectAllButtonComponent } from '@shared/components/molecules/buttons/select-all-button/select-all-button.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { EmployeeColComponent } from '@shared/components/organisms/cols/employee-col/employee-col.component';
import { GroupColComponent } from '@shared/components/organisms/cols/group-col/group-col.component';
import { EmptyStateComponent } from '@shared/components/organisms/empty-states/empty-state.component';
import { toObservable } from '@angular/core/rxjs-interop';
import { GetEmployeeGroupNotMember } from '@services/group/group-service.interface';
import { GroupService } from '@services/group/group.service';
import { QueryParamsService } from '@services/query-params/query-params.service';
import { ListActionsComponent } from '@shared/components/atoms/list-actions/list-actions.component';
import { MtCheckboxComponent } from '@shared/components/atoms/inputs/checkbox/checkbox.component';
import { ListComponent } from '@shared/components/atoms/list/list.component';
import { RowComponent } from '@shared/components/atoms/row/row.component';

/**
 * Modal to assign an employee to a group
 */
@Component({
  selector: 'mt-assign-employee-modal',
  templateUrl: './assign-employee-modal.component.html',
  styleUrls: ['./assign-employee-modal.component.scss'],
  providers: [ListHelperService, QueryParamsService],
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CustomModalComponent,
    EmployeeColComponent,
    EmptyStateComponent,
    GroupColComponent,
    InfiniteScrollModule,
    ListActionsComponent,
    ListComponent,
    MtCheckboxComponent,
    MtSearchComponent,
    MtSvgComponent,
    RowComponent,
    SelectAllButtonComponent,
    TranslocoModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignEmployeeModalComponent {
  modal = inject(NgbActiveModal);
  private groupService = inject(GroupService);
  private readonly listHelper = inject<ListHelperService<GetEmployeeGroupNotMember>>(ListHelperService);
  queryParamsService = inject(QueryParamsService);

  /**
   * Group identifier
   */
  @Input() groupId!: number;

  isLoading = signal(true);

  employeeCount = signal(0);

  loadedAll = signal(false);

  /**
   * Loads employees not assigned to the group and updates the list helper
   *
   * - debounces the query params by 40ms
   * - switches to the API call with the debounced query params
   * - scans the result and updates the list helper
   * - stops loading when the page size is reached
   * - updates the employee count
   * - sets the loading status to false
   *
   * @returns an observable of the employees not assigned to the group
   */
  employees$: Observable<GetEmployeeGroupNotMember[]> = toObservable(this.queryParamsService.queryParams).pipe(
    debounceTime(40),
    distinctUntilChanged(),
    tap(() => this.isLoading.set(true)),
    switchMap(queryParams => this.groupService.getEmployeesNotMemberOfGroup(this.groupId, queryParams)),
    scan((acc: GetEmployeeGroupNotMember[], items) => {
      acc.push(...items);
      if (this.queryParamsService.queryParams().pageSize > items.length) this.loadedAll.set(true);
      this.listHelper.all = acc;
      this.listHelper.setFiltered();
      this.employeeCount.set(acc.length);
      return acc;
    }, []),
    tap(() => this.isLoading.set(false))
  );

  /**
   * Updates the query params to the next page and triggers the loading of the next page of employees.
   * Called when the user reaches the bottom of the list.
   */
  scroll() {
    this.queryParamsService.updateToNextPage();
  }

  selectionList = computed(() => this.listHelper);
}
