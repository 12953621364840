import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PatternService {
  constructor(public location: Location) {}
  domainWithProtocolPattern = /((https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/;
  EMailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  URLPattern = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9.-]+\.[a-zA-Z]{2,5}[.]{0,1}/i;
  DomainPattern = /^(?=.{1,255}$)[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
  customDomainPattern = /^(http:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  forbidInputCharacters(input_form: UntypedFormGroup, e: any, forbidden_characters_pattern: any): void {
    const forbidden_characters_regex = new RegExp(`(${forbidden_characters_pattern})`, 'g');
    input_form.patchValue({
      [e.target.getAttribute('formControlName')]: e.target.value.replace(forbidden_characters_regex, '')
    });
  }

  getAbsolutePath(path: string): string {
    return this.location.prepareExternalUrl(path);
  }
  youtubeVideoLinkPattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
  sanitizeSignatureHTMLPattern = /<!--[\s\S]*?-->/g;
}
