import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntegrationsSignature } from 'src/app/model/interfaces/signature.interface';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgIf } from '@angular/common';

@Component({
  selector: 'mt-signature-rollout-preview',
  templateUrl: './signature-rollout-preview.component.html',
  styleUrls: ['./signature-rollout-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf]
})
export class SignatureRolloutPreviewComponent {
  /**
   * Hold signature html preview string
   */
  data!: SafeHtml;

  /**
   * Hold signature object
   */
  private _signature!: IntegrationsSignature;

  /**
   * Input integration signature object
   */
  @Input() set signature(val: IntegrationsSignature) {
    val.signatureHtml = val.signatureHtml
      .replaceAll('?m=o', '')
      .replaceAll('&m=o', '')
      .replaceAll('?track=n', '')
      .replaceAll('&track=n', '')
      .replaceAll('moz-do-not-send="true"', '');
    this._signature = val;
    this.data = this.sanitized.bypassSecurityTrustHtml(this._signature.signatureHtml);
  }
  get signature(): IntegrationsSignature {
    return this._signature;
  }

  constructor(private sanitized: DomSanitizer) {}
}
