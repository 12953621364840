import { Component, Input } from '@angular/core';
import { TargetSender } from 'src/app/model/enums/target-sender.enum';
import { TranslocoModule } from '@ngneat/transloco';
import { MtSvgComponent } from '@atoms/svg/mt-svg.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'tagcol',
  templateUrl: './tag-col.component.html',
  styleUrls: ['./tag-col.component.scss'],
  standalone: true,
  imports: [NgClass, NgbTooltip, NgIf, MtSvgComponent, TranslocoModule]
})
export class TagColComponent {
  @Input() type: string | TargetSender = '';
  @Input() cube = false;
  @Input() mini = false;
  @Input() iconColor = '';

  /**
   * Add class to parent, determine if colors should be inverted
   * @defaultValue false
   */
  @Input() invert = false;

  getTooltipInfo(type: string): string {
    switch (type) {
      case 'sender':
        return 'senderbased_tag_info';
      case 'target':
        return 'recipientbased_tag_info';
      case 'prospect':
        return 'prospect_tag_info';
      case 'opt_out_accountwide':
        return 'opt_out_accountwide';
      case 'opt_out_contactwide':
        return 'opt_out_contactwide';
      default:
        return '&nbsp;'.repeat(30);
    }
  }

  getTagTitle(type: string): string {
    switch (type) {
      case 'sender':
        return 'sender_based_caps';
      case 'target':
        return 'target_group_based_caps';
      case 'prospect':
        return 'new_prospects_label';
      case 'opt_out_accountwide':
        return 'opt_out_accountwide';
      case 'opt_out_contactwide':
        return 'opt_out_contactwide';
      default:
        return '&nbsp;'.repeat(30);
    }
  }
}
