import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

// Rxjs
import { combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';

// Interfaces
import { ISignatureGetOne } from 'src/app/services/signature/signature-service.interface';
import { ResponseGetIntegrationData, EmployeeDetails } from 'src/app/services/employee/employee-service.interface';

// Services
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { InstallationService } from '@services/installation/installation.service';
import { SignatureService } from 'src/app/services/signature/signature.service';

// Components
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { EmptyStateComponent } from '@shared/components/organisms/empty-states/empty-state.component';
import { InstallationChecklistWithSelectedListComponent } from '@shared/components/organisms/installation/components/installation-checklist-with-selected-list/installation-checklist-with-selected-list.component';
import { SignatureSetupCheckComponent } from '@shared/components/organisms/installation/components/signature-setup-check/signature-setup-check.component';

@Component({
  selector: 'mt-install-signature-modal',
  templateUrl: './install-signature-modal.component.html',
  styleUrls: ['./install-signature-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    CustomModalComponent,
    EmptyStateComponent,
    InstallationChecklistWithSelectedListComponent,
    NgIf,
    SignatureSetupCheckComponent,
    TranslocoModule
  ]
})
export class InstallSignatureModalComponent implements OnInit {
  /**
   * Holds the signature id and user id
   * @defaultValue {
   * signatureId: '',
   * userId: ''
   *}
   */
  @Input()
  inputData = {
    signatureId: '',
    userId: ''
  };

  /**
   * Selected mail client list
   */
  integrationsList$ = this.installationService.integrationList$;

  /**
   * Checks the selected mail client list should be display or not
   */
  isSelectionListShown$ = this.installationService.isSelectedListStepShown$;

  /**
   * Holds the integration data with user and liked group details
   */

  loadData$!: Observable<{
    integrationData: ResponseGetIntegrationData;
    isUserLinkedWithGroup: boolean;
    signature: ISignatureGetOne;
    userData: EmployeeDetails;
  }>;

  constructor(
    private employeeService: EmployeeService,
    private installationService: InstallationService,
    private signatureService: SignatureService,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.loadData$ = this.employeeService.getEmployeeDetails(this.inputData.userId).pipe(
      switchMap(userData =>
        combineLatest([
          of(userData),
          this.employeeService.getIntegrationData(userData.id, userData?.activationCode || ''),
          this.signatureService.getOne(this.inputData.signatureId)
        ])
      ),
      tap(([, integrationData]) => {
        if (integrationData.signatures.length > 0) {
          const steps = integrationData.signatures.map((data, index) => {
            return {
              stepNo: index + 1,
              tabTitle: data.signatureTitle,
              subtitle: data.groupTitle,
              open: false
            };
          });
          this.installationService.updateSignaturePreviewStepStatus(steps);
        }
      }),
      map(([userData, integrationData, signature]) => {
        // Is chosen user member of a group the signature is assigned to
        const isUserLinkedWithGroup =
          userData?.Groups.filter(employeeGroup =>
            signature.activeGroups.some(signatureGroup => signatureGroup.id === employeeGroup.id)
          ).length > 0;

        return { userData, integrationData, isUserLinkedWithGroup, signature };
      })
    );
  }
}
