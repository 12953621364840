<mt-custom-modal
  *transloco="let translate"
  [headline]="translate('Chooseemployees')"
>
  @if (loadData$ | async) {
  <ng-container modal-body>
    <div class="content">
      @if (list.all.length > 0 || (searchData$ | async)) {
      <mt-list-actions>
        <ng-container left-side>
          <mt-select-all-button
            class="leftbutton"
            [checked]="list.isSelectedAll()"
            (click)="list.updateSelectionAll()"
          ></mt-select-all-button>
        </ng-container>
        <ng-container right-side>
          <mt-search
            class="leftbutton"
            [disableEnter]="true"
            (search)="list.setFilterText($event)"
          ></mt-search>
          <mt-sort-dropdown>
            <li ngbDropdownItem (click)="sortList('firstname')">
              {{ translate('Vorname') }}
            </li>
            <li ngbDropdownItem (click)="sortList('lastname')">
              {{ translate('Nachname') }}
            </li>
            <li ngbDropdownItem (click)="sortList('email')">
              {{ translate('E-Mail-Adresse') }}
            </li>
          </mt-sort-dropdown>
        </ng-container>
      </mt-list-actions>
      }

      <div class="main_block_with_scroll">
        <div
          infiniteScroll
          [infiniteScrollDisabled]="infinitescrolloptions.disable"
          [infiniteScrollContainer]="'.main_block_with_scroll'"
          [fromRoot]="true"
          (scrolled)="infinitescrolloptions.scrollAction()"
        >
          <!-- list items -->
          <mt-list>
            @for ( employee of list.all; track employee.id; let isOdd = $odd,
            isEven = $even ) {
            <mt-row
              [isEven]="isEven"
              [isOdd]="isOdd"
              [isSelected]="list.isSelected(employee)"
              (click)="list.updateSelection(employee)"
              data-cy="row-assign-employee-modal"
            >
              <div class="tcell first select">
                <selectcol [list]="list" [item]="employee"></selectcol>
              </div>
              <div class="tcell w-50">
                <employeecol
                  [user]="employee"
                  [disableLinks]="true"
                ></employeecol>
              </div>
              <div class="tcell w-50">
                <groupcol
                  [groups]="employee.Groups"
                  [disableLinks]="true"
                ></groupcol>
              </div>
            </mt-row>
            }
          </mt-list>

          <!-- no list items -->
          <mt-emptystate
            [loadingPromise]="isLoading"
            [showEmpty]="list.all.length === 0"
            class="nodataarea"
            class="text-center"
            hltitle="emptystate_employee_3_hl"
            subtitle="html.emptystate_employee_3_sl"
            svg="employees"
          ></mt-emptystate>
        </div>
      </div>
    </div>
  </ng-container>
  } @else {
  <!-- LOADING -->
  <div class="card martop20">
    <mt-emptystate [loadingPromise]="true"></mt-emptystate>
  </div>
  }
  <div modal-footer>
    <div class="flex-center-space-between">
      <div class="flex">
        @if (list.selected.length) {
        <div>
          <span>{{ list.selected.length }}</span>
          <span class="marleft5">{{ translate('employeesselected') }}</span>
        </div>
        }
      </div>

      <div class="flex">
        <mt-button
          (click)="modal.close()"
          class="mt_left"
          color="default"
          ngbAutoFocus
          text="abort"
        ></mt-button>
        <mt-button
          data-cy="assign-employee-button"
          (click)="assignEmployees()"
          [disabled]="!list.selected.length"
          class="roboto_light"
          color="primary"
          icon="plus"
          text="assigneemployees"
        >
        </mt-button>
      </div>
    </div>
  </div>
</mt-custom-modal>
