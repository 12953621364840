<span
  [ngClass]="{
    tagcol: !cube && !invert,
    tagcol_mini: mini,
    tagcol_cube: cube,
    'invert-color': invert
  }"
  ngbTooltip="{{ getTooltipInfo(type) | transloco }}"
  tooltipClass="status-info-tooltip"
>
  <span
    class="target_icon {{ type }}-type {{ iconColor }}"
    [ngClass]="{ large_icon: !(cube || mini) }"
  >
    <!-- TODO: fix svg not being displayed -->
    <mt-svg
      *ngIf="type === 'sender'"
      class="medium-svg"
      src="assets/images/svgs/sender.svg"
    ></mt-svg>
    <mt-svg
      *ngIf="type === 'target'"
      class="medium-svg"
      src="assets/images/svgs/targegroup.svg"
    ></mt-svg>
    <mt-svg
      *ngIf="type === 'prospect'"
      class="medium-svg prospect"
      src="assets/images/svgs/prospect_label_svg.svg"
    ></mt-svg>
    <span
      class="title"
      *ngIf="!(cube || mini)"
      transloco="{{ getTagTitle(type) }}"
    ></span>
  </span>
</span>
