import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Given an array of values, validate that a particular value is already present or not
 * @param keys - Array of already existing values
 * @returns Null or object with alreadyExist set to true
 */
export function alreadyExistValidator(keys: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) return null;

    // Check already existing
    const alreadyExists = keys.find(key => key === value);

    return alreadyExists ? { alreadyExist: true } : null;
  };
}
