import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'mt-product-container-info',
  templateUrl: './product-container-info.component.html',
  styleUrls: ['./product-container-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductContainerInfoComponent {
  /**
   * Input of include items
   */
  @Input() includesProduct!: string[];

  /**
   * Input of disclude items
   */
  @Input() excludesProduct!: string[];
}
