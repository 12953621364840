import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@services/auth/auth.service';
import { switchMap, take, tap } from 'rxjs';
import { AccountService } from 'src/app/services/account/account.service';
import { PaymentService } from 'src/app/services/payment/payment.service';

@Component({
  selector: 'mt-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookingConfirmationComponent {
  /**
   * Input of product name
   */
  @Input() product!: string;

  /**
   * Input of payment method type is invoice
   * @defaultValue false
   */
  @Input() isPaymentMethodInvoice = false;

  /**
   * Update necessary data after plan puchase/upgrade
   */
  updateSubscriptionData$ = this.authService.authorizationScope$.pipe(
    take(1),
    switchMap(authorization => this.paymentService.getCustomerSubscriptionData(authorization.accountId)),
    tap(() => {
      this.accountService.refreshJwtToken();
    })
  );

  constructor(
    private accountService: AccountService,
    private authService: AuthService,
    private paymentService: PaymentService,
    public modal: NgbActiveModal
  ) {}
}
