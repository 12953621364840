<div data-hj-suppress class="flex-center-left employeecol first">
  <div style="margin-right: 10px">
    <img
      class="img-circle user-image-size"
      *ngIf="user?.userHasImage"
      src="{{ user.userImage }}"
      alt="User Profile Image"
    />
    <ngx-avatars
      *ngIf="!user?.userHasImage"
      name="{{ displayNameShort }}"
      bgColor="#D1D1EA"
      [style]="customStyle"
    ></ngx-avatars>
  </div>
  <div class="overflowxhidden">
    <div class="title overflowxhidden name">
      <a
        ngbTooltip="{{ displayName }}"
        tooltipClass="status-info-tooltip"
        placement="top"
        *ngIf="!disableLinks"
        [routerLink]="['/employees/details/' + user.id]"
        (click)="redirectToEmployeeDetail.emit()"
      >
        <span>{{ displayName }}</span>
      </a>
      <ng-container *ngIf="disableLinks">
        <span>{{ displayName }}</span>
      </ng-container>

      <mt-svg
        *ngIf="O365Group.hasO365Connection"
        ngbTooltip="{{
          !O365Group.isUserManuallyAssigned
            ? ('mo_emp_assigned_m365_rule' | transloco)
            : ('mo_emp_assigned_manually' | transloco)
        }}"
        placement="top"
        tooltipClass="status-info-tooltip"
        [src]="
          !O365Group.isUserManuallyAssigned
            ? 'assets/images/svgs/sync-arrows.svg'
            : 'assets/images/svgs/gear.svg'
        "
        class="medium-svg mt-grey ms-1"
      ></mt-svg>
    </div>

    <div class="subline overflowxhidden">
      <span>{{ displayEmail }}</span>
    </div>
  </div>
</div>
