<input
  type="file"
  class="file-input"
  accept="image/*"
  (change)="processFile($event)"
  data-cy="userPhoto"
  #imageInput
/>

<div class="file-upload">
  <mt-button
    (click)="imageInput.click()"
    color="primary"
    icon="open"
    text="{{ buttonTitle }}"
  ></mt-button>
</div>
