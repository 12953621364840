<ng-container
  *ngIf="targetGroupEntriesAndProspectsList$ | async"
></ng-container>
<div
  class="manuallyAddedDomainsModal"
  *ngIf="authService.authorizationScope$ | async as authorizationScope"
>
  <mt-custom-modal headline="{{ 'add_domains' | transloco }}">
    <!-- Modal Body -->
    <ng-container modal-body>
      <div class="flex-center-all baseonecolumn">
        <div class="row mt-columns-wrapper">
          <!-- Created Domains column -->
          <div class="col-lg-4 col-md-4 mt-left-column">
            <p>
              <span transloco="created_domains" class="format2"></span>
              <span class="format2">({{ manuallyAddedDomains.length }})</span>
            </p>

            <div *ngIf="manuallyAddedDomains.length === 0" class="mt-info-text">
              <span transloco="html.fill_in_txt_to_create_domain"></span>
            </div>

            <div *ngIf="manuallyAddedDomains.length > 0" class="">
              <div
                class="mt-tile"
                *ngFor="let domain of manuallyAddedDomains"
                [ngClass]="{
                  'mt-tile_selected':
                    selectedDomain && domain.content === selectedDomain.content
                }"
                (click)="onCreatedDomainClick(domain)"
              >
                <div class="inline-block list-item-box">
                  <p>
                    <strong>{{ domain.content }}</strong>
                  </p>
                  <p class="list-item-cname">
                    <span> {{ domain.company }} </span>
                  </p>
                </div>
                <div
                  class="inline-block"
                  (click)="clearDomain(domain); $event.stopPropagation()"
                >
                  <mt-svg
                    class="tiny-svg cursor-pointer"
                    src="assets/images/campaigns/cancel.svg"
                  ></mt-svg>
                </div>
              </div>
            </div>
          </div>

          <!-- Create Domain column -->
          <div class="col-lg-8 col-md-8 mt-right-column">
            <div class="title-create">
              <span class="col-sm-offset-1 format2">{{
                selectedDomain && selectedDomain.content
                  ? ('Edit' | transloco) + ' ' + ('Domain' | transloco)
                  : ('create_domains' | transloco)
              }}</span>
            </div>

            <form
              *ngIf="domainDataForm"
              [formGroup]="domainDataForm"
              class="form-horizontal"
            >
              <div class="domain-form">
                <div class="form-group" *ngFor="let item of accountData">
                  <div class="col-sm-4 col-sm-offset-1">
                    <label class="format3"
                      ><span [transloco]="item.field"></span>
                      <span *ngIf="item.required">
                        (<span transloco="required"></span>)</span
                      >
                    </label>
                  </div>
                  <div class="col-sm-5 formcontrolright">
                    <input
                      type="text"
                      [formControlName]="item.backendField"
                      class="form-control input-sm"
                      placeholder="{{ item.inputPlaceholderKey | transloco }}"
                    />
                  </div>
                  <div class="col-sm-1 no-padding tooltip-position">
                    <div
                      *ngIf="
                        domainDataForm.controls['content'].disabled &&
                        item.notEditableInfoMsg
                      "
                      class="info-icon"
                      ngbTooltip="{{ item.notEditableInfoMsg | transloco }}"
                      placement="top"
                      tooltipClass="status-info-tooltip"
                    >
                      <mt-svg
                        class="mt-grey tiny-svg"
                        src="assets/images/svgs/info.svg"
                      ></mt-svg>
                    </div>
                  </div>
                  <div class="col-sm-9 col-sm-offset-1">
                    <div
                      class="infobox infobox-error martop10 marbot10"
                      *ngIf="
                        item.field === 'domain' &&
                        domainDataForm.controls['content'].errors?.alreadyExist
                      "
                    >
                      <span class="symbol glyphicon glyphicon-alert"></span>
                      <div>
                        <div class="text">
                          <div
                            [innerHtml]="
                              'html.csv_domain_already_exist' | transloco
                            "
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div class="col-sm-10 padright30">
              <mt-button
                class="roboto_light pull-right"
                color="primary"
                text="{{
                  selectedDomain && selectedDomain.content ? 'Save' : 'create'
                }}"
                [disabled]="domainDataForm.invalid"
                (click)="onSubmit()"
              >
              </mt-button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container modal-footer>
      <div class="inline-flex-left footer">
        <div class="flex text-left">
          <input
            id="termsCheckbox"
            type="checkbox"
            class="marright10 cursor-pointer"
            [(ngModel)]="acceptedTrackingTerms"
          />
          <label for="termsCheckbox" class="terms-checkbox-lbl cursor-pointer"
            >{{ 'allowed_to_add_contact_domain' | transloco }}
          </label>
          <a
            target="_blank"
            [href]="
              translate.getActiveLang() === 'de'
                ? 'https://help.mailtastic.com/de/articles/4752798-domains-und-kontakte-tracken'
                : 'https://help.mailtastic.com/en/articles/4752798-tracking-domains-and-contacts'
            "
          >
            <span
              class="marleft5"
              tooltipClass="status-info-tooltip"
              placement="top"
              ngbTooltip="{{ 'conform_tracking_mo_info' | transloco }}"
            >
            </span>
            <mt-svg
              src="assets/images/svgs/info_other_svg.svg"
              class="mt-grau_opaque tiny-svg"
            ></mt-svg>
          </a>
        </div>
      </div>

      <div class="inline-flex-right">
        <div class="flex">
          <mt-button
            ngbAutoFocus
            text="abort"
            color="default"
            class="mt_left"
            (click)="modal.close([])"
          ></mt-button>
          <mt-button
            text="{{
              translate.translate('param.add_created_domains', {
                amount: this.manuallyAddedDomains.length
              })
            }}"
            color="primary"
            class="roboto_light"
            [disabled]="
              manuallyAddedDomains.length === 0 || !acceptedTrackingTerms
            "
            (click)="
              onAddCreatedDomains(
                authorizationScope.adminId,
                authorizationScope.accountId
              )
            "
          >
          </mt-button>
        </div>
      </div>
    </ng-container>
  </mt-custom-modal>
</div>
<!-- LOADING -->
<ng-template #loading>
  <div class="card martop20">
    <mt-emptystate [loadingPromise]="true"></mt-emptystate></div
></ng-template>
