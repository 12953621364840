import { inject, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

// Interfaces
import { ICampaignGet, ICampaignGetAllBy } from '../campaign/campaign-services.interface';
import { GroupBasicDataWithSignature, IEmployeeGet, EmployeeDetails } from '../employee/employee-service.interface';
import { IGroup, IGroupObject, GroupSmall } from 'src/app/model/interfaces/group.interface';
import { IGroupGetMembers, GroupGetOne, GetEmployeeGroupNotMember } from '../group/group-service.interface';
import { ISignatureGetAll } from '../signature/signature-service.interface';
import { ImageCropper, IUserGetAdmins } from 'src/app/model/interfaces/user.interface';
import { SELECT_TYPE } from '../list-helper/list-helper-service.interface';
import {
  TargetGroupGetTargetGroupEntries,
  TargetGroupsGetTargetGroups
} from 'src/app/services/target-group/target-group-service.type';
import { BookingConfig, BookingContainer, PriceData, Product } from 'src/app/model/interfaces/payment.interface';
import { GetOverallStatsResponse } from '../user/user-service.interface';

// Modals

import { AlertGroupWithEmptyEmployeesComponent } from 'src/app/modules/signatures/components/alert-group-with-empty-employees/alert-group-with-empty-employees.component';
import { GuideSignatureListModalComponent } from 'src/app/modules/get-started-guide/components/modals/guide-signature-list-modal/guide-signature-list-modal.component';
import { ImageCropperModalComponent } from 'src/app/shared/components/organisms/modals/signature-placeholder-type/image-cropper/image-cropper.component';
import {
  CompanyDataImage,
  ImageCropped,
  ISignaturePlaceholderImg,
  UploadProfilePhotoData,
  ImageDimensionWithMode
} from 'src/app/model/interfaces/signature.interface';

import { ResetPasswordModalComponent } from 'src/app/modules/auth/components/modals/reset-password/reset-password-modal.component';
import { RolloutConfirmType } from 'src/app/model/interfaces/signature.interface';
import { SelectNotificationsCampaignComponent } from 'src/app/modules/notifications/components/modals/select-notifications-campaign/select-notifications-campaign.component';
import { SelectNotificationsTargetGroupsComponent } from 'src/app/modules/notifications/components/modals/select-notifications-target-groups/select-notifications-target-groups.component';
import { SetPasswordComponent } from 'src/app/modules/auth/components/modals/set-password/set-password.component';

import { UnsavedChanges } from 'src/app/model/types/unsaved-changes.type';
import { filter, from, map, Observable, take } from 'rxjs';
import { BookingConfirmationComponent } from 'src/app/modules/booking/components/modals/booking-confirmation/booking-confirmation.component';
import { IDataset } from 'src/app/model/interfaces/dataset.interface';
import { PlanUpgradeComponent } from 'src/app/modules/booking/components/modals/plan-upgrade/plan-upgrade.component';
import { ChooseCreateTypeModalData } from 'src/app/model/interfaces/event.interface';
import { GatedContentInfoComponent } from 'src/app/shared/components/organisms/modals/gated-content-info/gated-content-info.component';
import { ManuallyAddDomainsModalComponent } from 'src/app/shared/components/organisms/modals/manually-add-domains-modal/manually-add-domains-modal.component';
import { DomainInputFields } from 'src/app/model/interfaces/recipient.interface';
import { cloneDeep } from 'lodash-es';
import {
  ITargetGroupListEntry,
  ITargetGroupListEntryCampaign
} from 'src/app/model/interfaces/target-group-list.interface';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';
import { TargetSender } from 'src/app/model/enums/target-sender.enum';
import { AssignTargetgroupModalComponent } from 'src/app/shared/components/organisms/modals/assign-targetgroup-modal/assign-targetgroup-modal.component';
import { ConnectionSyncDisconnectType } from '@services/azure-sync/azure-sync.interface';
import { TrialVersionExpiredComponent } from '@shared/components/organisms/modals/trial-version-expired/trial-version-expired.component';
import { UsersToBuyComponent } from '@shared/components/organisms/modals/users-to-buy/users-to-buy.component';
import { AddEmployeeToGroupComponent } from '@shared/components/organisms/modals/employee/add-employee-to-group/add-employee-to-group.component';
import { AddSignatureToGroupComponent } from '@shared/components/organisms/modals/group/add-signature-to-group/add-signature-to-group.component';
import { DuplicateGroupModalComponent } from '@modules/groups/components/modals/duplicate-group/duplicate-group.component';
import { AddTargetToCampaignModalComponent } from '@shared/components/organisms/modals/add-target-to-campaign/add-target-to-campaign.component';
import { AssignCampaignModalComponent } from '@shared/components/organisms/modals/assign-campaign-modal/assign-campaign-modal.component';
import { CancelConnectionComponent } from '@shared/components/organisms/modals/cancel-connection/cancel-connection.component';
import { EditAccountDataComponent } from '@shared/components/organisms/modals/edit-account-data/edit-account-data.component';
import { EditUserCompanyDataModalComponent } from '@shared/components/organisms/modals/edit-user-company-data/edit-user-company-data.component';
import { EditUserDataModalComponent } from '@shared/components/organisms/modals/edit-user-data/edit-user-data.component';
import { AssignDefaultSignatureModalComponent } from '@shared/components/organisms/modals/employee/assign-default-signature-modal/assign-default-signature-modal.component';
import { AssignGroupModalComponent } from '@shared/components/organisms/modals/group/assign-group-modal/assign-group-modal.component';
import { CreateEditGroupModalComponent } from '@shared/components/organisms/modals/group/create-edit-group-modal/create-edit-group-modal.component';
import { InfobarPlayVideoComponent } from '@shared/components/organisms/modals/infobar-play-video/infobar-play-video.component';
import { SelectCreationModalComponent } from '@shared/components/organisms/modals/select-creation/select-creation.component';
import { UnsavedChangesComponent } from '@shared/components/organisms/modals/unsaved-changes/unsaved-changes.component';
import { UploadCompanyLogoComponent } from '@shared/components/organisms/modals/upload-company-logo/upload-company-logo.component';
import { UploadProfilePhotoComponent } from '@shared/components/organisms/modals/upload-profile-photo/upload-profile-photo.component';
import { AlertCompleteAccountDataComponent } from '@modules/signatures/components/modals/alert-complete-account-data/alert-complete-account-data.component';
import { InstallSignatureModalColleaguesComponent } from '@shared/components/organisms/modals/install-signature-modal-colleagues/install-signature-modal-colleagues.component';
import { InstallSignatureModalToChooseComponent } from '@shared/components/organisms/modals/install-signature-modal-to-choose/install-signature-modal-to-choose.component';
import { InstallSignatureModalComponent } from '@shared/components/organisms/modals/install-signature-modal/install-signature-modal.component';
import { RolloutConfirmComponent } from '@modules/signatures/components/modals/rollout-confirm/rollout-confirm.component';
import { DomainOptOutModalComponent } from '@modules/recipients/components/modals/domain-opt-out-modal/domain-opt-out-modal.component';
import { EditDomainModalComponent } from '@modules/recipients/components/modals/edit-domain-modal/edit-domain-modal.component';
import { SelectTargetGroupModalComponent } from '@modules/recipients/components/modals/select-target-group-modal/select-target-group-modal.component';
import { InviteAdminComponent } from '@modules/settings/components/modals/invite-admin/invite-admin.component';
import { InviteExternalAdminComponent } from '@modules/settings/components/modals/invite-external-admin/invite-external-admin.component';
import { UsersToBuyLicenses } from '@model/interfaces/plans.interface';
import { FailedLoginModalComponent } from '@modules/auth/components/modals/failed-login-modal/failed-login-modal.component';
import { EDataType } from '@model/enums/data-type.enum';
import { AddDirectoryExtensionAttributeComponent } from '@shared/components/organisms/data-mapping/modal/add-directory-extension-attribute/add-directory-extension-attribute.component';
import { O365ExtensionProperty } from '@modules/o365/model/interfaces/o365-preferences.interface';
import { RBARole } from '@services/rba/rba-settings.interface';
import { AlertService } from '@services/alert/alert.service';
import { EBoxType } from '@model/enums/box-type.enum';
import { ImageCropperV2Component } from '@shared/components/organisms/modals/signature-placeholder-type/image-cropper-v2/image-cropper-v2.component';
import { TranslationDictionary } from '@services/account/account-service.interface';
import { AddEditDictionaryComponent } from '@modules/settings/components/modals/add-edit-dictionary/add-edit-dictionary.component';
import { UploadDictionaryComponent } from '@modules/settings/components/modals/upload-dictionary/upload-dictionary.component';

import { AssignEmployeeModalComponent } from '@shared/components/organisms/modals/employee/assign-employee-modal/assign-employee-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  protected alert = inject(AlertService);
  protected modal = inject(NgbModal);
  protected operators = inject(CustomOperators);

  /**
   * Modal options
   */
  modalOptions: NgbModalOptions = {
    animation: true,
    backdrop: 'static',
    backdropClass: 'mt_modal_default_backdrop'
  };

  /**
   * @deprecated Use openImageCropperModalV2 instead as a observable
   *
   * Opens a modal to crop uploaded image for user profile
   * @param image - selected image to crop
   * @returns cropped image data
   */
  async openImageCropperModal(image: HTMLImageElement): Promise<ISignaturePlaceholderImg> {
    const modalInstance = this.modal.open(ImageCropperModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeModal mt_modal_930'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['displayImage'] = image;

    return (await modalInstance.result) as ISignaturePlaceholderImg;
  }

  /**
   * Opens a modal to crop uploaded image for user profile
   * @param image - selected image to crop
   * @returns cropped image data
   */
  openImageCropperModalV2(image: HTMLImageElement): Observable<ImageCropper> {
    const modalInstance = this.modal.open(ImageCropperV2Component, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeModal mt_modal_930'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['displayImage'] = image;

    return from(modalInstance.result as Promise<ImageCropper>).pipe(take(1));
  }

  /**
   * Opens the modal to upload a new profile photo
   * @param uploadProfilePhotoData - Details of dataset, user, and image object
   * @param imageDimensions - custom image dimensions
   * @param availableOptions - Determines which of the advanced options are shown
   * @param defaultDataset - Determines default dataset for upload photo fromenduser cockpit while user not logged in
   * @returns Whether a new photo was uploaded or not
   */
  openUploadProfilePhotoModal(
    uploadProfilePhotoData?: UploadProfilePhotoData,
    imageDimensions?: ImageDimensionWithMode,
    availableOptions?: {
      photoUrl: boolean;
      useForAllEmployees: boolean;
      useForAllProfiles: boolean;
      customDimensions: boolean;
    },
    defaultDataset?: IDataset
  ): Observable<UploadProfilePhotoData> {
    const modalInstance = this.modal.open(UploadProfilePhotoComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeModal mt_modal_930'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['uploadProfilePhotoData'] = cloneDeep(uploadProfilePhotoData);

    if (imageDimensions) {
      modalInstance.componentInstance['imageDimensions'] = imageDimensions;
    }

    if (availableOptions) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['availableOptions'] = availableOptions;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['defaultDataset'] = defaultDataset || ({} as IDataset);

    return from(modalInstance.result as Promise<UploadProfilePhotoData>).pipe(
      this.operators.filterNullish(),
      map(result => {
        // Check if photo was removed
        if (
          result.imageUploaded?.value?.image &&
          typeof result.imageUploaded?.value?.image !== 'string' &&
          !result.imageUploaded?.value?.image?.$ngfDataUrl?.length
        ) {
          result.imageUploaded.value.image = '';
        }
        return result;
      })
    );
  }

  /**
   * Opens a modal to assign a default signature for the user
   * @param userId - User to assign to
   * @returns Id of group assigned
   */
  async openAssignDefaultSignatureModal(userId: string): Promise<number> {
    const modalInstance = this.modal.open(AssignDefaultSignatureModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_1200 baseonecolumn'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['userId'] = userId;

    return (await modalInstance.result) as number;
  }

  /**
   * Opens a modal to assign existing groups to the employee
   * @param assignedGroups - Groups the employee is already assigned to
   * @param selectionType - Used to pass in `single` to use single selection in the list.
   * @param checkForActiveSignature - Used to pass if we should check for existing signature when assigning the department.
   * By default `multi` is used
   * @returns Array of new groups to assign the employee to
   */
  async openSelectExistingGroupsModal(
    assignedGroups?: (IGroup | IGroupObject | GroupGetOne | GroupBasicDataWithSignature)[],
    selectionType?: SELECT_TYPE,
    checkForActiveSignature?: boolean
  ): Promise<GroupSmall[]> {
    const modalInstance = this.modal.open(AssignGroupModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_930 mt_modal_with_head wizard baseonecolumn'
    });

    if (checkForActiveSignature) {
      modalInstance.componentInstance.checkForActiveSignature = checkForActiveSignature;
    }

    if (assignedGroups?.length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['assignedGroups'] = assignedGroups;
    }

    if (selectionType) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['selectionType'] = selectionType;
    }

    return ((await modalInstance.result) || []) as GroupSmall[];
  }

  /**
   * Opens a modal to assign existing groups to the employee
   * @param assignedGroups - Groups the employee is already assigned to
   * @returns Array of new groups to assign the employee to
   */
  openAssignGroupModal(assignedGroups: IGroupObject[]): Observable<IGroupObject[]> {
    const modalInstance = this.modal.open(AssignGroupModalComponent, {
      ...this.modalOptions,
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal mt_modal_930'
    });

    modalInstance.componentInstance.assignedGroups = assignedGroups;

    return from(modalInstance.result).pipe(take(1));
  }

  /**
   * Opens a modal to assign existing campaign to the employee
   * @param dataObject - campaign the employee is already assigned to
   * @returns Array of new campaign to assign the employee to
   */
  openAddTargetToCampaignModal(
    dataObject: ITargetGroupListEntryCampaign[]
  ): Observable<ITargetGroupListEntryCampaign[]> {
    const modalInstance = this.modal.open(AddTargetToCampaignModalComponent, {
      ...this.modalOptions,
      backdrop: 'static',
      backdropClass: 'mt_modal_default_backdrop',
      windowClass: 'mt_modal mt_modal_930'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['dataObject'] = dataObject;
    return from(modalInstance.result).pipe(take(1));
  }

  /**
   * Opens a modal to create a new group or edit an existing one's title
   * @param groupToEdit - Group to edit title for
   * @returns Boolean when editing a group. Number when creating a new one.
   */
  async openCreateEditGroupModal(groupToEdit?: IGroup): Promise<boolean | number> {
    const modalInstance = this.modal.open(CreateEditGroupModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_930'
    });

    if (groupToEdit) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['group'] = groupToEdit;
    }

    return (await modalInstance.result) as boolean | number;
  }

  /**
   * Opens a modal to edit the user data
   * @param user - Employee to edit data
   * @param btnText - Text tag displayed on save button
   * @param isCancelButton - Determine to display cancel button
   * @returns Observable of changes made
   */
  openEditUserDataModal(
    user: EmployeeDetails,
    btnText = 'endprematurely',
    isCancelButton?: boolean
  ): Observable<boolean> {
    const modalInstance = this.modal.open(EditUserDataModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeDataEditModal mt_modal_700'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['user'] = user;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['btnText'] = btnText;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['showCancelButton'] = isCancelButton;

    return from(modalInstance.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * @deprecated User openPlayVideoModalV2() instead
   * Opens a modal to play a video
   * @param url - translate tag containing url
   * @param title - translate tag containing title
   * @returns Boolean indicating video was closed - useful for tour guide
   */
  async openPlayVideoModal(url: string, title: string): Promise<boolean> {
    const modalRef = this.modal.open(InfobarPlayVideoComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_background_color',
      size: 'md',
      backdrop: false
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.youtubeLink = url;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.modalHeader = title;

    // waits for the modal to close
    await modalRef.result;

    return true;
  }

  /**
   * Opens a modal to play a video
   * @param url - translate tag containing url
   * @param title - translate tag containing title
   * @returns Boolean indicating video was closed - useful for tour guide
   */
  openPlayVideoModalV2(url: string, title: string): Observable<boolean> {
    const modalRef = this.modal.open(InfobarPlayVideoComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_background_color',
      size: 'md',
      backdrop: false
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.youtubeLink = url;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.modalHeader = title;

    // waits for the modal to close
    return from(modalRef.result as Promise<boolean>).pipe(
      take(1),
      map(() => true)
    );
  }

  /**
   * Opens a modal to assign existing employees to a group
   * @param assignedEmployees - Employees already assigned to the group
   * @returns Array of new employees to assign (or empty)
   */
  async openSelectExistingEmployeesModal(
    assignedEmployees?: IEmployeeGet[] | IGroupGetMembers[]
  ): Promise<IEmployeeGet[]> {
    const modalInstance = this.modal.open(AddEmployeeToGroupComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_900 baseonecolumn'
    });

    if (assignedEmployees?.length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['assignedEmployees'] = assignedEmployees;
    }

    return ((await modalInstance.result) || []) as IEmployeeGet[];
  }

  /**
   * Opens a modal to alert group has 0 employees OR rollout error because group has 0 employees
   * @param errorMessageS1 - Error message to display
   * @param errorMessageS2 - Error message to display
   * @param isRollOutError - true for rollout error and false for group has 0 employees error
   * @returns boolean if user wants to assign or not
   */
  async openAlertGroupsWithEmptyEmployeesModal(
    errorMessageS1: string,
    errorMessageS2: string,
    isRollOutError: boolean
  ): Promise<boolean> {
    const modalInstance = this.modal.open(AlertGroupWithEmptyEmployeesComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_900 baseonecolumn'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.errorMessageS1 = errorMessageS1;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.errorMessageS2 = errorMessageS2;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.isRollOutError = isRollOutError;

    return (await modalInstance.result) as boolean;
  }

  /**
   * @deprecated Use openSelectSignatureModalV2()
   * Opens a modal to assign a signature to a group
   * @param assignedSignature - Id of the signature currently assigned
   * @returns Signature assigned or `undefined`
   */
  async openSelectSignatureModal(assignedSignature?: string): Promise<ISignatureGetAll | undefined> {
    const modalInstance = this.modal.open(AddSignatureToGroupComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_900 baseonecolumn wizard mt_modal_with_head'
    });

    if (assignedSignature) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['selectedSignature'] = assignedSignature;
    }

    return (await modalInstance.result) as ISignatureGetAll | undefined;
  }

  /**
   * Opens a modal to assign a signature to a group
   * @param assignedSignature - Id of the signature currently assigned
   * @returns Signature assigned or `undefined`
   */
  openSelectSignatureModalV2(assignedSignature?: string): Observable<ISignatureGetAll> {
    const modalInstance = this.modal.open(AddSignatureToGroupComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_900 baseonecolumn wizard mt_modal_with_head'
    });

    if (assignedSignature) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['selectedSignature'] = assignedSignature;
    }

    return from(modalInstance.result as Promise<ISignatureGetAll>).pipe(take(1), this.operators.filterNullish());
  }

  /**
   * Opens a modal to assign a campaign to a group, target group, event, etc
   * @param campaignType - Type of campaigns to load
   * @param assignedCampaign - Id of the campaign currently assigned
   * @returns Campaign chosen
   */
  openSelectCampaignModalV2(campaignType: TargetSender, assignedCampaign?: number): Observable<ICampaignGetAllBy> {
    const modalInstance = this.modal.open(AssignCampaignModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_900 baseonecolumn wizard mt_modal_with_head'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['selectedCampaign'] = assignedCampaign;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['campaignType'] = campaignType;

    return from(modalInstance.result as Promise<ICampaignGetAllBy>).pipe(this.operators.filterNullish());
  }

  /**
   * Opens a modal to duplicate a group
   * @param groupId - Group identifier to duplicate
   * @returns Observable with boolean of true
   */
  openDuplicateGroupModal(groupId: number): Observable<boolean> {
    const modalInstance = this.modal.open(DuplicateGroupModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_with_head groupCopyModal'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['groupId'] = groupId;

    return from(modalInstance.result as Promise<boolean>).pipe(this.operators.filterNullish());
  }

  /**
   * Opens a modal to choose the installation type for the admin/user or colleagues
   * @param signatureId - Signature to install
   * @param modalToDisplay - If `signatureGuide` should be shown right away
   * @param isExternalAdmin - If external admin or not
   */
  openInstallationForWhomModal(
    signatureId?: string,
    modalToDisplay?: 'selection' | 'signatureGuide',
    isExternalAdmin?: boolean
  ): void {
    const modalInstance = this.modal.open(InstallSignatureModalToChooseComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_930'
    });

    if (signatureId) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['signatureId'] = signatureId;
    }

    if (modalToDisplay) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['modalToDisplay'] = modalToDisplay;
    }

    if (isExternalAdmin) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['isExternalAdmin'] = isExternalAdmin;
    }
  }

  /**
   * Opens a modal to 'install' a signature for the admin or a user
   * @param signatureId - Signature to install
   * @param userId - The user id
   */
  openInstallSignatureModal(signatureId: string, userId: string): void {
    const modalInstance = this.modal.open(InstallSignatureModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_930'
    });

    const modalData = {
      signatureId,
      userId
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['inputData'] = modalData;
  }

  /**
   * Opens a modal to 'install' a signature for the colleagues
   * @param signatureId - Signature to install
   * @param userId - The logged in user id
   * @param isExternalAdmin - If the logged in user is external admin or not
   */
  openInstallSignatureModalForColleagues(signatureId: string, userId: string, isExternalAdmin: boolean): void {
    const modalInstance = this.modal.open(InstallSignatureModalColleaguesComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_930'
    });

    const modalData = {
      isExternalAdmin,
      signatureId,
      userId
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['modalData'] = modalData;
  }

  /**
   * Opens the modal to confirm rollout
   * @param title - The title of modal
   * @param description - The description of modal
   * @param isSaveBtn - Save button shown
   * @param isBackBtn - Go back button shown
   * @returns String of any type from RolloutConfirmType
   */
  async openRolloutConfirmModal(
    title?: string,
    description?: string,
    isSaveBtn?: boolean,
    isBackBtn?: boolean
  ): Promise<RolloutConfirmType> {
    const modalRef = this.modal.open(RolloutConfirmComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'md'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.modalHeader = title || 'HOWDOYOUWANTTOPROCEED';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.text = description || 'html.not_rolledout_yet';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.isSaveBtn = isSaveBtn;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.isBackBtn = isBackBtn;

    return (await modalRef.result) as RolloutConfirmType;
  }

  /**
   * Opens a modal for the signature list to rollout from guide
   * @param list - Signature list
   * @returns Boolean indicating modal was closed - useful for tour guide
   */
  async openGuideSignaturteListModal(list: ISignatureGetAll[]): Promise<boolean> {
    const modalRef = this.modal.open(GuideSignatureListModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_990',
      size: 'md'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.list = list;
    return (await modalRef.result) as boolean;
  }

  /**
   * Open subscription booking confirmation modal
   * @param product - Product name
   * @param isPaymentMethodInvoice - Boolean value to show the notice for chargify invoice payment
   * @returns Confirmation of the prompt
   */
  openBookingConfirmationModal(product: Product, isPaymentMethodInvoice = false): Observable<boolean> {
    const modalInstance = this.modal.open(BookingConfirmationComponent, {
      ...this.modalOptions,
      keyboard: false,
      windowClass: 'mt_modal',
      size: 'md'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.product = product;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.isPaymentMethodInvoice = isPaymentMethodInvoice;

    return from(modalInstance.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * Open plan upgrade modal
   * @param config - Default config object
   * @param bookingContainer - Object with all plan features
   * @param priceData - Object of PriceData
   * @param currentProduct - Current subscribed product
   * @returns Selected new plan
   */
  openPlanUpgradeModal(
    config: BookingConfig,
    bookingContainer: BookingContainer,
    priceData: PriceData,
    currentProduct: Product,
    statData: GetOverallStatsResponse
  ): Observable<Product> {
    const modalInstance = this.modal.open(PlanUpgradeComponent, {
      ...this.modalOptions,
      windowClass: 'mt_changeproduct_modal',
      size: 'lg'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.config = config;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.bookingContainer = bookingContainer;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.priceData = priceData;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.currentProduct = currentProduct;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.statData = statData;

    return from(modalInstance.result as Promise<Product>).pipe(this.operators.filterNullish());
  }

  /**
   * Open plan upgrade modal
   * @returns Boolean indicating modal was closed
   */
  openGatedContentInfoModal(): Observable<boolean> {
    const modalRef = this.modal.open(GatedContentInfoComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_gated_content',
      backdropClass: 'light-blue-backdrop',
      size: 'md',
      keyboard: false
    });

    return from(modalRef.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * @deprecated Use openChangeOptOutStatusModalV2
   * Opens a modal for the Opt In Out changes of target group list entry
   * @param targetGroupListEntry - Object of target group list entry to change OptInOut
   * @returns Boolean indicating modal was closed
   */
  async openChangeOptOutStatusModal(targetGroupListEntry: TargetGroupGetTargetGroupEntries): Promise<boolean> {
    const modalRef = this.modal.open(DomainOptOutModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'md'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.targetGroupListEntry = targetGroupListEntry;
    return (await modalRef.result) as boolean;
  }

  /**
   * Opens a modal to confirm changes of Opt in/out status of account
   * @param targetGroupListEntry - Account to update
   * @returns Boolean with confirmation
   */
  openChangeOptOutStatusModalV2(targetGroupListEntry: ITargetGroupListEntry): Observable<boolean> {
    const modalRef = this.modal.open(DomainOptOutModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'md'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.targetGroupListEntry = targetGroupListEntry;

    return from(modalRef.result as Promise<boolean>).pipe(this.operators.filterNullish());
  }

  /**
   * @deprecated Use openChangeTargetGroupModalV2
   * Opens to change target group for the target group list entry
   * @param targetGroupListId - Id to remove from list on change target group
   * @returns Selected target group to update with
   */
  async openChangeTargetGroupModal(targetGroupListId?: string): Promise<TargetGroupsGetTargetGroups> {
    const modalRef = this.modal.open(SelectTargetGroupModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'lg'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.targetGroupListId = targetGroupListId;
    return (await modalRef.result) as TargetGroupsGetTargetGroups;
  }

  /**
   * Opens a modal change target group assignment for the target group list entry
   * @param targetGroupListId - Id of the currently assigned target group
   * @returns Id of the selected target group
   */
  openChangeTargetGroupModalV2(targetGroupListId?: string): Observable<string> {
    const modalRef = this.modal.open(SelectTargetGroupModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'lg'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.targetGroupListId = targetGroupListId;

    return from(modalRef.result as Promise<TargetGroupsGetTargetGroups>).pipe(
      this.operators.filterNullish(),
      map(targetGroup => targetGroup.id)
    );
  }

  /**
   * Opens a modal to manually create a domain - target group list entry
   * @param currentAccounts - Currently created accounts (content field) - used only on wizards
   * @returns Observable with array of created domains
   */
  openAddDomainManuallyModal(currentAccounts?: string[]): Observable<DomainInputFields[]> {
    const modalInstance = this.modal.open(ManuallyAddDomainsModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'lg'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.currentAccounts = currentAccounts;

    return from(modalInstance.result as Promise<DomainInputFields[]>).pipe(this.operators.filterSelectedItems());
  }

  /**
   * @deprecated Use openEditDomainModalV2
   * Opens a modal for edit target group list entry
   * @param targetGroupListEntry - Object of target group list entry
   * @returns Object of target group list entry
   */
  async openEditDomainModal(
    targetGroupListEntry: TargetGroupGetTargetGroupEntries
  ): Promise<TargetGroupGetTargetGroupEntries> {
    const modalRef = this.modal.open(EditDomainModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'md'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.targetGroupListEntry = targetGroupListEntry;
    return (await modalRef.result) as TargetGroupGetTargetGroupEntries;
  }

  /**
   * Opens a modal to edit a target group list entry
   * @param targetGroupListEntry - Object of target group list entry
   * @returns Object of target group list entry
   */
  openEditDomainModalV2(targetGroupListEntry: ITargetGroupListEntry): Observable<TargetGroupGetTargetGroupEntries> {
    const modalRef = this.modal.open(EditDomainModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'md'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.targetGroupListEntry = targetGroupListEntry;
    return from(modalRef.result as Promise<TargetGroupGetTargetGroupEntries>).pipe(this.operators.filterNullish());
  }

  /**
   * Opens the external admin invitation modal
   * @param allAdministrators - Administrator list
   * @param roles - Administrator roles
   * @returns Observable of success
   */
  openInviteExternalAdminModal(allAdministrators: IUserGetAdmins[], roles: RBARole[] = []): Observable<boolean> {
    const modalRef = this.modal.open(InviteExternalAdminComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal'
    });

    modalRef.componentInstance.administrators = allAdministrators;
    modalRef.componentInstance.roles = roles;

    return from(modalRef.result as Promise<boolean>).pipe(
      take(1),
      filter(success => success)
    );
  }

  /**
   * Opens the admin invitation modal
   * @param allAdministrators - Administrator list
   */
  async openInviteAdminModal(allAdministrators: IUserGetAdmins[]): Promise<boolean> {
    const modalRef = this.modal.open(InviteAdminComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal_500'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.administrators = allAdministrators;
    return (await modalRef.result) as boolean;
  }

  /**
   * Open set password modal when click on reset password from email
   */
  openSetPasswordModal(code: string | null, id: string | null): void {
    const modalRef = this.modal.open(SetPasswordComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mobileVersion mt_modal_500',
      size: 'md'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.data = {
      code: code,
      id: id
    };
  }

  /**
   * Opens the modal to reset the password
   */
  openResetPasswordModal(): void {
    // Open modal and handle logic/alerts within
    this.modal.open(ResetPasswordModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'md'
    });
  }

  /**
   * Opens the failed login modal because of too many attempts with the wrong password
   * @returns Observable of type boolean
   */
  openFailedLoginModal(): Observable<boolean> {
    const modalInstance = this.modal.open(FailedLoginModalComponent, {
      windowClass: 'mt_modal'
    });

    return from(modalInstance.result as Promise<boolean>).pipe(take(1), this.operators.filterConfirmed());
  }

  /**
   * Opens the modal to notify for complete account data
   */
  openAlertCompleteAccountDataModal(): Observable<boolean> {
    // Open modal and handle logic/alerts within
    const modalRef = this.modal.open(AlertCompleteAccountDataComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal',
      size: 'md'
    });

    return from(modalRef.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * @deprecated This method is deprecated - Use `openUnsavedChangesActionModal` as observable
   * Opens the modal to alert for user to the unsaved signature changes
   * @param description - The description of modal
   * @param title - The title of modal
   * @param noSaveText - The Don't save btn message of modal
   * @returns Specific string response mentioned in UnsavedChanges interface
   */
  async openUnsavedChangesAlertModal(
    description?: string,
    saveText?: string,
    title?: string,
    noSaveText?: string
  ): Promise<UnsavedChanges> {
    const modalRef = this.modal.open(UnsavedChangesComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal_500'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.modalHeader = title || 'Attention';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.text = description || 'data_changes_notification_SL';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.saveBtnText = saveText;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.noSaveBtnText = noSaveText || 'dont_save_and_exit';

    return (await modalRef.result) as UnsavedChanges;
  }

  /**
   * Opens the modal to alert for user to the unsaved signature changes
   * @param description - The description of modal
   * @param title - The title of modal
   * @param noSaveText - The Don't save btn message of modal
   * @returns Specific string response mentioned in UnsavedChanges interface
   */
  openUnsavedChangesActionModal(
    description?: string,
    saveText?: string,
    title?: string,
    noSaveText?: string
  ): Observable<UnsavedChanges> {
    const modalRef = this.modal.open(UnsavedChangesComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal_500'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.modalHeader = title || 'Attention';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.text = description || 'data_changes_notification_SL';

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.saveBtnText = saveText;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalRef.componentInstance.noSaveBtnText = noSaveText || 'dont_save_and_exit';

    return from(modalRef.result as Promise<UnsavedChanges>).pipe(take(1));
  }

  /**
   * Opens a modal to edit account data.
   * @param accountId - Account id to edit data
   * @returns Boolean indicating if changes were made to the account details
   */
  async openEditAccountDataModal(accountId: string): Promise<boolean> {
    const modalInstance = this.modal.open(EditAccountDataComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_700'
    });

    modalInstance.componentInstance.accountId = accountId;

    return (await modalInstance.result) as boolean;
  }

  /**
   * Opens a modal to edit the company data.
   * @param dataset - Dataset to edit
   * @returns Observable of changes made
   */
  openEditUserCompanyDataModal(dataset: IDataset): Observable<boolean> {
    const modalInstance = this.modal.open(EditUserCompanyDataModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_700'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.dataset = dataset;

    return from(modalInstance.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * Close all modal popup layer that we had opened
   */
  closeAllModalPopup(): void {
    this.modal.dismissAll();
  }

  /**
   * Opens a modal to assign a campaigns to a notification
   * @param assignedCampaign - Id of the campaign currently assigned
   * @returns Campaigns assigned
   */
  async openSelectNotificationCampaignsModal(assignedCampaign?: ICampaignGet[]): Promise<ICampaignGet[]> {
    const modalInstance = this.modal.open(SelectNotificationsCampaignComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_900 baseonecolumn wizard mt_modal_with_head'
    });

    if (assignedCampaign) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['selectedCampaign'] = assignedCampaign;
    }

    return (await modalInstance.result) as ICampaignGet[];
  }

  /**
   * Opens a modal to assign a target group to a notification
   * @param assignedTargetGroup - Id of the target group currently assigned
   * @returns target group assigned
   */
  async openSelectNotificationTargetGroupModal(
    assignedTargetGroup?: TargetGroupsGetTargetGroups[]
  ): Promise<TargetGroupsGetTargetGroups[]> {
    const modalInstance = this.modal.open(SelectNotificationsTargetGroupsComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_900 baseonecolumn wizard mt_modal_with_head'
    });

    if (assignedTargetGroup) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['selectedTargetGroup'] = assignedTargetGroup;
    }

    return (await modalInstance.result) as TargetGroupsGetTargetGroups[];
  }

  /**
   * @deprecated Use openUploadCompanyLogoModalV2
   *
   * Opens the modal to upload a new company logo
   * @param companyLogo - Current company logo
   * @param imageDimensions - custom image dimensions
   * @param showCustomDimension - Determines if custom dimension options should be shown
   * @returns New company logo
   */
  openUploadCompanyLogoModal(
    companyLogo?: CompanyDataImage,
    imageDimensions?: ImageDimensionWithMode,
    showCustomDimension = false
  ): Observable<CompanyDataImage & { imgdimension: ImageDimensionWithMode }> {
    const modalInstance = this.modal.open(UploadCompanyLogoComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeModal mt_modal_930'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.companyLogo = { ...companyLogo, imgdimension: imageDimensions };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.showCustomDimension = showCustomDimension;

    return from(modalInstance.result as Promise<CompanyDataImage & { imgdimension: ImageDimensionWithMode }>).pipe(
      take(1)
    );
  }

  /**
   * Opens the modal to upload a new company logo with updated response `ISignaturePlaceholderImg`
   * @param companyLogo - Current company logo
   * @param imageDimensions - custom image dimensions
   * @param showCustomDimension - Determines if custom dimension options should be shown
   * @returns New company logo
   */
  openUploadCompanyLogoModalV2(
    companyLogo?: CompanyDataImage,
    imageDimensions?: ImageDimensionWithMode,
    showCustomDimension = false
  ): Observable<ISignaturePlaceholderImg> {
    const modalInstance = this.modal.open(UploadCompanyLogoComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeModal mt_modal_930'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.companyLogo = { ...companyLogo, imgdimension: imageDimensions };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance.showCustomDimension = showCustomDimension;

    return from(modalInstance.result as Promise<ISignaturePlaceholderImg>).pipe(take(1));
  }

  /**
   * Opens a modal process an image with the option to crop it
   * @param image - Image to process
   * @returns Processed image
   */
  openCropImageModal(image: HTMLImageElement): Observable<ImageCropped> {
    const modalInstance = this.modal.open(ImageCropperModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeModal mt_modal_930'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['displayImage'] = image;

    return from(modalInstance.result as Promise<ImageCropped>).pipe(take(1));
  }

  /**
   * Opens a modal to select the create event or campaign type
   * @param dataType - value of EDataType obj
   * @param inputData - The input data of selection
   */
  openCreateEventOrCampaignModal(dataType: EDataType, inputData: ChooseCreateTypeModalData): void {
    const modalInstance = this.modal.open(SelectCreationModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeModal mt_modal_930'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['dataType'] = dataType;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['inputData'] = inputData;
  }

  /**
   * Opens a modal to select target groups to assign
   * @param assignedTargetGroups - Ids of the already assigned target groups
   * @returns Newly selected target groups to assign
   */
  openAssignTargetGroupModal(assignedTargetGroups?: string[]): Observable<TargetGroupsGetTargetGroups[]> {
    const modalInstance = this.modal.open(AssignTargetgroupModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_930'
    });

    if (assignedTargetGroups) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      modalInstance.componentInstance['assignedTargetGroups'] = assignedTargetGroups;
    }

    return from(modalInstance.result as Promise<TargetGroupsGetTargetGroups[]>).pipe(
      this.operators.filterSelectedItems()
    );
  }

  /**
   * Opens a modal to disconnect sync
   * @param header - Header of the model
   * @param warningText - Warning box details tag
   * @param credentialTextTag - Credentials header text
   * @param syncDisplayName - Admin sync display name
   * @param syncEmail - Admin sync email
   * @param cancelConnectionQuestion - Cancel connection question
   * @param removeEmployeeInformation - Remove employee information tag
   * @param keepEmployeeInformation - Keep employee information tag
   * @returns Observable of sync disconnect type
   */
  openDisconnectSyncModal(
    header: string,
    warningText: string,
    credentialTextTag: string,
    syncDisplayName: string,
    syncEmail: string,
    cancelConnectionQuestion: string,
    removeEmployeeInformation: string,
    keepEmployeeInformation: string
  ): Observable<ConnectionSyncDisconnectType> {
    const modalInstance = this.modal.open(CancelConnectionComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_700'
    });

    const inputData = {
      cancelConnectionQuestion,
      credentialTextTag,
      header,
      keepEmployeeInformation,
      removeEmployeeInformation,
      syncDisplayName,
      syncEmail,
      warningText
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['inputData'] = inputData;

    return from(modalInstance.result as Promise<ConnectionSyncDisconnectType>).pipe(
      filter(isConfirm => isConfirm !== 'abort')
    );
  }

  /**
   * Open trial version expired modal to buy mailtastic paid version
   * @returns Observable of boolean
   */
  openTrialVersionExpiredModal(): Observable<boolean> {
    const modalInstance = this.modal.open(TrialVersionExpiredComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_930 adjust_datamapping_modal'
    });

    return from(modalInstance.result as Promise<boolean>).pipe(this.operators.filterConfirmed());
  }

  /**
   * Open users to but modal for license add
   * @param usersToBuyData - Object with max user, import users count
   * @returns Observable of boolean
   */
  openUsersToBuyModal(usersToBuyData: UsersToBuyLicenses): Observable<boolean> {
    const modalInstance = this.modal.open(UsersToBuyComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_700 adjust_datamapping_modal'
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['usersToBuyData'] = usersToBuyData;

    return from(modalInstance.result as Promise<boolean>).pipe(this.operators.filterConfirmed());
  }

  /**
   * Open modal to add directory extension attribute property
   * @param o365ExtensionProperties - Array of extension properties
   * @returns String of selected extension property name
   */
  addDirectoryExtensionAttributeModal(o365ExtensionProperties: O365ExtensionProperty[]): Observable<string> {
    const modalInstance = this.modal.open(AddDirectoryExtensionAttributeComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_700'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['o365ExtensionProperties'] = o365ExtensionProperties;

    return from(modalInstance.result as Promise<string>).pipe(take(1), this.operators.filterNullish());
  }

  /**
   * Opens a modal to confirm change signature of department
   * @returns Observable of success
   */
  openSignatureAssignmentConfirmationModal(): Observable<boolean> {
    return this.alert.defaultConfirmationPrompt(
      'department_assigned_signature_exists_plural',
      EBoxType.ERROR,
      'attention_hl',
      'assign_new_signature'
    );
  }

  /**
   * Opens a modal to edit the dictionary data
   * @param dictionary - Dictionary to edit data
   * @returns Observable of changes made
   */
  openAddEditDictionaryModal(dictionary: TranslationDictionary | null = null): Observable<TranslationDictionary> {
    const modalInstance = this.modal.open(AddEditDictionaryComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeDataEditModal mt_modal_700'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['dictionaryInfo'] = dictionary;

    return from(modalInstance.result as Promise<TranslationDictionary>).pipe(take(1));
  }

  /**
   * Opens a modal to add dictionary data
   * @returns Observable of changes made
   */
  openUploadDictionaryModal(): Observable<boolean> {
    const modalInstance = this.modal.open(UploadDictionaryComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal employeeDataEditModal mt_modal_700'
    });

    return from(modalInstance.result as Promise<boolean>).pipe(take(1));
  }

  /**
   * Opens a modal to assign users to a group
   *
   * Displays users not assigned to a group id
   *
   * @param groupId - Group id
   * @returns Selected users to assign
   */
  assignEmployeeToGroupModal(groupId: number): Observable<GetEmployeeGroupNotMember[]> {
    const modalInstance = this.modal.open(AssignEmployeeModalComponent, {
      ...this.modalOptions,
      windowClass: 'mt_modal mt_modal_930'
    });

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    modalInstance.componentInstance['groupId'] = groupId;

    return from(modalInstance.result as Promise<GetEmployeeGroupNotMember[]>).pipe(
      take(1),
      this.operators.filterSelectedItems()
    );
  }
}
