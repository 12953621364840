import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'mt-rollout-confirm',
  templateUrl: './rollout-confirm.component.html',
  styleUrls: ['./rollout-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolloutConfirmComponent {
  /**
   * Modal title
   * @defaultValue ''
   */
  modalHeader = '';

  /**
   * Modal body
   * @defaultValue ''
   */
  text = '';

  /**
   * Show save button
   * @defaultValue false
   */
  isSaveBtn = false;

  /**
   * Show back button
   * @defaultValue false
   */
  isBackBtn = false;

  constructor(public modal: NgbActiveModal) {}
}
