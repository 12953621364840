import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  forkJoin,
  map,
  ReplaySubject,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import { EBoxType } from 'src/app/model/enums/box-type.enum';
import {
  AddPaymentCredentials,
  PriceData,
  Product,
  ProductData,
  ProductDuration,
  ProductLinkType
} from 'src/app/model/interfaces/payment.interface';
import { AccountService } from 'src/app/services/account/account.service';
import { AlertService } from 'src/app/services/alert/alert.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { UserService } from 'src/app/services/user/user.service';
import { ModalService } from 'src/app/services/modal/modal.service';
import { NavigationSidebarService } from 'src/app/services/navigation-sidebar/navigation-sidebar.service';
import { UtilService } from '@services/util/util.service';
import { GetOverallStatsResponse } from 'src/app/services/user/user-service.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { BOOKING_CONFIG, BOOKING_CONTAINER, SERVICE_DATA } from 'src/app/modules/booking/model/const/booking.const';
import {
  BookingFormFields,
  BookingFormInputFields,
  BookingFormPatchFields,
  ChargifySuccess
} from 'src/app/modules/booking/model/interfaces/booking.interface';

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  // Holds total selected users
  private _totalUsers$ = new BehaviorSubject(0);

  // Holds the selected product
  private _selectedProduct$ = new BehaviorSubject<Product>(BOOKING_CONFIG.defaultPlan);

  /**
   * Sets new amount of total selected users
   * @param totalUsers - New total of selected users
   */
  set totalUsers(totalUsers: number) {
    this._totalUsers$.next(totalUsers);
  }

  /**
   * Sets new product as selected
   * @param selectedProduct - New selected product
   */
  set selectedProduct(selectedProduct: Product) {
    this._selectedProduct$.next(selectedProduct);
  }

  // Emits total selected users
  totalUsers$ = this._totalUsers$.asObservable().pipe(distinctUntilChanged());

  // Emits selected product to book
  selectedProduct$ = this._selectedProduct$.asObservable().pipe(distinctUntilChanged());

  /**
   * Load user stats and products data
   */
  loadProductAndUserStatsData$ = forkJoin([this.userService.getOverallStats(), this.paymentService.getProducts()]).pipe(
    map(([[statData], productsData]) => ({ statData, productsData }))
  );

  // Calculates the price when changing total users or selected prodcut
  calcPrice$ = combineLatest([this.totalUsers$, this.selectedProduct$, this.loadProductAndUserStatsData$]).pipe(
    map(([totalUsers, selectedProduct, loadProductAndUserStatsData]) => {
      // Min that must be booked for the selected product
      const planMinBookableAmount = this.getMinAmountWhichIsBookable(
        selectedProduct,
        loadProductAndUserStatsData.productsData
      );

      // Extra users being booked, if user hasn't selected enough users to cover their current usage this amount will be used for the calculation
      // otherwise will subtract the plan's minimum from the total selected users
      const additionalUsersToBook = Math.max(
        loadProductAndUserStatsData.statData.amountOfUsers - planMinBookableAmount,
        totalUsers - planMinBookableAmount
      );

      return this.calcPrice(
        {
          ...this.bookingForm.getRawValue(),
          additionalUsers: additionalUsersToBook,
          selectedProduct,
          userAmountInput: planMinBookableAmount
        },
        loadProductAndUserStatsData.productsData,
        loadProductAndUserStatsData.statData
      );
    })
  );

  /**
   * Fixed values for setup
   */
  config = BOOKING_CONFIG;

  /**
   * Fixed values for plans feature
   */
  bookingContainer = BOOKING_CONTAINER;

  /**
   * Hold account basic information from localstorage
   */
  account$ = this.accountService.getUserAccountData();

  /**
   * Chargify after subscription callback happened
   */
  private _chargifySuccess$: Subject<ChargifySuccess> = new Subject<ChargifySuccess>();
  chargifySuccess$ = this._chargifySuccess$
    .asObservable()
    .pipe(tap(chargifySuccess => this.chargifyCompleted(chargifySuccess)));

  /**
   * Hold query param of selected plan and set it default
   */
  private _paramSelectedPlan$: ReplaySubject<string> = new ReplaySubject<string>(1);
  paramSelectedPlan$ = this._paramSelectedPlan$.asObservable();

  /**
   * Load subscription page data as per the plan selection if any
   */
  loadSubscriptionPageData$ = combineLatest([this.paramSelectedPlan$, this.loadProductAndUserStatsData$]).pipe(
    map(([paramSelectedPlan, res]) => {
      this.bookingForm.reset();
      // If URL has selectedPlan param
      if (paramSelectedPlan) {
        // Not shows prompt for target plan selection
        if (paramSelectedPlan === 'Target') {
          this.acceptedTargetTechnicalInfoShowModal = false;
        }
        this.setFormValue('selectedProduct', paramSelectedPlan as Product, true, res.productsData, res.statData, true);
      } else {
        this.setFormValue('selectedProduct', this.config.defaultPlan, true, res.productsData, res.statData, true);
      }
      return {
        ...res,
        paramSelectedPlan
      };
    })
  );

  /**
   * Load booking details page data
   */
  loadDetailsPageData$ = this.loadProductAndUserStatsData$.pipe(
    map(res => {
      const minBookableAmount = res.statData.amountOfUsers < 10 ? 10 : res.statData.amountOfUsers;
      this.bookingForm.patchValue({ additionalUsers: minBookableAmount });
      this.setPriceData(
        this.calcPrice(this.bookingForm.value as BookingFormFields, res.productsData, res.statData, true)
      );
      return { ...res };
    })
  );

  /**
   * Additional Fields to calculate price based on subscription form inputs
   * @defaultValue
   * {
   * advancedBig: '',
   * advancedSmall: '',
   * amountOfUsers: 0,
   * basePrices: this.paymentService.servicedata.plans,
   * basicBig: '',
   * basicSmall: '',
   * minAmountWhichIsBookable: 0,
   * priceAdvancedPerUser: 0,
   * priceBasicPerUser: 0,
   * pricePerUser: 0,
   * priceTargetPerUser: 0,
   * targetBig: '',
   * targetSmall: '',
   * totalAmount: 0,
   * totalUsers: 0
   * }
   */
  private _priceData$ = new BehaviorSubject<PriceData>({
    additionalUsers: 0,
    advancedBig: '',
    advancedSmall: '',
    amountOfUsers: 0,
    basePrices: SERVICE_DATA.plans,
    basicBig: '',
    basicSmall: '',
    minAmountWhichIsBookable: 0,
    planMinAmount: 0,
    priceAdvancedPerUser: 0,
    priceBasicPerUser: 0,
    pricePerUser: 0,
    priceTargetPerUser: 0,
    targetBig: '',
    targetSmall: '',
    totalAmount: 0,
    totalUsers: 0
  });
  priceData$ = this._priceData$.asObservable();

  /**
   * Open modal popup for selected product as `Target`
   * @defaultValue true
   */
  acceptedTargetTechnicalInfoShowModal = true;

  /**
   * Booking form
   */
  bookingForm = new FormGroup<BookingFormInputFields>({
    additionalUsers: new FormControl<number>(0, { nonNullable: true }),
    billingInterval: new FormControl<ProductDuration>(this.config.billingInterval, { nonNullable: true }),
    paymentMethod: new FormControl<string>(this.config.paymentMethod, { nonNullable: true }),
    selectedProduct: new FormControl<Product>(this.config.defaultPlan, { nonNullable: true }),
    isTermsAccepted: new FormControl<boolean>(false, { nonNullable: true }),
    userAmountInput: new FormControl<number>(0, { nonNullable: true })
  });

  constructor(
    private accountService: AccountService,
    private alert: AlertService,
    private modalService: ModalService,
    private navigationSidebarService: NavigationSidebarService,
    private paymentService: PaymentService,
    private router: Router,
    private userService: UserService,
    private utilService: UtilService
  ) {}

  /**
   * After chargify completion process
   * callback `/booking?billing_interval=***&customer_id=***&plan_name=***`
   * @param chargifySuccess - Object of ChargifySuccess
   */
  chargifyCompleted(chargifySuccess: ChargifySuccess): void {
    if (!chargifySuccess.customerId) {
      void this.alert.defaultErrorMessage(this.alert.translate('DATEN_NICHT_GELADEN'));
    }

    const paymentDetails: AddPaymentCredentials = {
      billingInterval: chargifySuccess.billingInterval,
      chargifyCustomerNumber: chargifySuccess.customerId,
      hasMultiSignature: chargifySuccess.planName === 'Business',
      product: chargifySuccess.planName
    };
    const paymentMethod = chargifySuccess.paymentMethod ? chargifySuccess.paymentMethod.toLowerCase() : 'card';
    this.utilService.loadingStart();
    this.userService
      .addPaymentCredentials(paymentDetails)
      .pipe(
        tap(() => this.utilService.loadingStop()),
        switchMap(() =>
          this.modalService.openBookingConfirmationModal(chargifySuccess.planName, paymentMethod === 'invoice')
        ),
        tap(res => {
          this.navigationSidebarService.refreshUserHasSubscription();
          const redirectTo = res ? '/booking/overview' : '/booking/invoices';
          void this.router.navigate([redirectTo]);
        })
      )
      .subscribe();
  }

  /**
   * Assignment chargify success params
   * @param chargifySuccess - Object of ChargifySuccess
   */
  completeBookingProcess(chargifySuccess: ChargifySuccess): void {
    this._chargifySuccess$.next(chargifySuccess);
  }

  /**
   * Patch input value of booking form and update price data accordingly
   * @param key - Field name
   * @param value - Field value
   * @param isCalcPrice - Boolean if need to update price data
   * @param productsData - Object of all product data
   * @param statData - Object of user stat data
   * @param isFromOninit - The check the value is changed or patch from page oninit
   */
  setFormValue(
    key: BookingFormPatchFields,
    value: string | number | boolean,
    isCalcPrice: boolean,
    productsData: ProductData,
    statData: GetOverallStatsResponse,
    isFromOninit = false
  ): void {
    const obj = { [key]: value };
    this.bookingForm.patchValue(obj);
    if (isCalcPrice) {
      this.setPriceData(this.calcPrice(this.bookingForm.value as BookingFormFields, productsData, statData));
    }
    // If user changes the plan then terms needs to reset
    if (key === 'selectedProduct') {
      this.setTermsAndConditionFormValues(false);
    }

    if (!isFromOninit) {
      this.bookingForm.markAsDirty();
    }
  }

  /**
   * Set the default plan from the query param to param selected plan
   * @param value - String value
   */
  setParamSelectedPlan(value: string): void {
    this._paramSelectedPlan$.next(value);
  }

  /**
   * Sync tearms and condition checkbox value
   * @param value - Boolean value
   */
  setTermsAndConditionFormValues(value: boolean): void {
    this.bookingForm.patchValue({
      isTermsAccepted: value
    });
    this.bookingForm.markAsDirty();
  }

  /**
   * Booking details slider
   * Calc price based on increment of additional employees
   * @param value - Slider value of additional employees
   * @param productsData - Object of all product data
   * @param statData - Object of user stat data
   */
  updateAdditionalUsersForCalcPrice(value: number, productsData: ProductData, statData: GetOverallStatsResponse): void {
    this.bookingForm.patchValue({
      additionalUsers: value
    });

    this.setPriceData(this.calcPrice(this.bookingForm.value as BookingFormFields, productsData, statData, true));
  }

  /**
   * Open browser tab for chargify booking process
   * @param totalUsers - Total number of employees to purchase
   * @param productsData - Object of all product data
   */
  showChargifyBookingProcess(totalUsers: number, productsData: ProductData): void {
    const bookingFormValue = this.bookingForm.value as BookingFormFields;
    let paymentMethod = 'automatic';
    if (bookingFormValue.paymentMethod === 'invoice') {
      paymentMethod = 'invoice';
    }

    let resultUrl =
      productsData[bookingFormValue.selectedProduct][bookingFormValue.billingInterval][paymentMethod as ProductLinkType]
        .signupPage;

    // Replace QUANTITY with actual value
    resultUrl = resultUrl.replace('$QUANTITY$', `${totalUsers}`);

    // Open new tab for chargifypay
    window.open(
      `${resultUrl}&first_name=${this.account$.firstName || ''}&last_name=${this.account$.lastName || ''}&email=${
        this.account$.email || ''
      }&organization=${this.account$.companyName || ''}`,
      '_self',
      'location=0,status=0,width=800,height=800'
    );
  }

  /**
   * Calculates the price based on selected plan and other inputs
   * @param bookingFormValue - Object of booking form fields
   * @param productsData - Object of all product data
   * @param statData - Object of user stat data
   * @param isForAllPlan - Boolean to consider selected plan
   * @returns Object of PriceData
   */
  private calcPrice(
    bookingFormValue: BookingFormFields,
    productsData: ProductData,
    statData: GetOverallStatsResponse,
    isForAllPlan = false
  ): PriceData {
    let priceData!: PriceData;
    this.bookingForm.patchValue({
      userAmountInput: this.getMinAmountWhichIsBookable(bookingFormValue.selectedProduct, productsData)
    });

    if (bookingFormValue.selectedProduct === 'Target' && this.acceptedTargetTechnicalInfoShowModal) {
      this.acceptedTargetTechnicalInfoShowModal = false;

      void this.alert.defaultConfirmPrompt(
        'html.booking_target_group_technical_info_modal_text',
        EBoxType.INFO,
        'booking_target_group_technical_info_modal_headline',
        'booking_target_group_technical_info_modal_btn',
        false
      );
    }

    priceData = {
      ...priceData,
      planMinAmount: bookingFormValue.userAmountInput,
      additionalUsers: bookingFormValue.additionalUsers,
      minAmountWhichIsBookable: Math.max(statData.amountOfUsers, 10),
      totalUsers:
        (!isForAllPlan && bookingFormValue.selectedProduct
          ? productsData[bookingFormValue.selectedProduct].yearly.minBookableAmount
          : 0) + Number(bookingFormValue.additionalUsers)
    };

    // Calculates the prices for a given billing interval and the given users
    const priceObject = this.paymentService.calcPrice(
      SERVICE_DATA.plans,
      bookingFormValue.billingInterval,
      priceData.totalUsers
    );

    // Set format for booking page
    if (isForAllPlan) {
      priceData = {
        ...priceData,
        advancedBig: priceObject.formatted.advanced.beforeComma,
        advancedSmall: priceObject.formatted.advanced.afterComma,
        basePrices: priceObject.basePrices,
        basicBig: priceObject.formatted.basic.beforeComma,
        basicSmall: priceObject.formatted.basic.afterComma,
        targetBig: priceObject.formatted.target.beforeComma,
        targetSmall: priceObject.formatted.target.afterComma
      };
    }

    // Calc price per user per product which is needed for total amount on second screen with shopping basket
    if (bookingFormValue.billingInterval === 'yearly') {
      // Multiplay with 12 because in the second screen the price is total and not per month like in the first screen
      // Round numbers again because were multiplied by 12
      priceObject.priceBasic = Number((Math.round(priceObject.priceBasic * 12 * 100) / 100).toFixed(2));
      priceObject.priceAdvanced = Number((Math.round(priceObject.priceAdvanced * 12 * 100) / 100).toFixed(2));
      priceObject.priceTarget = Number((Math.round(priceObject.priceTarget * 12 * 100) / 100).toFixed(2));
    }

    // Shopping basket
    if (!isForAllPlan && bookingFormValue.selectedProduct === 'Basic') {
      priceData.pricePerUser = priceObject.priceBasicPerUser;
      priceData.totalAmount = priceObject.priceBasic;
    } else if (!isForAllPlan && bookingFormValue.selectedProduct === 'Advanced') {
      priceData.pricePerUser = priceObject.priceAdvancedPerUser;
      priceData.totalAmount = priceObject.priceAdvanced;
    } else if (!isForAllPlan && bookingFormValue.selectedProduct === 'Target') {
      priceData.pricePerUser = priceObject.priceTargetPerUser;
      priceData.totalAmount = priceObject.priceTarget;
    }

    priceData.priceBasicPerUser = priceObject.priceBasicPerUser;
    priceData.priceAdvancedPerUser = priceObject.priceAdvancedPerUser;
    priceData.priceTargetPerUser = priceObject.priceTargetPerUser;

    // Update payment method if previous selected invoice and then decrease additional users
    if (bookingFormValue.paymentMethod === 'invoice' && priceData.totalAmount < this.config.minAmountForInvoice) {
      this.bookingForm.patchValue({
        paymentMethod: 'creditcard'
      });
    }

    return priceData;
  }

  /**
   * Find and get minimum bookable amount from product data
   * @param selectedProduct - Selected product
   * @param productsData - Object of all product data
   * @returns Minimum bookable amount
   */
  private getMinAmountWhichIsBookable(selectedProduct: Product, productsData: ProductData): number {
    return productsData[selectedProduct].yearly.minBookableAmount;
  }

  /**
   * Set the value for price data
   * @param value - Object of price data
   */
  private setPriceData(value: PriceData): void {
    this._priceData$.next(value);
  }
}
