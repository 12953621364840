import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild, inject, signal } from '@angular/core';
import { CropperSettings, ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { ImageCropperModule } from 'ngx-image-cropper';
import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle
} from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ImageCropper } from '@model/interfaces/user.interface';

type CustomCropperSettings = Pick<
  CropperSettings,
  | 'aspectRatio'
  | 'cropperMaxHeight'
  | 'cropperMaxWidth'
  | 'cropperMinHeight'
  | 'cropperMinWidth'
  | 'resizeToHeight'
  | 'resizeToWidth'
  | 'roundCropper'
> & {
  croppedImageURL: string;
  cropperRange: CropperRange;
  displayImage?: HTMLImageElement;
  imageURL: string;
};

type CropperRange = {
  max: number;
  min: number;
};

const CORPPER_RANGE: CropperRange = {
  max: 300,
  min: 80
};

const CROPER_MANUAL_SIZE = 200;

@Component({
  selector: 'mt-image-cropper-v2',
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    FormsModule,
    ImageCropperModule,
    NgbDropdown,
    NgbDropdownItem,
    NgbDropdownMenu,
    NgbDropdownToggle,
    NgIf,
    ReactiveFormsModule,
    TranslocoModule
  ],
  templateUrl: './image-cropper-v2.component.html',
  styleUrl: './image-cropper-v2.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperV2Component {
  private fb = inject(NonNullableFormBuilder);

  public cdr = inject(ChangeDetectorRef);

  public modal = inject(NgbActiveModal);

  /**
   * Image setting form
   */
  imageForm = this.fb.group({
    cropManualSize: CROPER_MANUAL_SIZE,
    rangeValue: 50
  });

  /**
   * Holds cropper settings
   */
  cropperSettings = signal<CustomCropperSettings>(this.getInitialCropperSettings(CROPER_MANUAL_SIZE));

  /**
   * Set html element of image & update the image data in the cropper settings
   * @param image - Html element of image to be cropped
   */
  @Input() set displayImage(image: HTMLImageElement) {
    this.cropperSettings.update(setting => ({
      ...setting,
      displayImage: image,
      imageURL: image.getAttribute('src') || ''
    }));
  }

  /**
   * Cropper component
   */
  @ViewChild('cropper') cropper!: ImageCropperComponent;

  /**
   * Triggers on image being cropped & update the cropped image in cropper settings
   * @param event - Cropped image event
   */
  imageCropped(event: ImageCroppedEvent): void {
    if (event.base64) {
      this.cropperSettings.update(setting => ({ ...setting, croppedImageURL: event.base64 as string }));
    }
  }

  /**
   * Triggers when image loaded successfully & update the cropper settings based on the image
   */
  imageLoaded(): void {
    this.cropperSettings.update(setting => {
      const maxRange = Math.min(setting.displayImage?.height || 0, setting.displayImage?.width || 0);

      const cropSize = this.imageForm.controls.cropManualSize.value;

      const range = {
        max: maxRange,
        min: setting.cropperRange.min
      };
      this.imageForm.controls.rangeValue.setValue(this.getRangeValue(maxRange, range));

      return {
        ...setting,
        resizeToWidth: cropSize,
        resizeToHeight: cropSize,
        cropperMaxWidth: maxRange,
        cropperMaxHeight: maxRange,
        cropperRange: range
      };
    });
  }

  /**
   * Crops and saves the image
   * @param useCrop - should crop
   * @param settings - Cropper settings
   */
  cropAndSave(useCrop: boolean, settings: CustomCropperSettings): void {
    const cropImageData: ImageCropper = {
      value: {
        cropType: useCrop ? (settings.roundCropper ? 'circle' : 'square') : undefined,
        isCropped: useCrop,
        image: {
          $ngfDataUrl: useCrop ? settings.croppedImageURL : settings.imageURL
        }
      },
      imgdimension: useCrop
        ? {
            width: settings.resizeToWidth,
            height: settings.resizeToHeight,
            mode: 'custom'
          }
        : undefined
    };
    this.modal.close(cropImageData);
  }

  /**
   * Sets the cropper shape either square or circle & update the cricle crop flag in the cropper settings
   * @param type - Crop type
   */
  cropperShape(type: 'square' | 'circle'): void {
    this.cropperSettings.update(setting => ({
      ...setting,
      roundCropper: type === 'circle'
    }));
  }

  /**
   * Chage the manual size based on the size cropped image will be resized & update the resize size in the cropper settings
   * @param event - Size change event
   */
  onChangeManualSize(event: Event): void {
    const size = Number((event.target as HTMLInputElement).value);

    this.cropperSettings.update(setting => ({
      ...setting,
      resizeToWidth: size,
      resizeToHeight: size
    }));
    setTimeout(() => {
      this.cropper.resetCropperPosition();
    });
  }

  /**
   * Rescales the cropper window depending on the range percentage & update the cropper size & aspect ratio in the cropper settings
   * @param event - Input range event
   * @param cropperRange - Cropper range
   */
  onChangeRange(event: Event, cropperRange: CropperRange): void {
    const range = Number((event.target as HTMLInputElement).value);
    const cropperSize = this.getCropperSize(range, cropperRange);

    this.cropperSettings.update(setting => ({
      ...setting,
      cropperMaxWidth: cropperSize,
      cropperMaxHeight: cropperSize,
      aspectRatio: 0.99
    }));
    // Need to reassign the aspect ratio value after setting max cropper size
    // Because if we do not reassign then Clipping slider functionality will not work
    // TODO: find a better solution.
    setTimeout(() => {
      this.cropperSettings.update(setting => ({
        ...setting,
        aspectRatio: 1
      }));
    });
  }

  /**
   * Gets the cropper size based on the selected range & max/min size of the cropper
   * @param rangeValue - Range value
   * @param cropperRange - Cropper range
   * @returns Cropper size
   */
  private getCropperSize(rangeValue: number, cropperRange: CropperRange): number {
    return Math.floor((rangeValue / 100) * (cropperRange.max - cropperRange.min) + cropperRange.min);
  }

  /**
   * Gets the initial cropper settings
   * @param cropManualSize - Cropped image manual size
   * @returns Cropper settings
   */
  private getInitialCropperSettings(cropManualSize: number): CustomCropperSettings {
    const initialSettings: CustomCropperSettings = {
      cropperMinWidth: CORPPER_RANGE.min,
      cropperMinHeight: CORPPER_RANGE.min,
      aspectRatio: 1,
      cropperRange: {
        max: CORPPER_RANGE.max,
        min: CORPPER_RANGE.min
      },
      cropperMaxHeight: CORPPER_RANGE.max,
      cropperMaxWidth: CORPPER_RANGE.max,
      resizeToHeight: cropManualSize,
      resizeToWidth: cropManualSize,
      roundCropper: false,
      croppedImageURL: '',
      imageURL: ''
    };
    return initialSettings;
  }

  /**
   * Gets the percentage from the cropper window based on image size & cropper range
   * @param size - Image's height|width
   * @param cropperRange - Cropper range
   * @returns Range
   */
  private getRangeValue(size: number, cropperRange: CropperRange): number {
    return Math.floor((100 * (size - cropperRange.min)) / (cropperRange.max - cropperRange.min));
  }
}
