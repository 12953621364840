<ng-container *transloco="let translate">
  <mt-custom-modal headline="{{translate(add_edit_dictionary_term_title)}}">

    <ng-container modal-body>
      <div class="srcoll-div">
        <div class="srcoll-area">
          @if(dictionaryDataForm) {
            <form
              [formGroup]="dictionaryDataForm"
              class="form-horizontal"
            >
              <div class="row-class">
                <div class="row-data">
                  <div class="col-sm-3 control-label-account format3">
                    <label>{{ translate('german') }}*</label>
                  </div>
                  <div class="col-sm-10 col-lg-8 formcontrolright">
                    <input
                      type="text"
                      formControlName="DE"
                      class="form-control input-sm"
                    />
                  </div>
                </div>
                <div class="col-sm-3 control-label-account format3"></div>
                @if(dictionaryDataForm.controls['DE'].dirty && dictionaryDataForm.controls['DE'].errors) {
                  <div
                    class="col-sm-10 col-lg-8 text-danger"
                  >
                    <strong>{{ translate('required_field') }} </strong>
                  </div>
                }
              </div>
              <div class="row-class">
                <div class="row-data">
                  <div class="col-sm-3 control-label-account format3">
                    <label>{{ translate('english') }}*</label>
                  </div>
                  <div class="col-sm-10 col-lg-8 formcontrolright">
                    <input
                      type="text"
                      formControlName="EN"
                      class="form-control input-sm"
                    />
                  </div>
                </div>
                <div class="col-sm-3 control-label-account format3"></div>
                @if(dictionaryDataForm.controls['EN'].dirty && dictionaryDataForm.controls['EN'].errors) {
                  <div
                    class="col-sm-10 col-lg-8 text-danger"
                  >
                    <strong>{{ translate('required_field') }} </strong>
                  </div>
                }
              </div>
              <div class="row-class">
                <div class="col-sm-3 control-label-account format3">
                  <label>{{ translate('match_case') }}*</label>
                </div>
                <div class="col-sm-10 col-lg-8 formcontrolright">
                  <div class="checkbox-margin">
                    <mt-checkbox [checked]="dictionaryDataForm.get('matchCase')?.value === true" (click)="dictionaryDataForm.get('matchCase')?.setValue(!dictionaryDataForm.get('matchCase')?.value)"></mt-checkbox>
                  </div>
                </div>
              </div>
              <div class="row-class">
                <div class="col-sm-3 control-label-account format3">
                  <label>{{ translate('match_type') }}*</label>
                </div>
                <div class="col-sm-10 col-lg-8 formcontrolright">
                  <div class="title overflowxhidden name">
                    <mt-button
                      [text]="translate(dictionaryDataForm.get('matchType')?.value || 'matchWholeTerm')"
                      class="roboto_light"
                      [dropdown]="true"
                      ngbDropdownToggle
                      id="dropdownMatchTypes"
                    ></mt-button>
                  <ul ngbDropdownMenu aria-labelledby="dropdownMatchTypes">
                    <button ngbDropdownItem (click)="dictionaryDataForm.get('matchType')?.setValue('matchWholeTerm')">
                      {{ translate('matchWholeTerm') }}
                    </button>
      
                    <button ngbDropdownItem (click)="dictionaryDataForm.get('matchType')?.setValue('streetAddress')">
                      {{ translate('streetAddress') }}
                    </button>
                  </ul>
                  </div>
                </div>
              </div>
            </form>
          }
        </div>
      </div>
    </ng-container>

    <div modal-footer>
      <div class="flex-center-right">
        <mt-button
          text="abort"
          color="default"
          class="mt_left"
          (click)="modal.close(false)"
        ></mt-button>
        <mt-button
          data-cy="save-employee-info-btn"
          text="save_and_exit"
          class="roboto_light"
          color="primary"
          [disabled]="dictionaryDataForm.invalid"
          type="submit"
          (click)="modal.close(this.dictionaryDataForm.getRawValue())"
        ></mt-button>
      </div>
    </div>
  </mt-custom-modal>
</ng-container>