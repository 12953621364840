import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbDropdown, NgbDropdownToggle, NgbDropdownMenu } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';

/**
 * Dropdown displayed when multiple list items are selected
 */
@Component({
  selector: 'mt-select-all-dropdown',
  templateUrl: './select-all-dropdown.component.html',
  styleUrls: ['./select-all-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, ButtonComponent]
})
export class SelectAllDropdownComponent {}
