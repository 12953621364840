import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BookingContainer, Product } from 'src/app/model/interfaces/payment.interface';
import { GetOverallStatsResponse } from 'src/app/services/user/user-service.interface';

@Component({
  selector: 'mt-feature-general',
  templateUrl: './feature-general.component.html',
  styleUrls: ['./feature-general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeatureGeneralComponent {
  /**
   * Input of user stat data
   */
  @Input() statData!: GetOverallStatsResponse;

  /**
   * Input of feature list object
   */
  @Input() bookingContainer!: BookingContainer;

  /**
   * Hold product name for subscribed user
   */
  @Input() currentProduct!: Product;

  /**
   * Hold the status of has subscription plan or free trial
   */
  @Input() hasSubscription = false;
}
