import { DefaultSignatureType } from '@model/interfaces/signature.interface';

/**
 * Returns a query string containing pagination and filtering options
 * @param queryParams - Options for server side pagination and filtering
 * @returns Query string with pagination and filtering options
 */
export const getQueryStringPaginationFilter = (queryParams: { page: number; pageSize: number; search?: string }) =>
  `page=${queryParams.page}&pagesize=${queryParams.pageSize}` +
  (queryParams.search?.length ? `&search=${queryParams.search}` : '');

/**
 * Returns a query string used to filter the type of default signature
 * @param defaultSignatureType - Type of default signature
 * @returns Query string with type of default signature
 */
export const getQueryStringSignatureType = (defaultSignatureType: DefaultSignatureType) =>
  `defaultSignatureType=${defaultSignatureType}`;
