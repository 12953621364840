import { Component } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { NgbActiveModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { zip } from 'rxjs';

import { AlertService } from 'src/app/services/alert/alert.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { QueryHelperService } from 'src/app/services/query-helper/query-helper.service';

// Interfacews
import { IEmployeeGet } from 'src/app/services/employee/employee-service.interface';

// Components
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { CustomModalComponent } from '@shared/components/molecules/modals/custom/custom-modal.component';
import { EmployeeColComponent } from '@shared/components/organisms/cols/employee-col/employee-col.component';
import { EmptyStateComponent } from '@shared/components/organisms/empty-states/empty-state.component';
import { GroupColComponent } from '@shared/components/organisms/cols/group-col/group-col.component';
import { ListActionsComponent } from '@shared/components/atoms/list-actions/list-actions.component';
import { ListComponent } from '@shared/components/atoms/list/list.component';
import { MtSearchComponent } from '@shared/components/atoms/inputs/search/search.component';
import { RowComponent } from '@shared/components/atoms/row/row.component';
import { SelectAllButtonComponent } from '@shared/components/molecules/buttons/select-all-button/select-all-button.component';
import { SelectAllDropdownComponent } from '@shared/components/molecules/buttons/select-all-dropdown/select-all-dropdown.component';
import { SelectColComponent } from '@shared/components/organisms/cols/select-col/select-col.component';
import { SortDropdownComponent } from '@shared/components/molecules/buttons/sort-dropdown/sort-dropdown.component';

@Component({
  selector: 'mt-add-employee-to-group',
  templateUrl: './add-employee-to-group.component.html',
  styleUrls: ['./add-employee-to-group.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    CustomModalComponent,
    EmployeeColComponent,
    EmptyStateComponent,
    GroupColComponent,
    InfiniteScrollModule,
    ListComponent,
    ListActionsComponent,
    SortDropdownComponent,
    MtSearchComponent,
    NgbDropdownModule,
    NgFor,
    NgIf,
    RowComponent,
    SelectAllButtonComponent,
    SelectAllDropdownComponent,
    SelectColComponent,
    TranslocoModule
  ],
  providers: [ListHelperService, QueryHelperService]
})
export class AddEmployeeToGroupComponent {
  assignedEmployees: IEmployeeGet[] = [];

  sortDir = false;

  isLoading = false; // Loading spinner shown when loading more data or filtering

  infinitescrolloptions = {
    disable: true,
    scrollAction: (): void => {
      this.infinitescrolloptions.disable = true;

      // Load more group members until all are loaded
      if (this.list.all.length < this.list.denominator - this.assignedEmployees.length) {
        this.loadMoreData();
      }
    }
  };

  loadData$ = this.queryHelperService.queryObject$.pipe(
    tap(() => (this.isLoading = true)),
    switchMap(queryObj => zip(this.employeeService.get(queryObj), this.employeeService.count(queryObj))),
    map(([employees, count]) => {
      // Filter out employees already assigned
      const filteredEmployees = employees.filter(
        employee => !this.assignedEmployees.some(assigned => assigned.id === employee.id)
      );
      return { employees: filteredEmployees, count };
    }),
    tap(({ employees, count }) => {
      this.list.all = this.list.all.concat(employees);
      this.list.denominator = count;
      this.list.setFiltered();
      this.list.createFraction(this.list.all.length);
      this.infinitescrolloptions.disable = false;
      this.isLoading = false;
    }),
    catchError((err: HttpErrorResponse) => {
      void this.alert.defaultErrorMessage(err.message);
      throw err;
    })
  );

  // Pipes the result from the search bar
  // and sets the search accordingly
  searchData$ = this.list.filterText$.pipe(
    tap(text => {
      this.queryHelperService.resetPagination();
      this.queryHelperService.setSearch(text);
      this.loadFirstPageData();
    }),
    shareReplay(1)
  );

  constructor(
    private alert: AlertService,
    private employeeService: EmployeeService,
    private queryHelperService: QueryHelperService,
    public list: ListHelperService<IEmployeeGet>,
    public modal: NgbActiveModal
  ) {}

  /**
   * Triggers more data to be loaded
   */
  loadMoreData(): void {
    this.queryHelperService.getNextPage();
    this.queryHelperService.setQueryObservable(this.queryHelperService.getObject());
  }

  /**
   * Refreshes the list and loads first page with the applied query changes
   */
  loadFirstPageData(): void {
    this.list.resetData();
    this.queryHelperService.setQueryObservable(this.queryHelperService.getObject());
  }

  /**
   * Closes the modal with the list of selected employees
   */
  assignEmployees(): void {
    this.modal.close(this.list.selected);
  }

  /**
   * Sorts the list of users based on the selected parameter
   * @param mode - Parameter to sort by
   */
  sortList(mode: string): void {
    this.sortDir = !this.sortDir;
    this.queryHelperService.setQuerySort(mode, this.sortDir);
    this.loadFirstPageData();
  }
}
