import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs';

import { ICampaignGet } from 'src/app/services/campaign/campaign-services.interface';
import { ListHelperService } from 'src/app/services/list-helper/list-helper.service';
import { NotificationsCampaignsModalService } from '../../../services/notifications-campaigns.service';

@Component({
  selector: 'mt-select-notifications-campaign',
  templateUrl: './select-notifications-campaign.component.html',
  styleUrls: ['./select-notifications-campaign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ListHelperService]
})
export class SelectNotificationsCampaignComponent {
  /**
   * Already selected campaigns list
   * May be empty for the first time
   */
  selectedCampaign?: ICampaignGet[];

  /**
   * Loads the list of campaign
   */
  loadCampaigns$ = this.notificationsCampaignsModalService.campaignList$.pipe(
    tap(campaigns => {
      this.list.all = campaigns;
      const campaignIds = this.selectedCampaign?.map(campaign => campaign.id);
      this.list.all = this.list.all.filter(campaign => !campaignIds?.includes(campaign.id));
      this.list.setFiltered();
    })
  );

  constructor(
    public list: ListHelperService<ICampaignGet>,
    public modal: NgbActiveModal,
    public notificationsCampaignsModalService: NotificationsCampaignsModalService
  ) {}

  /**
   * Closes the modal and assigns the selected campaigns
   * @param selectedList - Selected campaigns
   */
  assignCampaign(selectedList: ICampaignGet[]): void {
    this.modal.close(selectedList);
  }
}
