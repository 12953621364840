import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AlertService } from '@services/alert/alert.service';
import { AuthClaims, AuthService } from '@services/auth/auth.service';
import { UserService } from '@services/user/user.service';
import { Observable, filter, map, zip } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {
  private alert = inject(AlertService);
  private authService = inject(AuthService);
  private translate = inject(TranslocoService);
  private userService = inject(UserService);

  /**
   * Method is used to validate claim
   * @param claim - Claim
   * @returns - Status of validated claim
   */
  validateClaim(claim: AuthClaims): Observable<boolean> {
    const defaultAdmin$ = this.userService.getAdmins().pipe(
      map(admins => admins.find(admin => admin.defaultAdmin === 1) || admins[0]),
      map(admin => `${admin.firstname} ${admin.lastname}`)
    );

    return zip([defaultAdmin$, this.authService.hasClaim(claim)]).pipe(
      map(([adminName, hasClaim]) => {
        if (!hasClaim) {
          void this.alert.defaultErrorMessage(
            this.translate.translate('param.disabled_button_hover', { admin_name: adminName }),
            this.translate.translate('disabled_button_modal_title')
          );
        }
        return hasClaim;
      }),
      filter(hasClaim => !!hasClaim)
    );
  }
}
