<div class="martop35" *transloco="let translate">
  <div class="bigprice flex">
    <div class="number" [attr.data-cy]="productName + '-big-price'">
      {{ priceBig }}
    </div>
    <div class="unit" [attr.data-cy]="productName + '-small-price'">
      ,{{ priceSmall | currency : 'EUR' : 'symbol' : '2.0-0' : 'de-DE' }}
    </div>
    <div class="fontsize20">*</div>
  </div>
  <div class="flex-center-all priceinfotext includedusers martop25">
    <span class="begin">{{ translate('per_month_for') }}</span>
    <input
      [value]="totalUsers"
      readonly="true"
      type="text"
      class="form-control numberofempsinput"
      [attr.data-cy]="productName + '-total-users'"
    />
    <span class="end">{{ translate('Users') }}</span>
  </div>
  <div class="hint">
    <span
      [attr.data-cy]="productName + '-per-users'"
      [innerHtml]="
        translate('param.AdjustProfessionalFlexible', {
          amount: (costPerUser | currency : 'EUR' : '' : '1.2-2' : 'de-DE')
        })
      "
    ></span>
    <span>
      / {{ translate('accounting') }} {{ translate(billingInterval) }}
    </span>
  </div>
</div>
