<span
  *transloco="let translate"
  class="info-icon baseline-top"
  tooltipClass="{{ tooltipClass }} status-info-tooltip"
  tooltipPlacement="top"
  [ngbTooltip]="tooltip ? htmlContent : ''"
>
  <mt-svg
    [filter]="filter"
    src="assets/images/svgs/{{ icon }}.svg"
    [size]="size"
  ></mt-svg>

  <ng-template #htmlContent>
    <span [innerHTML]="translate(tooltip)"> </span>
  </ng-template>
</span>
