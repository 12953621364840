import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

/**
 * Renders an item in a row of the list
 *
 */
@Component({
  selector: 'mt-row',
  templateUrl: './row.component.html',
  styleUrls: ['./row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass]
})
export class RowComponent {
  /**
   * Applies **selected** class to the row when the item is selected
   * @defaultValue false
   */
  @Input() isSelected = false;

  /**
   * Applies either **odd** or **even**, depending on the item's index
   * @defaultValue false
   */
  @Input() isOdd = false;

  /**
   * Applies either **odd** or **even**, depending on the item's index
   * @defaultValue false
   */
  @Input() isEven = false;

  /**
   * Applies **redrow** class to the row based on a provided condition
   * @defaultValue false
   */
  @Input() isRedRow = false;

  /**
   * Applies **timingerror** class to the row based on a provided condition
   * @defaultValue false
   */
  @Input() rowError = false;
}
