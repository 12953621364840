import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MtCheckboxComponent } from '@atoms/inputs/checkbox/checkbox.component';

@Component({
  selector: 'mt-select-all-button',
  templateUrl: './select-all-button.component.html',
  styleUrls: ['./select-all-button.component.scss'],
  standalone: true,
  imports: [MtCheckboxComponent, TranslocoModule]
})
export class SelectAllButtonComponent {
  _disabled = false;
  _color = 'mt-button-primary';

  /**
   * The class applied directly onto the button.
   */
  @Input() klass!: string;

  /**
   * Background color of the button.
   */
  @Input()
  set color(color: string) {
    if (color !== '') {
      this._color = `mt-button-${color}`;
    }
  }
  get color(): string {
    return this._color;
  }

  /**
   * Disables the button.
   * @defaultValue false
   */
  @Input()
  set disabled(value: unknown) {
    this._disabled = Boolean(value);
  }
  get disabled(): unknown {
    return this._disabled;
  }

  @Input() checked = false;
}
