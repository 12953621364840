import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, forwardRef, Renderer2 } from '@angular/core';

// Rxjs
import { Subject } from 'rxjs';

// Interfaces
import { InstallationStep } from 'src/app/model/interfaces/integrations.interface';
import { IntegrationsSignature, SignaturePreviewType } from 'src/app/model/interfaces/signature.interface';

// Services
import { AlertService } from 'src/app/services/alert/alert.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';
import { GeneratorStoreService } from 'src/app/services/generator-store/generator-store.service';
import { EmployeeDetails } from 'src/app/services/employee/employee-service.interface';

// Others
import { MACDATA } from 'src/app/shared/components/organisms/installation/model/installation-guide-modal.const';
import { environment } from '@environments/environment';
import { GetDownloadLinkPipe } from '@organisms/installation/pipes/get-download-link.pipe';
import { SignatureRolloutPreviewComponent } from '@organisms/cogsig-signatures/signature-rollout-preview/signature-rollout-preview.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule } from '@angular/forms';
import { NgFor, NgClass, NgIf, AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { PatternService } from '@shared/services/pattern.service';

@Component({
  selector: 'mt-installation-guide-steps',
  templateUrl: './installation-guide-steps.component.html',
  styleUrls: ['./installation-guide-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgClass,
    NgIf,
    FormsModule,
    TranslocoModule,
    SignatureRolloutPreviewComponent,
    forwardRef(() => InstallationGuideStepsComponent),
    AsyncPipe,
    GetDownloadLinkPipe,
    ButtonComponent
  ]
})
export class InstallationGuideStepsComponent {
  /**
   * The trigger of environment
   */
  isCogsig = environment.isCogSig;

  /**
   * Hold signature object
   */
  private _signature!: IntegrationsSignature;

  /**
   * The element reference of dropdown option to scroll at top
   */
  @ViewChild('optionList') private optionList!: ElementRef;

  /**
   * Installation data including the steps in guide modal
   */
  @Input() installationData!: InstallationStep[];

  /**
   * The user details
   */
  @Input() user!: EmployeeDetails;

  @Input() set signature(val: IntegrationsSignature) {
    val.signatureHtml = val.signatureHtml
      .replaceAll('?m=o', '')
      .replaceAll('&m=o', '')
      .replaceAll('?track=n', '')
      .replaceAll('&track=n', '')
      .replaceAll('moz-do-not-send="true"', '');
    this._signature = val;
  }
  get signature(): IntegrationsSignature {
    return this._signature;
  }

  macData = MACDATA;
  showSelectedMacOption$ = new Subject<string>();
  copiedSignatureHTML$ = this.generatorStoreService.signatureHTML$;

  constructor(
    public alert: AlertService,
    private employeeService: EmployeeService,
    private generatorStoreService: GeneratorStoreService,
    private pattern: PatternService
  ) {}

  /**
   * Selecting desired mac type for installation
   * @param event - Event that occurs when user selects mac type from dropdown list
   */
  changeMacType(event: Event): void {
    this.showSelectedMacOption$.next((event.target as HTMLInputElement).value);
    setTimeout(() => this.optionList.nativeElement.scrollIntoView());
  }

  /**
   * Copies the either the render HTML or HTML signature to the clipboard
   * @param signature - The signature object containing the HTML to be copied.
   * @param mimeType - The MIME type of the content to copy ('text/plain' or 'text/html').
   */
  copySignature(signature: IntegrationsSignature, mimeType: 'text/plain' | 'text/html'): void {
    const listener = (e: ClipboardEvent) => {
      e.preventDefault();

      if (e.clipboardData) {
        e.clipboardData.setData(
          mimeType,
          signature.signatureHtml.replace(this.pattern.sanitizeSignatureHTMLPattern, '')
        );
      }
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);

    void this.alert.defaultSuccessMessage(this.alert.translate('CONTENT_MOVED_CLIPBOARD_SUCCESS'));
  }

  /**
   * Send the signature to user in email
   * @param signatureTypeToSend - Signature preview type to send in email
   */
  sendSignatureInEmail(signatureTypeToSend: 'send_plain_text' | 'mobile_iphone_btn'): void {
    let txt = 'html.html_send_to_employee';
    let hl = 'html_send_to_employee_hl';
    let signaturePreviewType = 'html' as SignaturePreviewType;
    let signature = this.signature.signatureHtml;

    if (signatureTypeToSend === 'send_plain_text') {
      txt = 'html.plaintext_send_to_employee';
      hl = 'plaintext_send_to_employee_hl';
      signaturePreviewType = 'plain';
      signature = this.signature.signatureTxt;

      const regex = /(<([^>]+)>)/gi;
      signature = signature
        .replace(regex, '')
        .split('\n')
        .join('<br />')
        .split('  ')
        .join('&nbsp;&nbsp;')
        .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;');
    }

    signature = signature
      .replaceAll('?track=n', '')
      .replaceAll('&track=n', '')
      .replace(/\b(?:'width="700" height="210"'|'?m=o'|'&m=o'|'moz-do-not-send="true"')\b/gi, '');

    this.employeeService.sendSignaturToUser(this.user.id, signature, signaturePreviewType).subscribe(() => {
      void this.alert.defaultInfoMessage(this.alert.translate(txt), void this.alert.translate(hl));
    });
  }
}
