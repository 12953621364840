<div class="statuscol">
  <div class="title" *ngIf="!gated">
    <span class="innertitle" style="overflow: hidden">
      <mt-svg
        class="medium-svg calicon"
        [ngClass]="iconColor"
        src="assets/images/siteelements/campaign_list_svg.svg"
      ></mt-svg>
      <span
        [routerLink]="['/campaigns/details/', triggerEvent.campaignId]"
        (click)="redirectToCampaignDetail.emit()"
        tooltipClass="status-info-tooltip"
        tooltipPlacement="top"
        ngbTooltip="{{ triggerEvent.title }}"
        class="link"
      >
        {{ triggerEvent.title | textTruncate : 20 }}</span
      >
    </span>
    <span
      *ngIf="!hideLabel"
      class="status_badge event"
      ngbTooltip="{{ 'event_tag_info' | transloco }}"
      placement="top"
      tooltipClass="status-info-tooltip"
    >
      <mt-svg
        class="tiny-svg event_icon_img space-right"
        src="assets/images/svgs/calendar.svg"
        filter="--filter-white"
      ></mt-svg>
      <span transloco="event"></span>
    </span>
  </div>
  <div *ngIf="gated" class="title schrift_rot italic mt-opacity-50">
    <span
      [disableTooltip]="gated"
      ngbTooltip="{{ 'UnassignedCampaign' | transloco }}"
      placement="top"
      tooltipClass="status-info-tooltip"
    >
      <span transloco="Unassigned"></span>
    </span>
  </div>
  <div class="subline">
    <span transloco="Campaign"></span>
  </div>
</div>
