import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

// Rxjs
import { Observable, tap, map } from 'rxjs';

// Interfaces
import { CockpitIntegrationTypes } from 'src/app/model/interfaces/integrations.interface';
import { IntegrationsSignature } from 'src/app/model/interfaces/signature.interface';
import { IUserSmall } from 'src/app/model/interfaces/user.interface';

// Services
import { AlertService } from 'src/app/services/alert/alert.service';
import { InstallationService } from '@services/installation/installation.service';

// Others
import { INSTALLATION } from '@organisms/installation/model/installation-modal.const';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgClass, NgFor, AsyncPipe } from '@angular/common';
import { WizardTabComponent } from '@organisms/wizard/wizard-tab/wizard-tab.component';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { EmployeeDetails } from '@services/employee/employee-service.interface';

@Component({
  selector: 'mt-installation-checklist-with-selected-list',
  templateUrl: './installation-checklist-with-selected-list.component.html',
  styleUrls: ['./installation-checklist-with-selected-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    ButtonComponent,
    FormsModule,
    NgClass,
    NgFor,
    NgIf,
    ReactiveFormsModule,
    TranslocoModule,
    WizardTabComponent
  ]
})
export class InstallationChecklistWithSelectedListComponent implements OnInit {
  integrations = INSTALLATION;

  /**
   * Holds the admin details of user
   */
  @Input() adminData!: IUserSmall;

  /**
   * Holds the user data
   */
  @Input() user!: EmployeeDetails;

  /**
   * Holds the user activation code
   */
  @Input() activationCode!: string;

  /**
   * Holds the signature list
   * @defaultValue []
   */
  @Input() signatures: IntegrationsSignature[] = [];

  /**
   * Triggers the set up cheks before installation
   */
  @Input() checkSetup!: boolean;

  /**
   * Triggers the check of enduser cockpit rebrand or not
   * @defaultValue false
   */
  @Input() isEcRebrand = false;

  /**
   * Holds the selected mail client list
   */
  selectedMailClientList$ = this.installationService.integrationList$;

  /**
   * Holds the status of selected list section to display or not
   */
  isSelectedListShown$ = this.installationService.isSelectedListStepShown$;

  integrationTypeForm: FormGroup = this.fb.group({
    integration_android_4_1_title: new FormControl<boolean>(false, { nonNullable: true }),
    integration_apple_mail_3_2_title: new FormControl<boolean>(false, { nonNullable: true }),
    integration_gmail_2_1_title: new FormControl<boolean>(false, { nonNullable: true }),
    integration_gmail_2_3_title: new FormControl<boolean>(false, { nonNullable: true }),
    integration_gmail_2_4_title: new FormControl<boolean>(false, { nonNullable: true }),
    integration_other_5_1_title: new FormControl<boolean>(false, { nonNullable: true }),
    integration_outlook_1_3_title: new FormControl<boolean>(false, { nonNullable: true }),
    integration_outlook_1_4_title: new FormControl<boolean>(false, { nonNullable: true }),
    integration_outlook_1_5_title: new FormControl<boolean>(false, { nonNullable: true }),
    mail_easysync_header_rec: new FormControl<boolean>(false, { nonNullable: true }),
    outlook_mac_easysync_header_rec: new FormControl<boolean>(false, { nonNullable: true }),
    outlook_windows_easysync_header_rec: new FormControl<boolean>(false, { nonNullable: true })
  });

  checkList$: Observable<CockpitIntegrationTypes[]> = this.integrationTypeForm.valueChanges.pipe(
    map((data: Record<string, boolean>) => {
      this.installationService.openSelectionStep(Object.values(data).some(val => val === true));
      const selectedIntegrations = this.integrations.list.map(parent => {
        parent.parts.map(child => {
          data[child.title] ? (child.checked = true) : (child.checked = false);
        });
        return parent;
      });
      return selectedIntegrations;
    }),
    tap(list => this.installationService.updateFilteredIntegrationList(list))
  );

  constructor(
    private fb: NonNullableFormBuilder,
    public alert: AlertService,
    public installationService: InstallationService
  ) {}

  ngOnInit(): void {
    if (this.checkSetup) {
      this.integrations.steps[0].stepNo = 2;
      this.integrations.steps[0].open = false;
      this.integrations.steps[1].stepNo = 3;
    } else {
      this.integrations.steps[0].stepNo = 1;
      this.integrations.steps[0].open = true;
      this.integrations.steps[1].stepNo = 2;
    }
  }
}
