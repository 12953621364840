<button
  type="button"
  class="mt_button {{ klass }} {{ color }} {{ dropdown ? 'mt_dropdown' : '' }}"
  [disabled]="disabled"
>
  <mt-icons
    *ngIf="icon"
    [name]="icon"
    [ngStyle]="{
      'margin-right':
        text !== undefined || hltitle !== undefined ? '4px' : '0px'
    }"
  ></mt-icons>
  <span *ngIf="hltitle">{{ hltitle | transloco }}</span>
  <span *ngIf="text">{{ text | transloco }}</span>
  <span *ngIf="dropdown" style="margin-left: 4px" class="caret"></span>
</button>
